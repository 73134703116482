import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../../../axios';

import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';

import { Growl } from 'primereact/growl';

import { Card } from '@jsluna/card';
import { SelectField } from '@jsluna/form';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { IconButton } from '@jsluna/button';
import { ProgressBar, ProgressIndicator } from '@jsluna/progress';

import { Download } from '@jsluna/icons';

import ConfirmationDialog from '../../UI/ConfirmationDialog/ConfirmationDialog';
import Forecast from './Forecast/Forecast';
import Header from './Header/Header';
import PlanConfig from '../PlanConfig/PlanConfig';
import PublishedModal from '../../Plans/PublishedModal/PublishedModal';
import TopBar from './TopBar/TopBar';
import Trunks from './Trunks/Trunks';
import Warning from '../../UI/Icons/Warning';

import { Chart } from 'primereact/chart';

import { Toolbar } from 'react-data-grid-addons';

import cloneDeep from 'lodash-es/cloneDeep';
import groupBy from 'lodash-es/groupBy';
import uniqBy from 'lodash-es/uniqBy';

import { saveAs } from 'file-saver';

import moment from 'moment';

import uniq from 'lodash-es/uniq';

import classes from './PlanView.module.scss';

moment.locale('en-GB');

const PlanView = props => {
    const [chartData, setChartData] = useState({});
    const [jsonDownloadEnabled, setJsonDownloadEnabled] = useState(false);
    const [deletePlanModal, setDeletePlanModal] = useState(false);
    const [diPlanConfig, setDIPlanConfig] = useState(null);
    const [diPlanDetail, setDIPlanDetail] = useState(null);
    const [diPlanHeader, setDIPlanHeader] = useState(null);
    const [diSelectedSite, setDiSelectedSite] = useState(null);
    const [diSelectedView, setDiSelectedView] = useState(null);
    const [diSites, setDiSites] = useState([]);
    const [diView, setDiView] = useState([]);
    const [doCalc, setDoCalc] = useState(false);
    const [downloadEnabled, setDownloadEnabled] = useState(false);
    const [fieldData, setFieldData] = useState([]);
    const [forecast, setForecast] = useState([]);
    const [forecastView, setForecastView] = useState(<div></div>);
    const [groups, setGroups] = useState([]);
    const [isRail, setIsRail] = useState(false);
    const [limitOptions, setLimitOptions] = useState(null);
    const [planDays, setPlanDays] = useState([]);
    const [publishPlanModal, setPublishPlanModal] = useState(false);
    const [selectedLimit, setSelectedLimit] = useState('0');
    const [selectedPlanDay, setSelectedPlanDay] = useState(null);
    const [showConfig, setShowConfig] = useState(false);
    const [showPublishedModal, setShowPublishedModal] = useState(false);
    const [subtypeDCs, setSubtypeDCs] = useState(null);
    const [totals, setTotals] = useState([]);
    const [trunkData, setTrunkData] = useState([]);
    const [updateForecast, setUpdateForecast] = useState(false);
    const {
        json,
        jsonLoading,
        jsonSuccess,
        diPlanDeleteError,
        diPlanDeleteSuccess,
        diPlanDetailModel,
        emailGroups,
        forecastTrunks,
        getDIPlanDetailError,
        gettingWLFC,
        railDownload,
        railDownloading,
        onDIPlanDeleteReset,
        onGetDIForecastTrunks,
        onGetDIPlanDetail,
        onGetDIWLFC,
        onGetEmailGroups,
        onGetTrailers,
        onPublishReset,
        onSetActiveReset,
        publishError,
        publishSuccess,
        saveSuccess,
        setActiveDIPlanError,
        setActiveDIPlanSuccess,
        trailers,
        trailersLoading,
        wlfc
    } = props;
    const diPlanId = props.match.params.id;
    const history = useHistory();
    let growl;

    useEffect(() => {
        if (!trailers && !trailersLoading) onGetTrailers();
    }, [onGetTrailers, trailers, trailersLoading]);

    useEffect(() => {
        if (diPlanDetailModel && trailers) {
            setDIPlanDetail(diPlanDetailModel.planDetail);
            setSubtypeDCs(diPlanDetailModel.subtypeDCs);
            setDIPlanConfig(diPlanDetailModel.configuration);
            setDIPlanHeader(diPlanDetailModel.planHeader);
            setShowConfig(false);

            // date options for the JSON download
            const dates = uniq(
                diPlanDetailModel.planDetail.map(d => d.Date),
                true
            ).map(d => {
                const date = moment(d);
                return { label: `${date.format('ddd Do')} for ${date.add(1, 'days').format('ddd Do')}`, value: d };
            });

            dates.unshift({ label: 'Full week', value: 'none' });
            setPlanDays(dates);

            const limit = uniqBy(diPlanDetailModel.planDetail, s => s.SubtypeId)
                .filter(st => st.SubtypeId === 2 || st.SubtypeId === 5 || st.SubtypeId === 6)
                .map(st => ({ label: st.Subtype, value: st.SubtypeId.toString() }));

            limit.unshift({ label: 'Do not limit output content', value: '0' });

            setLimitOptions(limit);
        }
    }, [diPlanDetailModel, trailers]);

    useEffect(() => {
        if (setActiveDIPlanSuccess) {
            growl.show({ severity: 'success', summary: 'DI Plan Active status set' });
            onSetActiveReset();
        }
    }, [growl, onSetActiveReset, setActiveDIPlanSuccess]);

    useEffect(() => {
        if (setActiveDIPlanError === true || setActiveDIPlanSuccess === false) {
            growl.show({ severity: 'error', summary: 'Error setting active DI plan' });
            onSetActiveReset();
        }
    }, [growl, onSetActiveReset, setActiveDIPlanError, setActiveDIPlanSuccess]);

    // chart.js options for the bar graph
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            mode: 'index',
            intersect: true
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        min: 0,
                        max: 20
                    }
                }
            ]
        }
    };

    // load the DI plan detail
    useEffect(() => {
        if (diPlanId) {
            onGetDIPlanDetail(diPlanId);
            onGetDIForecastTrunks(diPlanId);
            onGetEmailGroups();
        } else growl.show({ severity: 'error', summary: 'No DI Plan specified' });
    }, [diPlanId, growl, onGetDIForecastTrunks, onGetDIPlanDetail, onGetEmailGroups]);

    // setup the email groups
    useEffect(() => {
        if (emailGroups && emailGroups.length > 0) {
            const grps = emailGroups.map(e => ({ label: e.name, value: e.id.toString() }));
            setGroups(grps);
        }
    }, [emailGroups]);

    // display an error if was encountered loading the DI Plan
    useEffect(() => {
        if (getDIPlanDetailError) growl.show({ severity: 'error', summary: 'Error retrieving the DI plan' });
    }, [diPlanDetail, getDIPlanDetailError, growl]);

    // build the available list of site views
    const buildViews = useCallback(() => {
        // group the data by CDC
        const groups = groupBy(diPlanDetail, d => d.CDC);
        const cdcSites = Object.keys(groups).map(g => ({ label: g, value: groups[g][0].CDCId }));

        setDiSites(cdcSites);
    }, [diPlanDetail]);

    // when we have some diPlanDetail, build the list of site
    useEffect(() => {
        if (diPlanDetail && diPlanDetail.length > 0) buildViews();
    }, [buildViews, diPlanDetail]);

    // assemble the data required for the chart
    const buildChartData = useCallback(() => {
        if (diSelectedSite && diPlanDetail) {
            // bar colours, from https://learnui.design/tools/data-color-picker.html
            const chartColours = ['#003f5c', '#374c80', '#7a5195', '#bc5090', '#ef5675', '#ff764a', '#ffa600'];
            const cd = {};
            let minDate = moment();

            if (diPlanDetail) {
                const dates = diPlanDetail.map(d => moment(d.Date));
                minDate = moment.min(dates);
            }

            let c = 0;

            cd.labels = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            cd.datasets = [];

            diView
                .filter(d => d.value !== 6 && +d.value < 9000000) // ignore cdc moving DI & WLFC
                .forEach(st => {
                    const ds = [0, 0, 0, 0, 0, 0, 0, 0];
                    const siteData = diPlanDetail.filter(d => d.CDCId === diSelectedSite);
                    const subtypeData = siteData.filter(t => t.SubtypeId === st.value);

                    subtypeData.forEach(std => {
                        const dtDate = moment(std.Date);
                        let dayOfWeek = dtDate.day() + 1;
                        // there are two Saturdays to handle, ensure we put the correct data on the correct day
                        if (dayOfWeek === 7 && dtDate.isSame(minDate)) dayOfWeek = 0;
                        ds[dayOfWeek] += Object.keys(std)
                            .filter(k => k.length === 3 && k !== 'CDC' && k !== 'Day')
                            .reduce((sum, v) => (sum += std[v]), 0);
                    });

                    cd.datasets.push({
                        backgroundColor: chartColours[c],
                        label: st.label,
                        data: ds
                    });

                    c += 1;
                });

            setChartData(cd);

            // initialise the totals
            const siteDetail = diPlanDetail.filter(d => d.CDCId === diSelectedSite);
            const dcFields = [];

            // for the totals we need all possible fields across all subtypes for the selected site
            /* prettier-ignore */
            siteDetail.forEach(sd => {
                Object.keys(sd).forEach(f => {
                    if (f.length === 3 && f !== 'CDC' && f !== 'Day')
                        if (!dcFields.includes(f))
                            dcFields.push(f);
                });
            });

            // grab a sample record to use as the basis for building the totals records
            // subtype doesn't matter as we'll be ignoring the dcs and adding them from the above list
            const oneSubType = siteDetail.filter(t => t.SubtypeId === siteDetail[0].SubtypeId);
            const totals = [];

            // build the totals table, setting each dc total to zero
            oneSubType.forEach(t => {
                const rec = {};
                Object.keys(t).forEach(k => {
                    if (k.length > 3 || k === 'CDC' || k === 'Day') rec[k] = t[k];
                });

                dcFields.forEach(f => (rec[f] = 0));
                totals.push(rec);
            });

            setTotals(totals);
            setDoCalc(true);
        }
    }, [diPlanDetail, diSelectedSite, diView]);

    // once a site has been selected, we can build the data for the chart
    useEffect(() => {
        if (diView) buildChartData();
    }, [buildChartData, diView]);

    // build the list of available subtypes for the selected site
    useEffect(() => {
        if (diSelectedSite && diPlanDetail) {
            const selSite = diPlanDetail.filter(d => d.CDCId === diSelectedSite);
            const subtypes = uniqBy(selSite, s => s.SubtypeId).map(st => ({ label: st.Subtype, value: st.SubtypeId }));

            // add the WLFC as a sudo-subtype
            subtypes.push({ label: 'Total DI by Site', value: '999998' });
            setDiView(subtypes);

            onGetDIWLFC(diPlanId);
        }
    }, [diPlanDetail, diPlanId, diSelectedSite, onGetDIWLFC]);

    // calculate totals
    useEffect(() => {
        if (totals.length > 0 && doCalc) {
            const newTotals = cloneDeep(totals);
            const fields = Object.keys(diPlanDetail[0]).filter(k => k.length === 3 && k !== 'CDC' && k !== 'Day');

            newTotals.forEach(t => {
                const sd = diPlanDetail.filter(d => d.Date === t.Date && d.ShiftSequence === t.ShiftSequence && d.CDCId === t.CDCId);

                // Subcontracted / Non NTP
                const sub = sd.find(dd => dd.SubtypeId === 1);
                // Fixed LST Plan
                const fxd = sd.find(dd => dd.SubtypeId === 2);
                // Containers
                const cnt = sd.find(dd => dd.SubtypeId === 3);
                // Long MD Paragon
                const lng = sd.find(dd => dd.SubtypeId === 4);
                // Paragon - All Remaining MD
                const rem = sd.find(dd => dd.SubtypeId === 5);

                fields.forEach(fld => {
                    const subv = sub ? sub[fld] : 0;
                    const fxdv = fxd ? fxd[fld] * (1 + 1 / 4.8) : 0;
                    const cntv = cnt ? cnt[fld] : 0;
                    const lngv = lng ? lng[fld] * 1.125 : 0;
                    const remv = rem ? rem[fld] : 0;

                    const total = subv + fxdv + cntv + lngv + remv;
                    t[fld] = total.toFixed(2);
                });
            });

            setTotals(newTotals);
            setDoCalc(false);
        }
    }, [diPlanDetail, doCalc, totals]);

    // grab the detail for the selected view so the Trunk component can display it
    useEffect(() => {
        if (diSelectedView && diSelectedSite) {
            const viewData = diPlanDetail.filter(d => d.CDCId === diSelectedSite && d.SubtypeId === diSelectedView);

            // not all subtypes have the same fields and field names can vary
            // get a list of configured fields for the selected subtype and only
            // display those
            /* prettier-ignore */
            const colData =
                subtypeDCs
                    .filter(s => s.cdcId === diSelectedSite && s.diSubtypeId === diSelectedView)
                    .map(c => c.dc)
                    .sort();

            setFieldData(colData);
            setTrunkData(viewData);

            // TODO: Make this generic
            if (diSelectedSite === 1128 && diSelectedView === 1) setIsRail(true);
            else setIsRail(false);
        }
    }, [diPlanDetail, diPlanId, diSelectedSite, diSelectedView, subtypeDCs]);

    const getFields = useCallback(subtype => {
        if (subtype) {
            const fields = Object.keys(subtype).filter(k => {
                if (k.length === 3 && k !== 'CDC' && k !== 'Day') return true;
                return false;
            });

            return fields;
        }
        return [];
    }, []);

    // wlfc figures (right hand window)
    useEffect(() => {
        if ((!gettingWLFC && wlfc && diPlanDetail && diSelectedSite) || updateForecast) {
            const currentDetail = diPlanDetail.filter(d => d.CDCId === diSelectedSite);
            const currentwlfc = wlfc[diSelectedSite];
            const subId = subtypeDCs.find(s => s.shortName === 'SUB').diSubtypeId;
            const fxdId = subtypeDCs.find(s => s.shortName === 'FXD').diSubtypeId;
            const conId = subtypeDCs.find(s => s.shortName === 'CON').diSubtypeId;
            const lngId = subtypeDCs.find(s => s.shortName === 'LNG').diSubtypeId;
            const remId = subtypeDCs.find(s => s.shortName === 'REM').diSubtypeId;
            const cdcId = subtypeDCs.find(s => s.shortName === 'CDC').diSubtypeId;
            const mdaTrl = diPlanConfig.find(d => d.dcId === diSelectedSite && d.optionName === 'MDA').optionValue;
            const mda = trailers.find(t => t.code === mdaTrl).bdCapacity;

            currentwlfc.forEach(w => {
                const sub = currentDetail.find(cd => cd.SubtypeId === subId && cd.Date === w.shiftDate && cd.ShiftSequence === w.shiftId);
                const fxd = currentDetail.find(cd => cd.SubtypeId === fxdId && cd.Date === w.shiftDate && cd.ShiftSequence === w.shiftId);
                const con = currentDetail.find(cd => cd.SubtypeId === conId && cd.Date === w.shiftDate && cd.ShiftSequence === w.shiftId);
                const lng = currentDetail.find(cd => cd.SubtypeId === lngId && cd.Date === w.shiftDate && cd.ShiftSequence === w.shiftId);
                const rem = currentDetail.find(cd => cd.SubtypeId === remId && cd.Date === w.shiftDate && cd.ShiftSequence === w.shiftId);
                const cdc = currentDetail.find(cd => cd.SubtypeId === cdcId && cd.Date === w.shiftDate && cd.ShiftSequence === w.shiftId);

                const subFields = getFields(sub);
                let subTotal = 0;
                subFields.forEach(sf => (subTotal += +sub[sf]));

                const fxdFields = getFields(fxd);
                let fxdTotal = 0;
                fxdFields.forEach(ff => (fxdTotal += fxd[ff]));
                /* prettier-ignore */
                fxdTotal *= (1 + (1 / 4.8));

                const conFields = getFields(con);
                let conTotal = 0;
                conFields.forEach(cf => (conTotal += con[cf]));

                const lngFields = getFields(lng);
                let lngTotal = 0;
                lngFields.forEach(lf => (lngTotal += lng[lf]));
                /* prettier-ignore */
                lngTotal *= (1 + (1 / 4.8));

                const remFields = getFields(rem);
                let remTotal = 0;
                remFields.forEach(rf => (remTotal += rem[rf]));

                const cdcFields = getFields(cdc);
                let cdcTotal = 0;
                cdcFields.forEach(cdcf => (cdcTotal += cdc[cdcf]));
                cdcTotal /= mda;

                w.assigned = +(subTotal + fxdTotal + conTotal + lngTotal + remTotal + cdcTotal).toFixed(1);
            });

            // add a 'Totals' row
            let asstotal = 0;
            let reqtotal = 0;
            let petotal = 0;
            let wtotal = 0;
            let ctotal = 0;
            currentwlfc.forEach(w => {
                asstotal += +w.assigned;
                reqtotal += +w.required;
                petotal += +w.palletEquivalent;
                wtotal += +w.forecast;
                ctotal += +w.cdcMovingDI;
            });

            const vtotal = {
                day: 'Tot',
                shift: '',
                assigned: asstotal.toFixed(2),
                required: reqtotal.toFixed(2),
                palletEquivalent: petotal,
                forecast: wtotal,
                cdcMovingDI: ctotal
            };

            currentwlfc.push(vtotal);
            if (updateForecast) setUpdateForecast(false);
            setForecast(wlfc[diSelectedSite]);
        }
    }, [diPlanConfig, diPlanDetail, diSelectedSite, getFields, gettingWLFC, subtypeDCs, trailers, updateForecast, wlfc]);

    const loading =
        props.gettingDIPlanDetail || props.settingActiveDIPlan || props.diPlanDeleting || props.publishRunning ? (
            <GridItem size="1/1">
                <ProgressIndicator page loading={true}>
                    <ProgressBar color="light" />
                    Please wait...
                </ProgressIndicator>
            </GridItem>
        ) : null;

    const handlePublish = () => {
        setPublishPlanModal(!publishPlanModal);
        props.onPublish(diPlanId, (selectedPlanDay || 'none') === 'none' ? 'none' : moment(selectedPlanDay).format('YYYY-MM-DD'), selectedLimit || '0');
    };

    const publishModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setPublishPlanModal(!publishPlanModal)}
            open={publishPlanModal}
            heading={`Publish DI Plan for ${moment(selectedPlanDay).format('YYYY-MM-DD')}`}
            message="Please click 'Publish' again to confirm publishing the plan."
            confirmAction={handlePublish}
            confirmButton="Publish"
        />
    );

    const chart = diSelectedSite ? <Chart type="horizontalBar" data={chartData} options={chartOptions} className={classes.dichart} /> : ' ';

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const allRows = trunkData.slice();
        for (let i = fromRow; i <= toRow; i++) allRows[i] = { ...allRows[i], ...updated };

        // save the changes
        const updatedRows = allRows.slice(fromRow, toRow + 1);
        props.onSaveChanges(updatedRows, diPlanId);

        // update the source data
        const updatedPlanDetail = cloneDeep(diPlanDetail);

        updatedPlanDetail.forEach(d => {
            if (d.CDCId === diSelectedSite && d.SubtypeId === diSelectedView) {
                const upd = allRows.find(r => r.Date === d.Date && r.Shift === d.Shift);
                Object.keys(diPlanDetail[0])
                    .filter(k => k.length === 3 && k !== 'CDC' && k !== 'Day')
                    .forEach(k => (d[k] = +upd[k]));
            }
        });

        setDIPlanDetail(updatedPlanDetail);
        setUpdateForecast(true);
    };

    useEffect(() => {
        if (saveSuccess === false) growl.show({ severity: 'error', summary: 'Error saving changes to the database' });
    }, [growl, saveSuccess]);

    const downloadRail = () => {
        setDownloadEnabled(true);
        props.onDownloadRail(diPlanId);
    };

    useEffect(() => {
        if (!railDownloading && railDownload && downloadEnabled) {
            const blob = new Blob([railDownload], { type: 'application/vnd.ms-excel' });
            saveAs(blob, 'Rail.xlsx');
            setDownloadEnabled(false);
        }
    }, [downloadEnabled, railDownload, railDownloading]);

    useEffect(() => {
        if (!jsonLoading && json && jsonDownloadEnabled) {
            const blob = new Blob([json], { type: 'text/json' });
            saveAs(blob, 'DI.json');
            setJsonDownloadEnabled(false);
        }
    }, [jsonDownloadEnabled, json, jsonLoading]);

    useEffect(() => {
        if (jsonSuccess === false) growl.show({ severity: 'error', summary: 'Error creating JSON file' });
    }, [growl, jsonSuccess]);

    const creatingJson = jsonLoading ? (
        <ProgressIndicator page loading={true}>
            <ProgressBar color="light" />
            Creating Output file...
        </ProgressIndicator>
    ) : null;

    const railHeader = (
        <Toolbar enableFilter={true}>
            <IconButton className="ln-u-push-left" variant="outlined" label="Download Rail" hideLabel onClick={() => downloadRail()}>
                <Download />
            </IconButton>
        </Toolbar>
    );

    const downloading =
        railDownloading || props.sendingGroupEmail ? (
            <GridItem size="1/1">
                <ProgressIndicator page loading={true}>
                    <ProgressBar color="light" />
                    {railDownloading ? 'Downloading...' : 'Sending...'}
                </ProgressIndicator>
            </GridItem>
        ) : null;

    const trunks = (
        <Trunks
            sourceData={trunkData}
            fieldData={fieldData}
            onGridRowsUpdated={onGridRowsUpdated}
            // set not editable for cdc moving di
            editable={true} // {diSelectedView !== 6}
            header={isRail ? railHeader : null}
        />
    );

    // eslint-disable-next-line react/no-multi-comp
    const views = () => {
        const tview = diSelectedView === 999998 ? <Trunks sourceData={totals} siteFilter={diSelectedSite} editable={false} /> : trunks;
        return (
            <Fragment>
                <GridItem size="1/2">{tview}</GridItem>
                <GridItem size="1/2">
                    {/* WLFC forecast */}
                    <Forecast sourceData={forecast} onGridRowsUpdated={onGridRowsUpdated} editable={true} loading={false} />
                </GridItem>
            </Fragment>
        );
    };

    /* eslint-disable-next-line react/no-multi-comp */
    const planner = () => {
        const msg = <h4 className="ln-u-push-top ln-u-color-orange">Please select a Site and View</h4>;
        const view = !diSelectedSite ? msg : views();

        return (
            <Fragment>
                {creatingJson}
                {downloading}
                <GridItem size="1/3">
                    <GridWrapper>
                        {/* Forecast trunks by warehouse */}
                        <GridItem size="1/1">{forecastView}</GridItem>
                        <GridItem size="1/1">{chart}</GridItem>
                    </GridWrapper>
                </GridItem>
                <GridItem size="2/3">
                    <GridWrapper>{dropdowns}</GridWrapper>
                    {view}
                </GridItem>
            </Fragment>
        );
    };

    const config = (
        <GridItem size="1/1">
            <PlanConfig growl={growl} />
        </GridItem>
    );

    const selectors = (
        <Fragment>
            <GridItem size="1/3">
                <SelectField name="di-site-select" label="Select Site" options={diSites} onChange={e => setDiSelectedSite(+e.target.value)} />
            </GridItem>
            <GridItem size="1/2">
                <SelectField name="di-view-select" label="Select View" options={diView} onChange={e => setDiSelectedView(+e.target.value)} />
            </GridItem>
        </Fragment>
    );

    const dropdowns = showConfig ? <GridItem size="5/6">&nbsp;</GridItem> : selectors;
    const display = showConfig ? config : planner();

    const getRag = value => {
        // Sainsbury's green
        let rag = '#218234';

        // dark green (non-Sains)
        if (value <= -1) rag = '#026f02';
        // Sainsbury's red
        else if (value >= 1) rag = '#d70000';

        return rag;
    };

    /* recalculate forecast / allocated trunks by warehouse */
    const createForecastView = useCallback(() => {
        const site = forecastTrunks.find(ft => ft.dcId === diSelectedSite).forecastTrunks;
        const gridItemsize = `1/${site.length}`;

        const forecastRemaining = (
            <GridWrapper style={{ marginLeft: '0.5rem' }}>
                <GridItem size="1/1" className={classes.unplannedHeader}>
                    Unplanned Forecast Trunks
                </GridItem>
                {site.map(t => (
                    <GridItem key={t.destDCName} size={gridItemsize} className="ln-u-text-align-center" style={{ fontWeight: 'bold' }}>
                        {t.destDCName}
                    </GridItem>
                ))}
                {site.map(t => {
                    const planned = totals.reduce((p, ttl) => (p += +ttl[t.destDCName] || 0), 0);
                    const val = Math.round(t.forecast - planned);

                    return (
                        <GridItem
                            key={`${t.destDCName}_value`}
                            size={gridItemsize}
                            style={{ backgroundColor: getRag(val) }}
                            className="ln-u-text-align-center ln-u-color-white"
                        >
                            {val}
                        </GridItem>
                    );
                })}
                {site.map(t => {
                    const val = Math.round(t.forecast);

                    return (
                        <GridItem key={`${t.destDCName}_value`} size={gridItemsize} style={{ backgroundColor: '#D7D7D7' }} className="ln-u-text-align-center">
                            {val}
                        </GridItem>
                    );
                })}
            </GridWrapper>
        );

        setForecastView(forecastRemaining);
    }, [diSelectedSite, forecastTrunks, totals]);

    useEffect(() => {
        if (forecastTrunks && diSelectedSite) createForecastView();
        else setForecastView(<div></div>);
    }, [createForecastView, diSelectedSite, forecastTrunks]);

    const onSend = groupToEmail => props.onSendGroupEmail(groupToEmail, diPlanId);

    const getJson = () => {
        setJsonDownloadEnabled(true);
        props.onGetDIJson(diPlanId, (selectedPlanDay || 'none') === 'none' ? 'none' : moment(selectedPlanDay).format('YYYY-MM-DD'), selectedLimit || '0');
    };

    const handleActiveClick = event => {
        const active = event.target.checked;
        props.onSetActive(diPlanId, active);
    };

    const handleDeletePlan = () => {
        setDeletePlanModal(false);
        props.onDIPlanDelete(diPlanId);
    };

    useEffect(() => {
        if (diPlanDeleteSuccess) {
            onDIPlanDeleteReset();
            history.push('/DI');
        }
    }, [diPlanDeleteSuccess, history, onDIPlanDeleteReset]);

    useEffect(() => {
        if (diPlanDeleteError === true || diPlanDeleteSuccess === false) {
            growl.show({ severity: 'error', summary: 'Error deleting DI plan' });
            onDIPlanDeleteReset();
        }
    }, [diPlanDeleteError, diPlanDeleteSuccess, growl, onDIPlanDeleteReset]);

    useEffect(() => {
        if (publishSuccess === null) return;
        if (publishSuccess) setShowPublishedModal(true);
        else {
            growl.show({ severity: 'error', summary: publishError });
            onPublishReset();
        }
    }, [publishSuccess, publishError, onPublishReset, growl]);

    const closePublishedModal = () => {
        setShowPublishedModal(false);
        onPublishReset();
    };

    const publishedModal = <PublishedModal handleClose={closePublishedModal} open={showPublishedModal} publishedInfo={props.publishedInfo || []} />;

    const deleteModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setDeletePlanModal(!deletePlanModal)}
            open={deletePlanModal}
            heading={`Delete Plan ${diPlanHeader ? diPlanHeader.name : ''}`}
            message="Please click 'Delete' again to confirm deleting the plan. You cannot undo this action!"
            confirmAction={handleDeletePlan}
            confirmButton="Delete"
            icon={<Warning height="64px" width="64px" />}
            iconGridLeft="1/6"
            iconGridRight="5/6"
        />
    );

    const statusArea = diPlanDetail ? (
        <GridItem size="1/1">
            <TopBar
                diPlanHeader={diPlanHeader}
                downloading={jsonLoading}
                handleActiveClick={handleActiveClick}
                handleDeleteClick={() => setDeletePlanModal(true)}
                handleDownloadClick={() => getJson('download')}
                publishClick={() => setPublishPlanModal(!publishPlanModal)}
                publishRunning={props.publishRunning}
                selectedPlanDay={selectedPlanDay}
            />
        </GridItem>
    ) : null;

    return (
        <Card>
            <GridWrapper>
                {statusArea}
                <GridItem size="1/1">
                    <Header
                        groups={groups}
                        handleLimitSelect={setSelectedLimit}
                        handlePlanDaySelect={setSelectedPlanDay}
                        handleSend={onSend}
                        limit={limitOptions}
                        planDays={planDays}
                    />
                </GridItem>
                {loading}
                {display}
            </GridWrapper>
            <Growl ref={el => (growl = el)}></Growl>
            {deleteModal}
            {publishModal}
            {publishedModal}
        </Card>
    );
};

PlanView.propTypes = {
    diPlanDeleteError: PropTypes.bool,
    diPlanDeleteSuccess: PropTypes.bool,
    diPlanDeleting: PropTypes.bool,
    diPlanDetailModel: PropTypes.object,
    emailGroups: PropTypes.array,
    forecastTrunks: PropTypes.array,
    getDIPlanDetailError: PropTypes.bool,
    gettingDIPlanDetail: PropTypes.bool,
    gettingWLFC: PropTypes.bool,
    json: PropTypes.any,
    jsonLoading: PropTypes.bool,
    jsonSuccess: PropTypes.bool,
    match: PropTypes.object,
    onDIPlanDelete: PropTypes.func.isRequired,
    onDIPlanDeleteReset: PropTypes.func.isRequired,
    onDownloadRail: PropTypes.func.isRequired,
    onGetDIForecastTrunks: PropTypes.func.isRequired,
    onGetDIJson: PropTypes.func.isRequired,
    onGetDIPlanDetail: PropTypes.func.isRequired,
    onGetDIWLFC: PropTypes.func.isRequired,
    onGetEmailGroups: PropTypes.func.isRequired,
    onGetTrailers: PropTypes.func.isRequired,
    onPublish: PropTypes.func.isRequired,
    onPublishReset: PropTypes.func.isRequired,
    onSaveChanges: PropTypes.func.isRequired,
    onSendGroupEmail: PropTypes.func.isRequired,
    onSetActive: PropTypes.func.isRequired,
    onSetActiveReset: PropTypes.func.isRequired,
    publishError: PropTypes.bool,
    publishRunning: PropTypes.bool,
    publishSuccess: PropTypes.bool,
    publishedInfo: PropTypes.array,
    railDownload: PropTypes.any,
    railDownloading: PropTypes.bool,
    saveSuccess: PropTypes.bool,
    sendingGroupEmail: PropTypes.bool,
    setActiveDIPlanError: PropTypes.bool,
    setActiveDIPlanSuccess: PropTypes.bool,
    settingActiveDIPlan: PropTypes.bool,
    trailers: PropTypes.array,
    trailersLoading: PropTypes.bool,
    wlfc: PropTypes.object
};

const mapStateToProps = state => {
    return {
        diPlanDeleteError: state.di.diPlanDeleteError,
        diPlanDeleteSuccess: state.di.diPlanDeleteSuccess,
        diPlanDeleting: state.di.diPlanDeleting,
        diPlanDetailModel: state.di.diPlanDetail,
        diPlanDetailSuccess: state.di.diPlanDetailSuccess,
        emailGroups: state.email.emailGroups,
        forecastTrunks: state.di.forecastTrunks,
        getDIPlanDetailError: state.di.getDIPlanDetailError,
        gettingDIPlanDetail: state.di.gettingDIPlanDetail,
        gettingWLFC: state.di.gettingWLFC,
        json: state.di.json,
        jsonLoading: state.di.jsonLoading,
        jsonSuccess: state.di.jsonSuccess,
        publishError: state.di.publishError,
        publishRunning: state.di.publishRunning,
        publishSuccess: state.di.publishSuccess,
        publishedInfo: state.di.publishedInfo,
        railDownload: state.di.rail,
        railDownloading: state.di.downloadingRail,
        saveSuccess: state.di.saveSuccess,
        sendingGroupEmail: state.email.sendingGroupEmail,
        setActiveDIPlanError: state.di.setActiveDIPlanError,
        setActiveDIPlanSuccess: state.di.setActiveDIPlanSuccess,
        settingActiveDIPlan: state.di.settingActiveDIPlan,
        trailers: state.trailers.trailers,
        trailersLoading: state.trailers.trailersLoading,
        wlfc: state.di.WLFC
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDownloadRail: planId => dispatch(actions.diDownloadRail(planId)),
        onDIPlanDelete: planId => dispatch(actions.diPlanDelete(planId)),
        onDIPlanDeleteReset: () => dispatch(actions.diPlanDeleteReset()),
        onGetDIJson: (planId, planDate, limit) => dispatch(actions.getDIJson(planId, planDate, limit)),
        onGetDIPlanDetail: id => dispatch(actions.getDIPlanDetail(id)),
        onGetDIForecastTrunks: id => dispatch(actions.diGetForecastTrunks(id)),
        onGetDIWLFC: id => dispatch(actions.diWLFC(id)),
        onGetEmailGroups: () => dispatch(actions.getEmailGroupNames()),
        onGetTrailers: () => dispatch(actions.getTrailers()),
        onPublish: (planId, planDate, limit) => dispatch(actions.publish(planId, planDate, limit)),
        onPublishReset: () => dispatch(actions.publishReset()),
        onSaveChanges: (changes, planId) => dispatch(actions.diSaveChanges(changes, planId)),
        onSendGroupEmail: (groupId, planId) => dispatch(actions.sendGroupEmail(groupId, planId)),
        onSetActive: (planId, active) => dispatch(actions.setActiveDIPlan(planId, active)),
        onSetActiveReset: () => dispatch(actions.setActiveDIPlanReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(PlanView, axios));
