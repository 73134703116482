import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {};

const diConfigGetDcsStart = state => updateObject(state, { loadingDiDcs: true });
const diConfigGetDcsSuccess = (state, action) => updateObject(state, { loadingDiDcs: false, diDcs: action.diDcs });
const diConfigGetDcsFail = (state, action) => updateObject(state, { loadingDiDcs: false, error: action.diDcsError });

const diConfigGetOptionsStart = state => updateObject(state, { loadingDiOptions: true });
const diConfigGetOptionsSuccess = (state, action) => updateObject(state, { loadingDiOptions: false, diConfigOptions: action.diConfigOptions });
const diConfigGetOptionsFail = (state, action) => updateObject(state, { loadingDiOptions: false, diConfigOptionsError: action.diConfigOptionsError });

const diConfigSetOptionsStart = state => updateObject(state, { settingDiOptions: true, setDiOptionsSuccess: undefined });
const diConfigSetOptionsSuccess = (state, action) => updateObject(state, { settingDiOptions: false, setDiOptionsSuccess: action.setDiOptionsSuccess });
const diConfigSetOptionsFail = (state, action) => updateObject(state, { settingDiOptions: false, setDiOptionsError: action.setDiOptionsError });

/* prettier-ignore */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DI_CONFIG_GET_DCS_START: return diConfigGetDcsStart(state);
        case actionTypes.DI_CONFIG_GET_DCS_SUCCESS: return diConfigGetDcsSuccess(state, action);
        case actionTypes.DI_CONFIG_GET_DCS_FAIL: return diConfigGetDcsFail(state, action);
        case actionTypes.DI_CONFIG_GET_OPTIONS_START: return diConfigGetOptionsStart(state);
        case actionTypes.DI_CONFIG_GET_OPTIONS_SUCCESS: return diConfigGetOptionsSuccess(state, action);
        case actionTypes.DI_CONFIG_GET_OPTIONS_FAIL: return diConfigGetOptionsFail(state, action);
        case actionTypes.DI_CONFIG_SET_OPTIONS_START: return diConfigSetOptionsStart(state);
        case actionTypes.DI_CONFIG_SET_OPTIONS_SUCCESS: return diConfigSetOptionsSuccess(state, action);
        case actionTypes.DI_CONFIG_SET_OPTIONS_FAIL: return diConfigSetOptionsFail(state, action);
        default: return state;
    }
};

export default reducer;
