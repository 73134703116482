import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as actions from '../../../store/actions/index';

import { connect } from 'react-redux';
import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';

import { Card } from '@jsluna/card';
// import { FormGroup, SelectField } from '@jsluna/form';
// import { ListGroup, ListGroupItem } from '@jsluna/list';
import { GridItem, GridWrapper } from '@jsluna/grid';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import moment from 'moment';
import classes from './PlanExtras.module.scss';

import { Button } from 'primereact/button';

import { Growl } from 'primereact/growl';

import saveAs from 'file-saver';

import ConfirmationDialog from '../../UI/ConfirmationDialog/ConfirmationDialog';

moment.locale('en-GB');

const PlanExtras = props => {
    const {
        onGetOutstandingPlanExtras,
        outstandingPlanExtras,
        rows,
        downloadPlanExtrasError,
        downloadPlanExtrasFail,
        downloadPlanExtrasSuccess,
        downloadPlanExtrasLoading,
        file,
        fileName,
        fileType,
        onDownloadPlanExtras,
        onDownloadPlanExtrasReset,
        onGetPlansForPlanExtras,
        onGetPlansForPlanExtrasReset,
        onPlanPlanExtras,
        onPlanPlanExtrasReset,
        planPlanExtrasError,
        planPlanExtrasFail,
        planPlanExtrasSuccess,
        plansForPlanExtras,
        plansForPlanExtrasFail,
        plansForPlanExtrasSuccess
    } = props;
    let growl;
    const [selectedRows, setSelectedRows] = useState([]);
    const [downloadFormat, setDownloadFormat] = useState('application/json');
    const [showSimulatedModal, setShowSimulatedModal] = useState(false);
    const [showAddToPlanModal, setShowAddToPlanModal] = useState(false);
    const [planExtrasRows, setPlanExtrasRows] = useState(null);
    const [columns, setColumns] = useState(null);

    // the linter thinks this is another component definition for some reason
    /* eslint-disable-next-line react/no-multi-comp */
    const requestedDateTemplate = rowData => {
        if (rowData && rowData.header) {
            const date = moment(rowData.header.dateRequested);

            return <span>{date.format('DD/MM/YYYY')}</span>;
        } else return <span></span>;
    };

    /* eslint-disable-next-line react/no-multi-comp */
    const duTemplate = rowData => {
        if (rowData && rowData.header) {
            let ret = 0;
            rowData.details.forEach(r => {
                ret += r.du;
            });
            return <span>{ret.toFixed(2)}</span>;
        } else return <span></span>;
    };

    /* eslint-disable-next-line react/no-multi-comp */
    const qtyTemplate = rowData => {
        if (rowData && rowData.header) {
            let ret = 0;
            rowData.details.forEach(r => {
                ret += r.qty;
            });
            return <span>{ret}</span>;
        } else return <span></span>;
    };

    /* eslint-disable-next-line react/no-multi-comp */

    const headerTemplate = useCallback((rowData, field) => {
        if (rowData && rowData.header) {
            return <span>{rowData.header[field]}</span>;
        } else return <span></span>;
    }, []);

    // let growl;

    useEffect(() => {
        if (!outstandingPlanExtras) onGetOutstandingPlanExtras();
    }, [outstandingPlanExtras, onGetOutstandingPlanExtras]);

    const onDownloadClicked = format => {
        onGetPlansForPlanExtras(selectedRows.map(r => r.header.id));
        setDownloadFormat(format);
        /*
        onDownloadPlanExtras(
            selectedRows.map(r => r.key.id),
            format
        );
        */
    };

    const onAddToPlanClicked = () => {
        setShowAddToPlanModal(true);
    };

    const downloadSimulated = () => {
        onDownloadPlanExtras(
            selectedRows.map(r => r.header.id),
            downloadFormat,
            true
        );
        setShowSimulatedModal(false);
    };

    const downloadSimulatedModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setShowSimulatedModal(!showSimulatedModal)}
            open={showSimulatedModal}
            heading="No Plan Found"
            message="No plan found for the selected plan extras, do you want to download them using a simulated plan?"
            confirmAction={downloadSimulated}
            cancelButton="No"
            confirmButton="Yes"
        />
    );

    const addSelectedAvailableToPlan = () => {
        const records = selectedRows.filter(r => r.header.status === PlanExtraStatus.Available);
        onPlanPlanExtras(records.map(r => r.header.id));
        setShowAddToPlanModal(!showAddToPlanModal);
    };

    const addToPlanModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setShowAddToPlanModal(!showAddToPlanModal)}
            open={showAddToPlanModal}
            heading="Add to plan"
            message="Would you like to add the selected available extras to plan?"
            confirmAction={addSelectedAvailableToPlan}
            cancelButton="No"
            confirmButton="Yes"
        />
    );

    // trigger the download dialog for the file
    useEffect(() => {
        if (downloadPlanExtrasSuccess && !downloadPlanExtrasLoading) {
            const blob = new Blob([file], { type: fileType });
            saveAs(blob, fileName);
            onDownloadPlanExtrasReset();
        } else if (downloadPlanExtrasFail) {
            growl.show({ severity: 'error', summary: 'Download Failed', detail: `Error: ${downloadPlanExtrasError}` });
            onDownloadPlanExtrasReset();
        }
    }, [
        downloadPlanExtrasLoading,
        downloadPlanExtrasSuccess,
        downloadPlanExtrasFail,
        downloadPlanExtrasError,
        file,
        fileName,
        fileType,
        growl,
        onDownloadPlanExtrasReset
    ]);

    // const handleSelectionChange = useCallback(
    //     e => {
    //         if (!e.originalEvent.ctrlKey) {
    //             if (selectedRows && selectedRows.length === 1 && selectedRows.filter(r => r.header.id === e.value[0].header.id).length > 0) {
    //                 setSelectedRows([]);
    //             } else {
    //                 setSelectedRows(e.value);
    //             }
    //         } else {
    //             setSelectedRows(e.value);
    //         }
    //     },
    //     [selectedRows, setSelectedRows]
    // );

    const handleSelectionChange = e => {
        if (!e.originalEvent.ctrlKey) {
            if (selectedRows && selectedRows.length === 1 && selectedRows.filter(r => r.header.id === e.value[0].header.id).length > 0) {
                setSelectedRows([]);
            } else {
                setSelectedRows(e.value);
            }
        } else {
            setSelectedRows(e.value);
        }
    };

    const newColumns = (
        <DataTable
            value={planExtrasRows}
            paginator
            rows={rows}
            selectionMode="multiple"
            selection={selectedRows}
            onSelectionChange={e => handleSelectionChange(e)}
        >
            <Column field="dcNo" header="DC No" body={r => headerTemplate(r, 'dcNo')} className="ln-u-text-align-center" filter filterMatchMode="equals" />
            <Column
                field="storeNo"
                header="Store No"
                body={r => headerTemplate(r, 'storeNo')}
                className="ln-u-text-align-center"
                filter
                filterMatchMode="equals"
            />
            <Column field="deliveryTypeName" header="Type" body={r => headerTemplate(r, 'deliveryTypeName')} className="ln-u-text-align-center" filter />
            <Column field="details" header="Qty" body={qtyTemplate} className={classes.nofilter} />
            <Column field="details" header="Du" body={duTemplate} className="ln-u-text-align-center" filter />
            <Column field="dateRequested" header="Requested" body={requestedDateTemplate} className={classes.nofilter} />
            <Column field="statusDescription" header="Status" body={r => headerTemplate(r, 'statusDescription')} className="ln-u-text-align-center" filter />
        </DataTable>
    );

    useEffect(() => {
        if (!planExtrasRows && outstandingPlanExtras) {
            setPlanExtrasRows(outstandingPlanExtras);
        }
    }, [planExtrasRows, setPlanExtrasRows, columns, setColumns, outstandingPlanExtras, rows, selectedRows, headerTemplate]);

    useEffect(() => {
        if (plansForPlanExtrasSuccess === true) {
            onDownloadPlanExtras(
                selectedRows.map(r => r.header.id),
                downloadFormat,
                false
            );
            onGetPlansForPlanExtrasReset();
        } else if (plansForPlanExtrasFail === true) {
            setShowSimulatedModal(true);
            onGetPlansForPlanExtrasReset();
        }
    }, [
        plansForPlanExtras,
        plansForPlanExtrasFail,
        plansForPlanExtrasSuccess,
        downloadFormat,
        selectedRows,
        setShowSimulatedModal,
        onDownloadPlanExtras,
        onGetPlansForPlanExtrasReset
    ]);

    useEffect(() => {
        if (planPlanExtrasSuccess === true) {
            onPlanPlanExtrasReset();
            growl.show({ severity: 'success', summary: 'Add to plan OK', detail: 'Successfully planned the selected items.' });
            onGetOutstandingPlanExtras();
        } else if (planPlanExtrasFail === true) {
            onPlanPlanExtrasReset();
            growl.show({ severity: 'error', summary: 'Add to plan Failed', detail: `Error: ${planPlanExtrasError}` });
        }
    }, [planPlanExtrasSuccess, planPlanExtrasFail, planPlanExtrasError, onPlanPlanExtrasReset, growl, onGetOutstandingPlanExtras]);

    /*
    useEffect(() => {
        if (!outstanding) onGetAdHocDeliveryTypes();
    }, [outstanding, onGetAdHocDeliveryTypes]);
    */

    // const handleAdHocDeliveryTypeChange = e => {
    //     setAdHocDeliveryType(e.target.value);
    // };

    const PlanExtraStatus = {
        New: 0,
        Pending: 1,
        Available: 2,
        Rejected: 3,
        Planned: 4,
        Published: 5
    };

    return (
        <Card>
            <h5>PlanExtras</h5>
            <GridWrapper>
                <GridItem size="1/1">
                    {/*
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => onDownloadClicked('text/csv')}
                        icon="pi pi-download"
                        tooltip="Download Selected (CSV)"
                        tooltipOptions={{ position: 'top' }}
                        disabled={!selectedRows || selectedRows.length === 0}
                    >
                        <span className="notificationCounter">{'csv'}</span>
                    </Button>
                */}
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => onDownloadClicked('application/json')}
                        icon="pi pi-download"
                        tooltip="Download Selected (JSON)"
                        tooltipOptions={{ position: 'top' }}
                        disabled={!selectedRows || selectedRows.length === 0}
                    >
                        <span className="notificationCounter">{'js'}</span>
                    </Button>
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => onAddToPlanClicked()}
                        icon="pi pi-sign-in"
                        tooltip="Add To Plan"
                        tooltipOptions={{ position: 'top' }}
                        disabled={!selectedRows || selectedRows.filter(r => r.header.status === PlanExtraStatus.Available).length === 0}
                    ></Button>
                </GridItem>
                <GridItem size="1/1" className={`ln-u-text-align-left ${classes.lunaDataTableStyle}`}>
                    <span></span>
                    {newColumns}
                </GridItem>
            </GridWrapper>
            <Growl className="customGrowl" ref={el => (growl = el)}></Growl>
            {downloadSimulatedModal}
            {addToPlanModal}
        </Card>
    );
};

PlanExtras.propTypes = {
    // outstanding: PropTypes.object,
    downloadPlanExtrasError: PropTypes.string,
    downloadPlanExtrasFail: PropTypes.bool,
    downloadPlanExtrasLoading: PropTypes.bool,
    downloadPlanExtrasSuccess: PropTypes.bool,
    file: PropTypes.any,
    fileName: PropTypes.string,
    fileType: PropTypes.string,
    onDownloadPlanExtras: PropTypes.func,
    onDownloadPlanExtrasReset: PropTypes.func,
    onGetOutstandingPlanExtras: PropTypes.func,
    onGetPlansForPlanExtras: PropTypes.func,
    onGetPlansForPlanExtrasReset: PropTypes.func,
    onPlanPlanExtras: PropTypes.func,
    onPlanPlanExtrasReset: PropTypes.func,
    outstandingPlanExtras: PropTypes.array,
    planPlanExtrasError: PropTypes.string,
    planPlanExtrasFail: PropTypes.bool,
    planPlanExtrasSuccess: PropTypes.bool,
    plansForPlanExtras: PropTypes.array,
    plansForPlanExtrasFail: PropTypes.bool,
    plansForPlanExtrasSuccess: PropTypes.bool,
    rows: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    return {
        outstandingPlanExtras: state.requests.outstandingPlanExtras,
        plansForPlanExtras: state.requests.plansForPlanExtras,
        plansForPlanExtrasSuccess: state.requests.plansForPlanExtrasSuccess,
        plansForPlanExtrasFail: state.requests.plansForPlanExtrasFail,
        downloadPlanExtrasFail: state.requests.downloadPlanExtrasFail,
        downloadPlanExtrasError: state.requests.error,
        downloadPlanExtrasSuccess: state.requests.downloadPlanExtrasSuccess,
        downloadPlanExtrasLoading: state.requests.downloadPlanExtrasLoading,
        planPlanExtrasFail: state.requests.planPlanExtrasFail,
        planPlanExtrasError: state.requests.error,
        planPlanExtrasSuccess: state.requests.planPlanExtrasSuccess,
        planPlanExtrasLoading: state.requests.planPlanExtrasLoading,
        file: state.requests.file,
        fileName: state.requests.fileName,
        fileType: state.requests.fileType
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetOutstandingPlanExtras: () => dispatch(actions.getOutstandingPlanExtras()),
        onGetPlansForPlanExtras: ids => dispatch(actions.getPlansForPlanExtras(ids)),
        onGetPlansForPlanExtrasReset: () => dispatch(actions.getPlansForPlanExtrasReset()),
        onDownloadPlanExtras: (ids, format, simulatePlan) => dispatch(actions.downloadPlanExtras(ids, format, simulatePlan)),
        onDownloadPlanExtrasReset: () => dispatch(actions.downloadPlanExtrasReset()),
        onPlanPlanExtras: ids => dispatch(actions.planPlanExtras(ids)),
        onPlanPlanExtrasReset: () => dispatch(actions.planPlanExtrasReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null)(withErrorHandler(PlanExtras, axios));
