import React, { Fragment, Suspense, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import classes from './Schedule.module.scss';

import { updateObject } from '../../shared/utility';

import * as actions from '../../store/actions/index';
import { TabPanel } from '@jsluna/tabs';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

import { Modal, ModalHeading } from '@jsluna/modal';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';

import { Growl } from 'primereact/growl';

import AddAdHocVolume from './AddAdHocVolume/AddAdHocVolume';

const Schedule = React.lazy(() => import('./Schedule/Schedule'));
const TrunkSchedule = React.lazy(() => import('./TrunkSchedule/TrunkSchedule'));
// const AddAdHocVolume = React.lazy(() => import('./AddAdHocVolume/AddAdHocVolume'));

const Summary = props => {
    const {
        dcNo,
        generalOptions,
        generalOptionsLoading,
        generalOptionsSuccess,
        holdOverStoreSchedulesItems,
        holdOverTrunkSchedulesItems,
        plan,
        planId,
        onGetPlan,
        onGetSchedule,
        onGetTrunkSchedule,
        onGetGeneralOptions,
        onSetActiveSchedule,
        onHoldOverStoreSchedules,
        onHoldOverStoreSchedulesReset,
        onHoldOverStoreSchedulesSuccess,
        onHoldOverStoreSchedulesFail,
        onHoldOverStoreSchedulesRunning,
        onHoldOverTrunkSchedules,
        onHoldOverTrunkSchedulesReset,
        onHoldOverTrunkSchedulesSuccess,
        onHoldOverTrunkSchedulesFail,
        onHoldOverTrunkSchedulesRunning,
        schedule,
        scheduleLoading,
        trunkSchedule,
        trunkScheduleLoading
    } = props;

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
    }, []);

    const [showAddAdHocVolumeModal, setShowAddAdHocVolumeModal] = useState(false);
    const [dcSchedule, setDcSchedule] = useState(null);
    const [dcTrunkSchedule, setDcTrunkSchedule] = useState(null);
    const [activeTab, setActiveTab] = useState('store');
    const [addAdHocVolumeModalRecord, setAddAdHocVolumeModalRecord] = useState(null);
    const [generalFieldOptions, setGeneralFieldOptions] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showHoldOverModal, setShowHoldOverModal] = useState(false);
    const [holdOverType, setHoldOverType] = useState(false);

    let growl;

    const tabs = [
        { name: 'Store Schedule', key: 'store' },
        { name: 'Trunk Schedule', key: 'trunk' },
        { name: 'Close', key: 'close' }
    ];

    const pid = props.match ? props.match.params.planId : planId;
    const dcNum = props.match ? props.match.params.dcNo : dcNo;

    const onHoldOverClicked = (rows, type) => {
        setSelectedRows(rows);
        setShowHoldOverModal(true);
        setHoldOverType(type);
    };

    const onHoldOverConfirmed = () => {
        setShowHoldOverModal(false);
        const data = selectedRows.map(s => ({
            LocationNo: s.locationNo,
            LocationName: s.locationName,
            PlanId: s.planId,
            PlanLocationHeaderId: s.planLocationHeaderId,
            Action: 'Hold'
        }));

        switch (holdOverType) {
            case 'store':
                onHoldOverStoreSchedules(data);
                break;
            case 'trunk':
                onHoldOverTrunkSchedules(data);
                break;
            default:
                break;
        }
    };

    const onAdHocVolumeModalClose = useCallback(
        (success, record, message, growler) => {
            if (!growler) growler = growl;
            if (success) {
                setShowAddAdHocVolumeModal(false);
                growler.show({ severity: 'success', summary: message ? message : 'Volume Added' });
            } else if (message) {
                growler.show({ severity: 'error', summary: message });
            } else {
                setShowAddAdHocVolumeModal(false);
            }
        },
        [growl]
    );

    const onShowAddAdHocVolume = useCallback(
        (headerId, saveFunction, saveResetFunction) => {
            const dynamicFields = [];
            dynamicFields.push({
                name: 'Type',
                shortName: 'volumeTypeId',
                fieldTypeName: 'select',
                isRequired: true,
                options: generalFieldOptions.ScheduleViewVolumeTypes
            });
            dynamicFields.push({
                name: 'DUs',
                shortName: 'du',
                fieldTypeName: 'int',
                isRequired: true,
                options: []
            });

            const newformDetails = updateObject(addAdHocVolumeModalRecord, {
                saveFunction: saveFunction,
                saveResetFunction: saveResetFunction,
                record: {
                    headerId: headerId,
                    fields: {
                        du: null,
                        volumeTypeId: null
                    }
                },
                options: {
                    fieldOptions: dynamicFields
                }
            });
            setAddAdHocVolumeModalRecord(newformDetails);
            setTimeout(() => {
                setShowAddAdHocVolumeModal(true);
            }, 100);
        },
        [addAdHocVolumeModalRecord, generalFieldOptions]
    );

    useEffect(() => {
        if (onHoldOverStoreSchedulesRunning === false) {
            if (onHoldOverStoreSchedulesSuccess === true) {
                growl.show({ severity: 'success', summary: 'Deliveries held over.' });
            } else if (onHoldOverStoreSchedulesFail === true) {
                growl.show({ severity: 'error', summary: 'Error setting held over deliveries.' });
            }
        }
    }, [onHoldOverStoreSchedulesSuccess, onHoldOverStoreSchedulesFail, onHoldOverStoreSchedulesRunning, holdOverStoreSchedulesItems, growl]);

    useEffect(() => {
        if (onHoldOverTrunkSchedulesRunning === false) {
            if (onHoldOverTrunkSchedulesSuccess === true) {
                growl.show({ severity: 'success', summary: 'Deliveries held over.' });
            } else if (onHoldOverTrunkSchedulesFail === true) {
                growl.show({ severity: 'error', summary: 'Error setting held over deliveries.' });
            }
        }
    }, [onHoldOverTrunkSchedulesSuccess, onHoldOverTrunkSchedulesFail, onHoldOverTrunkSchedulesRunning, holdOverTrunkSchedulesItems, growl]);

    const holdOverModal = (
        <Modal small handleClose={() => setShowHoldOverModal(!showHoldOverModal)} open={showHoldOverModal}>
            <ModalHeading element="h3">Hold Over Deliver{selectedRows.length > 1 ? 'ies' : 'y'}</ModalHeading>
            <p>
                Are you sure you want to hold over the selected deliver{selectedRows.length > 1 ? 'ies' : 'y'}, this will mean that the selected deliver
                {selectedRows.length > 1 ? 'ies' : 'y'} will be made available to the next plan that is processed,
                <b className={classes.dangerText}>THIS CAN NOT BE UNDONE!</b> Please click 'CONFIRM' to confirm holding over
                {selectedRows.length > 1 ? 'these deliveries' : 'this delivery'}.
            </p>
            <ul>
                {selectedRows.map((r, i) => {
                    return (
                        <li key={i}>
                            {r.locationNo} - {r.locationName}
                        </li>
                    );
                })}
            </ul>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={() => onHoldOverConfirmed(selectedRows)}>Confirm</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={() => setShowHoldOverModal(!showHoldOverModal)}>Cancel</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );

    useEffect(() => {
        // if (dcSchedule === null && dcTrunkSchedule === null && !isNaN(pid) && !isNaN(dcNum) && pid !== null && dcNum !== null) {
        if (dcSchedule === null && dcTrunkSchedule === null && !isNaN(pid) && pid !== null && dcNum !== null) {
            if (!plan) onGetPlan(+pid);
            onGetGeneralOptions('ScheduleViewVolumeTypes', 'Id', 'Name');
            onGetSchedule(+pid, dcNum);
            onGetTrunkSchedule(+pid, dcNum);
        }
    }, [pid, dcNum, plan, onGetPlan, onGetSchedule, onGetTrunkSchedule, dcSchedule, dcTrunkSchedule, onGetGeneralOptions]);

    useEffect(() => {
        if (dcSchedule !== null || !generalFieldOptions || !generalFieldOptions.ScheduleViewVolumeTypes) return;
        setDcSchedule(
            <Schedule
                onAddAdHocVolume={onShowAddAdHocVolume}
                onCloseAdHocVolume={onAdHocVolumeModalClose}
                onHoldOverSchedule={onHoldOverClicked}
                onHoldOverReset={onHoldOverStoreSchedulesReset}
                growl={growl}
            />
        );
    }, [
        schedule,
        dcSchedule,
        generalFieldOptions,
        growl,
        onAdHocVolumeModalClose,
        onShowAddAdHocVolume,
        onHoldOverStoreSchedulesReset,
        onHoldOverTrunkSchedulesReset
    ]);

    useEffect(() => {
        if (generalOptionsLoading === true || !generalOptions || (generalFieldOptions && generalFieldOptions[generalOptions.key])) return;
        if (generalOptionsSuccess === false) {
            growl.show({ severity: 'error', summary: 'Error getting field options from general data.' });
        } else {
            const newGeneralFieldOptions = updateObject(generalFieldOptions, { [generalOptions.key]: generalOptions.value });
            setGeneralFieldOptions(newGeneralFieldOptions);
        }
    }, [generalOptionsLoading, generalOptions, generalOptionsSuccess, setGeneralFieldOptions, generalFieldOptions, growl]);

    useEffect(() => {
        if (dcTrunkSchedule !== null || !generalFieldOptions || !generalFieldOptions.ScheduleViewVolumeTypes) return;
        setDcTrunkSchedule(
            <TrunkSchedule
                onAddAdHocVolume={onShowAddAdHocVolume}
                onHoldOverSchedule={onHoldOverClicked}
                onCloseAdHocVolume={onAdHocVolumeModalClose}
                onHoldOverReset={onHoldOverTrunkSchedulesReset}
                growl={growl}
            />
        );
    }, [trunkSchedule, dcTrunkSchedule, generalFieldOptions, growl, onAdHocVolumeModalClose, onShowAddAdHocVolume, onHoldOverTrunkSchedulesReset]);

    const loading = (
        <ProgressIndicator page loading={true}>
            <ProgressSpinner />
            Loading...
        </ProgressIndicator>
    );

    useEffect(() => {
        if (activeTab !== 'close') return;
        setDcSchedule(null);
        setDcTrunkSchedule(null);
    }, [activeTab]);

    const onHandleTabChange = e => {
        setActiveTab(e);
        onSetActiveSchedule(e);
    };

    const addAdHocVolumeModal = (
        <Modal fullScreen className={classes.largeModal} restrictClose open={showAddAdHocVolumeModal}>
            <AddAdHocVolume
                formDetails={addAdHocVolumeModalRecord}
                handleClose={(success, record, message, growler) => onAdHocVolumeModalClose(success, record, message, growler)}
            />
        </Modal>
    );

    const growler = <Growl className="customGrowl" ref={el => (growl = el)}></Growl>;
    const tabPanel = () => {
        if (dcSchedule === null && dcTrunkSchedule === null) return null;
        else {
            const panels = tabs.map(tab => {
                let sched = null;
                if (tab.key === 'store') sched = <Suspense fallback={loading}>{dcSchedule}</Suspense>;
                if (tab.key === 'trunk') sched = <Suspense fallback={loading}>{dcTrunkSchedule}</Suspense>;
                return (
                    <TabPanel key={tab.key} className={tab.key} active={activeTab === tab.key}>
                        {sched}
                    </TabPanel>
                );
            });

            const pills = tabs.map(tab => {
                if (tab.key === 'close') {
                    return (
                        <Link key={tab.key} to={`/plan/${+pid}`}>
                            <Button
                                style={{ float: 'right' }}
                                onClick={() => onHandleTabChange('close')}
                                icon="pi pi-times"
                                tooltip="Close Schedule"
                                tooltipOptions={{ position: 'left' }}
                            />
                        </Link>
                    );
                }

                return (
                    <div
                        key={tab.key}
                        className={`ln-c-tabs__link ${activeTab === tab.key ? 'is-active' : ''}`}
                        role="tab"
                        onClick={() => onHandleTabChange(tab.key)}
                        aria-selected="true"
                    >
                        {tab.name}
                    </div>
                );
            });

            return (
                <Fragment>
                    <nav className="ln-c-tabs ln-c-tabs">
                        <div className="ln-c-tabs__list-wrap">
                            <div className="ln-c-tabs__list">{pills}</div>
                        </div>
                    </nav>
                    {panels}
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            {scheduleLoading || trunkScheduleLoading ? loading : null}
            {!(scheduleLoading || trunkScheduleLoading) && (schedule !== null || dcTrunkSchedule !== null) ? tabPanel() : null}
            {addAdHocVolumeModal}
            {growler}
            {holdOverModal}
        </Fragment>
    );
};

Summary.propTypes = {
    dcNo: PropTypes.string,
    generalOptions: PropTypes.object,
    generalOptionsLoading: PropTypes.bool,
    generalOptionsSuccess: PropTypes.bool,
    holdOverStoreSchedulesItems: PropTypes.array,
    holdOverTrunkSchedulesItems: PropTypes.array,
    match: PropTypes.object,
    onGetGeneralOptions: PropTypes.func,
    onGetPlan: PropTypes.func,
    onGetSchedule: PropTypes.func,
    onGetTrunkSchedule: PropTypes.func,
    onHoldOverStoreSchedules: PropTypes.func,
    onHoldOverStoreSchedulesFail: PropTypes.bool,
    onHoldOverStoreSchedulesReset: PropTypes.func,
    onHoldOverStoreSchedulesRunning: PropTypes.bool,
    onHoldOverStoreSchedulesSuccess: PropTypes.bool,
    onHoldOverTrunkSchedules: PropTypes.func,
    onHoldOverTrunkSchedulesFail: PropTypes.bool,
    onHoldOverTrunkSchedulesReset: PropTypes.func,
    onHoldOverTrunkSchedulesRunning: PropTypes.bool,
    onHoldOverTrunkSchedulesSuccess: PropTypes.bool,
    onSetActiveSchedule: PropTypes.func,
    plan: PropTypes.object,
    planId: PropTypes.number,
    schedule: PropTypes.object,
    scheduleLoading: PropTypes.bool,
    trunkSchedule: PropTypes.object,
    trunkScheduleLoading: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        outputSummary: state.plan.outputSummary,
        plan: state.plan.plan,
        processPlanSuccess: state.plan.processPlanSuccess,
        schedule: state.schedule.schedule,
        scheduleSuccess: state.schedule.scheduleSuccess,
        scheduleLoading: state.schedule.scheduleLoading,
        trunkSchedule: state.trunkSchedule.trunkSchedule,
        trunkScheduleSuccess: state.trunkSchedule.trunkScheduleSuccess,
        trunkScheduleLoading: state.trunkSchedule.trunkScheduleLoading,
        holdOverStoreSchedulesItems: state.schedule.holdOverStoreSchedulesItems,
        onHoldOverStoreSchedulesRunning: state.schedule.holdOverStoreSchedulesRunning,
        onHoldOverStoreSchedulesSuccess: state.schedule.holdOverStoreSchedulesSuccess,
        onHoldOverStoreSchedulesFail: state.schedule.holdOverStoreSchedulesFail,
        holdOverTrunkSchedulesItems: state.trunkSchedule.holdOverTrunkSchedulesItems,
        onHoldOverTrunkSchedulesRunning: state.trunkSchedule.holdOverTrunkSchedulesRunning,
        onHoldOverTrunkSchedulesSuccess: state.trunkSchedule.holdOverTrunkSchedulesSuccess,
        onHoldOverTrunkSchedulesFail: state.trunkSchedule.holdOverTrunkSchedulesFail,
        generalOptionsLoading: state.general.loading,
        generalOptionsSuccess: state.general.success,
        generalOptions: state.general.options
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetPlan: planId => dispatch(actions.getPlan(planId)),
        onGetSchedule: (planId, dcNo) => dispatch(actions.getSchedule(planId, dcNo)),
        onHoldOverStoreSchedules: schedules => dispatch(actions.holdOverStoreSchedules(schedules)),
        onHoldOverTrunkSchedules: schedules => dispatch(actions.holdOverTrunkSchedules(schedules)),
        onHoldOverStoreSchedulesReset: () => dispatch(actions.holdOverStoreSchedulesReset()),
        onHoldOverTrunkSchedulesReset: () => dispatch(actions.holdOverTrunkSchedulesReset()),
        onGetGeneralOptions: (tableName, valueColumn, labelColumn) => dispatch(actions.generalGetDataAsOptions(tableName, valueColumn, labelColumn)),
        onGetTrunkSchedule: (planId, dcNo) => dispatch(actions.getTrunkSchedule(planId, dcNo)),
        onSetActiveSchedule: tab => dispatch(actions.setActiveSchedule(tab))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Summary, axios));
