import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from '../../axios';

import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
// import * as actions from '../../store/actions/index';

/* Luna imports*/
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { SiteLayout } from '@jsluna/site-layout';
import { Header, HeaderLogo, HeaderNav } from '@jsluna/header';
import { IconButton } from '@jsluna/button';
import { GridView } from '@jsluna/icons';
import { Modal } from '@jsluna/modal';
import { Card } from '@jsluna/card';
import { ListGroup, ListGroupDropdown, ListGroupItem } from '@jsluna/list';

import User from './User/User';

import classes from './Layout.module.scss';
import './Layout.scss';

import { menuOptions } from './MenuOptions/MenuOptions';

const Layout = props => {
    const [size, setSize] = useState([]);
    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            setMenuListState(menuOptions.mainMenu);
        } else document.body.classList.toggle('has-overlay', size[0] <= 720);
    }, [mounted, size]);

    useLayoutEffect(() => {
        const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const [menuOpen, setMenuModalState] = useState(false);
    const [menuList, setMenuListState] = useState([]);

    const menuModalHandler = () => setMenuModalState(!menuOpen);

    const menuItems = () => {
        if (!menuList || menuList.length === 0) return <div></div>;

        return menuList.map((m, ind) => (
            <GridItem key={ind} element="li" size="1/3">
                <Link onClick={menuModalHandler} to={m.to}>
                    <Card className={[classes.cardLink, 'cardAction'].join(' ')} style={{ height: '100%' }}>
                        <h5>{m.title}</h5>
                        <p>{m.description}</p>
                    </Card>
                </Link>
            </GridItem>
        ));
    };

    const removeFocus = e => {
        if (e) e.preventDefault();
    };

    const whichMenu = () => {
        if (size[0] <= 720) {
            return (
                <ListGroupDropdown id="shop">
                    Menu
                    <ListGroup>
                        {menuList.map((m, ind) => (
                            <ListGroupItem key={ind} element="a" href={`#${m.to}`}>
                                {m.title}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </ListGroupDropdown>
            );
        } else {
            return (
                <div className={classes.ignoreMe}>
                    <IconButton
                        variant="text"
                        label="Open Main Menu"
                        hideLabel
                        onClick={menuModalHandler}
                        onMouseDown={removeFocus}
                        onKeyUp={e => (e.keyCode === 13 || e.keyCode === 32 ? removeFocus() : null)}
                    >
                        <GridView />
                    </IconButton>
                </div>
            );
        }
    };

    return (
        <SiteLayout stickyHeader className="ln-u-bg-color-grey-v-light">
            <Header>
                <HeaderLogo>
                    <Link to="/" className={classes.plainLink}>
                        PLEX
                    </Link>
                </HeaderLogo>
                {!props.isAuthenticated ? null : (
                    <HeaderNav drawer label="Main">
                        {whichMenu()}
                        {
                            <User
                                classes={[classes.ignoreMe, 'ln-c-list-group__item', 'ln-c-header__nav-item'].join(' ')}
                                click={e => menuModalHandler(e)}
                                keyUp={e => removeFocus(e)}
                            />
                        }
                    </HeaderNav>
                )}
            </Header>
            <Container size={window.location.href.indexOf('/plan/') > 0 ? 'lg' : 'soft'}>{props.children}</Container>

            <Modal className={classes.modal_bg} hideCloseButton handleClose={menuModalHandler} open={menuOpen}>
                <GridWrapper matrix element="ul" equalHeight className="ln-u-bg-color-grey-v-light ln-u-soft">
                    {menuItems()}
                </GridWrapper>
            </Modal>
        </SiteLayout>
    );
};

Layout.propTypes = {
    children: PropTypes.node,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.givenName !== null
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onSetAuthRedirectPath: path => dispatch(actions.setAuthRedirectPath(path))
//     };
// };

export default connect(mapStateToProps)(withErrorHandler(Layout, axios));
