import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    locations: null,
    locationsError: null,
    updateLocationDetailsRunning: null,
    updateLocationDetailsError: null,
    updateLocationDetailsSuccess: null
};

const getLocationsResponse = (state, action) => {
    return updateObject(state, {
        locations: action.locations === undefined ? state.locations : action.locations,
        locationsError: action.locationsError === undefined ? state.locationsError : action.locationsError
    });
};

const updateLocationDetails = (state, action) =>
    updateObject(state, {
        updateLocationDetailsRunning:
            action.updateLocationDetailsRunning === undefined ? state.updateLocationDetailsRunning : action.updateLocationDetailsRunning,
        updateLocationDetailsError: action.updateLocationDetailsError === undefined ? state.updateLocationDetailsError : action.updateLocationDetailsError,
        updateLocationDetailsSuccess:
            action.updateLocationDetailsSuccess === undefined ? state.updateLocationDetailsSuccess : action.updateLocationDetailsSuccess
    });

// prettier-ignore
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LOCATIONS_START: return getLocationsResponse(state, action);
        case actionTypes.GET_LOCATIONS_FAIL: return getLocationsResponse(state, action);
        case actionTypes.GET_LOCATIONS_SUCCESS: return getLocationsResponse(state, action);
        case actionTypes.GET_LOCATIONS_RESET: return getLocationsResponse(state, action);
        case actionTypes.UPDATE_LOCATION_DETAILS:
            return updateLocationDetails(state, action);
        default: return state;
    }
};

export default reducer;
