import React from 'react';
import PropTypes from 'prop-types';

import { GridItem, GridWrapper } from '@jsluna/grid';
import { TextInputField } from '@jsluna/form';
import classes from './Schedule.module.scss';

import { Button } from 'primereact/button';

// const comments = ['Warehouse', 'Transport', 'Destination', 'Other'];

const ScheduleExpanded = props => {
    const { edit, cols, details, onEditTotal, onAddVolume } = props;

    // const volumes = details.map((c, i) => {
    //     let inputs;
    //     return Object.keys(c).map(m => {
    //         if (m === 'volumeGradeId') return false;
    //         return (
    //             <GridItem size="1/12" key={i}>
    //                 <TextInputField name="text-input-1" placeholder="Placeholder" label={m} readOnly={!edit} defaultValue={details[m]} />
    //             </GridItem>
    //         );
    //     });
    // });

    const groups = details.reduce((groups, item) => {
        const gpName = `${item.volumeTypeName}`;
        // const gpName = `${item.volumeTypeId}`;
        const group = groups[gpName] || [];
        group.push(item);
        groups[gpName] = group;
        return groups;
    }, {});

    const totalItem = (groups, grp, i) => {
        if (edit) {
            return (
                <TextInputField
                    name={`text-input-${i}`}
                    placeholder="Placeholder"
                    label="Total"
                    disabled={!edit}
                    hideLabel
                    readOnly={!edit}
                    onChange={e => onEditTotal(e, grp)}
                    defaultValue={Math.ceil(groups[grp].reduce((a, b) => ({ du: a.du + b.du })).du).toString()}
                    style={{ height: '28px' }}
                />
            );
        } else {
            return <div>{Math.ceil(groups[grp].reduce((a, b) => ({ du: a.du + b.du })).du).toString()} </div>;
        }
    };

    const headers = [];
    return (
        <GridWrapper className={['ln-u-flush', classes.expandedDetails].join(' ')}>
            <GridItem size="1/2">
                {Object.keys(groups).map((grp, i) => {
                    let addTotal = i === 0;
                    return (
                        <GridWrapper key={grp} className="ln-u-flush">
                            <GridItem size="1/6">
                                {i === 0 ? (
                                    <div>
                                        <br />
                                    </div>
                                ) : null}
                                {grp}
                            </GridItem>
                            {groups[grp].map(m => {
                                let addHeader = false;
                                if (headers.findIndex(h => h === m.volumeGradeName) === -1) {
                                    addHeader = true;
                                    headers.push(m.volumeGradeName);
                                    addTotal = true;
                                }
                                return (
                                    <GridItem size="1/6" key={`${i}${m.volumeGradeName}`}>
                                        {/* <TextInputField
                                            name="text-input-1"
                                            placeholder="Placeholder"
                                            label={m.volumeGradeName}
                                            readOnly={!edit}
                                            defaultValue={m.du.toString()}
                                        /> */}
                                        {addHeader ? <div style={{ fontWeight: 'bold' }}>{m.volumeGradeName}</div> : null}
                                        {(Math.round((m.du + Number.EPSILON) * 100) / 100).toString()}
                                    </GridItem>
                                );
                            })}
                            <GridItem size="1/6">
                                {addTotal ? <div style={{ fontWeight: 'bold' }}>Total</div> : null}
                                {totalItem(groups, grp, i)}
                            </GridItem>
                        </GridWrapper>
                    );
                })}
            </GridItem>
            <GridItem size="1/2">
                {cols.map((c, i) => {
                    return (
                        <GridItem size="1/2" key={c.props.field}>
                            <TextInputField name={`comment-text-input-${i}`} placeholder="Placeholder" label={c.props.header} readOnly={!edit} />
                        </GridItem>
                    );
                })}
            </GridItem>
            <GridItem size="1/2">
                <Button onClick={e => onAddVolume(e)} icon="pi pi-plus" className="buttonActive" tooltip="Add Volume" tooltipOptions={{ position: 'left' }} />
            </GridItem>
        </GridWrapper>
    );
};

ScheduleExpanded.propTypes = {
    cols: PropTypes.array,
    details: PropTypes.array,
    edit: PropTypes.bool,
    onAddVolume: PropTypes.func,
    onEditTotal: PropTypes.func
};

export default ScheduleExpanded;
