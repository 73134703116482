import axios from '../../axios';

import * as actionTypes from './actionTypes';

import moment from 'moment';
moment.locale('en-GB');

export const getDICSV = (planId, date, limit) => {
    return dispatch => {
        dispatch(getDICSVStart(date));

        const url = `/di/getoutput/${planId}/${date ? date : 'none'}/${limit}`;

        axios
            .get(url)
            .then(response => dispatch(getDICSVSuccess(response.data)))
            .catch(error => dispatch(getDICSVFail(error)));
    };
};

export const getDICSVStart = date => ({
    type: actionTypes.GET_DI_CSV,
    csv: null,
    csvError: null,
    csvSuccess: null,
    csvLoading: true,
    date
});

export const getDICSVSuccess = csv => ({
    type: actionTypes.GET_DI_CSV,
    csv,
    csvError: null,
    csvSuccess: true,
    csvLoading: false
});

export const getDICSVFail = csvError => ({
    type: actionTypes.GET_DI_CSV,
    csv: null,
    csvError,
    csvSuccess: false,
    csvLoading: false
});

export const getDIJson = (planId, date, limit) => {
    return dispatch => {
        dispatch(getDIJsonStart(date));

        const url = `/di/downloadjson/${planId}/${date ? date : 'none'}/${limit}`;

        axios
            .get(url, { responseType: 'blob' })
            .then(response => dispatch(getDIJsonSuccess(response.data)))
            .catch(error => dispatch(getDIJsonFail(error)));
    };
};

export const getDIJsonStart = date => ({
    type: actionTypes.GET_DI_JSON,
    json: null,
    jsonError: null,
    jsonSuccess: null,
    jsonLoading: true,
    date
});

export const getDIJsonSuccess = json => ({
    type: actionTypes.GET_DI_JSON,
    json,
    jsonError: null,
    jsonSuccess: true,
    jsonLoading: false
});

export const getDIJsonFail = jsonError => ({
    type: actionTypes.GET_DI_JSON,
    json: null,
    jsonError,
    jsonSuccess: false,
    jsonLoading: false
});

export const getDIPlansStart = () => ({ type: actionTypes.GET_DI_PLANS_START, getDIPlansError: null });
export const getDIPlansSuccess = diPlans => ({ type: actionTypes.GET_DI_PLANS_SUCCESS, diPlans, getDIPlansError: null });
export const getDIPlansFail = error => ({ type: actionTypes.GET_DI_PLANS_FAIL, getDIPlansError: error });

export const getDIPlans = () => {
    return dispatch => {
        dispatch(getDIPlansStart());

        const url = '/di/getplans';

        axios
            .get(url)
            .then(response => {
                dispatch(getDIPlansSuccess(response.data));
            })
            .catch(error => dispatch(getDIPlansFail(error)));
    };
};

export const getDIPlanDetailStart = () => ({ type: actionTypes.GET_DI_PLAN_DETAIL_START, getDIPlanDetailError: null });
export const getDIPlanDetailSuccess = diPlanDetail => ({ type: actionTypes.GET_DI_PLAN_DETAIL_SUCCESS, diPlanDetail, getDIPlanDetailError: null });
export const getDIPlanDetailFail = error => ({ type: actionTypes.GET_DI_PLAN_DETAIL_FAIL, getDIPlanDetailError: error });

export const getDIPlanDetail = id => {
    return dispatch => {
        dispatch(getDIPlanDetailStart());

        const url = `/di/getplan/${id}`;

        axios
            .get(url)
            .then(response => {
                dispatch(getDIPlanDetailSuccess(response.data));
            })
            .catch(error => dispatch(getDIPlanDetailFail(error)));
    };
};

export const diGetCdcStart = () => ({ type: actionTypes.DI_GET_CDC_START });
export const diGetCdcSuccess = cdc => ({ type: actionTypes.DI_GET_CDC_SUCCESS, cdc: cdc });
export const diGetCdcFail = error => ({ type: actionTypes.DI_GET_CDC_FAIL, getCdcError: error });

export const diGetCdc = wcDate => {
    return dispatch => {
        const url = `/di/getcdcmovingdi/${wcDate}`;
        dispatch(diGetCdcStart());

        axios
            .get(url)
            .then(response => dispatch(diGetCdcSuccess(response.data)))
            .catch(error => dispatch(diGetCdcFail(error)));
    };
};

export const createNewDIPlanStart = () => ({ type: actionTypes.DI_CREATE_PLAN_START });
export const createNewDIPlanSuccess = planId => ({ type: actionTypes.DI_CREATE_PLAN_SUCCESS, planId: planId });
export const createNewDIPlanFail = error => ({ type: actionTypes.DI_CREATE_PLAN_FAIL, createNewDIPlanError: error });

export const createNewDIPlan = planDetails => {
    return dispatch => {
        const name = encodeURI(planDetails.name);
        const url = `/di/createplan/${name}/${planDetails.date.format('YYYY-MM-DD')}`;
        dispatch(createNewDIPlanStart());

        axios
            .get(url)
            .then(response => dispatch(createNewDIPlanSuccess(response.data)))
            .catch(error => dispatch(createNewDIPlanFail(error)));
    };
};

export const diPlanReset = () => ({ type: actionTypes.DI_PLAN_RESET, planId: null, diPlanDetail: null });

export const diGetForecastTrunksStart = () => ({ type: actionTypes.DI_GET_FORECAST_TRUNKS_START });
export const diGetForecastTrunksSuccess = forecastTrunks => ({ type: actionTypes.DI_GET_FORECAST_TRUNKS_SUCCESS, forecastTrunks: forecastTrunks });
export const diGetForecastTrunksFail = error => ({ type: actionTypes.DI_GET_FORECAST_TRUNKS_FAIL, diGetForecastTrunksError: error });

export const diGetForecastTrunks = planId => {
    return dispatch => {
        const url = `/di/gettrunkforecast/${planId}`;
        dispatch(diGetForecastTrunksStart());

        axios
            .get(url)
            .then(response => dispatch(diGetForecastTrunksSuccess(response.data)))
            .catch(error => dispatch(diGetForecastTrunksFail(error)));
    };
};

export const diSaveChangesStart = () => ({ type: actionTypes.DI_SAVE_CHANGES_START });
export const diSaveChangesSuccess = success => ({ type: actionTypes.DI_SAVE_CHANGES_SUCCESS, saveSuccess: success });
export const diSaveChangesFail = error => ({ type: actionTypes.DI_SAVE_CHANGES_FAIL, saveError: error });

export const diSaveChanges = (changes, planId) => {
    return dispatch => {
        const url = `/di/savechanges/${planId}`;
        dispatch(diSaveChangesStart());

        axios
            .post(url, changes)
            .then(response => dispatch(diSaveChangesSuccess(response.data)))
            .catch(error => dispatch(diSaveChangesFail(error)));
    };
};

export const diWLFCStart = () => ({ type: actionTypes.DI_WLFC_START });
export const diWLFCSuccess = wlfc => ({ type: actionTypes.DI_WLFC_SUCCESS, WLFC: wlfc });
export const diWLFCFail = error => ({ type: actionTypes.DI_WLFC_FAIL, WLFCError: error });

export const diWLFC = planId => {
    return dispatch => {
        const url = `/di/getforecastmodels/${planId}`;
        dispatch(diWLFCStart());

        axios
            .get(url)
            .then(response => dispatch(diWLFCSuccess(response.data)))
            .catch(error => dispatch(diWLFCFail(error)));
    };
};

export const diDownloadRailStart = () => ({ type: actionTypes.DI_DOWNLOAD_RAIL_START });
export const diDownloadRailSuccess = rail => ({ type: actionTypes.DI_DOWNLOAD_RAIL_SUCCESS, rail: rail });
export const diDownloadRailFail = error => ({ type: actionTypes.DI_DOWNLOAD_RAIL_FAIL, downloadRailError: error });

export const diDownloadRail = planId => {
    return dispatch => {
        const url = `/di/downloadrail/${planId}`;
        dispatch(diDownloadRailStart());

        axios
            .get(url, { responseType: 'blob' })
            .then(response => dispatch(diDownloadRailSuccess(response.data)))
            .catch(error => dispatch(diDownloadRailFail(error)));
    };
};

export const setActiveDIPlanStart = () => ({
    type: actionTypes.DI_PLAN_SET_ACTIVE,
    setActiveDIPlanError: null,
    setActiveDIPlanSuccess: null,
    settingActiveDIPlan: true
});

export const setActiveDIPlanSuccess = success => ({
    type: actionTypes.DI_PLAN_SET_ACTIVE,
    setActiveDIPlanError: null,
    setActiveDIPlanSuccess: success,
    settingActiveDIPlan: null
});

export const setActiveDIPlanFail = error => ({
    type: actionTypes.DI_PLAN_SET_ACTIVE,
    setActiveDIPlanError: error,
    setActiveDIPlanSuccess: false,
    settingActiveDIPlan: null
});

export const setActiveDIPlan = (planId, active) => {
    return dispatch => {
        dispatch(setActiveDIPlanStart());

        const url = `/di/setactive/${planId}/${active ? 'true' : 'false'}`;

        axios
            .get(url)
            .then(response => dispatch(setActiveDIPlanSuccess(response.data)))
            .catch(error => dispatch(setActiveDIPlanFail(`Failed setting DI plan active status: ${error}`)));
    };
};

export const setActiveDIPlanReset = () => ({
    type: actionTypes.DI_PLAN_SET_ACTIVE,
    setActiveDIPlanError: null,
    setActiveDIPlanSuccess: null,
    settingActiveDIPlan: null
});

export const diPlanDeleteStart = () => ({
    type: actionTypes.DI_PLAN_DELETE,
    diPlanDeleting: true,
    diPlanDeleteSuccess: null,
    diPlanDeleteError: null
});

export const diPlanDeleteSuccess = success => ({
    type: actionTypes.DI_PLAN_DELETE,
    diPlanDeleting: false,
    diPlanDeleteSuccess: success,
    diPlanDeleteError: null
});
export const diPlanDeleteFail = () => ({
    type: actionTypes.DI_PLAN_DELETE,
    diPlanDeleting: false,
    diPlanDeleteSuccess: null,
    diPlanDeleteError: null
});

export const diPlanDeleteReset = () => ({
    type: actionTypes.DI_PLAN_DELETE,
    diPlanDeleting: null,
    diPlanDeleteSuccess: null,
    diPlanDeleteError: null
});

export const diPlanDelete = planId => {
    return dispatch => {
        dispatch(diPlanDeleteStart());

        const url = `/di/delete/${planId}`;

        axios
            .delete(url)
            .then(response => dispatch(diPlanDeleteSuccess(response.data)))
            .catch(error => dispatch(diPlanDeleteFail(`Failed deleting DI plan: ${error}`)));
    };
};

// #region publish
export const publish = (planId, date, limit) => {
    return dispatch => {
        dispatch(publishStart());

        const url = `/di/publishJson/${planId}/${date ? date : 'none'}/${limit}`;

        axios
            .get(url)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(publishSuccess(response.data));
                else dispatch(publishFail(response.data));
            })
            .catch(error => dispatch(publishFail(error)));
    };
};
export const publishStart = () => ({
    type: actionTypes.PUBLISH_DI,
    publishSuccess: null,
    publishRunning: true,
    publishError: null,
    publishedInfo: null
});

export const publishSuccess = published => ({
    type: actionTypes.PUBLISH_DI,
    publishSuccess: true,
    publishRunning: false,
    publishError: null,
    publishedInfo: published
});

export const publishFail = error => ({
    type: actionTypes.PUBLISH_DI,
    publishSuccess: false,
    publishRunning: false,
    publishError: error,
    publishedInfo: null
});

export const publishReset = () => ({
    type: actionTypes.PUBLISH_DI,
    publishSuccess: null,
    publishRunning: null,
    publishError: null
});
// #endregion
