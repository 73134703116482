import React, { Fragment, Suspense, useEffect, useState } from 'react';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { ListView, Settings } from '@jsluna/icons';

import { Growl } from 'primereact/growl';

import About from './About/About';
import Animation from './Animation/Animation';

import classes from './Configuration.module.scss';

const AuditLog = React.lazy(() => import('./Audit/Audit'));
const Email = React.lazy(() => import('./Email/Email'));
const PlanConfig = React.lazy(() => import('./PlanConfig/PlanConfig'));

const Configuration = () => {
    const [content, setContent] = useState(<span></span>);
    const [selectedPage, setSelectedPage] = useState(null);
    let growl;

    const loading = (
        <ProgressIndicator page loading={true}>
            <ProgressSpinner />
            Loading...
        </ProgressIndicator>
    );

    useEffect(() => {
        // lazy load the required option
        switch (selectedPage) {
            case 'planconfig':
                setContent(<PlanConfig planId={-1} growl={growl} />);
                break;
            case 'audit':
                setContent(<AuditLog />);
                break;
            case 'email':
                setContent(<Email />);
                break;
            default:
                break;
        }
    }, [growl, selectedPage, setContent]);

    const newLocClasses = [classes.menuItem];
    const defaultContent = (
        <Fragment>
            <h3 className="ln-u-push-top">PLEX Configuration</h3>
            <div className={classes.animation}>
                <Animation />
            </div>
            <div>
                <About />
            </div>
        </Fragment>
    );

    return (
        <Container className={classes.container}>
            <Growl ref={el => (growl = el)} style={{ zIndex: '9999' }} sticky="true" />
            <GridWrapper className="ln-u-push-top">
                <GridItem size="1/4" className={classes.menuContainer}>
                    <GridWrapper>
                        <GridItem size="1/1" className={classes.settingsIcon}>
                            <div style={{ cursor: 'pointer' }} onClick={() => setSelectedPage(null)}>
                                <Settings />
                                <h6>Configuration</h6>
                            </div>
                        </GridItem>
                        <GridItem size="1/1">
                            <div className={newLocClasses.join(' ')} onClick={() => setSelectedPage('planconfig')}>
                                <span className={`pi pi-sliders-v ${classes.picon}`}></span>
                                <h5>Configuration</h5>
                            </div>
                        </GridItem>
                        <GridItem size="1/1">
                            <div className={classes.menuItem} onClick={() => setSelectedPage('email')}>
                                <span className={`pi pi-users ${classes.picon}`}></span>
                                <h5>Email Groups</h5>
                            </div>
                        </GridItem>
                        <GridItem size="1/1">
                            <div className={classes.menuItem} onClick={() => setSelectedPage('audit')}>
                                <ListView className={classes.icon} />
                                <h5>Audit Log</h5>
                            </div>
                        </GridItem>
                    </GridWrapper>
                </GridItem>
                <GridItem size="2/3">{selectedPage ? <Suspense fallback={loading}>{content}</Suspense> : defaultContent}</GridItem>
            </GridWrapper>
        </Container>
    );
};

export default withErrorHandler(Configuration, axios);
