import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import axios from '../../axios';

import { Container } from '@jsluna/grid';
import { Card } from '@jsluna/card';

import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';

import Intro from './Intro';

const Home = props => {
    let message = <span style={{ color: 'red' }}>Please login to access the features</span>;

    if (props.isAuthenticated) {
        // prettier-ignore
        message = 'Welcome to the home of planning for Sainsbury\'s Argos distribution';
    }

    return (
        <Container soft size="def">
            <Card>
                <p>{message}</p>
                <Intro />
            </Card>
        </Container>
    );
};

Home.propTypes = {
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.givenName !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: path => dispatch(actions.setAuthRedirectPath(path))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Home, axios));
