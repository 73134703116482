import axios from '../../axios';
import * as actionTypes from './actionTypes';

import { getOutputSummaryReset } from './plans';
export const processPlanStart = () => {
    return {
        type: actionTypes.PROCESS_PLAN_START,
        processPlanRunning: true,
        processPlanFail: null,
        processPlanComplete: false
    };
};

export const processPlanRunning = () => {
    return {
        type: actionTypes.PROCESS_PLAN_RUNNING,
        processPlanRunning: true,
        processPlanFail: null,
        processPlanComplete: false
    };
};

export const processPlanComplete = () => {
    return {
        type: actionTypes.PROCESS_PLAN_COMPLETE,
        processPlanRunning: false,
        processPlanFail: null,
        processPlanComplete: true
    };
};

export const processPlanFail = error => {
    return {
        type: actionTypes.PROCESS_PLAN_FAIL,
        processPlanRunning: null,
        processPlanFail: error,
        processPlanComplete: null
    };
};
export const processPlanReset = () => {
    return {
        type: actionTypes.PROCESS_PLAN_RESET,
        processPlanRunning: null,
        processPlanFail: null,
        processPlanComplete: null
    };
};

export const processPlanUpdateStatus = status => {
    return {
        type: actionTypes.PROCESS_PLAN_UPDATE_STATUS,
        processPlanStatus: status
    };
};

export const processPlan = planId => {
    return dispatch => {
        dispatch(getOutputSummaryReset());
        dispatch(processPlanStart());
        const url = `/Plan/ProcessPlan/${planId}`;

        axios
            .post(url)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    dispatch(processPlanRunning(response.data));
                } else dispatch(processPlanFail(`Failed processing plan ${response.data}`));
            })
            .catch(error => {
                dispatch(processPlanFail(`Failed processing plan ${error}`));
            });
    };
};
