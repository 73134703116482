import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    cvlSummary: null
};

const cvlStart = state => updateObject(state, { cvlSummaryLoading: true });
const cvlSuccess = (state, action) => updateObject(state, { cvlSummary: action.cvlSummary, cvlSummaryLoading: false });
const cvlFail = (state, action) => updateObject(state, { cvlSummaryError: action.cvlSummaryError, cvlSummaryLoading: false });

const pullCvlStart = state => updateObject(state, { pullingCvl: true });
const pullCvlSuccess = (state, action) => updateObject(state, { pullingCvl: false, pullStatus: action.pullStatus });
const pullCvlFail = (state, action) => updateObject(state, { pullingCvl: false, pullError: action.pullError });

const getCvlListStart = state => updateObject(state, { gettingCvlList: true });
const getCvlListSuccess = (state, action) => updateObject(state, { gettingCvlList: false, cvlList: action.cvlList });
const getCvlListFail = (state, action) => updateObject(state, { gettingCvlList: false, cvlListError: action.cvlListError });

const getWeightedGrowthStart = state => updateObject(state, { gettingGrowth: true });
const getWeightedGrowthSuccess = (state, action) => updateObject(state, { gettingGrowth: false, growth: action.weightedGrowth });
const getWeightedGrowthFail = (state, action) => updateObject(state, { gettingGrowth: false, growthError: action.weightedGrowthError });

const getGrowthSummaryStart = state => updateObject(state, { gettingGrowthSummary: true });
const getGrowthSummarySuccess = (state, action) => updateObject(state, { gettingGrowthSummary: false, growthSummary: action.growthSummary });
const getGrowthSummaryFail = (state, action) => updateObject(state, { gettingGrowthSummary: false, growthSummaryError: action.growthSummaryError });

// prettier-ignore
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CVL_START: return cvlStart(state);
        case actionTypes.CVL_SUCCESS: return cvlSuccess(state, action);
        case actionTypes.CVL_FAIL: return cvlFail(state, action);
        case actionTypes.PULL_CVL_START: return pullCvlStart(state);
        case actionTypes.PULL_CVL_SUCCESS: return pullCvlSuccess(state, action);
        case actionTypes.PULL_CVL_FAIL: return pullCvlFail(state, action);
        case actionTypes.GET_CVL_LIST_START: return getCvlListStart(state);
        case actionTypes.GET_CVL_LIST_SUCCESS: return getCvlListSuccess(state, action);
        case actionTypes.GET_CVL_LIST_FAIL: return getCvlListFail(state, action);
        case actionTypes.GET_WEIGHTED_GROWTH_START: return getWeightedGrowthStart(state);
        case actionTypes.GET_WEIGHTED_GROWTH_SUCCESS: return getWeightedGrowthSuccess(state, action);
        case actionTypes.GET_WEIGHTED_GROWTH_FAIL: return getWeightedGrowthFail(state, action);
        case actionTypes.GET_GROWTH_SUMMARY_START: return getGrowthSummaryStart(state);
        case actionTypes.GET_GROWTH_SUMMARY_SUCCESS: return getGrowthSummarySuccess(state, action);
        case actionTypes.GET_GROWTH_SUMMARY_FAIL: return getGrowthSummaryFail(state, action);
        default:
            return state;
    }
};

export default reducer;
