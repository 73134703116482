import React from 'react';
import PropTypes from 'prop-types';

import { ButtonGroupPrimary, ButtonGroupWrapper, FilledButton } from '@jsluna/button';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { Modal, ModalHeading } from '@jsluna/modal';

import Warning from '../../UI/Icons/Warning';

const FailedModal = props => {
    return (
        <Modal handleClose={props.handleClose} open={props.open} fullScreen>
            <GridWrapper>
                <GridItem size="1/1">
                    <ModalHeading element="h3" className="ln-u-push-bottom">
                        Failed Plan
                    </ModalHeading>
                </GridItem>
                <GridItem size="1/4">
                    <Warning height="120px" width="120px" />
                </GridItem>
                <GridItem size="3/4" className="ln-u-push-bottom">
                    <GridWrapper>
                        <GridItem size="1/1" style={{ fontWeight: 'bold', fontSize: '1.5rem' }} className="ln-u-color-orange">
                            This plan will be deleted, please try creating the plan again.
                        </GridItem>
                        <GridItem size="1/1" className="ln-u-push-top">
                            If the problem persists, please contact the service desk.
                        </GridItem>
                    </GridWrapper>
                </GridItem>
            </GridWrapper>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={props.handleClose} style={{ width: '140px' }}>
                        OK
                    </FilledButton>
                </ButtonGroupPrimary>
            </ButtonGroupWrapper>
        </Modal>
    );
};

FailedModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default FailedModal;
