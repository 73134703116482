import React, { Fragment, Suspense, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../axios';
import * as actions from '../../store/actions/index';

import { Card } from '@jsluna/card';
import { Checkbox, Form, FormGroup, TextInputField } from '@jsluna/form';
import { ListGroup, ListGroupItem } from '@jsluna/list';

import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';

import 'react-dates/initialize';
import { SingleDatePicker } from '@jsluna/date-picker';
import { updateObject } from '../../shared/utility';

import { Modal } from '@jsluna/modal';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

import { saveAs } from 'file-saver';

import Growth from './Growth/Growth';
import moment from 'moment';

import ConfirmationDialog from '../UI/ConfirmationDialog/ConfirmationDialog';
import PublishedModal from './PublishedModal/PublishedModal';

moment.locale('en-GB');

const PlanTabs = React.lazy(() => import('./Tabs/Tabs'));
const PlanConfig = React.lazy(() => import('../Configuration/PlanConfig/PlanConfig'));

const Plan = props => {
    const {
        plan,
        creatingPlan,
        gettingPlan,
        onGetPlan,
        planLoading,
        onDeletePlan,
        planSuccess,
        deletePlanSuccess,
        onDeletePlanReset,
        history,
        publishScheduleError,
        publishScheduleSuccess,
        publishScheduleRunning,
        downloadJsonScheduleData,
        downloadJsonScheduleError,
        downloadJsonScheduleFilename,
        downloadJsonScheduleSuccess,
        downloadJsonScheduleRunning,
        onPublishSchedule,
        onPublishScheduleReset,
        onDownloadJsonSchedule,
        onDownloadJsonScheduleReset,
        onUnpublishSchedule,
        onUnpublishScheduleReset,
        updatingPlan,
        unpublishScheduleError,
        unpublishScheduleSuccess,
        unpublishScheduleRunning
    } = props;
    let growl;
    const pid = props.match.params.id;

    const [focused, setFocused] = useState(false);

    const [planDetails, setPlanDetails] = useState({
        id: null,
        name: '',
        date: null,
        active: null,
        status: null,
        locked: null,
        valid: false
    });

    const [planTabs, setPlanTabs] = useState(null);
    const [deletePlanModal, setDeletePlanModal] = useState(false);
    const [publishPlanModal, setPublishPlanModal] = useState(false);
    const [showPublishedModal, setShowPublishedModal] = useState(false);
    const [showUnpublishModal, setShowUnpublishModal] = useState(false);
    const [downloadJsonPlanModal, setDownloadJsonPlanModal] = useState(false);
    const [showGrowthModal, setShowGrowthModal] = useState(false);
    const [showConfigModal, setShowConfigModal] = useState(false);
    const [config, setConfig] = useState(<span></span>);

    let planDetailsId = planDetails.id;

    const setPlanDetailsId = useCallback(plan => setPlanDetails(updateObject(planDetails, { id: plan })), [planDetails]);

    useEffect(() => {
        const planId = isNaN(+pid) ? null : +pid;
        if (deletePlanSuccess) {
            history.push('/plans');
            onDeletePlanReset();
        } else if (planId !== null && !planLoading && (plan === null || plan.active === undefined) && !gettingPlan && (planSuccess === null || planSuccess)) {
            setPlanDetailsId(planId);
            onGetPlan(planId);
        }
    }, [gettingPlan, pid, plan, planLoading, onGetPlan, setPlanDetailsId, planSuccess, deletePlanSuccess, onDeletePlanReset, history]);

    useEffect(() => {
        if (plan !== null && plan.name) {
            setPlanDetails(plan);
            setConfig(<PlanConfig planId={+pid} scrollPanelHeight="350px" growl={growl} />);
        }
    }, [plan, growl, pid]);

    useEffect(() => {
        if (plan !== null && planSuccess && planDetails.id !== plan.id) {
            history.push(`/plan/${plan.id}`);
            setPlanDetails(
                updateObject(planDetails, {
                    id: plan.id
                })
            );
            growl.show({ severity: 'success', summary: 'Plan Created' });
        } else if (planSuccess !== null && planSuccess !== undefined && !planSuccess) {
            history.push('/plans');
            onDeletePlanReset();
            growl.show({ severity: 'error', summary: 'Plan Failed', detail: 'none' });
        }
    }, [growl, history, plan, planDetails, planSuccess, onDeletePlanReset]);

    useEffect(() => {
        if (publishScheduleSuccess === null) return;
        if (publishScheduleSuccess) setShowPublishedModal(true);
        else {
            growl.show({ severity: 'error', summary: publishScheduleError });
            onPublishScheduleReset();
        }
    }, [publishScheduleSuccess, publishScheduleError, onPublishScheduleReset, growl]);

    useEffect(() => {
        if (unpublishScheduleSuccess === null) return;
        else {
            growl.show({ severity: 'error', summary: 'Unpublish failed', detail: unpublishScheduleError });
            onUnpublishScheduleReset();
        }
    }, [unpublishScheduleSuccess, unpublishScheduleError, onUnpublishScheduleReset, growl]);

    useEffect(() => {
        if (downloadJsonScheduleSuccess === null) return;
        if (downloadJsonScheduleSuccess) {
            const blob = new Blob([downloadJsonScheduleData], { type: 'application/json' });
            saveAs(blob, downloadJsonScheduleFilename);
            growl.show({ severity: 'success', summary: 'Plan successfully downloaded' });
        } else growl.show({ severity: 'error', summary: downloadJsonScheduleError });
        onDownloadJsonScheduleReset();
    }, [downloadJsonScheduleSuccess, growl, downloadJsonScheduleData, downloadJsonScheduleFilename, onDownloadJsonScheduleReset, downloadJsonScheduleError]);

    useEffect(() => {
        if (planDetailsId !== null && planTabs === null) {
            setPlanTabs(
                <PlanTabs
                    planId={planDetailsId}
                    planProcessed={planDetails.status && planDetails.status === 4}
                    history={history === null ? null : history}
                    scrollPanelHeight="350px"
                    growl={growl}
                />
            );
        }
    }, [growl, history, planDetails, planDetailsId, planTabs]);

    const handleChange = (e, field) => {
        let value;
        if (field === 'date') {
            value = moment(e);
            if (!value._isValid) value = null;
        } else if (field === 'active') value = e.target.checked;
        else value = e.target.value;

        let updatedPlan = updateObject(planDetails, {
            [field]: value
        });

        const valid = updatedPlan.name !== '' && updatedPlan.date !== null;

        updatedPlan = updateObject(updatedPlan, {
            valid
        });

        setPlanDetails(updatedPlan);
    };

    const handleDeletePlan = () => {
        setPlanDetails(
            updateObject(planDetails, {
                id: null
            })
        );
        onDeletePlan(planDetailsId);
        planDetailsId = null;
    };

    const closePublishedModal = () => {
        setShowPublishedModal(false);
        onPublishScheduleReset();
    };

    const publishedModal = <PublishedModal handleClose={closePublishedModal} open={showPublishedModal} publishedInfo={props.publishedInfo || []} />;

    const deleteModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setDeletePlanModal(!deletePlanModal)}
            open={deletePlanModal}
            heading={`Delete Plan ${plan ? planDetails.name : ''}`}
            message="Please click 'Delete' again to confirm deleting the plan. You can not undo this action!"
            confirmAction={handleDeletePlan}
            confirmButton="Delete"
        />
    );

    const handlePublishPlan = () => {
        setPublishPlanModal(!publishPlanModal);
        onPublishSchedule(plan.id);
    };

    const publishModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setPublishPlanModal(!publishPlanModal)}
            open={publishPlanModal}
            heading={`Publish Plan ${plan ? planDetails.name : ''}`}
            message="Please click 'Publish' again to confirm publishing the plan."
            confirmAction={handlePublishPlan}
            confirmButton="Publish"
        />
    );

    const handleUnpublishPlan = () => {
        setShowUnpublishModal(!showUnpublishModal);
        onUnpublishSchedule(plan.id);
    };

    const unPublishModalMessage = (
        <p>
            This process will set all information on the plan as unpublished in Plex. <br />
            <b>
                THIS DOES NOT AFFECT THE STATE OF THIS INFORMATION ON EXTERNAL SYSTEMS <br />
                THIS IS A PLEX PROCESS ONLY.{' '}
            </b>
            <br />
            Please click <b>'Unpublish'</b> again to confirm unpublishing the plan.
        </p>
    );

    const unPublishModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setShowUnpublishModal(!showUnpublishModal)}
            open={showUnpublishModal}
            heading={`Unpublish Plan ${plan ? planDetails.name : ''}`}
            message={unPublishModalMessage}
            confirmAction={handleUnpublishPlan}
            confirmButton="Unpublish"
        />
    );

    const handleDownloadJsonPlan = () => {
        setDownloadJsonPlanModal(!downloadJsonPlanModal);
        onDownloadJsonSchedule(plan.id);
    };

    const downloadJsonModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setDownloadJsonPlanModal(!downloadJsonPlanModal)}
            open={downloadJsonPlanModal}
            heading={`Download Plan ${plan ? planDetails.name : ''}`}
            message="Please click 'Download' again to confirm downloading the plan."
            confirmAction={handleDownloadJsonPlan}
            confirmButton="Download"
        />
    );

    // eslint-disable-next-line react/no-multi-comp
    const pleaseWait = () => {
        let msg = '';
        let loading = false;
        let color = 'light';
        switch (true) {
            case creatingPlan:
                msg = 'Creating Plan';
                loading = true;
                break;
            case updatingPlan:
                msg = 'Updating Plan';
                loading = true;
                break;
            case downloadJsonScheduleRunning:
                msg = 'Downloading Schedule';
                loading = true;
                break;
            case publishScheduleRunning:
                msg = 'Publishing Schedule';
                loading = true;
                break;
            case loading:
                msg = 'Loading';
                loading = true;
                break;
            default:
                color = 'dark'; // not implemented
                break;
        }
        return (
            <ProgressIndicator page loading={loading}>
                <ProgressSpinner color={color} />
                {msg}, please wait...
            </ProgressIndicator>
        );
    };

    const openGrowthModal = () => setShowGrowthModal(!showGrowthModal);
    const closeGrowthModal = () => setShowGrowthModal(false);
    const growthModal = <Growth planId={+pid} open={showGrowthModal} onClose={closeGrowthModal} />;

    const openConfigModal = () => setShowConfigModal(!showConfigModal);

    const configModal = (
        <Modal handleClose={() => setShowConfigModal(!showConfigModal)} open={showConfigModal}>
            <Suspense fallback={pleaseWait()}>{config}</Suspense>
        </Modal>
    );

    return (
        <Card style={{ height: '-webkit-fill-available' }}>
            {planDetails.id === null ? <h2>Create New</h2> : null}
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    if (!planDetails.valid) {
                        growl.show({ severity: 'error', summary: 'Missing Information', detail: 'Please enter a name and select a date' });
                        return false;
                    }
                    planDetails.id === null ? props.onCreatePlan(planDetails) : props.onUpdatePlan(planDetails);
                }}
            >
                <FormGroup name="planCreation" label="Plan Name and Date">
                    <ListGroup type="full" inline>
                        <ListGroupItem>
                            <TextInputField
                                name="planName"
                                placeholder="Enter Plan Name"
                                value={planDetails.name ? planDetails.name : ''}
                                onChange={e => handleChange(e, 'name')}
                            />
                        </ListGroupItem>
                        <ListGroupItem>
                            <SingleDatePicker
                                id="planDate"
                                date={planDetails.date ? moment(planDetails.date) : null}
                                displayFormat="DD/MM/YYYY"
                                focused={focused}
                                disabled={planDetails.id !== null}
                                onDateChange={e => handleChange(e, 'date')}
                                onFocusChange={() => setFocused(!focused)}
                                isOutsideRange={() => false}
                            />
                        </ListGroupItem>
                        {planDetails.id === null ? null : (
                            <Fragment>
                                <ListGroupItem>
                                    <Checkbox name="planActive" label="Active" defaultChecked={planDetails.active} onChange={e => handleChange(e, 'active')} />
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Checkbox name="planProcessed" label="Processed" disabled defaultChecked={planDetails.status && planDetails.status === 4} />
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Checkbox name="planLocked" label="Locked" disabled defaultChecked={planDetails.disabled} />
                                </ListGroupItem>
                            </Fragment>
                        )}
                        <ListGroupItem>
                            <Button
                                type="submit"
                                // icon={planDetails.id === null ? 'pi pi-check' : 'pi pi-save'}
                                icon={`pi ${creatingPlan || updatingPlan ? 'pi-spin pi-spinner' : planDetails.id === null ? 'pi pi-check' : 'pi pi-save'}`}
                                className={creatingPlan || updatingPlan ? 'buttonActive' : ''}
                                tooltip={`${planDetails.id === null ? 'Create' : 'Update'} Plan`}
                                tooltipOptions={{ position: 'top' }}
                            />
                        </ListGroupItem>
                        {planDetails.id === null ? null : (
                            <Fragment>
                                <ListGroupItem>
                                    <Button
                                        type="button"
                                        onClick={() => setPublishPlanModal(!publishPlanModal)}
                                        icon={`pi ${publishScheduleRunning ? 'pi-spin pi-spinner' : 'pi-cloud-upload'}`}
                                        className={publishScheduleRunning ? 'buttonActive' : ''}
                                        tooltip="Publish Plan"
                                        tooltipOptions={{ position: 'top' }}
                                    />
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Button
                                        type="button"
                                        onClick={() => setShowUnpublishModal(!showUnpublishModal)}
                                        icon={`pi ${unpublishScheduleRunning ? 'pi-spin pi-spinner' : 'pi-cloud-upload'}`}
                                        className={unpublishScheduleRunning ? 'buttonActive' : ''}
                                        tooltip="Unpublish Plan"
                                        tooltipOptions={{ position: 'top' }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" height="22.5" width="22.5" className="iconOverlay">
                                            <g>
                                                <line x1="0" y1="0" x2="22.5" y2="22.5" />
                                                <line x1="0" y1="22.5" x2="22.5" y2="0" />
                                            </g>
                                        </svg>
                                    </Button>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Button
                                        type="button"
                                        onClick={() => setDownloadJsonPlanModal(!downloadJsonPlanModal)}
                                        icon={`pi ${downloadJsonScheduleRunning ? 'pi-spin pi-spinner' : 'pi-cloud-download'}`}
                                        className={downloadJsonScheduleRunning ? 'buttonActive' : ''}
                                        tooltip="Download Json Plan"
                                        tooltipOptions={{ position: 'top' }}
                                    />
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Button
                                        type="button"
                                        onClick={openGrowthModal}
                                        icon="pi pi-sort-numeric-up"
                                        tooltip="Open CVL Growth"
                                        tooltipOptions={{ position: 'top' }}
                                    />
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Button
                                        type="button"
                                        onClick={openConfigModal}
                                        icon="pi pi-cog"
                                        tooltip="Open Plan Configuration"
                                        tooltipOptions={{ position: 'top' }}
                                    />
                                </ListGroupItem>
                                <ListGroupItem style={{ paddingLeft: '10em' }}>
                                    <Button
                                        type="button"
                                        onClick={() => setDeletePlanModal(!deletePlanModal)}
                                        icon="pi pi-trash"
                                        tooltip="Delete Plan"
                                        tooltipOptions={{ position: 'top' }}
                                    />
                                </ListGroupItem>
                            </Fragment>
                        )}
                    </ListGroup>
                </FormGroup>
            </Form>

            {creatingPlan ? pleaseWait() : <Suspense fallback={pleaseWait()}>{planTabs}</Suspense>}

            {pleaseWait()}

            {deleteModal}

            {publishModal}

            {downloadJsonModal}

            {growthModal}

            {configModal}

            {publishedModal}

            {unPublishModal}

            <Growl className="customGrowl" ref={el => (growl = el)}></Growl>
        </Card>
    );
};

Plan.propTypes = {
    creatingPlan: PropTypes.bool,
    deletePlanSuccess: PropTypes.bool,
    downloadJsonScheduleData: PropTypes.any,
    downloadJsonScheduleError: PropTypes.string,
    downloadJsonScheduleFilename: PropTypes.string,
    downloadJsonScheduleRunning: PropTypes.bool,
    downloadJsonScheduleSuccess: PropTypes.bool,
    gettingPlan: PropTypes.bool,
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
    onCreatePlan: PropTypes.func,
    onDeletePlan: PropTypes.func,
    onDeletePlanReset: PropTypes.func,
    onDownloadJsonSchedule: PropTypes.func,
    onDownloadJsonScheduleReset: PropTypes.func,
    onGetPlan: PropTypes.func,
    onPublishSchedule: PropTypes.func,
    onPublishScheduleReset: PropTypes.func,
    onUnpublishSchedule: PropTypes.func,
    onUnpublishScheduleReset: PropTypes.func,
    onUpdatePlan: PropTypes.func,
    plan: PropTypes.object,
    planLoading: PropTypes.bool,
    planSuccess: PropTypes.bool,
    publishScheduleError: PropTypes.string,
    publishScheduleRunning: PropTypes.bool,
    publishScheduleSuccess: PropTypes.bool,
    publishedInfo: PropTypes.array,
    unpublishScheduleError: PropTypes.string,
    unpublishScheduleRunning: PropTypes.bool,
    unpublishScheduleSuccess: PropTypes.bool,
    updatingPlan: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        gettingPlan: state.plan.gettingPlan,
        plan: state.plan.plan,
        planLoading: state.plan.loading,
        planSuccess: state.plan.planSuccess,
        creatingPlan: state.plan.creatingPlan,
        updatingPlan: state.plan.updatingPlan,
        deletePlanSuccess: state.plan.deletePlanSuccess,
        publishScheduleError: state.plan.publishScheduleError,
        publishScheduleRunning: state.plan.publishScheduleRunning,
        publishScheduleSuccess: state.plan.publishScheduleSuccess,
        publishedInfo: state.plan.publishedInfo,
        unpublishScheduleError: state.plan.unpublishScheduleError,
        unpublishScheduleRunning: state.plan.unpublishScheduleRunning,
        unpublishScheduleSuccess: state.plan.unpublishScheduleSuccess,
        downloadJsonScheduleData: state.plan.downloadJsonScheduleData,
        downloadJsonScheduleError: state.plan.downloadJsonScheduleError,
        downloadJsonScheduleRunning: state.plan.downloadJsonScheduleRunning,
        downloadJsonScheduleFilename: state.plan.downloadJsonScheduleFilename,
        downloadJsonScheduleSuccess: state.plan.downloadJsonScheduleSuccess
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreatePlan: planDetails => {
            dispatch(actions.processPlanReset());
            dispatch(actions.createPlan(planDetails));
        },
        onUpdatePlan: planDetails => dispatch(actions.updatePlan(planDetails)),
        onGetPlan: planId => dispatch(actions.getPlan(planId)),
        onDeletePlan: planId => dispatch(actions.deletePlan(planId)),
        onDeletePlanReset: () => dispatch(actions.deletePlanReset()),
        onPublishSchedule: (planId, dcNo) => dispatch(actions.publishSchedule(planId, dcNo)),
        onPublishScheduleReset: () => dispatch(actions.publishScheduleReset()),
        onUnpublishSchedule: (planId, dcNo, headerIds) => dispatch(actions.unpublishSchedule(planId, dcNo, headerIds)),
        onUnpublishScheduleReset: () => dispatch(actions.unpublishScheduleReset()),
        onDownloadJsonSchedule: (planId, dcNo) => dispatch(actions.downloadJsonSchedule(planId, dcNo)),
        onDownloadJsonScheduleReset: () => dispatch(actions.downloadJsonScheduleReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Plan, axios));
