import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../../axios';

import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';

import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';

import FailedModal from '../Plans/FailedModal/FailedModal';
import PlanCreate from './PlanCreate/PlanCreate';
import PlanConfig from './PlanConfig/PlanConfig';
import PlanTile from '../Plans/PlanTile';

import { actionStatus } from '../Plans/Pipeline/ActionStatus';

import { Accordion, AccordionItem } from '@jsluna/accordion';
import { Card } from '@jsluna/card';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { ListGroup } from '@jsluna/list';

import moment from 'moment';
import classes from './DI.module.scss';

moment.locale('en-GB');

const DI = props => {
    const configInfo = 'View/Edit the Global DI Plan Configuration';
    const createInfo = 'Create new DI Plan';
    const returnInfo = 'Return to the previous view';
    const configicon = 'pi-cog';
    const createicon = 'pi-plus';
    const returnicon = 'pi-undo';
    const [configIcon, setConfigIcon] = useState(configicon);
    const [configTooltip, setConfigTooltip] = useState(configInfo);
    const [createIcon, setCreateIcon] = useState(createicon);
    const [createTooltip, setCreateTooltip] = useState(createInfo);
    const [deleteId, setDeleteId] = useState(null);
    const [showFailedModal, setShowFailedModal] = useState(false);
    const [showConfig, setShowConfig] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const { diPlanDeleteSuccess, diPlans, onDIPlanDeleteReset, onGetDIPlans, onResetDIPlan, getDIPlansError, diPlansError } = props;
    const history = useHistory();
    let growl = {};

    useEffect(() => {
        onResetDIPlan();
    }, [onResetDIPlan]);

    useEffect(() => {
        onGetDIPlans();
    }, [onGetDIPlans]);

    useEffect(() => {
        if (getDIPlansError) growl.show({ severity: 'error', summary: 'Error retrieving DI plan(s)' });
    }, [diPlans, getDIPlansError, growl]);

    useEffect(() => {
        if (diPlansError) growl.show({ severity: 'error', summary: diPlansError });
    }, [growl, diPlansError]);

    const handleCreateOrEditDIPlan = id => {
        if (id) {
            let plan;

            // find the plan for the given id
            // months
            for (let dip = 0; dip < diPlans.length; dip++) {
                const m = diPlans[dip];
                // weeks
                for (let pw = 0; pw < m.plans.length; pw++) {
                    const w = m.plans[pw];
                    // individual plans
                    for (let pi = 0; pi < w.length; pi++) {
                        if (w[pi].id === id) {
                            plan = w[pi];
                            break;
                        }
                    }
                    if (plan) break;
                }
                if (plan) break;
            }

            if (plan && plan.status === actionStatus.error) {
                setDeleteId(id);
                setShowFailedModal(true);
            } else history.push(`/planview/${id}`);
        } else {
            const create = !showCreate;
            setShowCreate(create);
            setCreateIcon(create ? returnicon : createicon);
            setCreateTooltip(create ? returnInfo : createInfo);
        }
    };

    const planList = () => {
        if (!diPlans || diPlans.length === 0) return null;

        return diPlans.map(month => {
            const weeks = month.plans.length;
            const isOpen = moment().format('MMMM YYYY') === month.month;
            const planCount = month.plans.reduce((pc, p) => (pc += p.length), 0);
            const activeCount = month.plans.reduce((ac, p) => (ac += p.filter(ap => ap.active === true).length), 0);

            return (
                <AccordionItem
                    className={`${classes.planHeader} ln-u-border-sides`}
                    defaultOpen={isOpen}
                    key={month.month}
                    id={month.month}
                    title={`${month.month} (${weeks} Week${weeks > 1 ? 's' : ''}, ${planCount} Plan${planCount > 1 ? 's' : ''}, ${activeCount} Active)`}
                >
                    <GridWrapper horizontalAlign="center">
                        {month.plans.map((p, ind) => {
                            if (p.length > 0) {
                                const content = (
                                    <ListGroup actionable className="ln-u-text-align-center">
                                        {p.map((plan, pid) => (
                                            <PlanTile key={pid.toString()} plan={plan} click={id => handleCreateOrEditDIPlan(id)} last={p.length === ind + 1} />
                                        ))}
                                    </ListGroup>
                                );
                                return (
                                    <GridItem key={ind} size="1/8">
                                        {content}
                                    </GridItem>
                                );
                            }

                            return null;
                        })}
                    </GridWrapper>
                </AccordionItem>
            );
        });
    };

    const planDisplay = () => {
        if (!diPlans || diPlans.length === 0) {
            return (
                <p>
                    No plans exist, click the
                    <Button
                        style={{ marginLeft: '0.4rem', marginRight: '0.4rem' }}
                        onClick={() => handleCreateOrEditDIPlan(null)}
                        icon="pi pi-plus"
                        tooltip={createInfo}
                        tooltipOptions={{ position: 'top' }}
                    />
                    icon to create a new plan
                </p>
            );
        } else return <Accordion titleElement="h3">{planList()}</Accordion>;
    };

    const config = (
        <GridItem size="1/1">
            <PlanConfig global={true} growl={growl} />
        </GridItem>
    );

    let display = showConfig ? config : planDisplay();
    display = showCreate ? <PlanCreate onPlanCreated={handleCreateOrEditDIPlan} /> : display;

    const handleConfigClick = () => {
        const newShowConfig = !showConfig;
        setShowConfig(newShowConfig);
        setConfigIcon(newShowConfig ? returnicon : configicon);
        setConfigTooltip(newShowConfig ? returnInfo : configInfo);
    };

    const handleClose = () => {
        props.onDIPlanDelete(deleteId);
        setShowFailedModal(false);
    };

    useEffect(() => {
        if (diPlanDeleteSuccess) {
            window.location.reload();
            onDIPlanDeleteReset();
        }
    }, [diPlanDeleteSuccess, onDIPlanDeleteReset]);

    return (
        <Card>
            <GridWrapper>
                <GridItem size="1/4">
                    <h2>DI Plan Index</h2>
                </GridItem>
                <GridItem size="2/8" element="li" offsetRight={{ sm: '4/8', md: '4/8' }} style={{ textAlign: 'right' }}>
                    <Button
                        style={{ marginLeft: '3rem' }}
                        onClick={() => handleCreateOrEditDIPlan(null)}
                        icon={`pi ${createIcon}`}
                        tooltip={createTooltip}
                        tooltipOptions={{ position: 'left' }}
                    />
                    <Button
                        style={{ marginLeft: '3rem' }}
                        onClick={() => history.push('importCDC')}
                        icon="pi pi-upload"
                        tooltip="Upload Barton CDC"
                        tooltipOptions={{ position: 'left' }}
                    />
                    <Button
                        style={{ marginLeft: '3rem' }}
                        onClick={handleConfigClick}
                        icon={`pi ${configIcon}`}
                        tooltip={configTooltip}
                        tooltipOptions={{ position: 'left' }}
                    />
                </GridItem>
            </GridWrapper>
            {display}
            <Growl ref={el => (growl = el)}></Growl>
            <FailedModal open={showFailedModal} handleClose={handleClose} />
        </Card>
    );
};

DI.propTypes = {
    diPlanDeleteSuccess: PropTypes.bool,
    diPlans: PropTypes.array,
    diPlansError: PropTypes.string,
    getDIPlansError: PropTypes.bool,
    onDIPlanDelete: PropTypes.func,
    onDIPlanDeleteReset: PropTypes.func,
    onGetDIPlans: PropTypes.func,
    onResetDIPlan: PropTypes.func
};

const mapStateToProps = state => {
    return {
        diPlanDeleteSuccess: state.di.diPlanDeleteSuccess,
        diPlansError: state.di.diPlansError,
        diPlans: state.di.diPlans,
        getDIPlansError: state.di.getDIPlansError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDIPlanDelete: planId => dispatch(actions.diPlanDelete(planId)),
        onDIPlanDeleteReset: () => dispatch(actions.diPlanDeleteReset()),
        onGetDIPlans: () => dispatch(actions.getDIPlans()),
        onResetDIPlan: () => dispatch(actions.diPlanReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(DI, axios));
