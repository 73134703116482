import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from '../../../axios';

import { GridItem, GridWrapper } from '@jsluna/grid';

import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';

import { getToken } from '../../../adalConfig';

/**
 * Import
 * @param {string} accept - A comma seperated list of file extensions filter in the UI. Must start with a '.'
 * @param {string} detail - The message content for the success message
 * @param {string} name - A unique name for the import control - Required
 * @param {function} onError - Callback function to execute in the event of an error
 * @param {string} path - The relative path to the API, e.g. 'Catalogue/Import' - Required
 * @param {string} sumary - The title for the success message
 */
const Import = props => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        getToken().then(t => setToken(t));
    }, []);

    let growl;

    const beforeSend = event => event.xhr.setRequestHeader('Authorization', `Bearer ${token}`);

    const uploaded = () =>
        growl.show({ severity: 'success', summary: props.summary || 'Import Complete', detail: props.detail || 'Import successfully uploaded' });

    return (
        <GridItem size="1/1" className="ln-u-push-top">
            <GridWrapper>
                <GridItem size="1/2">
                    <FileUpload
                        name={props.name}
                        accept={props.accept}
                        url={`${axios.defaults.baseURL}${props.path}`}
                        onUpload={uploaded}
                        onError={props.onError} // this isn't being triggered on a status code error, don't know why - it should be.
                        onBeforeSend={beforeSend}
                    />
                </GridItem>
            </GridWrapper>
            <Growl ref={el => (growl = el)} />
        </GridItem>
    );
};

Import.propTypes = {
    accept: PropTypes.string,
    detail: PropTypes.string,
    name: PropTypes.string.isRequired,
    onError: PropTypes.func,
    path: PropTypes.string.isRequired,
    summary: PropTypes.string
};

export default Import;
