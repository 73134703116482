export const resizeAction = (el, heightAdj = 0) => {
    if (!el) return null;
    const documentScrollTop = document.documentElement.scrollTop;
    if (documentScrollTop > 50) heightAdj += 26;
    return new Promise(resolve => {
        setTimeout(() => {
            const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            resolve(`${windowHeight - (documentScrollTop + el.getBoundingClientRect().top) - heightAdj}px`);
        }, 200);
    });
};
