import React from 'react';

import classes from './fileIcon.module.scss';

const Azureblob = () => {
    return (
        <div className={[classes.fileIcon, classes.azureblob].join(' ')}>
            <svg viewBox="0 0 307 273" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M38.2164 67.6148C17.2338 104.8 0.0606285 135.725 0.000159761 136.283C-0.060309 136.841 17.0523 167.828 38.0955 205.137L76.3722
                    273H230.507L268.723 205.137C289.766 167.828 307 136.903 307 136.469C307 136.035 289.827 105.172 268.784 67.8627L230.568 0H76.3117L38.2164
                    67.6148ZM215.269 70.4656L231.475 86.951L231.112 201.729L229.66 205.447C227.665 210.467 222.707 215.549 217.809 217.594L214.18
                    219.082H153.712C97.3549 219.082 93.0616 219.02 90.2195 217.966C83.9913 215.735 78.6095 210.095 76.8559 204.022C76.1303 201.729
                    76.0094 189.334 76.0094 136.283C76.0094 72.4488 76.0094 71.2713 77.2188 67.9246C79.3956 62.099 83.447 57.8227 89.1916 55.4057L92.6383
                    53.9183H199.063L215.269 70.4656Z"
                    fill="#0078D7"
                />
                <path
                    d="M93.9081 66.3133C91.6708 67.057 89.373 69.2261 88.345 71.7051C87.6194 73.3784 87.4984 84.6579 87.4984 136.469C87.4984 197.886 87.4984
                    199.25 88.7078 201.729C89.4939 203.34 90.7638 204.766 92.2755 205.633L94.6337 206.997H153.53C209.706 206.997 212.487 206.935 214.483
                    205.819C215.632 205.199 217.204 203.588 217.93 202.286L219.32 199.869V92.9625L206.501 92.8386L193.621 92.6527L193.44 79.1421L193.319
                    65.6935L144.46 65.7555C117.612 65.7555 94.8756 66.0654 93.9081 66.3133ZM179.774 81.993C185.518 85.0917 187.877 91.4132 187.877
                    103.808C187.877 118.31 184.309 125.561 175.964 128.04C170.099 129.776 163.689 128.288 159.94 124.136C159.093 123.206 157.703
                    120.603 156.977 118.372C155.768 114.902 155.586 113.042 155.586 105.358C155.526 98.6023 155.768 95.5035 156.554 92.9625C158.731
                    85.7734 162.963 81.5591 169.01 80.6295C172.518 80.1337 177.415 80.7535 179.774 81.993ZM140.348 80.9394C141.195 81.2493 141.316 83.6043
                    141.316 100.771V120.232H145.125C149.539 120.232 150.386 120.851 150.386 124.012C150.386 128.164 149.963 128.288 136.297 128.288C129.585
                    128.288 123.719 128.04 123.296 127.793C122.208 127.049 121.724 123.144 122.51 121.533C123.115 120.417 123.719 120.232 127.408
                    120.232H131.641V105.667C131.641 97.6726 131.459 91.1033 131.278 91.1033C131.096 91.1033 129.403 91.9709 127.468 93.0865C123.235 95.5035
                    121.966 95.1936 121.966 91.909C121.966 90.6075 122.208 89.1821 122.449 88.8102C122.691 88.3764 125.473 86.3312 128.617 84.286C133.273
                    81.2493 134.725 80.5675 136.841 80.5675C138.232 80.5675 139.804 80.7535 140.348 80.9394ZM142.344 145.455C145.609 146.633 148.693 150.227
                    150.023 154.504C150.688 156.549 151.233 160.577 151.414 165.473C151.777 174.212 150.809 180.471 148.451 184.872C143.432 194.168 128.315
                    195.345 122.631 186.793C119.97 182.826 119.305 179.418 119.305 169.192C119.245 158.718 119.97 154.876 122.873 150.599C126.924 144.588
                    134.604 142.605 142.344 145.455ZM177.053 145.022C178.141 145.641 178.202 146.509 178.202 164.854V184.066H182.072C186.365 184.066 186.667
                    184.314 186.667 188.404C186.667 190.201 186.365 191.007 185.518 191.503C184.793 191.875 179.411 192.123 172.276 192.123C158.549 192.123
                    158.67 192.185 158.973 187.351L159.154 184.376L168.527 184.004V154.256L164.717 156.487C160.424 158.904 159.154 159.152 158.61
                    157.664C158.126 156.363 158.186 153.512 158.731 152.706C158.973 152.273 161.754 150.289 164.898 148.182C169.675 145.084 171.066 144.402
                    173.243 144.402C174.694 144.402 176.388 144.712 177.053 145.022Z"
                    fill="#0078D7"
                />
                <path
                    d="M169.192 88.8102C168.406 89.1821 167.136 90.6695 166.471 92.0329C165.322 94.264 165.201 95.5655 165.201 104.118C165.201 117.876 166.833
                    121.595 172.578 120.665C177.113 119.922 178.443 115.336 178.081 102.321C177.778 92.5907 176.932 89.8018 173.848 88.6863C171.49 87.8186
                    171.006 87.8186 169.192 88.8102Z"
                    fill="#0078D7"
                />
                <path
                    d="M132.85 152.583C130.008 154.132 128.98 157.479 128.738 166.465C128.436 175.885 129.282 181.091 131.399 183.198C133.152 185.057 136.357
                    185.181 138.474 183.446C141.013 181.401 141.618 178.488 141.618 168.262C141.618 158.594 141.013 155.371 138.957 153.45C137.446 152.087
                    134.604 151.653 132.85 152.583Z"
                    fill="#0078D7"
                />
            </svg>
        </div>
    );
};

export default Azureblob;
