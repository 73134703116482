import React from 'react';
import classes from './Animation.module.scss';

/* eslint-disable max-len */
const Animation = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1900 330.25" height="125">
            <symbol id="a">
                <svg viewBox="1000 -50 294 438">
                    <rect className={classes.cls1} x="3" y="3" width="147" height="239" rx="2" />
                    <circle className={classes.cls1} cx="20" cy="260" r="10" />
                    <circle className={classes.cls1} cx="132" cy="260" r="10" />
                </svg>
            </symbol>
            <use xlinkHref="#a" className={classes.ulc1} />
            <use xlinkHref="#a" className={classes.ulc2} />
            <use xlinkHref="#a" className={classes.ulc3} />
            <g className={classes.trailer}>
                <path
                    className={classes.cls1}
                    d="M335 280.25h316a2 2 0 002-2v-67a2 2 0 012-2h337a2 2 0 002-2v-194a2 2 0 00-2-2H5a2 2 0 00-2 2v265a2 2 0 002 2h12M225.96 280.25h11.11M115.67 280.25h12.37"
                />
                <g>
                    <path
                        className={classes.cls2}
                        d="M286 236.25a44 44 0 11-44 44 44.05 44.05 0 0144-44m0-6a50 50 0 1050 50 50 50 0 00-50-50zM177 236.25a44 44 0 11-44 44 44.05 44.05 0 0144-44m0-6a50 50 0 1050 50 50 50 0 00-50-50zM66.5 235.25a44.5 44.5 0 11-44.5 44.5 44.55 44.55 0 0144.5-44.5m0-6a50.5 50.5 0 1050.5 50.5 50.5 50.5 0 00-50.5-50.5z"
                    />
                </g>
                <g>
                    <path
                        className={classes.cls1}
                        d="M1004.48 282.25H820.04M1018 282.25h-10.52M1011 282.25h-10.52M1125 282.25h-13.19M725.26 280.25H664.5a2 2 0 01-2-2v-57.83a2 2 0 012-2h336.7a2 2 0 002-2V54.49a2 2 0 012-2h150l18.49-.25a2.42 2.42 0 012.34 1.94l16.08 94.75a28 28 0 01.34 4v127.32a2 2 0 01-2 2h-66.74M1015 2.9l151.11 40.49"
                    />
                </g>
                <g>
                    <path
                        className={classes.cls2}
                        d="M771 236.25a44 44 0 11-44 44 44.05 44.05 0 0144-44m0-6a50 50 0 1050 50 50 50 0 00-50-50zM1065.5 235.25a44.5 44.5 0 11-44.5 44.5 44.55 44.55 0 0144.5-44.5m0-6a50.5 50.5 0 1050.5 50.5 50.5 50.5 0 00-50.5-50.5z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Animation;
