import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../axios';
import * as actions from '../../../store/actions/index';

import { ButtonGroupPrimary, ButtonGroupWrapper, FilledButton, IconButton } from '@jsluna/button';
import { Modal, ModalHeading } from '@jsluna/modal';
import { ProgressBar, ProgressIndicator } from '@jsluna/progress';

import { Download } from '@jsluna/icons';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import classes from './Growth.module.scss';

const Growth = props => {
    const [showGrowthModal, setShowGrowthModal] = useState(false);
    const { gettingGrowthSummary, onGetGrowthSummary, open, planId } = props;
    let dt;

    useEffect(() => {
        if (!isNaN(planId)) onGetGrowthSummary(planId);
    }, [onGetGrowthSummary, planId]);

    useEffect(() => {
        if (open !== showGrowthModal) setShowGrowthModal(open);
    }, [open, setShowGrowthModal, showGrowthModal]);

    const loading = (
        <ProgressIndicator className="ln-u-justify-content-center" loading={gettingGrowthSummary} preventFocus>
            <ProgressBar className="ln-u-push-bottom-sm" />
        </ProgressIndicator>
    );

    const paginatorLeft = (
        <IconButton label="Download" hideLabel={true} onClick={() => dt.exportCSV()} variant="outlined">
            <Download />
        </IconButton>
    );
    const content = (
        <DataTable
            value={(props.growthSummary || []).sort((a, b) => +a.locationNo - +b.locationNo)}
            paginator
            rows={10}
            ref={el => (dt = el)}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            paginatorLeft={paginatorLeft}
        >
            <Column field="dcNo" header="DC No" className="ln-u-text-align-center" style={{ width: '5em' }} filter />
            <Column field="locationNo" header="Location No" className="ln-u-text-align-center" style={{ width: '8em' }} filter />
            <Column field="locationName" header="Location Name" filter filterMatchMode="contains" />
            <Column field="originalDUs" header="Original" className="ln-u-text-align-center" style={{ width: '6em' }} />
            <Column field="grownDUs" header="Grown" className="ln-u-text-align-center" style={{ width: '6em' }} />
        </DataTable>
    );

    const close = () => {
        setShowGrowthModal(!showGrowthModal);
        props.onClose();
    };

    return (
        <Modal handleClose={close} open={showGrowthModal} className={classes.wide}>
            <ModalHeading element="h3">CVL Growth</ModalHeading>
            {loading}
            {content}
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={close}>Close</FilledButton>
                </ButtonGroupPrimary>
            </ButtonGroupWrapper>
        </Modal>
    );
};

Growth.propTypes = {
    gettingGrowthSummary: PropTypes.bool,
    growthSummary: PropTypes.array,
    onClose: PropTypes.func,
    onGetGrowthSummary: PropTypes.func.isRequired,
    open: PropTypes.bool,
    planId: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    return {
        gettingGrowthSummary: state.cvl.gettingGrowthSummary,
        growthSummary: state.cvl.growthSummary
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetGrowthSummary: planId => dispatch(actions.getGrowthSummary(planId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Growth, axios));
