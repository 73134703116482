import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    csv: null,
    csvError: null,
    csvSuccess: null,
    csvLoading: null,
    date: null,
    diPlanDeleteError: null,
    diPlanDeleteSuccess: null,
    diPlanDeleting: null,
    gettingDIPlanDetail: false,
    gettingDIPlans: false,
    diPlanDetail: null,
    diPlanDetailError: null,
    diPlanDetailSuccess: null,
    diPlans: null,
    diPlansError: null,
    diPlansSuccess: null,
    json: null,
    jsonError: null,
    jsonSuccess: null,
    jsonLoading: null,
    planId: null,
    publishSuccess: null,
    setActiveDIPlanSuccess: null,
    settingActiveDIPlan: null,
    setActiveDIPlanError: null
};

const getDICSVStart = (state, action) =>
    updateObject(state, {
        csv: action.csv === undefined ? state.csv : action.csv,
        csvError: action.csvError === undefined ? state.csvError : action.csv,
        csvSuccess: action.csvSuccess === undefined ? state.csvSuccess : action.csvSuccess,
        csvLoading: action.csvLoading === undefined ? state.csvLoading : action.csvLoading
    });

const getDIJsonStart = (state, action) =>
    updateObject(state, {
        json: action.json === undefined ? state.json : action.json,
        jsonError: action.jsonError === undefined ? state.csvError : action.json,
        jsonSuccess: action.jsonSuccess === undefined ? state.jsonSuccess : action.jsonSuccess,
        jsonLoading: action.jsonLoading === undefined ? state.jsonLoading : action.jsonLoading
    });

const getDIPlansStart = state => updateObject(state, { diPlansError: null, gettingDIPlans: true, diPlansSuccess: null });
const getDIPlansSuccess = (state, action) => updateObject(state, { diPlans: action.diPlans, diPlansError: null, gettingDIPlans: false });
const getDIPlansFail = (state, action) =>
    updateObject(state, { diPlansError: action.diPlansError, gettingDIPlans: false, diPlanSuccess: false, getDIPlansError: true });

const getDIPlanDetailStart = state => updateObject(state, { diPlanDetailError: null, gettingDIPlanDetail: true, diPlanDetailSuccess: null });
const getDIPlanDetailsSuccess = (state, action) =>
    updateObject(state, { diPlanDetail: action.diPlanDetail, diPlanDetailError: null, gettingDIPlanDetail: false, diPlanDetailSuccess: true });
const getDIPlanDetailFail = (state, action) =>
    updateObject(state, { diPlanDetailError: action.diPlanDetailError, gettingDIPlanDetail: false, diPlanDetailSuccess: false, getDIPlanDetailError: true });

const diGetCdcStart = state => updateObject(state, { loadingcdc: true });
const diGetCdcSuccess = (state, action) => updateObject(state, { loadingcdc: false, cdc: action.cdc });
const diGetCdcFail = (state, action) => updateObject(state, { loadingcdc: false, getCdcError: action.getCdcError });

const createNewDIPlanStart = state => updateObject(state, { creatingNewDIPlan: true });
const createNewDIPlanSuccess = (state, action) => updateObject(state, { creatingNewDIPlan: false, planId: action.planId });
const createNewDIPlanFail = (state, action) => updateObject(state, { creatingNewDIPlan: false, createNewDIPlanError: action.createNewDIPlanError });

const diGetForecastTrunksStart = state => updateObject(state, { loadingForecastTrunks: true });
const diGetForecastTrunksSuccess = (state, action) => updateObject(state, { loadingForecastTrunks: false, forecastTrunks: action.forecastTrunks });
const diGetForecastTrunksFail = (state, action) =>
    updateObject(state, { loadingForecastTrunks: false, diGetForecastTrunksError: action.diGetForecastTrunksError });

const diSaveChangesStart = state => updateObject(state, { savingChanges: true });
const diSaveChangesSuccess = (state, action) => updateObject(state, { savingChanges: false, saveSuccess: action.saveSuccess });
const diSaveChangesFail = (state, action) => updateObject(state, { savingChanges: false, saveError: action.saveError });

const diWLFCStart = state => updateObject(state, { gettingWLFC: true });
const diWLFCSuccess = (state, action) => updateObject(state, { gettingWLFC: false, WLFC: action.WLFC });
const diWLFCFail = (state, action) => updateObject(state, { gettingWLFC: false, WLFCError: action.WLFCError });

const diDownloadRailStart = state => updateObject(state, { downloadingRail: true });
const diDownloadRailSuccess = (state, action) => updateObject(state, { downloadingRail: false, rail: action.rail });
const diDownloadRailFail = (state, action) => updateObject(state, { downloadingRail: false, railError: action.railError });

const diPlanReset = state => {
    return updateObject(state, {
        planId: null,
        diPlanDetail: null
    });
};

const setActiveDIPlan = (state, action) =>
    updateObject(state, {
        setActiveDIPlanSuccess: action.setActiveDIPlanSuccess === undefined ? state.setActiveDIPlanSuccess : action.setActiveDIPlanSuccess,
        settingActiveDIPlan: action.settingActiveDIPlan === undefined ? state.settingActiveDIPlan : action.settingActiveDIPlan,
        setActiveDIPlanError: action.setActiveDIPlanError === undefined ? state.setActiveDIPlanError : action.setActiveDIPlanError
    });

const setActiveDIPlanReset = state =>
    updateObject(state, {
        setActiveDIPlanSuccess: null,
        settingActiveDIPlan: null,
        setActiveDIPlanError: null
    });

const diPlanDelete = (state, action) =>
    updateObject(state, {
        diPlanDeleting: action.diPlanDeleting === undefined ? state.diPlanDeleting : action.diPlanDeleting,
        diPlanDeleteSuccess: action.diPlanDeleteSuccess === undefined ? state.diPlanDeleteSuccess : action.diPlanDeleteSuccess,
        diPlanDeleteError: action.diPlanDeleteError === undefined ? state.diPlanDeleteError : action.diPlanDeleteError
    });

const diPlanDeleteReset = state =>
    updateObject(state, {
        diPlanDeleting: null,
        diPlanDeleteSuccess: null,
        diPlanDeleteError: null
    });

const publish = (state, action) =>
    updateObject(state, {
        publishSuccess: action.publishSuccess === undefined ? state.publishSuccess : action.publishSuccess,
        publishRunning: action.publishRunning === undefined ? state.publishRunning : action.publishRunning,
        publishError: action.publishError === undefined ? state.publishError : action.publishError,
        publishedInfo: action.publishedInfo
    });

// prettier-ignore
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DI_PLANS_START: return getDIPlansStart(state);
        case actionTypes.GET_DI_PLANS_SUCCESS: return getDIPlansSuccess(state, action);
        case actionTypes.GET_DI_PLANS_FAIL: return getDIPlansFail(state, action);
        case actionTypes.GET_DI_PLAN_DETAIL_START: return getDIPlanDetailStart(state);
        case actionTypes.GET_DI_PLAN_DETAIL_SUCCESS: return getDIPlanDetailsSuccess(state, action);
        case actionTypes.GET_DI_PLAN_DETAIL_FAIL: return getDIPlanDetailFail(state, action);
        case actionTypes.DI_GET_CDC_START: return diGetCdcStart(state);
        case actionTypes.DI_GET_CDC_SUCCESS: return diGetCdcSuccess(state, action);
        case actionTypes.DI_GET_CDC_FAIL: return diGetCdcFail(state, action);
        case actionTypes.DI_CREATE_PLAN_START: return createNewDIPlanStart(state);
        case actionTypes.DI_CREATE_PLAN_SUCCESS: return createNewDIPlanSuccess(state, action);
        case actionTypes.DI_CREATE_PLAN_FAIL: return createNewDIPlanFail(state, action);
        case actionTypes.DI_GET_FORECAST_TRUNKS_START: return diGetForecastTrunksStart(state);
        case actionTypes.DI_GET_FORECAST_TRUNKS_SUCCESS: return diGetForecastTrunksSuccess(state, action);
        case actionTypes.DI_GET_FORECAST_TRUNKS_FAIL: return diGetForecastTrunksFail(state, action);
        case actionTypes.DI_SAVE_CHANGES_START: return diSaveChangesStart(state);
        case actionTypes.DI_SAVE_CHANGES_SUCCESS: return diSaveChangesSuccess(state, action);
        case actionTypes.DI_SAVE_CHANGES_FAIL: return diSaveChangesFail(state, action);
        case actionTypes.DI_WLFC_START: return diWLFCStart(state);
        case actionTypes.DI_WLFC_SUCCESS: return diWLFCSuccess(state, action);
        case actionTypes.DI_WLFC_FAIL: return diWLFCFail(state, action);
        case actionTypes.DI_DOWNLOAD_RAIL_START: return diDownloadRailStart(state);
        case actionTypes.DI_DOWNLOAD_RAIL_SUCCESS: return diDownloadRailSuccess(state, action);
        case actionTypes.DI_DOWNLOAD_RAIL_FAIL: return diDownloadRailFail(state, action);
        case actionTypes.GET_DI_CSV: return getDICSVStart(state, action);
        case actionTypes.GET_DI_JSON: return getDIJsonStart(state, action);
        case actionTypes.DI_PLAN_RESET: return diPlanReset(state);
        case actionTypes.DI_PLAN_SET_ACTIVE: return setActiveDIPlan(state, action);
        case actionTypes.DI_PLAN_SET_ACTIVE_RESET: return setActiveDIPlanReset(state);
        case actionTypes.DI_PLAN_DELETE: return diPlanDelete(state, action);
        case actionTypes.DI_PLAN_DELETE_RESET: return diPlanDeleteReset(state);
        case actionTypes.PUBLISH_DI: return publish(state, action);
        default: return state;
    }
};

export default reducer;
