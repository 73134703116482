import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const getOutstandingPlanExtrasStart = () => ({ type: actionTypes.GET_OUTSTANDING_PLAN_EXTRAS_START });
export const getOutstandingPlanExtrasSuccess = planExtras => ({ type: actionTypes.GET_OUTSTANDING_PLAN_EXTRAS_SUCCESS, outstandingPlanExtras: planExtras });
export const getOutstandingPlanExtrasFail = error => ({ type: actionTypes.GET_OUTSTANDING_PLAN_EXTRAS_FAIL, outstandingPlanExtrasError: error });

export const getOutstandingPlanExtras = () => {
    return dispatch => {
        const url = '/PlanExtras';

        dispatch(getOutstandingPlanExtrasStart());
        axios
            .get(url)
            .then(response => {
                const ret = response.data;
                dispatch(getOutstandingPlanExtrasSuccess(ret));
            })
            .catch(error => dispatch(getOutstandingPlanExtrasFail(error)));
    };
};

export const getPlansForPlanExtrasStart = () => ({ type: actionTypes.GET_PLANS_FOR_PLAN_EXTRAS_START });
export const getPlansForPlanExtrasSuccess = plans => ({ type: actionTypes.GET_PLANS_FOR_PLAN_EXTRAS_SUCCESS, plansForPlanExtras: plans });
export const getPlansForPlanExtrasFail = error => ({ type: actionTypes.GET_PLANS_FOR_PLAN_EXTRAS_FAIL, plansForPlanExtrasError: error });
export const getPlansForPlanExtrasReset = () => ({ type: actionTypes.GET_PLANS_FOR_PLAN_EXTRAS_RESET });
export const getPlansForPlanExtras = ids => {
    return dispatch => {
        let url = '/PlanExtras/FindPlans';

        let pre = '?';

        ids.forEach(id => {
            url += `${pre}ids=${id}`;
            pre = '&';
        });

        dispatch(getPlansForPlanExtrasStart());
        axios
            .get(url)
            .then(response => {
                if (response.status === 200) dispatch(getPlansForPlanExtrasSuccess(response.data));
                else dispatch(getPlansForPlanExtrasFail(`Unable to find a plan. (Code ${response.status})`));
            })
            .catch(error => {
                dispatch(getPlansForPlanExtrasFail(error));
            });
    };
};

export const getPlansForPreDefinedStart = () => ({ type: actionTypes.GET_PLANS_FOR_PRE_DEFINED_START });
export const getPlansForPreDefinedSuccess = plans => ({ type: actionTypes.GET_PLANS_FOR_PRE_DEFINED_SUCCESS, plansForPreDefined: plans });
export const getPlansForPreDefinedFail = error => ({ type: actionTypes.GET_PLANS_FOR_PRE_DEFINED_FAIL, plansForPreDefinedError: error });
export const getPlansForPreDefinedReset = () => ({ type: actionTypes.GET_PLANS_FOR_PRE_DEFINED_RESET });
export const getPlansForPreDefined = () => {
    return dispatch => {
        const url = '/PreDefinedRoutes/FindPlans';

        dispatch(getPlansForPreDefinedStart());
        axios
            .get(url)
            .then(response => {
                if (response.status === 200) dispatch(getPlansForPreDefinedSuccess(response.data));
                else dispatch(getPlansForPreDefinedFail(`Unable to find a plan. (Code ${response.status})`));
            })
            .catch(error => {
                dispatch(getPlansForPreDefinedFail(error));
            });
    };
};

export const getProcessedRequestsCountStart = () => ({ type: actionTypes.GET_PROCESSED_REQUESTS_COUNT_START });
export const getProcessedRequestsCountSuccess = count => ({ type: actionTypes.GET_PROCESSED_REQUESTS_COUNT_SUCCESS, processedRequestsCount: count });
export const getProcessedRequestsCountFail = error => ({ type: actionTypes.GET_PROCESSED_REQUESTS_COUNT_FAIL, processedRequestsCountError: error });

export const getProcessedRequestsCount = filters => {
    return dispatch => {
        const url = '/ProcessedStoreRequests/getrequestrecordcount';

        dispatch(getProcessedRequestsCountStart());
        axios
            .post(url, filters)
            .then(response => dispatch(getProcessedRequestsCountSuccess(response.data)))
            .catch(error => dispatch(getProcessedRequestsCountFail(error)));
    };
};
export const getAdHocDeliveryTypesStart = () => ({ type: actionTypes.GET_AD_HOC_DELIVERY_TYPES_START });
export const getAdHocDeliveryTypesSuccess = adHocDeliveryTypes => ({
    type: actionTypes.GET_AD_HOC_DELIVERY_TYPES_SUCCESS,
    adHocDeliveryTypes: adHocDeliveryTypes
});
export const getAdHocDeliveryTypesFail = error => ({ type: actionTypes.GET_AD_HOC_DELIVERY_TYPES_FAIL, adHocDeliveryTypesError: error });

export const getAdHocDeliveryTypes = () => {
    return dispatch => {
        const url = '/PreDefinedRoutes/adhocdeliverytypes';

        dispatch(getAdHocDeliveryTypesStart());
        axios
            .get(url)
            .then(response => dispatch(getAdHocDeliveryTypesSuccess(response.data)))
            .catch(error => dispatch(getAdHocDeliveryTypesFail(error)));
    };
};

export const getPreDefinedRoutesFieldOptionsStart = () => ({ type: actionTypes.GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_START });
export const getPreDefinedRoutesFieldOptionsSuccess = preDefinedRoutesFieldOptions => ({
    type: actionTypes.GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_SUCCESS,
    preDefinedRoutesFieldOptions: preDefinedRoutesFieldOptions
});
export const getPreDefinedRoutesFieldOptionsFail = error => ({
    type: actionTypes.GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_FAIL,
    PreDefinedRoutesFieldOptionsError: error
});

export const getPreDefinedRoutesFieldOptions = () => {
    return dispatch => {
        const url = '/PreDefinedRoutes/FieldOptions';

        dispatch(getPreDefinedRoutesFieldOptionsStart());
        axios
            .get(url)
            .then(response => dispatch(getPreDefinedRoutesFieldOptionsSuccess(response.data)))
            .catch(error => dispatch(getPreDefinedRoutesFieldOptionsFail(error)));
    };
};

export const getProcessedRequestsStart = () => ({ type: actionTypes.GET_PROCESSED_REQUESTS_START });
export const getProcessedRequestsSuccess = processedRequests => ({ type: actionTypes.GET_PROCESSED_REQUESTS_SUCCESS, processedRequests: processedRequests });
export const getProcessedRequestsFail = error => ({ type: actionTypes.GET_PROCESSED_REQUESTS_FAIL, processedRequestsError: error });

export const getProcessedRequests = (pageSize, pageNumber, filters) => {
    return dispatch => {
        const url = '/ProcessedStoreRequests/getrequestpage';

        dispatch(getProcessedRequestsStart());
        axios
            .post(url, { size: pageSize, page: pageNumber, filter: filters })
            .then(response => dispatch(getProcessedRequestsSuccess(response.data)))
            .catch(error => dispatch(getProcessedRequestsFail(error)));
    };
};

export const updatePreDefinedRouteStart = () => {
    return {
        type: actionTypes.UPDATE_PRE_DEFINED_ROUTE_START,
        preDefinedRoute: null,
        preDefinedRouteError: null,
        preDefinedRouteSuccess: null,
        preDefinedRouteUpdating: true
    };
};

export const updatePreDefinedRouteSuccess = data => {
    return {
        type: actionTypes.UPDATE_PRE_DEFINED_ROUTE_SUCCESS,
        preDefinedRoute: data,
        preDefinedRouteError: null,
        preDefinedRouteSuccess: true,
        preDefinedRouteUpdating: false
    };
};

export const updatePreDefinedRouteFail = error => {
    return {
        type: actionTypes.UPDATE_PRE_DEFINED_ROUTE_FAIL,
        preDefinedRoute: null,
        preDefinedRouteSuccess: false,
        preDefinedRouteUpdating: false,
        preDefinedRouteError: error
    };
};

export const updatePreDefinedRouteReset = () => {
    return {
        type: actionTypes.UPDATE_PRE_DEFINED_ROUTE_RESET,
        preDefinedRouteSuccess: null
    };
};

export const updatePreDefinedRoute = preDefinedRoute => {
    return dispatch => {
        dispatch(updatePreDefinedRouteStart());

        const url = '/PreDefinedRoutes/PreDefinedRoute';

        axios
            .post(url, preDefinedRoute)
            .then(response => {
                if (response.status === 200) dispatch(updatePreDefinedRouteSuccess(response.data));
                else dispatch(updatePreDefinedRouteFail(`Failed saving pre-defined route (Code ${response.status})`));
                updatePreDefinedRouteReset();
            })
            .catch(error => {
                dispatch(updatePreDefinedRouteFail(`Failed saving pre-defined route ${error}`));
                updatePreDefinedRouteReset();
            });
    };
};

export const getPreDefinedRoutesStart = () => {
    return {
        type: actionTypes.GET_PRE_DEFINED_ROUTES_START,
        preDefinedRoutes: null,
        error: null,
        getPreDefinedRoutesSuccess: null,
        getPreDefinedRoutesLoading: true
    };
};

export const getPreDefinedRoutesSuccess = preDefinedRoutes => {
    return {
        type: actionTypes.GET_PRE_DEFINED_ROUTES_SUCCESS,
        preDefinedRoutes: preDefinedRoutes,
        error: null,
        getPreDefinedRoutesSuccess: true,
        getPreDefinedRoutesLoading: false
    };
};

export const getPreDefinedRoutesFail = error => {
    return {
        type: actionTypes.GET_PRE_DEFINED_ROUTES_FAIL,
        preDefinedRoutes: null,
        error: error,
        getPreDefinedRoutesSuccess: false,
        getPreDefinedRoutesLoading: false
    };
};

export const getPreDefinedRoutes = () => {
    return dispatch => {
        dispatch(getPreDefinedRoutesStart());

        const url = '/PreDefinedRoutes/PreDefinedRoutes';

        axios
            .get(url)
            .then(response => {
                if (response.status === 200) dispatch(getPreDefinedRoutesSuccess(response.data));
                else dispatch(getPreDefinedRoutesFail(`Failed saving pre-defined route (Code ${response.status})`));
            })
            .catch(error => {
                dispatch(getPreDefinedRoutesFail(`Failed saving pre-defined route ${error}`));
            });
    };
};

export const deletePreDefinedRoutesStart = () => {
    return {
        type: actionTypes.DELETE_PRE_DEFINED_ROUTES_START,
        preDefinedRoutes: null,
        error: null,
        preDefinedRoutesDeleteSuccess: null,
        preDefinedRoutesDeleting: true
    };
};

export const deletePreDefinedRoutesSuccess = preDefinedRoutes => {
    return {
        type: actionTypes.DELETE_PRE_DEFINED_ROUTES_SUCCESS,
        preDefinedRoutes,
        error: null,
        preDefinedRoutesDeleteSuccess: true,
        preDefinedRoutesDeleting: false
    };
};

export const deletePreDefinedRoutesFail = error => {
    return {
        type: actionTypes.DELETE_PRE_DEFINED_ROUTES_FAIL,
        preDefinedRoutes: null,
        error: error,
        preDefinedRoutesDeleteSuccess: false,
        preDefinedRoutesDeleting: false
    };
};
export const deletePreDefinedRoutesReset = () => {
    return {
        type: actionTypes.DELETE_PRE_DEFINED_ROUTES_RESET,
        preDefinedRoutesDeleteSuccess: null
    };
};

export const deletePreDefinedRoutes = preDefinedRoutes => {
    return dispatch => {
        dispatch(deletePreDefinedRoutesStart());

        const url = '/PreDefinedRoutes/PreDefinedRoutes';

        axios
            .delete(url, { data: preDefinedRoutes })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    dispatch(deletePreDefinedRoutesSuccess(response.data));
                    dispatch(deletePreDefinedRoutesReset());
                } else dispatch(deletePreDefinedRoutesFail(`Failed deleting pre-defined routes (Code ${response.status})`));
            })
            .catch(error => {
                dispatch(deletePreDefinedRoutesFail(`Failed deleting pre-defined routes ${error}`));
            });
    };
};

export const downloadPreDefinedRoutesStart = () => {
    return {
        type: actionTypes.DOWNLOAD_PRE_DEFINED_ROUTES_START,
        file: null,
        error: null,
        downloadPreDefinedRoutesSuccess: null,
        downloadPreDefinedRoutesFail: null,
        downloadPreDefinedRoutesLoading: true
    };
};

export const downloadPreDefinedRoutesSuccess = (file, fileName, fileType) => {
    return {
        type: actionTypes.DOWNLOAD_PRE_DEFINED_ROUTES_SUCCESS,
        file,
        fileName,
        fileType,
        error: null,
        downloadPreDefinedRoutesSuccess: true,
        downloadPreDefinedRoutesFail: null,
        downloadPreDefinedRoutesLoading: false
    };
};

export const downloadPreDefinedRoutesFail = error => {
    return {
        type: actionTypes.DOWNLOAD_PRE_DEFINED_ROUTES_FAIL,
        file: null,
        error: error,
        downloadPreDefinedRoutesSuccess: false,
        downloadPreDefinedRoutesFail: true,
        downloadPreDefinedRoutesLoading: false
    };
};
export const downloadPreDefinedRoutesReset = () => {
    return {
        type: actionTypes.DOWNLOAD_PRE_DEFINED_ROUTES_RESET
    };
};

export const downloadPreDefinedRoutes = (ids, format, simulatePlan) => {
    return dispatch => {
        dispatch(downloadPreDefinedRoutesStart());

        let url = `/PreDefinedRoutes/Download?format=${format}&simulatePlan=${simulatePlan}`;

        ids.forEach(id => {
            url += `&ids=${id}`;
        });

        let filename = 'unknown.file';
        switch (format) {
            case 'text/csv':
                filename = 'ProDefinedRoutes.csv';
                break;
            case 'application/json':
                filename = 'ProDefinedRoutes.json';
                break;
            default:
                filename = 'unknown.file';
                break;
        }

        axios
            .get(url, {
                responseType: 'blob',
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
                if (response.status === 200) dispatch(downloadPreDefinedRoutesSuccess(response.data, filename, format));
                else dispatch(downloadPreDefinedRoutesFail(`Failed to download file (Code ${response.status})`));
            })
            .catch(error => {
                dispatch(downloadPreDefinedRoutesFail(`Failed to download file ${error}`));
            });
    };
};

export const downloadPlanExtrasStart = () => {
    return {
        type: actionTypes.DOWNLOAD_PLAN_EXTRAS_START,
        file: null,
        error: null,
        downloadPlanExtrasSuccess: null,
        downloadPlanExtrasLoading: true
    };
};

export const downloadPlanExtrasSuccess = (file, fileName, fileType) => {
    return {
        type: actionTypes.DOWNLOAD_PLAN_EXTRAS_SUCCESS,
        file,
        fileName,
        fileType,
        error: null,
        downloadPlanExtrasSuccess: true,
        downloadPlanExtrasLoading: false
    };
};

export const downloadPlanExtrasFail = error => {
    return {
        type: actionTypes.DOWNLOAD_PLAN_EXTRAS_FAIL,
        file: null,
        error: error,
        downloadPlanExtrasSuccess: false,
        downloadPlanExtrasLoading: false
    };
};
export const downloadPlanExtrasReset = () => {
    return {
        type: actionTypes.DOWNLOAD_PLAN_EXTRAS_RESET
    };
};

export const downloadPlanExtras = (ids, format, simulatePlan) => {
    return dispatch => {
        dispatch(downloadPlanExtrasStart());

        let url = `/PlanExtras/Download?format=${format}&simulateSchedule=${simulatePlan}`;

        ids.forEach(id => {
            url += `&ids=${id}`;
        });

        let filename = 'unknown.file';
        switch (format) {
            case 'text/csv':
                filename = 'PlanExtras.csv';
                break;
            case 'application/json':
                filename = 'PlanExtras.json';
                break;
            default:
                filename = 'unknown.file';
                break;
        }

        axios
            .get(url, {
                responseType: 'blob',
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
                if (response.status === 200) dispatch(downloadPlanExtrasSuccess(response.data, filename, format));
                else dispatch(downloadPlanExtrasFail(`Failed to download file (Code ${response.status})`));
            })
            .catch(error => {
                dispatch(downloadPlanExtrasFail(`Failed to download file ${error}`));
            });
    };
};

export const planPlanExtrasStart = () => {
    return {
        type: actionTypes.PLAN_PLAN_EXTRAS_START
    };
};

export const planPlanExtrasSuccess = () => {
    return {
        type: actionTypes.PLAN_PLAN_EXTRAS_SUCCESS,
        planPlanExtrasSuccess: true,
        planPlanExtrasFail: false
    };
};

export const planPlanExtrasFail = error => {
    return {
        type: actionTypes.PLAN_PLAN_EXTRAS_FAIL,
        error: error,
        planPlanExtrasSuccess: false,
        planPlanExtrasFail: true
    };
};
export const planPlanExtrasReset = () => {
    return {
        type: actionTypes.DOWNLOAD_PLAN_EXTRAS_RESET,
        planPlanExtrasSuccess: null,
        planPlanExtrasFail: null
    };
};

export const planPlanExtras = ids => {
    return dispatch => {
        dispatch(planPlanExtrasStart());

        const url = '/PlanExtras/AddToPlan';
        axios
            .post(url, ids)
            .then(response => {
                if (response) {
                    if (response && response.status === 200) dispatch(planPlanExtrasSuccess(response.data));
                    else if (response) dispatch(planPlanExtrasFail(`Failed to add to plan (Code ${response.status})`));
                }
            })
            .catch(error => {
                dispatch(planPlanExtrasFail(`Failed to add to plan ${error}`));
            });
    };
};

export const planPreDefinedStart = () => {
    return {
        type: actionTypes.PLAN_PRE_DEFINED_START
    };
};

export const planPreDefinedSuccess = () => {
    return {
        type: actionTypes.PLAN_PRE_DEFINED_SUCCESS,
        planPreDefinedSuccess: true,
        planPreDefinedFail: false
    };
};

export const planPreDefinedFail = error => {
    return {
        type: actionTypes.PLAN_PRE_DEFINED_FAIL,
        error: error,
        planPreDefinedSuccess: false,
        planPreDefinedFail: true
    };
};
export const planPreDefinedReset = () => {
    return {
        type: actionTypes.PLAN_PRE_DEFINED_RESET,
        planPreDefinedSuccess: null,
        planPreDefinedFail: null
    };
};

export const planPreDefined = ids => {
    return dispatch => {
        dispatch(planPreDefinedStart());

        const url = '/PreDefinedRoutes/AddToPlan';
        axios
            .post(url, ids)
            .then(response => {
                if (response) {
                    if (response && response.status === 200) dispatch(planPreDefinedSuccess(response.data));
                    else if (response) dispatch(planPreDefinedFail(`Failed to add to plan (Code ${response.status})`));
                }
            })
            .catch(error => {
                dispatch(planPreDefinedFail(`Failed to add to plan ${error}`));
            });
    };
};
/*
export const publishPreDefinedStart = () => {
    return {
        type: actionTypes.PUBLISH_PRE_DEFINED_START
    };
};

export const publishPreDefinedSuccess = () => {
    return {
        type: actionTypes.PUBLISH_PRE_DEFINED_SUCCESS,
        publishPreDefinedSuccess: true,
        publishPreDefinedFail: false
    };
};

export const publishPreDefinedFail = error => {
    return {
        type: actionTypes.PUBLISH_PRE_DEFINED_FAIL,
        error: error,
        publishPreDefinedSuccess: false,
        publishPreDefinedFail: true
    };
};
export const publishPreDefinedReset = () => {
    return {
        type: actionTypes.PUBLISH_PRE_DEFINED_RESET,
        publishPreDefinedSuccess: null,
        publishPreDefinedFail: null
    };
};

export const publishPreDefined = ids => {
    return dispatch => {
        dispatch(publishPreDefinedStart());

        const url = '/PreDefinedRoutes/Publish';
        axios
            .post(url, ids)
            .then(response => {
                if (response) {
                    console.log(`Response is: ${response}`);
                    if (response && response.status === 200) dispatch(publishPreDefinedSuccess(response.data));
                    else if (response) dispatch(publishPreDefinedFail(`Failed to publish (Code ${response.status})`));
                }
            })
            .catch(error => {
                dispatch(publishPreDefinedFail(`Failed to publish ${error}`));
            });
    };
};
*/
