import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'primereact/chart';

const Capability = props => {
    const { outputSummary } = props;

    const [chartData, setChartData] = useState({});
    const [chartMax, setChartMax] = useState(0);

    const myChart = useRef(null);

    const options = {
        // eslint-disable-next-line no-console
        onClick: e => console.log(e),
        maintainAspectRatio: false,
        tooltips: {
            mode: 'index',
            intersect: false
        },
        responsive: true,
        title: {
            display: false,
            text: 'Plan vs Capability',
            fontSize: 32
        },
        legend: {
            display: false,
            position: 'right'
        },
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    gridLines: {
                        drawOnChartArea: false
                    },
                    ticks: {
                        min: 0,
                        max: chartMax,
                        maxTicksLimit: 10000
                    }
                }
            ]
        },
        animation: {
            duration: 1,
            onProgress: () => {
                const chartInstance = myChart.current.chart;
                const ctx = chartInstance.ctx;

                ctx.font = 'normal bold 14px Arial';
                // ctx.fontStyle = 'bold';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                const shifts = chartInstance.data.datasets.filter(x => x.label.includes('Shift'));
                const shiftArr = [];
                shifts.forEach(x => {
                    x.data.forEach((d, i) => {
                        if (shiftArr.findIndex(m => m.ind === i) > -1) shiftArr[i].volume += Math.ceil(d);
                        else shiftArr.push({ ind: i, volume: d });
                    });
                });

                chartInstance.data.datasets.forEach((dataset, i) => {
                    const meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach((bar, index) => {
                        let data = dataset.data[index];
                        if (dataset.label.includes('Shift') && dataset.label === 'Early Shift') {
                            data = shiftArr[index].volume;
                        } else if (dataset.label.includes('Shift') && dataset.label !== 'Early Shift') {
                            data = '';
                        }
                        ctx.fillText(data, bar._model.x, bar._model.y - 1); // bar._model.y - 5
                    });
                });
            }
        },
        style: [{ width: '500px' }]
    };

    const createChart = useCallback(() => {
        const chartDataObject = {
            labels: [],
            datasets: []
        };

        const shiftColours = ['orangered', 'darkorange', 'orange', 'grey', 'lightgrey'];

        const sortedCarouselData = Object.values(outputSummary).sort((a, b) => (a.pickDCName > b.pickDCName) - (a.pickDCName < b.pickDCName));

        let maxCapability = 0;

        const maxplan = sortedCarouselData.reduce((a, b) => (a.pickQty > b.pickQty ? a : b), 0).pickQty;

        sortedCarouselData.forEach(dc => {
            if (!dc.capability) return;

            const maxDCCapability = dc.capability.reduce((a, b) => +a + +b.deployedVolume, 0);

            if (maxDCCapability > maxCapability) maxCapability = maxDCCapability;

            chartDataObject.labels.push(dc.pickDCName);

            let objIndex = chartDataObject.datasets.findIndex(m => m.label === 'Plan Volume');

            if (objIndex === -1) {
                chartDataObject.datasets.push({
                    type: 'bar',
                    label: 'Plan Volume',
                    backgroundColor: shiftColours[3],
                    stack: 'Stack 0',
                    data: []
                });
                objIndex = chartDataObject.datasets.length - 1;
            }

            const volume = dc.pickQty;

            chartDataObject.datasets[objIndex].data.push(volume);

            // let siteVolume = 0;

            // objIndex = chartDataObject.datasets.findIndex(m => m.label === 'Site Capability');

            // if (objIndex === -1) {
            //     chartDataObject.datasets.push({
            //         type: 'bar',
            //         label: 'Site Capability',
            //         backgroundColor: shiftColours[4],
            //         stack: 'Stack 2',
            //         visible: false,
            //         data: []
            //     });
            // }

            dc.capability.forEach((c, i) => {
                let objIndex = chartDataObject.datasets.findIndex(m => m.label === c.shiftName);

                if (objIndex === -1) {
                    chartDataObject.datasets.push({
                        type: 'bar',
                        label: c.shiftName,
                        backgroundColor: shiftColours[i],
                        stack: 'Stack 1',
                        // display: false,
                        // borderColor: 'rgba(0,0,0,1)',
                        // borderWidth: 1,
                        // lineTension: 0.5,
                        fill: false,
                        data: []
                    });
                    objIndex = chartDataObject.datasets.length - 1;
                }

                const volume = Math.floor(c.deployedVolume);
                // siteVolume += volume;
                chartDataObject.datasets[objIndex].data.push(volume);
            });

            // objIndex = chartDataObject.datasets.findIndex(m => m.label === 'Site Capability');

            // chartDataObject.datasets[objIndex].data.push(siteVolume);
        });

        // Math.ceil(maxCapability / 10000) * 10000
        const chartMax = Math.ceil((maxplan > maxCapability ? maxplan : maxCapability) / 10000) * 10000 + 10000;

        setChartMax(chartMax);

        setChartData(chartDataObject);
    }, [outputSummary]);

    useEffect(() => {
        if (outputSummary) createChart();
    }, [outputSummary, createChart]);

    return (
        <div style={{ marginBottom: '10px' }}>
            <h3>Plan vs Capability</h3>
            <Chart ref={myChart} type="bar" data={chartData} options={options} style={{ height: '300px' }} refresh />
        </div>
    );
};

Capability.propTypes = {
    outputSummary: PropTypes.object
};

export default Capability;
