import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import axios from '../../../axios';
import * as actions from '../../../store/actions';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';

import { Accordion, AccordionItem } from '@jsluna/accordion';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { IconButton } from '@jsluna/button';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { RadioButtonField, SelectField, SwitchField, TextInputField } from '@jsluna/form';

import Save from '../../UI/Icons/Save';

import { ScrollPanel } from 'primereact/scrollpanel';
import { Slider } from 'primereact/slider';

import uniqBy from 'lodash-es/uniqBy';

import { isValidNumber } from '../../../shared/utility';

import classes from './PlanConfig.module.scss';

export const PlanConfig = props => {
    const [cOptions, setCOptions] = useState(null);
    const [dirty, setDirty] = useState(false);
    const [options, setOptions] = useState(null);
    const [sliderValues, setSliderValues] = useState({});
    const [weeks, setWeeks] = useState({});
    const [weightWeeksValid, setWeightWeeksValid] = useState({});
    const { configOptions, dcs, loadingConfigOptions, loadingDcs, onGetConfigOptions, onGetDcs, planId } = props;

    // update when the planId changes
    useEffect(() => {
        const id = +(planId || -1) === -1 ? null : planId;
        onGetConfigOptions(id);
    }, [onGetConfigOptions, planId]);

    // load the dc list
    useEffect(() => {
        if (!dcs && !loadingDcs) onGetDcs();
    }, [dcs, loadingDcs, onGetDcs]);

    // set the options list
    useEffect(() => {
        if (configOptions) {
            const opts = uniqBy(configOptions, o => o.name);
            const olist = opts.filter(o => o.type).sort((a, b) => (a.sequence || 999) - (b.sequence || 999));
            setOptions(olist);
            setCOptions(configOptions);

            // initialise weeks and sliderValues
            configOptions
                .filter(co => co.configOptionId === 18)
                .forEach(opt => {
                    const covals = (opt.value || '3-3').split('-');
                    weeks[opt.dcNo] = covals[0];
                    sliderValues[opt.dcNo] = +covals[1];
                });
        }
    }, [configOptions, sliderValues, weeks]);

    const updateOption = (dc, configOptionId, configValue) => {
        const updated = [...cOptions];
        const optIndex = cOptions.findIndex(co => co.dcNo === dc && co.configOptionId === configOptionId);
        if (optIndex === -1) {
            // we don't have a currently saved matching option, so create one
            const optType = options.find(o => o.configOptionId === configOptionId);
            const newOption = {
                ...optType,
                value: configValue,
                dcNo: dc
            };
            updated.push(newOption);
        } else updated[optIndex].value = configValue;

        setCOptions(updated);
        setDirty(true);
    };

    // control handlers
    const switchHandler = (event, dc) => {
        const configValue = event.target.checked ? '1' : '0';
        const configOption = event.target.value;
        const configOptionId = +configOption.substr(0, configOption.indexOf('-'));
        updateOption(dc, configOptionId, configValue);
    };

    const selectHandler = (event, dc) => {
        const configValue = event.target.value;
        const configOptionId = +event.target.dataset.configOptionId;
        updateOption(dc, configOptionId, configValue);
    };

    // update text values
    const textHandler = (event, dc) => {
        const configValue = event.target.value;
        const configOptionId = +event.target.dataset.configOptionId;
        updateOption(dc, configOptionId, configValue);
    };

    // handle sliders
    const sliderHandler = (event, dc, configOptionId) => {
        const coValue = `${weeks[dc] || 3}-${event.value}`;

        setSliderValues({
            ...sliderValues,
            [dc]: event.value
        });
        updateOption(dc, configOptionId, coValue);
    };

    // handle weighting weeks - must be a whole number value
    const weightWeeksHandler = (event, dc) => {
        const value = event.target.value;
        const validNumber = isValidNumber(value || '', 1, 52, true, true);
        const valid = validNumber ? null : 'Must be a whole number from 1-52';
        const configOptionId = +event.target.dataset.configOptionId;
        const coValue = `${value}-${sliderValues[dc]}`;
        updateOption(dc, configOptionId, coValue);
        setWeightWeeksValid({
            ...weightWeeksValid,
            [dc]: valid
        });
        setWeeks({
            ...weeks,
            [dc]: value
        });
    };

    // get any options required for dropdown lists
    const getOptions = optionInfo => {
        let options = [];

        if (!optionInfo) return [{ label: '', value: 0 }];

        if (optionInfo.startsWith('range:')) {
            // look for range:n1-n2-n3 where n1 is the starting value, n2 is the ending value and n3 is the increment
            // handles numbers with decimal points
            // don't care about the first element, so ignore it
            const [, optFrom, optTo, incr] = optionInfo.match(/:(\d+\.?\d*)-(\d+\.?\d*)-(\d+\.?\d*)$/);

            for (let x = +optFrom; x <= +optTo; x += +incr) {
                const val = Number.isInteger(x) ? x.toString() : x.toFixed(1);
                options.push({ label: val, value: val });
            }
        } else options = JSON.parse(optionInfo);

        return options;
    };

    // return the required control for the supplied option
    const getControl = (dc, option) => {
        let configOption = cOptions.find(c => c.dcNo === +dc && c.configOptionId === option.configOptionId);
        if (!configOption) {
            configOption = { ...option };
            configOption.dcNo = +dc;
            configOption.value = undefined;
        }

        switch (configOption.type) {
            case 'switch':
                const val = `${configOption.configOptionId || ''}-${dc}`.toString();
                return (
                    <SwitchField
                        key={`opt-${option.name}-${dc}`}
                        name={option.name}
                        label={option.name}
                        hideLabel={true}
                        onClick={event => switchHandler(event, +dc)}
                        options={[
                            {
                                label: '',
                                value: val,
                                defaultChecked: configOption.value === '1'
                            }
                        ]}
                    />
                );
            case 'select':
                return (
                    <SelectField
                        data-config-option-id={configOption.configOptionId}
                        name={`sel-${configOption.name}-${dc}`}
                        hideLabel={true}
                        options={getOptions(configOption.options)}
                        defaultValue={configOption.value}
                        onChange={event => selectHandler(event, +dc)}
                    />
                );
            case 'text':
                return (
                    <TextInputField
                        data-config-option-id={configOption.configOptionId}
                        name={`text-${configOption.name}-${dc}`}
                        hideLabel={true}
                        value={configOption.value || undefined}
                        placeholder={configOption.default}
                        onChange={event => textHandler(event, +dc, configOption)}
                    />
                );
            case 'radio':
                const opts = getOptions(configOption.options);
                const def = configOption.value || configOption.default;

                if (configOption.default)
                    opts.forEach(o => {
                        if (o.value === def.toString()) o.defaultChecked = true;
                    });

                return (
                    <RadioButtonField
                        data-config-option-id={configOption.configOptionId}
                        name={`radio-${configOption.name}-${dc}`}
                        hideLabel={true}
                        options={opts}
                        listType="inline"
                        onChange={event => selectHandler(event, +dc)}
                    />
                );
            case 'weight':
                const wval = (configOption.value || '3-3').split('-');
                const wks = wval[0];
                sliderValues[dc] = +wval[1];

                return (
                    <GridWrapper>
                        <GridItem size="1/6" style={{ textAlign: 'right', marginTop: '10px' }}>
                            Older
                        </GridItem>
                        <GridItem size="2/6" style={{ marginTop: '20px' }}>
                            <Slider min={1} max={3} value={sliderValues[dc]} onChange={event => sliderHandler(event, +dc, configOption.configOptionId)} />
                        </GridItem>
                        <GridItem size="1/6" style={{ marginTop: '10px' }}>
                            Recent
                        </GridItem>
                        <GridItem size="1/3">
                            <TextInputField
                                data-config-option-id={configOption.configOptionId}
                                error={weightWeeksValid[dc]}
                                name={`text-${configOption.name}-weeks-${dc}`}
                                hideLabel={true}
                                value={wks || ''}
                                placeholder="Weeks history"
                                onChange={event => weightWeeksHandler(event, +dc)}
                            />
                        </GridItem>
                    </GridWrapper>
                );
            default:
                return 'Unknown control type';
        }
    };

    const saveChanges = () => {
        const valid = !Object.values(weightWeeksValid).reduce((v, w) => (v = v || !!w), false);
        if (valid) {
            props.onSetConfigOptions(cOptions, planId);
            setDirty(false);
        } else
            props.growl.show({
                severity: 'error',
                summary: 'Invalid Value',
                detail: 'Unable to save changes due to an invalid user entered value. Please correct it and try again'
            });
    };

    const content = (
        <GridWrapper className="ln-u-push-top">
            <GridItem size="1/2">{props.global ? <h4>Global DI Plan Configuration</h4> : <h4>DI Plan Configuration</h4>}</GridItem>
            <GridItem size="1/4" className="ln-u-text-align-center">
                <ProgressIndicator loading={loadingDcs || loadingConfigOptions || props.settingOptions}>
                    <ProgressSpinner />
                    Please wait...
                </ProgressIndicator>
            </GridItem>
            <GridItem size="1/4" className="ln-u-text-align-right">
                <IconButton variant="outlined" label="Save" disabled={!dirty} onClick={() => saveChanges()}>
                    <Save />
                </IconButton>
            </GridItem>
            <GridItem size="1/1">
                <ScrollPanel style={{ width: '100%', height: props.scrollPanelHeight }} className={props.scrollPanelClassName}>
                    <Accordion titleElement="h3">
                        {(options || []).map((option, idx) => (
                            <AccordionItem
                                key={`config-item-key-${option.configOptionId}-${option.dcNo}-${idx}`}
                                id={`config-item-${option.configOptionid}-${option.dcNo}-${idx}`}
                                title={option.name}
                                className={!props.showDividerLine ? classes.noline : null}
                            >
                                {(dcs || []).map(dc => (
                                    <Fragment key={`dckey-${option.configOptionId}-${dc.locationNo}`}>
                                        <GridItem size="1/3">
                                            {dc.locationNo} {dc.locationName}
                                        </GridItem>
                                        <GridItem size="2/3">{getControl(dc.locationNo, option)}</GridItem>
                                    </Fragment>
                                ))}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </ScrollPanel>
            </GridItem>
        </GridWrapper>
    );

    return content;
};

/* eslint-disable no-multi-spaces */
// prettier-ignore
PlanConfig.propTypes = {
    configOptions: PropTypes.array,             // array of configuration options (redux)
    dcs: PropTypes.array,                       // array of DCs from (redux)
    global: PropTypes.bool,                     // is this the global config?
    growl: PropTypes.object.isRequired,         // growl component for error message display
    loadingConfigOptions: PropTypes.bool,       // are the config options currently loading? (redux)
    loadingDcs: PropTypes.bool,                 // is the list of DCs currently loading? (redux)
    onGetConfigOptions: PropTypes.func,         // function to retrieve the config options (redux)
    onGetDcs: PropTypes.func,                   // get the list of active dcs (redux)
    onSetConfigOptions: PropTypes.func,         // function to set a specific config option (redux)
    planId: PropTypes.number,                   // optional planId - only to be used for plan specific config (** user **)
    setConfigOptionsSuccess: PropTypes.bool,    // status of config option set attempt (redux)
    settingOptions: PropTypes.bool,             // config options are being save (redux)
    showDividerLine: PropTypes.bool             // show the divider line between accordion items (** user **)
};

// prettier-ignore
PlanConfig.defaultProps = {
    scrollPanelClassName: 'visibleBar',         // default class for the scrollpanel from un-modularised DCConfig.scss
    scrollPanelHeight: '530px',                 // default height for the scrollpanel
    showDividerLine: false                      // hide the divider lines between accordions by default
};

/* eslint-enable no-multi-spaces */
const mapStateToProps = state => {
    return {
        configOptions: state.diConfig.diConfigOptions,
        dcs: state.diConfig.diDcs,
        loadingConfigOptions: state.diConfig.loadingDiOptions,
        loadingDcs: state.diConfig.loadingDiDcs,
        setConfigOptionsSuccess: state.diConfig.setDiOptionsSuccess,
        settingOptions: state.diConfig.settingDiOptions
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetConfigOptions: planId => dispatch(actions.diConfigGetOptions(planId)),
        onGetDcs: () => dispatch(actions.diConfigGetDcs()),
        onSetConfigOptions: (configOptionsToSave, planId) => dispatch(actions.diConfigSetOptions(configOptionsToSave, planId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(PlanConfig, axios));
