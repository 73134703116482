import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    loadingDistribution: false,
    distribution: []
};

const getEmailDistributionStart = state => updateObject(state, { loadingDistribution: true });
const getEmailDistributionSuccess = (state, action) => updateObject(state, { loadingDistribution: false, distribution: action.distribution });
const getEmailDistributionFail = (state, action) =>
    updateObject(state, { loadingDistribution: false, distributionError: true, distributionErrorObject: action.distributionError });

const addNewGroupStart = state => updateObject(state, { addingGroup: true });
const addNewGroupSuccess = (state, action) => updateObject(state, { addingGroup: false, newGroupId: action.newGroupId });
const addNewGroupFail = (state, action) => updateObject(state, { addingGroup: false, newGroupError: true, newGroupErrorObject: action.newGroupError });

const addNewEmailStart = state => updateObject(state, { addingEmail: true });
const addNewEmailSuccess = (state, action) => updateObject(state, { addingEmail: false, newEmailId: action.newEmailId });
const addNewEmailFail = (state, action) => updateObject(state, { addingEmail: false, newEmailError: true, newEmailErrorObject: action.newEmailError });

const emailSaveChangesStart = state => updateObject(state, { emailSavingChanges: true });
const emailSaveChangesSuccess = (state, action) => updateObject(state, { emailSavingChanges: false, emailSaveChangesSuccess: action.emailSaveChangesSuccess });
const emailSaveChangesFail = (state, action) =>
    updateObject(state, { emailSavingChanges: false, emailSaveChangesError: true, emailSaveChangesErrorObject: action.emailSaveChangesError });

const getEmailGroupNamesStart = state => updateObject(state, { gettingGroups: true });
const getEmailGroupNamesSuccess = (state, action) => updateObject(state, { gettingGroups: false, emailGroups: action.emailGroups });
const getEmailGroupNamesFail = (state, action) =>
    updateObject(state, { gettingGroups: false, emailGroupsError: true, emailGroupsErrorObject: action.emailGroupsError });

const sendGroupEmailStart = state => updateObject(state, { sendingGroupEmail: true });
const sendGroupEmailSuccess = (state, action) => updateObject(state, { sendingGroupEmail: false, sendingGroupEmailSuccess: action.sendingGroupEmailSuccess });
const sendGroupEmailFail = (state, action) =>
    updateObject(state, { sendingGroupEmail: false, sendingGroupEmailError: true, sendingGroupEmailErrorObject: action.sendingGroupEmailError });

/* prettier-ignore */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EMAIL_DISTRIBUTION_START: return getEmailDistributionStart(state);
        case actionTypes.GET_EMAIL_DISTRIBUTION_SUCCESS: return getEmailDistributionSuccess(state, action);
        case actionTypes.GET_EMAIL_DISTRIBUTION_FAIL: return getEmailDistributionFail(state, action);
        case actionTypes.ADD_NEW_GROUP_START: return addNewGroupStart(state);
        case actionTypes.ADD_NEW_GROUP_SUCCESS: return addNewGroupSuccess(state, action);
        case actionTypes.ADD_NEW_GROUP_FAIL: return addNewGroupFail(state, action);
        case actionTypes.ADD_NEW_EMAIL_START: return addNewEmailStart(state);
        case actionTypes.ADD_NEW_EMAIL_SUCCESS: return addNewEmailSuccess(state, action);
        case actionTypes.ADD_NEW_EMAIL_FAIL: return addNewEmailFail(state, action);
        case actionTypes.EMAIL_SAVE_CHANGES_START: return emailSaveChangesStart(state);
        case actionTypes.EMAIL_SAVE_CHANGES_SUCCESS: return emailSaveChangesSuccess(state, action);
        case actionTypes.EMAIL_SAVE_CHANGES_FAIL: return emailSaveChangesFail(state, action);
        case actionTypes.GET_EMAIL_GROUP_NAMES_START: return getEmailGroupNamesStart(state);
        case actionTypes.GET_EMAIL_GROUP_NAMES_SUCCESS: return getEmailGroupNamesSuccess(state, action);
        case actionTypes.GET_EMAIL_GROUP_NAMES_FAIL: return getEmailGroupNamesFail(state, action);
        case actionTypes.SEND_GROUP_EMAIL_START: return sendGroupEmailStart(state);
        case actionTypes.SEND_GROUP_EMAIL_SUCCESS: return sendGroupEmailSuccess(state, action);
        case actionTypes.SEND_GROUP_EMAIL_FAIL: return sendGroupEmailFail(state, action);
        default: return state;
    }
};

export default reducer;
