/* eslint-disable no-undef */
// import axios from '../../axios';
import { HubConnectionBuilder } from '@aspnet/signalr';
import * as actionTypes from './actionTypes';
import * as SignalR from '@aspnet/signalr';

// export const signalRPipelineStart = () => {
//     return {
//         type: actionTypes.SIGNALR_START
//     };
// };

export const signalRPipelineSuccess = data => {
    return {
        type: actionTypes.SIGNALR_PIPELINE_SUCCESS,
        data
    };
};

export const signalRPipelineReset = () => {
    return {
        type: actionTypes.SIGNALR_PIPELINE_SUCCESS,
        data: null
    };
};

export const signalRCVLSuccess = data => {
    return {
        type: actionTypes.SIGNALR_CVL_SUCCESS,
        data
    };
};

// export const signalRPipelineFail = error => {
//     return {
//         type: actionTypes.SIGNALR_FAIL,
//         error: error
//     };
// };

/* eslint-disable-next-line no-undef */
const baseURL = window.location.origin.includes('parkfarm')
    ? window._env_.REACT_APP_SERVER_URL.replace('eu-west-1', 'parkfarm').replace('https://', 'http://')
    : window._env_.REACT_APP_SERVER_URL;

const hubConnectURL = baseURL + '/plexHub';
const hubConnect = new HubConnectionBuilder().withUrl(hubConnectURL).build();

export const signalRRegisterCommands = (store, callback) => {
    hubConnect.on('pipelineupdate', data => {
        store.dispatch(signalRPipelineSuccess(data));
    });
    hubConnect.on('cvlinform', data => {
        store.dispatch(signalRCVLSuccess(data));
    });

    hubConnect.start().then(() => {
        callback();
    });

    hubConnect.onclose(() => {
        setTimeout(() => {
            hubConnect.start().then(() => {
                callback();
            });
        }, 5000);
    });
};

export const signalRInvokeMiddleware = () => {
    return next => async action => {
        if (hubConnect.connection.connectionState === SignalR.HubConnectionState.Connected)
            switch (action.type) {
                case actionTypes.SIGNALR_CVL_INFORM:
                    /* eslint-disable-next-line no-undef */
                    hubConnect.invoke('cvlinform');
                    break;
                default:
            }
        return next(action);
    };
};

// export default signalRInvokeMiddleware;
