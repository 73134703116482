import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {};

const configGetDcsStart = state => updateObject(state, { loadingDcs: true });
const configGetDcsSuccess = (state, action) => updateObject(state, { loadingDcs: false, dcs: action.dcs });
const configGetDcsFail = (state, action) => updateObject(state, { loadingDcs: false, error: action.error });

const configGetOptionsStart = state => updateObject(state, { loadingOptions: true });
const configGetOptionsSuccess = (state, action) => updateObject(state, { loadingOptions: false, configOptions: action.configOptions });
const configGetOptionsFail = (state, action) => updateObject(state, { loadingOptions: false, configOptionsError: action.configOptionsError });

const configSetOptionsStart = state => updateObject(state, { settingOptions: true, setOptionsSuccess: undefined });
const configSetOptionsSuccess = (state, action) => updateObject(state, { settingOptions: false, setOptionsSuccess: action.setOptionsSuccess });
const configSetOptionsFail = (state, action) => updateObject(state, { settingOptions: false, setOptionsError: action.setOptionsError });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONFIG_GET_DCS_START:
            return configGetDcsStart(state);
        case actionTypes.CONFIG_GET_DCS_SUCCESS:
            return configGetDcsSuccess(state, action);
        case actionTypes.CONFIG_GET_DCS_FAIL:
            return configGetDcsFail(state, action);
        case actionTypes.CONFIG_GET_OPTIONS_START:
            return configGetOptionsStart(state);
        case actionTypes.CONFIG_GET_OPTIONS_SUCCESS:
            return configGetOptionsSuccess(state, action);
        case actionTypes.CONFIG_GET_OPTIONS_FAIL:
            return configGetOptionsFail(state, action);
        case actionTypes.CONFIG_SET_OPTIONS_START:
            return configSetOptionsStart(state);
        case actionTypes.CONFIG_SET_OPTIONS_SUCCESS:
            return configSetOptionsSuccess(state, action);
        case actionTypes.CONFIG_SET_OPTIONS_FAIL:
            return configSetOptionsFail(state, action);
        default:
            return state;
    }
};

export default reducer;
