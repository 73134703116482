import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    trailers: null,
    trailersError: null,
    trailersSuccess: null,
    trailersLoading: null
};

const getTrailersStart = (state, action) =>
    updateObject(state, {
        trailers: action.trailers === undefined ? state.trailers : action.trailers,
        trailersError: action.trailersError === undefined ? state.trailersError : action.trailers,
        trailersSuccess: action.trailersSuccess === undefined ? state.trailersSuccess : action.trailersSuccess,
        trailersLoading: action.trailersLoading === undefined ? state.trailersLoading : action.trailersLoading
    });

// prettier-ignore
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRAILERS: return getTrailersStart(state, action);
        default: return state;
    }
};

export default reducer;
