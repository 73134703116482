import axios from '../../axios';
import * as actionTypes from './actionTypes';

import moment from 'moment';

moment.locale('en-GB');

// #region getTrunkSchedule
export const getTrunkSchedule = (planId, dcNo) => {
    return dispatch => {
        dispatch(getTrunkScheduleStart(planId, dcNo));
        const url = `/trunkschedule/${planId}/${dcNo}`;
        axios
            .get(url)
            .then(response => {
                dispatch(getTrunkScheduleSuccess(response.data));
            })
            .catch(error => dispatch(getTrunkScheduleFail(error)));
    };
};

export const getTrunkScheduleStart = (planId, dcNo) => ({
    type: actionTypes.GET_TRUNK_SCHEDULE,
    trunkSchedule: null,
    trunkScheduleError: null,
    trunkScheduleSuccess: null,
    trunkScheduleLoading: true,
    dcNo,
    planId
});

export const getTrunkScheduleSuccess = trunkSchedule => ({
    type: actionTypes.GET_TRUNK_SCHEDULE,
    trunkSchedule,
    trunkScheduleError: null,
    trunkScheduleSuccess: true,
    trunkScheduleLoading: false
});

export const getTrunkScheduleFail = error => ({
    type: actionTypes.GET_TRUNK_SCHEDULE,
    trunkSchedule: null,
    trunkScheduleError: error,
    trunkScheduleSuccess: false,
    trunkScheduleLoading: null
});
// #endregion

// #region addAdhocVolume
export const addAdhocTrunkVolume = (headerId, data) => {
    return dispatch => {
        dispatch(addAdhocTrunkVolumeStart());

        const url = `/trunkschedule/addAdhocVolume/${headerId}`;

        axios
            .post(url, data)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(addAdhocTrunkVolumeSuccess(response.data));
                else dispatch(addAdhocTrunkVolumeFail(response.status.toString()));
            })
            .catch(error => dispatch(addAdhocTrunkVolumeFail(error)));
    };
};
export const addAdhocTrunkVolumeStart = () => ({
    type: actionTypes.ADD_ADHOC_TRUNK_VOLUME,
    addAdhocTrunkVolumeSuccess: false,
    addAdhocTrunkVolumeRunning: true,
    addAdhocTrunkVolumeError: null
});
export const addAdhocTrunkVolumeSuccess = adhocTrunkVolume => ({
    type: actionTypes.ADD_ADHOC_TRUNK_VOLUME,
    adhocTrunkVolume,
    addAdhocTrunkVolumeSuccess: true,
    addAdhocTrunkVolumeRunning: false,
    addAdhocTrunkVolumeError: null
});
export const addAdhocTrunkVolumeFail = error => ({
    type: actionTypes.ADD_ADHOC_TRUNK_VOLUME,
    addAdhocTrunkVolumeSuccess: false,
    addAdhocTrunkVolumeRunning: false,
    addAdhocTrunkVolumeError: error
});

export const addAdhocTrunkVolumeReset = () => ({
    type: actionTypes.ADD_ADHOC_TRUNK_VOLUME,
    addAdhocTrunkVolumeSuccess: null,
    addAdhocTrunkVolumeError: null,
    addAdhocTrunkVolumeRunning: null
});
// #endregion
// #region downloadTrunkSchedule
export const downloadTrunkSchedule = (planId, dcNo) => {
    return dispatch => {
        dispatch(downloadTrunkScheduleStart());

        const url = `/trunkschedule/downloadschedule/${planId}/${dcNo}`;

        axios
            .get(url, { responseType: 'blob' })
            .then(response => {
                let str = response.headers['content-disposition'];
                str = str.substring(str.indexOf('filename=') + 10);
                str = str.substring(0, str.indexOf(';') - 1);
                dispatch(downloadTrunkScheduleSuccess(response.data, str));
            })
            .catch(error => dispatch(downloadTrunkScheduleFail(error)));
    };
};

export const downloadTrunkScheduleStart = () => ({
    type: actionTypes.DOWNLOAD_TRUNK_SCHEDULE,
    downloadTrunkSuccess: null,
    downloadingTrunkSchedule: true,
    downloadTrunkFilename: null
});

export const downloadTrunkScheduleSuccess = (data, filename) => ({
    type: actionTypes.DOWNLOAD_TRUNK_SCHEDULE,
    downloadTrunkData: data,
    downloadTrunkSuccess: true,
    downloadingTrunkSchedule: false,
    downloadTrunkFilename: filename
});

export const downloadTrunkScheduleFail = error => ({
    type: actionTypes.DOWNLOAD_TRUNK_SCHEDULE,
    downloadTrunkSuccess: false,
    error,
    downloadingTrunkSchedule: false,
    downloadTrunkFilename: null
});

export const downloadTrunkScheduleReset = () => ({
    type: actionTypes.DOWNLOAD_TRUNK_SCHEDULE,
    downloadTrunkSuccess: null,
    error: null,
    downloadingTrunkSchedule: null,
    downloadTrunkFilename: null
});
// #endregion

// #region deleteTrunkSchedules
export const deleteTrunkSchedules = (schedules, action) => {
    return dispatch => {
        dispatch(deleteTrunkSchedulesStart());

        const url = '/trunkSchedule/toggleTrunkSchedules';
        const data = schedules.map(m => ({ ...m, action: action }));

        axios
            .post(url, data)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(deleteTrunkSchedulesSuccess(response.data));
                else {
                    dispatch(deleteTrunkSchedulesFail(response.status));
                }
            })
            .catch(error => {
                dispatch(deleteTrunkSchedulesFail(error));
            });
    };
};

export const deleteTrunkSchedulesStart = () => ({
    type: actionTypes.DELETE_TRUNK_SCHEDULES,
    deleteTrunkSchedulesRunning: true
});
export const deleteTrunkSchedulesSuccess = success => ({
    type: actionTypes.DELETE_TRUNK_SCHEDULES,
    deleteTrunkSchedulesRunning: false,
    deleteTrunkSchedulesSuccess: true,
    deleteTrunkSchedulesMessage: success
});
export const deleteTrunkSchedulesFail = error => ({
    type: actionTypes.DELETE_TRUNK_SCHEDULES,
    deleteTrunkSchedulesRunning: false,
    deleteTrunkSchedulesSuccess: false,
    deleteTrunkSchedulesMessage: error
});

export const deleteTrunkSchedulesReset = () => ({
    type: actionTypes.DELETE_TRUNK_SCHEDULES,
    deleteTrunkSchedulesRunning: null,
    deleteTrunkSchedulesSuccess: null,
    deleteTrunkSchedulesMessage: null
});
// #endregion

// #region editTrunkSchedules
export const editTrunkSchedules = schedules => {
    return dispatch => {
        dispatch(editTrunkSchedulesStart());

        const url = '/trunkSchedule/editTrunkSchedules';

        axios
            .post(url, schedules)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(editTrunkSchedulesSuccess(response.data));
                else dispatch(editTrunkSchedulesFail(response.status));
            })
            .catch(error => dispatch(editTrunkSchedulesFail(error)));
    };
};

export const editTrunkSchedulesStart = () => ({
    type: actionTypes.EDIT_TRUNK_SCHEDULES,
    editTrunkSchedulesSuccess: false,
    editTrunkSchedulesRunning: true,
    editTrunkSchedulesError: null
});
export const editTrunkSchedulesSuccess = success => ({
    type: actionTypes.EDIT_TRUNK_SCHEDULES,
    editTrunkSchedulesSuccess: success,
    editTrunkSchedulesRunning: false,
    editTrunkSchedulesError: null
});
export const editTrunkSchedulesFail = error => ({
    type: actionTypes.EDIT_TRUNK_SCHEDULES,
    editTrunkSchedulesSuccess: false,
    editTrunkSchedulesRunning: false,
    editTrunkSchedulesError: error
});

export const editTrunkSchedulesReset = () => ({
    type: actionTypes.EDIT_TRUNK_SCHEDULES,
    editTrunkSchedulesSuccess: null,
    editTrunkSchedulesError: null,
    editTrunkSchedulesRunning: null
});

// #endregion

// #region holdOverTrunkSchedules
export const holdOverTrunkSchedules = schedules => {
    return dispatch => {
        dispatch(holdOverTrunkSchedulesStart());

        const url = '/schedule/holdOverDeliveries';

        axios
            .post(url, schedules)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(holdOverTrunkSchedulesSuccess(true, schedules));
                else dispatch(holdOverTrunkSchedulesFail(true));
            })
            .catch(error => dispatch(holdOverTrunkSchedulesFail(error)));
    };
};

export const holdOverTrunkSchedulesStart = () => ({
    type: actionTypes.HOLD_OVER_TRUNK_SCHEDULES,
    holdOverTrunkSchedulesSuccess: false,
    holdOverTrunkSchedulesRunning: true,
    holdOverTrunkSchedulesError: null
});
export const holdOverTrunkSchedulesSuccess = (success, schedules) => ({
    type: actionTypes.HOLD_OVER_TRUNK_SCHEDULES,
    holdOverTrunkSchedulesItems: schedules,
    holdOverTrunkSchedulesSuccess: success,
    holdOverTrunkSchedulesRunning: false,
    holdOverTrunkSchedulesError: null
});
export const holdOverTrunkSchedulesFail = error => ({
    type: actionTypes.HOLD_OVER_TRUNK_SCHEDULES,
    holdOverTrunkSchedulesSuccess: false,
    holdOverTrunkSchedulesRunning: false,
    holdOverTrunkSchedulesError: error
});

export const holdOverTrunkSchedulesReset = () => ({
    type: actionTypes.HOLD_OVER_TRUNK_SCHEDULES,
    holdOverTrunkSchedulesItems: null,
    holdOverTrunkSchedulesSuccess: null,
    holdOverTrunkSchedulesError: null,
    holdOverTrunkSchedulesRunning: false
});
// #endregion
