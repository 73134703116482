import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const generalGetDataAsOptionsStart = () => ({ type: actionTypes.GENERAL_GET_DATA_AS_OPTIONS_START });
export const generalGetDataAsOptionsSuccess = options => ({ type: actionTypes.GENERAL_GET_DATA_AS_OPTIONS_SUCCESS, options: options });
export const generalGetDataAsOptionsFail = error => ({ type: actionTypes.GENERAL_GET_DATA_AS_OPTIONS_FAIL, optionsError: error });

export const generalGetDataAsOptions = (tableName, valueColumn, labelColumn) => {
    return dispatch => {
        const url = `/generaldata/GetDataAsOptions/${tableName}/${valueColumn}/${labelColumn}`;

        dispatch(generalGetDataAsOptionsStart());
        axios
            .get(url)
            .then(response => dispatch(generalGetDataAsOptionsSuccess(response.data)))
            .catch(error => dispatch(generalGetDataAsOptionsFail(error)));
    };
};
