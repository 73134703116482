import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../axios';
import * as actions from '../../store/actions/index';

import { Card } from '@jsluna/card';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { Table } from '@jsluna/table';
import { SelectField } from '@jsluna/form';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';

import Input from '../UI/Input/Input';

import saveAs from 'file-saver';
import { Growl } from 'primereact/growl';
import { updateObject } from '../../shared/utility';

import CsvIcon from '../UI/Icons/CsvIcon';
import AzureBlobIcon from '../UI/Icons/AzureBlobIcon';
import JsonIcon from '../UI/Icons/JsonIcon';
import { ArrowRight } from '@jsluna/icons';
import classes from '../UI/Icons/fileIcon.module.scss';

const LegacyFileMgt = props => {
    const {
        gettingMergedFile,
        gettingOutputTargets,
        mergedFile,
        mergedFileName,
        onGetLegacyLog,
        onGetOutputTargets,
        onResetLegacyLog,
        outputTargets,
        onTransformCsvAction,
        onTransformCsvReset,
        transformCsvData,
        transformCsvError,
        transformCsvFileName,
        transformCsvRunning,
        transformCsvSuccess,
        legacyLogData,
        legacyLogError,
        legacyLogRunning,
        legacyLogSuccess
    } = props;

    let growl;

    const transformOptions = [
        { label: 'Download', value: 'download' },
        { label: 'Publish', value: 'publish' }
    ];

    const [merging, setMerging] = useState(false);
    const [failedTransformMessage, setFailedTransformMessage] = useState(null);
    const [transformFileName, setTransformFileName] = useState(null);
    // const [selectedTarget, setSelectedTarget] = useState(null);
    const [transformAction, setTransformAction] = useState(null);
    const [targets, setTargets] = useState([]);
    const [transformDate, setTransformDate] = useState(null);
    const [validation, setValidation] = useState({
        dateFrom: false
    });

    // get a list of output targets
    useEffect(() => {
        if (!outputTargets && !gettingOutputTargets) onGetOutputTargets();
    }, [gettingOutputTargets, onGetOutputTargets, outputTargets]);

    // build the target list for the select component
    useEffect(() => {
        if (targets.length === 0 && outputTargets) setTargets(outputTargets.map(t => ({ label: t.name, value: t.id.toString() })));
    }, [outputTargets, setTargets, targets]);

    const failedTransportOrderDataMessage = useCallback(() => {
        setFailedTransformMessage(`No log information found for the ${transformDate.format('DD/MM/YYYY')}`);
    }, [transformDate]);

    // const failedTransportOrderDataMessage = () => {
    //     setFailedTransformMessage(`No data found for the ${transformDate.format('DD/MM/YYYY')}`);
    // };

    // inform the user of the state of the
    useEffect(() => {
        if (!legacyLogRunning && legacyLogSuccess && legacyLogData && legacyLogData.length > 0) {
            growl.show({ severity: 'warn', summary: 'Not all orders were processed' });
        } else if (!legacyLogRunning && legacyLogError) {
            growl.show({ severity: 'error', summary: 'Failed to retrieve the legacy log' });
        } else if (!legacyLogRunning && legacyLogError && legacyLogData && legacyLogData.length === 0) {
            growl.show({ severity: 'success', summary: 'All orders were transformed' });
        } else if (legacyLogSuccess && legacyLogData.length === 0) {
            failedTransportOrderDataMessage();
        }
    }, [growl, legacyLogRunning, legacyLogSuccess, legacyLogError, failedTransportOrderDataMessage, legacyLogData]);

    // trigger the download dialog for the merged file
    useEffect(() => {
        if (transformCsvSuccess && transformCsvData) {
            setTransformFileName(transformCsvFileName);
            if (transformAction === 'download') {
                const blob = new Blob([transformCsvData], { type: 'application/json' });
                saveAs(blob, transformCsvFileName);
                growl.show({ severity: 'success', summary: 'Transport Order Downloaded' });
            } else growl.show({ severity: 'success', summary: transformCsvData });
        } else if (transformCsvError) {
            growl.show({ severity: 'error', summary: transformCsvError });
        }
        if (transformCsvSuccess || transformCsvError) {
            onGetLegacyLog(transformDate, transformCsvFileName);
            setTimeout(() => {
                onTransformCsvReset();
            }, 2000);
        }
    }, [
        transformCsvSuccess,
        transformCsvData,
        transformCsvFileName,
        transformCsvError,
        transformAction,
        onTransformCsvReset,
        onGetLegacyLog,
        transformDate,
        growl
    ]);

    // trigger the download dialog for the merged file
    useEffect(() => {
        if (merging && !gettingMergedFile && mergedFile) {
            const blob = new Blob([mergedFile], { type: 'text/csv' });
            saveAs(blob, `${mergedFileName || 'Merged'}.csv`);
            setMerging(false);
        }
    }, [gettingMergedFile, mergedFile, mergedFileName, merging]);

    // const uploadHandler = event => {
    //     setMerging(true);
    //     props.onUpload(event.files, selectedTarget);
    // };

    const loading = (
        <ProgressIndicator page loading={props.gettingOutputTargets}>
            <ProgressSpinner />
            Loading...
        </ProgressIndicator>
    );

    const mergingIndicator = (
        <ProgressIndicator page loading={true}>
            <ProgressSpinner />
            Merging...
        </ProgressIndicator>
    );

    const transforming = (
        <ProgressIndicator page loading={transformCsvRunning}>
            <ProgressSpinner />
            Transforming Csv Files...
        </ProgressIndicator>
    );

    const selectChangeHandler = (e, type) => {
        resetFailedTransportOrderLog();
        if (type === 'transform') {
            // setSelectedTarget(null);
            setTransformAction(e.target.value);
        } else {
            setTransformAction(null);
            // setSelectedTarget(e.target.value);
        }
    };

    const resetFailedTransportOrderLog = () => {
        setTransformFileName(null);
        setFailedTransformMessage(null);
        onResetLegacyLog();
    };
    // const uploader = (
    //     <GridItem size="1/1">
    //         <FileUpload name="csvFiles" multiple={true} accept=".csv" uploadLabel="Merge" customUpload={true} uploadHandler={uploadHandler} />
    //     </GridItem>
    // );

    const uploadTransformHandler = event => {
        resetFailedTransportOrderLog();
        if (invalid()) {
            growl.show({ severity: 'error', summary: 'Required information missing or incorrect' });
            return;
        }
        onTransformCsvAction(event.files, transformDate, transformAction);
    };

    const onFieldValidate = (field, valid) => {
        const newValidation = updateObject(validation, { [field]: valid });
        setValidation(newValidation);
    };

    const uiSave = () => {
        if (legacyLogData.length === 0) {
            growl.show({ severity: 'info', summary: 'Nothing to download' });
            return;
        }
        const data = [];
        data.push('Reference, File, Details\n');
        legacyLogData.forEach(d => {
            data.push(d.reference + ', ' + d.details + '\n');
        });

        const file = new File(data, 'Failed transport orders.csv', { type: 'data:text/csv;charset=utf-8' });
        saveAs(file);
    };

    const invalid = () => Object.keys(validation).some(key => !validation[key]);

    const transform = (
        <Fragment>
            {!invalid() ? (
                <GridItem size="1/1">
                    <FileUpload
                        name="csvTranformFiles"
                        multiple={true}
                        accept=".csv"
                        uploadLabel="Process Files"
                        customUpload={true}
                        uploadHandler={uploadTransformHandler}
                    />
                </GridItem>
            ) : null}
        </Fragment>
    );

    const failedTransform = (
        <Fragment>
            <GridItem size="1/3" className="ln-u-display-1 ln-u-push-bottom ln-u-push-top">
                Information
            </GridItem>
            <GridItem size="1/3" className="ln-u-display-1 ln-u-push-bottom ln-u-push-top">
                <Button
                    style={{ marginLeft: '0.4rem', marginRight: '0.4rem' }}
                    onClick={() => onGetLegacyLog(transformDate, transformCsvFileName)}
                    icon="pi pi-list"
                    tooltip="Get failed transport orders"
                    disabled={legacyLogData.length !== 0 || !transformDate}
                    tooltipOptions={{ position: 'top' }}
                />
            </GridItem>
            <GridItem size="1/3" className="ln-u-display-1 ln-u-push-bottom ln-u-push-top">
                <Button
                    style={{ marginLeft: '0.4rem', marginRight: '0.4rem' }}
                    onClick={() => uiSave()}
                    icon="pi pi-download"
                    tooltip="Download failed transport orders"
                    disabled={legacyLogData.length === 0}
                    tooltipOptions={{ position: 'top' }}
                />
            </GridItem>
        </Fragment>
    );

    const failedTransformTable = () => {
        if (legacyLogData.length === 0)
            return (
                <Fragment>
                    <br />
                    <span>{failedTransformMessage}</span>
                </Fragment>
            );
        else
            return (
                <Table
                    data={legacyLogData}
                    columns={[
                        {
                            name: 'Filename',
                            accessor: rowData => ({ value: rowData.details.split(',')[0] })
                        },
                        {
                            name: 'Details',
                            accessor: rowData => ({ value: rowData.details.split(',')[1] })
                        }
                    ]}
                />
            );
    };

    // eslint-disable-next-line react/no-multi-comp
    const icon = type => {
        let i = null;
        switch (type) {
            case 'download':
                i = <JsonIcon />;
                break;
            case 'publish':
                i = <AzureBlobIcon />;
                break;
            default:
                i = null;
                break;
        }
        return (
            <Fragment>
                <CsvIcon />
                {i ? (
                    <div className={[classes.arrowAnimation, transformCsvRunning ? classes.move : ''].join(' ')}>
                        <ArrowRight className="ln-u-display-5" />
                        <ArrowRight className="ln-u-display-5" />
                        <ArrowRight className="ln-u-display-5" />
                    </div>
                ) : null}
                {i}
            </Fragment>
        );
    };

    return (
        <Card className="ln-u-push-top">
            {loading}
            <h2>Legacy CSV File Management</h2>
            <p>
                This section is used to transform the multiple Paragon CSV files into the new JSON Transport Order. To load a previous error log, select a date
                and click the list load icon
            </p>
            <GridWrapper>
                <GridItem size="4/10">
                    <GridItem size="1/2">
                        <SelectField
                            name="transformSelect"
                            label="Transform Action"
                            options={transformOptions}
                            onChange={e => selectChangeHandler(e, 'transform')}
                        />
                    </GridItem>
                    <GridItem size="1/2">
                        {/* <FormGroup name="date" label="Date" error={filterIt(validation) ? <span></span> : null}></FormGroup> */}
                        <Input
                            label="Date"
                            hideLabel={true}
                            fieldType="date"
                            onChange={(field, value) => {
                                resetFailedTransportOrderLog();
                                setTransformDate(value, 'transform');
                            }} // setTransformDate(field, value)
                            value={transformDate}
                            name="dateFrom"
                            isRequired={true}
                            onValidate={(field, valid) => onFieldValidate(field, valid)}
                        />
                    </GridItem>
                </GridItem>
                <GridItem size="2/10">{icon(transformAction)}</GridItem>
                {/* <GridItem size="4/10">
                    <GridItem size="1/2">
                        <SelectField name="target-select" label="Merge Output Target" options={targets} onChange={e => selectChangeHandler(e, 'merge')} />
                    </GridItem>
                    <GridItem size="3/4">&nbsp;</GridItem>
                    {selectedTarget ? uploader : null}
                </GridItem> */}
                <GridItem size="4/10">{failedTransform}</GridItem>
                <GridItem size="1/2">{transformAction ? transform : <span></span>}</GridItem>
                <GridItem size="1/2">
                    {!transformFileName ? '' : transformFileName}
                    {failedTransformTable()}
                    {/* <ul id="log" style={{ overflowY: 'scroll', maxHeight: '325px' }}>
                            <GridItem size="1/2" style={{ fontSize: '13px' }}>
                                Refernce
                            </GridItem>
                            <GridItem size="1/2" style={{ fontSize: '13px' }}>
                                Details
                            </GridItem>
                            {legacyLogData.map((m, ind) => (
                                <li key={ind}>
                                    <GridItem size="1/2" style={{ fontSize: '13px' }}>
                                        {m.reference || ''}
                                    </GridItem>
                                    <GridItem size="1/2" style={{ fontSize: '13px' }}>
                                        {m.details || ''}
                                    </GridItem>
                                </li>
                            ))}
                            <div className="fadeout"></div>
                        </ul> */}
                </GridItem>
            </GridWrapper>
            {gettingMergedFile ? mergingIndicator : null}
            {transforming}
            <Growl className="customGrowl" ref={el => (growl = el)}></Growl>
        </Card>
    );
};

LegacyFileMgt.propTypes = {
    gettingMergedFile: PropTypes.bool,
    gettingOutputTargets: PropTypes.bool,
    legacyLogData: PropTypes.array,
    legacyLogError: PropTypes.string,
    legacyLogRunning: PropTypes.bool,
    legacyLogSuccess: PropTypes.bool,
    mergedFile: PropTypes.any,
    mergedFileName: PropTypes.string,
    onGetLegacyLog: PropTypes.func.isRequired,
    onGetOutputTargets: PropTypes.func.isRequired,
    onResetLegacyLog: PropTypes.func.isRequired,
    onTransformCsvAction: PropTypes.func.isRequired,
    onTransformCsvReset: PropTypes.func.isRequired,
    // onUpload: PropTypes.func.isRequired,
    outputTargets: PropTypes.array,
    transformCsvData: PropTypes.any,
    transformCsvError: PropTypes.string,
    transformCsvFileName: PropTypes.string,
    transformCsvRunning: PropTypes.bool,
    transformCsvSuccess: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        gettingMergedFile: state.csv.gettingMergedFile,
        gettingOutputTargets: state.plan.gettingOutputTargets,
        mergedFile: state.csv.mergedFile,
        mergedFileName: state.csv.mergedFileName,
        transformCsvData: state.csv.transformCsvData,
        transformCsvError: state.csv.transformCsvError,
        transformCsvFileName: state.csv.transformCsvFileName,
        transformCsvRunning: state.csv.transformCsvRunning,
        transformCsvSuccess: state.csv.transformCsvSuccess,
        legacyLogRunning: state.csv.legacyLogRunning,
        legacyLogSuccess: state.csv.legacyLogSuccess,
        legacyLogData: state.csv.legacyLogData,
        legacyLogError: state.csv.legacyLogError,
        outputTargets: state.plan.outputTargets
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetOutputTargets: () => dispatch(actions.getOutputTargets()),
        onUpload: (files, targetId) => dispatch(actions.getMergedFile(files, targetId)),
        onTransformCsvAction: (files, date, action) => {
            dispatch(actions.legacyLogReset());
            if (action === 'download') dispatch(actions.transformCsvDownload(files, date));
            else dispatch(actions.transformCsvPublish(files, date));
        },
        onTransformCsvReset: () => dispatch(actions.transformCsvReset()),
        onGetLegacyLog: (date, filename) => dispatch(actions.getLegacyLog(date, filename)),
        onResetLegacyLog: () => dispatch(actions.legacyLogReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(LegacyFileMgt, axios));
