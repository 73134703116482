import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    trunkSchedule: null,
    trunkScheduleSuccess: null,
    trunkScheduleError: null,
    trunkScheduleLoading: null,
    planId: null,
    dcNo: null,
    downloadTrunkSuccess: null,
    downloadingTrunkSchedule: null,
    downloadTrunkFilename: null,
    downloadTrunkData: null,
    editTrunkSchedulesRunning: null,
    editTrunkSchedulesError: null,
    editTrunkSchedulesSuccess: null,
    holdOverTrunkSchedulesRunning: null,
    holdOverTrunkSchedulesError: null,
    holdOverTrunkSchedulesSuccess: null,
    holdOverTrunkSchedulesItems: null,
    adhocTrunkVolume: null,
    addAdhocTrunkVolumeError: null,
    addAdhocTrunkVolumeRunning: null,
    addAdhocTrunkVolumeSuccess: null
};

const getTrunkSchedule = (state, action) =>
    updateObject(state, {
        trunkSchedule: action.trunkSchedule === undefined ? state.trunkSchedule : action.trunkSchedule,
        trunkScheduleSuccess: action.trunkScheduleSuccess === undefined ? state.trunkScheduleSuccess : action.trunkScheduleSuccess,
        trunkScheduleError: action.trunkScheduleError === undefined ? state.trunkScheduleError : action.trunkScheduleError,
        trunkScheduleLoading: action.trunkScheduleLoading === undefined ? state.trunkScheduleLoading : action.trunkScheduleLoading,
        planId: action.planId === undefined ? state.planId : action.planId,
        dcNo: action.dcNo === undefined ? state.dcNo : action.dcNo
    });

const downloadTrunkSchedule = (state, action) =>
    updateObject(state, {
        downloadTrunkSuccess: action.downloadTrunkSuccess === undefined ? state.downloadTrunkSuccess : action.downloadTrunkSuccess,
        downloadingTrunkSchedule: action.downloadingTrunkSchedule === undefined ? action.downloadingTrunkSchedule : action.downloadingTrunkSchedule,
        downloadTrunkFilename: action.downloadTrunkFilename === undefined ? action.downloadTrunkFilename : action.downloadTrunkFilename,
        downloadTrunkData: action.downloadTrunkData === undefined ? action.downloadTrunkData : action.downloadTrunkData
    });

const deleteTrunkSchedules = (state, action) =>
    updateObject(state, {
        deleteTrunkSchedulesRunning: action.deleteTrunkSchedulesRunning === undefined ? state.deleteTrunkSchedulesRunning : action.deleteTrunkSchedulesRunning,
        deleteTrunkSchedulesSuccess: action.deleteTrunkSchedulesSuccess === undefined ? state.deleteTrunkSchedulesSuccess : action.deleteTrunkSchedulesSuccess,
        deleteTrunkSchedulesMessage: action.deleteTrunkSchedulesMessage === undefined ? state.deleteTrunkSchedulesMessage : action.deleteTrunkSchedulesMessage
    });

const editTrunkSchedules = (state, action) =>
    updateObject(state, {
        editTrunkSchedulesRunning: action.editTrunkSchedulesRunning === undefined ? state.editTrunkSchedulesRunning : action.editTrunkSchedulesRunning,
        editTrunkSchedulesError: action.editTrunkSchedulesError === undefined ? state.editTrunkSchedulesError : action.editTrunkSchedulesError,
        editTrunkSchedulesSuccess: action.editTrunkSchedulesSuccess === undefined ? state.editTrunkSchedulesSuccess : action.editTrunkSchedulesSuccess
    });

const addAdhocTrunkVolume = (state, action) =>
    updateObject(state, {
        adhocTrunkVolume: action.adhocTrunkVolume === undefined ? state.adhocTrunkVolume : action.adhocTrunkVolume,
        addAdhocTrunkVolumeRunning: action.addAdhocTrunkVolumeRunning === undefined ? state.addAdhocTrunkVolumeRunning : action.addAdhocTrunkVolumeRunning,
        addAdhocTrunkVolumeError: action.addAdhocTrunkVolumeError === undefined ? state.addAdhocTrunkVolumeError : action.addAdhocTrunkVolumeError,
        addAdhocTrunkVolumeSuccess: action.addAdhocTrunkVolumeSuccess === undefined ? state.addAdhocTrunkVolumeSuccess : action.addAdhocTrunkVolumeSuccess
    });

const holdOverTrunkSchedules = (state, action) =>
    updateObject(state, {
        holdOverTrunkSchedulesItems: action.holdOverTrunkSchedulesItems === undefined ? state.holdOverTrunkSchedulesItems : action.holdOverTrunkSchedulesItems,
        holdOverTrunkSchedulesRunning:
            action.holdOverTrunkSchedulesRunning === undefined ? state.holdOverTrunkSchedulesRunning : action.holdOverTrunkSchedulesRunning,
        holdOverTrunkSchedulesError: action.holdOverTrunkSchedulesError === undefined ? state.holdOverTrunkSchedulesError : action.holdOverTrunkSchedulesError,
        holdOverTrunkSchedulesSuccess:
            action.holdOverTrunkSchedulesSuccess === undefined ? state.holdOverTrunkSchedulesSuccess : action.holdOverTrunkSchedulesSuccess
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRUNK_SCHEDULE:
            return getTrunkSchedule(state, action);
        case actionTypes.DOWNLOAD_TRUNK_SCHEDULE:
            return downloadTrunkSchedule(state, action);
        case actionTypes.DELETE_TRUNK_SCHEDULES:
            return deleteTrunkSchedules(state, action);
        case actionTypes.EDIT_TRUNK_SCHEDULES:
            return editTrunkSchedules(state, action);
        case actionTypes.HOLD_OVER_TRUNK_SCHEDULES:
            return holdOverTrunkSchedules(state, action);
        case actionTypes.ADD_ADHOC_TRUNK_VOLUME:
            return addAdhocTrunkVolume(state, action);
        default:
            return state;
    }
};

export default reducer;
