import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = { error: null, options: null, loading: false, success: null };

const generalGetDataAsOptionsStart = state => updateObject(state, { loading: true, success: null });
const generalGetDataAsOptionsSuccess = (state, action) => updateObject(state, { loading: false, success: true, options: action.options });
const generalGetDataAsOptionsFail = (state, action) => updateObject(state, { loading: false, success: false, error: action.error });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GENERAL_GET_DATA_AS_OPTIONS_START:
            return generalGetDataAsOptionsStart(state);
        case actionTypes.GENERAL_GET_DATA_AS_OPTIONS_SUCCESS:
            return generalGetDataAsOptionsSuccess(state, action);
        case actionTypes.GENERAL_GET_DATA_AS_OPTIONS_FAIL:
            return generalGetDataAsOptionsFail(state, action);
        default:
            return state;
    }
};

export default reducer;
