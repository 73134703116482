import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Data, Filters, Toolbar } from 'react-data-grid-addons';
import DataGrid from 'react-data-grid';

import { ProgressBar, ProgressIndicator } from '@jsluna/progress';

// import { colourScale } from '../../../../shared/utility';
import classes from './Forecast.module.scss';

const Forecast = props => {
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filters, setFilters] = useState({});
    const selectors = Data.Selectors;
    const { AutoCompleteFilter, NumericFilter } = Filters;
    const { editable, sourceData } = props;

    const lunaGreen = '#218234';
    const lunaRed = '#d70000';
    const lunaYellow = '#ffc911';
    const lunaAqua = '#00a9cf';

    /* eslint-disable react/no-multi-comp, react/prop-types */
    const ragFormatter = ({ value }) => {
        let bgColour = lunaGreen;

        if (value <= -0.4) bgColour = lunaRed;
        if (value >= 0.5) bgColour = lunaYellow;
        if (value >= 1) bgColour = lunaAqua;

        return (
            <div
                className={`ln-u-text-align-center ln-u-color-${bgColour === lunaYellow || bgColour === lunaAqua ? 'dark' : 'white'}`}
                style={{ backgroundColor: bgColour }}
            >
                {value}
            </div>
        );
    };

    const defaultFormatter = ({ value }) => <div className="ln-u-text-align-center">{value}</div>;
    /* eslint-enable react/no-multi-comp, react/prop-types */

    // setup the data grid
    useEffect(() => {
        if (sourceData && sourceData.length > 0) {
            const cols = [
                { key: 'day', name: 'Day', filterable: true, filterRenderer: AutoCompleteFilter, formatter: defaultFormatter, width: 60 },
                { key: 'shiftName', name: 'Shift', filterable: true, filterRenderer: AutoCompleteFilter, formatter: defaultFormatter, width: 60 },
                { key: 'variance', name: 'Variance', filtertable: true, filterRenderer: NumericFilter, formatter: ragFormatter },
                { key: 'assigned', name: 'Assigned', filtertable: true, filterRenderer: NumericFilter, formatter: defaultFormatter },
                { key: 'required', name: 'Required', filtertable: true, filterRenderer: NumericFilter, formatter: defaultFormatter },
                { key: 'palletEquivalent', name: 'Pal Eqv', filtertable: true, filterRenderer: NumericFilter, formatter: defaultFormatter, width: 60 },
                { key: 'forecast', name: 'WLFC', filtertable: true, filterRenderer: NumericFilter, formatter: defaultFormatter, width: 60 },
                { key: 'cdcMovingDI', name: 'CDC', filtertable: true, filterRenderer: NumericFilter, formatter: defaultFormatter, width: 60 }
            ];

            setData(sourceData.map(s => ({ ...s, variance: Math.round((s.required - s.assigned) * -10) / 10 })));
            setColumns(cols);
        }
    }, [AutoCompleteFilter, NumericFilter, editable, sourceData]);

    const handleFilterChange = filter => filters => {
        const newFilters = { ...filters };

        if (filter.filterTerm) newFilters[filter.column.key] = filter;
        else delete newFilters[filter.column.key];

        return newFilters;
    };

    const getValidFilterValues = (rows, columnId) => rows.map(r => r[columnId]).filter((item, i, a) => i === a.indexOf(item));
    const rowGetter = i => ({ ...filteredRows[i] });
    const updateRows = useCallback(() => {
        const newRows = selectors.getRows({ rows: data, filters });
        setFilteredRows(newRows);
    }, [filters, selectors, data]);

    useEffect(() => {
        if (data) updateRows();
    }, [data, updateRows]);

    const loading = (
        <ProgressIndicator loading preventFocus>
            <ProgressBar size="small" />
        </ProgressIndicator>
    );

    const display = props.loading ? (
        loading
    ) : (
        <DataGrid
            columns={columns}
            enableCellSelect={true}
            getValidFilterValues={columnKey => getValidFilterValues(props.sourceData, columnKey)}
            minHeight={600}
            onAddFilter={filter => setFilters(handleFilterChange(filter))}
            onClearFilters={() => setFilters({})}
            onGridRowsUpdated={props.onGridRowsUpdated}
            rowsCount={filteredRows.length}
            rowGetter={rowGetter}
            toolbar={
                <Toolbar enableFilter={true}>
                    <div className={classes.gridTitle}>{props.title}</div>
                </Toolbar>
            }
        />
    );

    return <div className={classes.fixWidth}>{display}</div>;
};

Forecast.propTypes = {
    editable: PropTypes.bool,
    loading: PropTypes.bool,
    onGridRowsUpdated: PropTypes.func,
    sourceData: PropTypes.array,
    title: PropTypes.string
};

export default Forecast;
