import React from 'react';
import PropTypes from 'prop-types';

import { Cancel, Dislike, InfoCircle, Like, Tick } from '@jsluna/icons';
import { actionStatus } from '../../Plans/Pipeline/ActionStatus';
import { ProgressSpinner } from '@jsluna/progress';

const StatusIcon = props => {
    const { status, className } = props;

    const statusIcon = status => {
        let icon;

        if (typeof status === 'boolean') {
            if (status) icon = <Tick className={!className ? 'ln-u-color-green' : className} />;
            else icon = <Cancel className={!className ? 'ln-u-color-dark' : className} />;
        } else if (!isNaN(9)) {
            switch (status) {
                case actionStatus.running:
                    icon = (
                        <ProgressSpinner
                            className={!className ? 'ln-u-bg-color-orange ln-u-color-white' : className}
                            size="small"
                            style={{ height: '40px', width: '40px' }}
                        />
                    );
                    break;
                case actionStatus.complete:
                    icon = <Like className={!className ? 'ln-u-bg-color-green ln-u-color-white' : className} />;
                    break;
                case actionStatus.skipped:
                    icon = <Like className={!className ? 'ln-u-bg-color-orange ln-u-color-white' : className} />;
                    break;
                case actionStatus.jobComplete:
                    icon = <Like className={!className ? 'ln-u-bg-color-green ln-u-color-white' : className} />;
                    break;
                case actionStatus.error:
                case actionStatus.cancelled:
                    icon = <Dislike className={!className ? 'ln-u-bg-color-red ln-u-color-white' : className} />;
                    break;
                default:
                    icon = <InfoCircle className={!className ? 'ln-u-bg-color-blue ln-u-color-white' : className} />;
            }
        } else {
            icon = <InfoCircle className={!className ? 'ln-u-bg-color-blue ln-u-color-white' : className} />;
        }
        return icon;
    };

    return statusIcon(status);
};

StatusIcon.propTypes = {
    status: PropTypes.any
};
export default StatusIcon;
