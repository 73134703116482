import React from 'react';
import PropTypes from 'prop-types';

import { ListGroup, ListGroupItem } from '@jsluna/list';
import { SingleDatePicker } from '@jsluna/date-picker';

import classes from './DatePickerWithWeek.module.scss';

import moment from 'moment';
moment.locale('en-GB');

const DatePickerWithWeek = props => {
    let dateVal = null;

    if (props.value) dateVal = moment(props.value);

    return (
        <ListGroup inline className={classes.dateList}>
            <ListGroupItem className={props.className}>
                <SingleDatePicker
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    id={props.id}
                    isOutsideRange={() => false}
                    date={dateVal}
                    focused={props.focused}
                    onDateChange={props.changed}
                    onFocusChange={props.onFocused}
                    displayFormat="DD/MM/YYYY"
                />
            </ListGroupItem>
            <ListGroupItem className={classes.weekNumberLi}>
                <span className={classes.weekNumber}>{dateVal === null ? '#' : dateVal.week()}</span>
            </ListGroupItem>
        </ListGroup>
    );
};

DatePickerWithWeek.propTypes = {
    changed: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    focused: PropTypes.bool,
    id: PropTypes.string,
    onFocused: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    value: PropTypes.any
};

export default DatePickerWithWeek;
