import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const configGetDcsStart = () => ({ type: actionTypes.CONFIG_GET_DCS_START });
export const configGetDcsSuccess = dcs => ({ type: actionTypes.CONFIG_GET_DCS_SUCCESS, dcs: dcs });
export const configGetDcsFail = error => ({ type: actionTypes.CONFIG_GET_DCS_FAIL, dcsError: error });

export const configGetDcs = () => {
    return dispatch => {
        const url = '/configuration/getactivedcs';

        dispatch(configGetDcsStart());
        axios
            .get(url)
            .then(response => dispatch(configGetDcsSuccess(response.data)))
            .catch(error => dispatch(configGetDcsFail(error)));
    };
};

export const configGetOptionsStart = () => ({ type: actionTypes.CONFIG_GET_OPTIONS_START });
export const configGetOptionsSuccess = configOptions => ({ type: actionTypes.CONFIG_GET_OPTIONS_SUCCESS, configOptions: configOptions });
export const configGetOptionsFail = error => ({ type: actionTypes.CONFIG_GET_OPTIONS_FAIL, configOptionsError: error });

export const configGetOptions = (planId, whse) => {
    return dispatch => {
        const url = `/configuration/getconfigurationoptions/${planId || 0}/${whse || 'none'}`;

        // if (!planId) url = `${url}/exclude`;

        dispatch(configGetOptionsStart());
        axios
            .get(url)
            .then(response => dispatch(configGetOptionsSuccess(response.data)))
            .catch(error => dispatch(configGetOptionsFail(error)));
    };
};

export const configSetOptionsStart = () => ({ type: actionTypes.CONFIG_SET_OPTIONS_START });
export const configSetOptionsSuccess = success => ({ type: actionTypes.CONFIG_SET_OPTIONS_SUCCESS, setOptionsSuccess: success });
export const configSetOptionsFail = error => ({ type: actionTypes.CONFIG_SET_OPTIONS_FAIL, setOptionsError: error });

export const configSetOptions = (configOptions, planId) => {
    return dispatch => {
        const url = `/configuration/setoptions/${planId || 0}`;

        dispatch(configSetOptionsStart());
        axios
            .post(url, configOptions)
            .then(response => dispatch(configSetOptionsSuccess(response.data)))
            .catch(error => dispatch(configSetOptionsFail(error)));
    };
};
