import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const cvlStart = () => ({ type: actionTypes.CVL_START });
export const cvlSuccess = data => ({ type: actionTypes.CVL_SUCCESS, cvlSummary: data });
export const cvlFail = error => ({ type: actionTypes.CVL_FAIL, cvlSummaryError: error });
export const cvlInform = () => ({ type: actionTypes.SIGNALR_CVL_INFORM });

export const getCvl = cvlInfo => {
    return dispatch => {
        const url = '/CvlSummary';

        dispatch(cvlStart());
        axios
            .post(url, cvlInfo)
            .then(response => dispatch(cvlSuccess(response.data)))
            .catch(error => dispatch(cvlFail(error)));
    };
};

export const pullCvlStart = () => ({ type: actionTypes.PULL_CVL_START });
export const pullCvlSuccess = status => ({ type: actionTypes.PULL_CVL_SUCCESS, pullStatus: status });
export const pullCvlFail = error => ({ type: actionTypes.PULL_CVL_FAIL, pullError: error });

export const pullCvl = (cvlDate, cvlType) => {
    return dispatch => {
        dispatch(pullCvlStart());
        dispatch(cvlInform());

        let url = '/cvlsummary/addcvl';

        if (cvlDate && cvlDate !== null && cvlType && cvlType !== null) url += `/${cvlDate}/${cvlType}`;

        axios
            .get(url)
            .then(response => dispatch(pullCvlSuccess(response.data)))
            .catch(error => dispatch(pullCvlFail(error)));
    };
};

export const getCvlListStart = () => ({ type: actionTypes.GET_CVL_LIST_START });
export const getCvlListSuccess = cvlList => ({ type: actionTypes.GET_CVL_LIST_SUCCESS, cvlList: cvlList });
export const getCvlListFail = error => ({ type: actionTypes.GET_CVL_LIST_FAIL, cvlListError: error });

export const getCvlList = () => {
    return dispatch => {
        const url = '/cvlsummary/getlist';

        dispatch(getCvlListStart());
        axios
            .get(url)
            .then(response => dispatch(getCvlListSuccess(response.data)))
            .catch(error => dispatch(getCvlListFail(error)));
    };
};

export const getWeightedGrowthStart = () => ({ type: actionTypes.GET_WEIGHTED_GROWTH_START });
export const getWeightedGrowthSuccess = weightedGrowth => ({ type: actionTypes.GET_WEIGHTED_GROWTH_SUCCESS, weightedGrowth: weightedGrowth });
export const getWeightedGrowthFail = error => ({ type: actionTypes.GET_WEIGHTED_GROWTH_FAIL, weightedGrowthError: error });

export const getWeightedGrowth = (fac, whse, storeNo) => {
    return dispatch => {
        dispatch(getWeightedGrowthStart());

        let url = '/cvlsummary/getweightedgrowth';
        if (storeNo) url = `${url}/${storeNo}`;
        if (fac || whse)
            if (storeNo) url = `${url}/${fac || 0}`;
            else url = `${url}/none/${fac || 0}`;

        if (whse) url = `${url}/whse`;

        axios
            .get(url)
            .then(response => dispatch(getWeightedGrowthSuccess(response.data)))
            .catch(error => dispatch(getWeightedGrowthFail(error)));
    };
};

export const getGrowthSummaryStart = () => ({ type: actionTypes.GET_GROWTH_SUMMARY_START });
export const getGrowthSummarySuccess = summary => ({ type: actionTypes.GET_GROWTH_SUMMARY_SUCCESS, growthSummary: summary });
export const getGrowthSummaryFail = error => ({ type: actionTypes.GET_GROWTH_SUMMARY_FAIL, growthSummaryError: error });

export const getGrowthSummary = planId => {
    return dispatch => {
        dispatch(getGrowthSummaryStart());

        const url = `/cvlsummary/getgrowthsummary/${planId}`;

        axios
            .get(url)
            .then(response => dispatch(getGrowthSummarySuccess(response.data)))
            .catch(error => dispatch(getGrowthSummaryFail(error)));
    };
};
