import React, { Fragment, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Card } from '@jsluna/card';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { Modal, ModalHeading } from '@jsluna/modal';
import 'react-dates/initialize';

import { ScrollPanel } from 'primereact/scrollpanel';

import { connect } from 'react-redux';
import axios from '../../../axios';

import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';

import { updateObject } from '../../../shared/utility';

import Input from '../../UI/Input/Input';

import classes from './EditPreDefinedRoute.module.scss';

import moment from 'moment';
moment.locale('en-GB');

const EditPreDefinedRoute = props => {
    const { formDetails, onUpdateRoute, preDefinedRoute, handleClose, preDefinedRouteSuccess, preDefinedRouteUpdating, onUpdatePreDefinedRouteReset } = props;

    const [formRecord, setformRecord] = useState(null);
    const [dateFromError, setDateFromError] = useState(null);
    const [dateToError, setDateToError] = useState(null);
    const [validation, setValidation] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [newDeliveryTypeId, setNewDeliveryTypeId] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState({ show: false, title: 'Confirmation', message: 'Are you sure?' });

    const confirmationModalDefault = { show: false, title: 'Confirmation', message: 'Are you sure?' };

    /*
    useEffect(() => {
        if (validation !== null) {
            if (validation === true) {
                // TODO save data
                growl.show({ severity: 'success', summary: 'Route saved.' });
            } else {
                growl.show({ severity: 'error', summary: 'Required fields empty or invalid.' });
            }
            setValidation([]);
        }
    }, [validation, growl]);
    */

    useEffect(() => {
        if (formDetails && !formRecord) {
            setformRecord(formDetails.record);
            const newValidation = {};
            const isNewRecord = formDetails.record.dateFrom === null;

            Object.keys(formDetails.record.fields).forEach(f => {
                newValidation[f] = !isNewRecord;
            });

            newValidation.dateFrom = !isNewRecord;
            newValidation.dateTo = !isNewRecord;
            newValidation.deliveryTypeId = !isNewRecord;
            newValidation.description = true;
            newValidation.source = !isNewRecord;
            newValidation.destination = !isNewRecord;

            setValidation(newValidation);
        }
    }, [formRecord, formDetails]);

    useEffect(() => {
        if (formDetails && newDeliveryTypeId !== null) {
            const newFormRecord = updateObject(formRecord, { deliveryTypeId: newDeliveryTypeId });
            setNewDeliveryTypeId(null);
            setformRecord(newFormRecord);

            const newValidation = validation;
            Object.keys(formDetails.record.fields).forEach(f => {
                newValidation[f] = false;
            });
            setValidation(newValidation);
        }
    }, [newDeliveryTypeId, setNewDeliveryTypeId, formRecord, setformRecord, formDetails, validation]);

    useEffect(() => {
        if (preDefinedRouteUpdating === false) {
            if (preDefinedRouteSuccess === true && preDefinedRoute) {
                onUpdatePreDefinedRouteReset();
                setformRecord(null);
                handleClose(true, preDefinedRoute);
            } else if (preDefinedRouteSuccess === false) {
                handleClose(false);
            }
        }
    }, [onUpdateRoute, preDefinedRouteSuccess, preDefinedRouteUpdating, handleClose, onUpdatePreDefinedRouteReset, formRecord, preDefinedRoute]);

    const confirmationModal = (
        <Modal small handleClose={() => setShowConfirmationModal(confirmationModalDefault)} open={showConfirmationModal.show}>
            <ModalHeading element="h3">{showConfirmationModal.title}</ModalHeading>
            <p>{showConfirmationModal.message}</p>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={() => handleClose()}>Yes</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={() => setShowConfirmationModal(confirmationModalDefault)}>No</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );

    const handleChange = (field, value, isOption) => {
        if (isOption) {
            const newFields = updateObject(formRecord.fields, {
                [field]: value
            });
            const newRecord = updateObject(formRecord, {
                fields: newFields
            });
            setformRecord(newRecord);
        } else {
            const newRecord = updateObject(formRecord, {
                [field]: value
            });
            setformRecord(newRecord);
        }

        if (field === 'dateFrom') {
            validateDates(value, moment(formRecord.dateTo));
        } else if (field === 'dateTo') {
            validateDates(moment(formRecord.dateFrom), value);
        }
        setHasChanges(true);
    };

    const validateDates = (dateFrom, dateTo) => {
        let toHasError = false;
        const dateValidations = [];
        if (dateTo && dateTo.isValid() && !dateTo.isSameOrAfter(moment(), 'day')) {
            dateValidations.push({ field: 'dateTo', valid: false });
            setDateToError('Date is in the past');
            toHasError = true;
        }
        if (dateFrom && dateFrom.isValid() && dateTo && dateTo.isValid() && !dateTo.isSameOrAfter(dateFrom, 'day')) {
            setDateToError('Date From is after Date To.');
            setDateFromError('Date From is after Date To.');
            onFieldsValidate(
                [
                    { field: 'dateFrom', valid: false },
                    { field: 'dateTo', valid: false }
                ],
                true
            );
            return;
        } else {
            setDateFromError(null);
            dateValidations.push({ field: 'dateFrom', valid: true });
        }
        if (!toHasError) {
            setDateToError(null);
            dateValidations.push({ field: 'dateTo', valid: true });
        }
        if (dateValidations.length > 0) onFieldsValidate(dateValidations, true);
    };

    const closeForm = () => {
        if (hasChanges) {
            setShowConfirmationModal({ show: true, title: 'Outstanding Changes', message: 'All changes will be lost, are you sure?' });
        } else handleClose();
    };

    const saveForm = () => {
        let formValid = true;
        if (
            Object.keys(validation).forEach(r => {
                const fo = formDetails.options.fieldOptions.filter(fo => fo.shortName === r)[0];
                if (fo && !validation[r] && fo.validDeliveryTypes.filter(v => v.toString() === formRecord.deliveryTypeId.toString()).length > 0) {
                    if (formRecord.fields[fo.shortName] || fo.isRequired) {
                        formValid = false;
                    }
                } else if (!fo && !validation[r]) {
                    formValid = false;
                }
            })
        );

        if (formValid === true) {
            if (hasChanges) {
                onUpdateRoute(formRecord);
                onUpdatePreDefinedRouteReset();
            } else handleClose(false, null, 'No changes have been made.');
        } else {
            handleClose(false, null, 'Required fields empty or invalid.');
        }
    };

    const onFieldValidate = (field, valid, manualValidation) => {
        onFieldsValidate([{ field: field, valid: valid }], manualValidation);
    };

    const onFieldsValidate = (fields, manualValidation) => {
        let newValidation = validation;
        let updates = false;
        fields.forEach(f => {
            if (((f.field === 'dateFrom' || f.field === 'dateTo') && manualValidation) || (f.field !== 'dateFrom' && f.field !== 'dateTo')) {
                newValidation = updateObject(newValidation, { [f.field]: f.valid });
                updates = true;
            }
        });
        if (updates) {
            setValidation(newValidation);
        }
    };

    const handleAllDaysChange = (field, value) => {
        if (field === 'activeDaysAll') {
            const newRecord = updateObject(formRecord, {
                activeDaysAll: value,
                activeDaysSun: false,
                activeDaysMon: false,
                activeDaysTue: false,
                activeDaysWed: false,
                activeDaysThu: false,
                activeDaysFri: false,
                activeDaysSat: false
            });
            setformRecord(newRecord);
        } else {
            handleChange(field, value);
        }
        setHasChanges(true);
    };

    const handleTypeChange = (field, value) => {
        handleChange(field, value);
        setNewDeliveryTypeId(value);
    };

    const formContent = () => {
        if (!formRecord) {
            return (
                <GridItem size="1/1">
                    <span></span>
                </GridItem>
            );
        }
        return formDetails.options.fieldOptions.map((d, i) => {
            if (!d.validDeliveryTypes || d.validDeliveryTypes.filter(r => +r === +formRecord.deliveryTypeId).length === 0) {
                return null;
            } else {
                return (
                    <GridItem key={i} size={d.fieldTypeName === 'autocomplete' || d.fieldTypeName === 'select' ? '1/1' : '1/2'}>
                        <GridWrapper>
                            <GridItem
                                size={d.fieldTypeName === 'autocomplete' || d.fieldTypeName === 'select' ? '1/4' : '2/4'}
                                className="ln-u-text-align-right"
                            >
                                <p>{`${d.name}${d.isRequired ? '*' : ''}`}</p>
                            </GridItem>
                            <GridItem size={d.fieldTypeName === 'autocomplete' || d.fieldTypeName === 'select' ? '3/4' : '2/4'}>
                                <Input
                                    label={d.name}
                                    hideLabel={true}
                                    fieldType={d.fieldTypeName}
                                    onChange={(field, value) => handleChange(field, value, true)}
                                    options={d.options}
                                    name={d.shortName}
                                    isRequired={d.isRequired}
                                    value={formRecord.fields[d.shortName]}
                                    onValidate={(field, valid) => onFieldValidate(field, valid)}
                                />
                            </GridItem>
                        </GridWrapper>
                    </GridItem>
                );
            }
        });
    };
    const mainContent = !formRecord ? null : (
        <GridWrapper className={classes.textAlignVerticalCenter}>
            <GridItem size="1/2">
                <GridWrapper>
                    <GridItem size="1/4" className="ln-u-text-align-right">
                        <p>Description*</p>
                    </GridItem>
                    <GridItem size="3/4">
                        <Input
                            hideLabel={true}
                            fieldType="string"
                            onChange={(field, value) => handleChange(field, value)}
                            name="description"
                            value={formRecord.description}
                            isRequired={true}
                            onValidate={(field, valid) => onFieldValidate(field, valid)}
                        />
                    </GridItem>
                </GridWrapper>
            </GridItem>
            <GridItem size="1/2">
                <GridWrapper>
                    <GridItem size="1/4" className="ln-u-text-align-right">
                        <p>Type*</p>
                    </GridItem>
                    <GridItem size="3/4">
                        <Input
                            label=""
                            hideLabel={true}
                            fieldType="select"
                            options={formDetails.options.deliveryTypes}
                            onChange={(field, value) => handleTypeChange(field, value)}
                            name="deliveryTypeId"
                            value={formRecord.deliveryTypeId.toString()}
                            isRequired={true}
                            onValidate={(field, valid) => onFieldValidate(field, valid)}
                        />
                    </GridItem>
                </GridWrapper>
            </GridItem>
            <GridItem size="1/2">
                <GridWrapper>
                    <GridItem size="1/4" className="ln-u-text-align-right">
                        <p>Source*</p>
                    </GridItem>
                    <GridItem size="3/4">
                        <Input
                            label=""
                            hideLabel={true}
                            fieldType="autocomplete"
                            options={formDetails.options.locations}
                            onChange={(field, value) => handleChange(field, value)}
                            name="source"
                            placeholder="Select Source"
                            value={!formRecord.source ? '' : formRecord.source.toString()}
                            isRequired={true}
                            onValidate={(field, valid) => onFieldValidate(field, valid)}
                        />
                    </GridItem>
                </GridWrapper>
            </GridItem>
            <GridItem size="1/2">
                <GridWrapper>
                    <GridItem size="1/4" className="ln-u-text-align-right">
                        <p>Destination*</p>
                    </GridItem>
                    <GridItem size="3/4">
                        <Input
                            label=""
                            hideLabel={true}
                            fieldType="autocomplete"
                            options={formDetails.options.locations}
                            onChange={(field, value) => handleChange(field, value)}
                            name="destination"
                            placeholder="Select Destination"
                            value={!formRecord.destination ? '' : formRecord.destination.toString()}
                            isRequired={true}
                            onValidate={(field, valid) => onFieldValidate(field, valid)}
                        />
                    </GridItem>
                </GridWrapper>
            </GridItem>
            <GridItem size="1/2">
                <GridWrapper>
                    <GridItem size="1/2" className="ln-u-text-align-right">
                        <p>Date From*</p>
                    </GridItem>
                    <GridItem size="1/2">
                        <Input
                            label=""
                            hideLabel={true}
                            fieldType="date"
                            onChange={(field, value) => handleChange(field, value)}
                            value={formRecord.dateFrom}
                            name="dateFrom"
                            isRequired={true}
                            onValidate={(field, valid) => onFieldValidate(field, valid)}
                            error={dateFromError}
                        />
                    </GridItem>
                </GridWrapper>
            </GridItem>
            <GridItem size="1/2">
                <GridWrapper>
                    <GridItem size="1/2" className="ln-u-text-align-right">
                        <p>Date To*</p>
                    </GridItem>
                    <GridItem size="1/2">
                        <Input
                            label=""
                            hideLabel={true}
                            fieldType="date"
                            onChange={(field, value) => handleChange(field, value)}
                            value={formRecord.dateTo}
                            name="dateTo"
                            isRequired={true}
                            onValidate={(field, valid) => onFieldValidate(field, valid)}
                            className={'alignRight'}
                            error={dateToError}
                        />
                    </GridItem>
                </GridWrapper>
            </GridItem>
            {formContent()}
            <GridItem size="1/1">
                <Input
                    label="Active Days*"
                    name="EditPreDefinedRouteActiveDays"
                    listType="inline"
                    fieldType="check"
                    onChange={(field, value) => handleAllDaysChange(field, value)}
                    onValidate={(field, valid) => onFieldValidate(field, valid)}
                    options={[
                        {
                            value: 'activeDaysAll',
                            name: 'activeDaysAll',
                            label: 'All',
                            checked: formRecord.activeDaysAll
                        },
                        {
                            value: 'activeDaysSun',
                            name: 'activeDaysSun',
                            label: 'Sunday',
                            checked: formRecord.activeDaysSun,
                            disabled: formRecord.activeDaysAll
                        },
                        {
                            value: 'activeDaysMon',
                            name: 'activeDaysMon',
                            label: 'Monday',
                            checked: formRecord.activeDaysMon,
                            disabled: formRecord.activeDaysAll
                        },
                        {
                            value: 'activeDaysTue',
                            name: 'activeDaysTue',
                            label: 'Tuesday',
                            checked: formRecord.activeDaysTue,
                            disabled: formRecord.activeDaysAll
                        },
                        {
                            value: 'activeDaysWed',
                            name: 'activeDaysWed',
                            label: 'Wednesday',
                            checked: formRecord.activeDaysWed,
                            disabled: formRecord.activeDaysAll
                        },
                        {
                            value: 'activeDaysThu',
                            name: 'activeDaysThu',
                            label: 'Thursday',
                            checked: formRecord.activeDaysThu,
                            disabled: formRecord.activeDaysAll
                        },
                        {
                            value: 'activeDaysFri',
                            name: 'activeDaysFri',
                            label: 'Friday',
                            checked: formRecord.activeDaysFri,
                            disabled: formRecord.activeDaysAll
                        },
                        {
                            value: 'activeDaysSat',
                            name: 'activeDaysSat',
                            label: 'Saturday',
                            checked: formRecord.activeDaysSat,
                            disabled: formRecord.activeDaysAll
                        }
                    ]}
                />
            </GridItem>
            <GridItem size="1/1"></GridItem>
        </GridWrapper>
    );
    return (
        <Card style={{ padding: 0, marginBottom: 0 }}>
            <Fragment>
                <h3 element="h3">{formRecord && formRecord.id > 0 ? 'Edit' : 'Add'} Pre-Defined Route</h3>
                <ScrollPanel className="custombar1" style={{ width: '100%', height: '400px' }}>
                    {mainContent}
                </ScrollPanel>
                <p>* = Required Field</p>
            </Fragment>
            <ButtonGroupWrapper style={{ paddingTop: 0, marginBottom: 0 }}>
                <ButtonGroupPrimary style={{ paddingTop: 0, marginBottom: 0 }}>
                    <FilledButton onClick={() => saveForm()}>Save</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary style={{ paddingTop: 0, marginBottom: 0 }}>
                    <TextButton onClick={() => closeForm()}>Cancel</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
            {confirmationModal}
        </Card>
    );
};

const mapStateToProps = state => {
    return {
        preDefinedRoute: state.requests.preDefinedRoute,
        preDefinedRouteSuccess: state.requests.preDefinedRouteSuccess,
        preDefinedRouteUpdating: state.requests.preDefinedRouteUpdating
    };
};

EditPreDefinedRoute.propTypes = {
    formDetails: PropTypes.object,
    handleClose: PropTypes.func,
    onUpdatePreDefinedRouteReset: PropTypes.func,
    onUpdateRoute: PropTypes.func,
    preDefinedRoute: PropTypes.object,
    preDefinedRouteSuccess: PropTypes.bool,
    preDefinedRouteUpdating: PropTypes.bool
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateRoute: data => dispatch(actions.updatePreDefinedRoute(data)),
        onUpdatePreDefinedRouteReset: () => dispatch(actions.updatePreDefinedRouteReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(EditPreDefinedRoute, axios));
