import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    gettingMergedFile: false,
    mergedFile: null,
    mergedFileError: null,
    transformCsvData: null,
    transformCsvFileName: null,
    transformCsvSuccess: null,
    transformCsvRunning: null,
    transformCsvError: null,
    legacyLogData: [],
    legacyLogError: null,
    legacyLogRunning: null,
    legacyLogSuccess: null
};

const getMergedFileStart = state => updateObject(state, { gettingMergedFile: true });
const getMergedFileSuccess = (state, action) =>
    updateObject(state, { gettingMergedFile: false, mergedFile: action.mergedFile, mergedFileName: action.mergedFileName });
const getMergedFileFail = (state, action) => updateObject(state, { gettingMergedFile: false, mergedFileError: action.mergedFileError });

const transformCsv = (state, action) =>
    updateObject(state, {
        transformCsvData: action.transformCsvData === undefined ? state.transformCsvData : action.transformCsvData,
        transformCsvFileName: action.transformCsvFileName === undefined ? state.transformCsvFileName : action.transformCsvFileName,
        transformCsvSuccess: action.transformCsvSuccess === undefined ? state.transformCsvSuccess : action.transformCsvSuccess,
        transformCsvRunning: action.transformCsvRunning === undefined ? state.transformCsvRunning : action.transformCsvRunning,
        transformCsvError: action.transformCsvError === undefined ? state.transformCsvError : action.transformCsvError
    });

const legacyLog = (state, action) =>
    updateObject(state, {
        legacyLogData: action.legacyLogData === undefined ? state.legacyLogData : action.legacyLogData,
        legacyLogSuccess: action.legacyLogSuccess === undefined ? state.legacyLogSuccess : action.legacyLogSuccess,
        legacyLogRunning: action.legacyLogRunning === undefined ? state.legacyLogRunning : action.legacyLogRunning,
        legacyLogError: action.legacyLogError === undefined ? state.legacyLogError : action.legacyLogError
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MERGED_FILE_START:
            return getMergedFileStart(state);
        case actionTypes.GET_MERGED_FILE_SUCCESS:
            return getMergedFileSuccess(state, action);
        case actionTypes.GET_MERGED_FILE_FAIL:
            return getMergedFileFail(state, action);
        case actionTypes.TRANSFORM_CSV:
            return transformCsv(state, action);
        case actionTypes.LEGACY_LOG:
            return legacyLog(state, action);
        default:
            return state;
    }
};

export default reducer;
