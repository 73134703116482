import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    schedule: null,
    scheduleSuccess: null,
    scheduleError: null,
    scheduleLoading: null,
    planId: null,
    dcNo: null,
    downloadSuccess: null,
    downloadingSchedule: null,
    downloadFilename: null,
    activeSchedule: null,
    editStoreSchedulesRunning: null,
    editStoreSchedulesError: null,
    editStoreSchedulesSuccess: null,
    holdOverStoreSchedulesRunning: null,
    holdOverStoreSchedulesError: null,
    holdOverStoreSchedulesSuccess: null,
    holdOverStoreSchedulesItems: null,
    adhocVolume: null,
    addAdhocVolumeRunning: null,
    addAdhocVolumeError: null,
    addAdhocVolumeSuccess: null
};

const setActiveSchedule = (state, action) =>
    updateObject(state, {
        activeSchedule: action.activeSchedule === undefined ? state.activeSchedule : action.activeSchedule
    });

const getScheduleStart = (state, action) =>
    updateObject(state, {
        schedule: action.schedule === undefined ? state.schedule : action.schedule,
        scheduleSuccess: action.scheduleSuccess === undefined ? state.scheduleSuccess : action.scheduleSuccess,
        scheduleError: action.scheduleError === undefined ? state.scheduleError : action.scheduleError,
        scheduleLoading: action.scheduleLoading === undefined ? state.scheduleLoading : action.scheduleLoading,
        planId: action.planId === undefined ? state.planId : action.planId,
        dcNo: action.dcNo === undefined ? state.dcNo : action.dcNo
    });

const downloadSchedule = (state, action) =>
    updateObject(state, {
        downloadSuccess: action.downloadSuccess === undefined ? state.downloadSuccess : action.downloadSuccess,
        downloadingSchedule: action.downloadingSchedule === undefined ? state.downloadingSchedule : action.downloadingSchedule,
        downloadData: action.downloadData === undefined ? state.downloadData : action.downloadData,
        downloadFilename: action.downloadFilename === undefined ? state.downloadFilename : action.downloadFilename
    });

const deleteStoreSchedules = (state, action) =>
    updateObject(state, {
        deleteStoreSchedulesRunning: action.deleteStoreSchedulesRunning === undefined ? state.deleteStoreSchedulesRunning : action.deleteStoreSchedulesRunning,
        deleteStoreSchedulesError: action.deleteStoreSchedulesError === undefined ? state.deleteStoreSchedulesError : action.deleteStoreSchedulesError,
        deleteStoreSchedulesSuccess: action.deleteStoreSchedulesSuccess === undefined ? state.deleteStoreSchedulesSuccess : action.deleteStoreSchedulesSuccess
    });

const addAdhocVolume = (state, action) =>
    updateObject(state, {
        adhocVolume: action.adhocVolume === undefined ? state.adhocVolume : action.adhocVolume,
        addAdhocVolumeRunning: action.addAdhocVolumeRunning === undefined ? state.addAdhocVolumeRunning : action.addAdhocVolumeRunning,
        addAdhocVolumeError: action.addAdhocVolumeError === undefined ? state.addAdhocVolumeError : action.addAdhocVolumeError,
        addAdhocVolumeSuccess: action.addAdhocVolumeSuccess === undefined ? state.addAdhocVolumeSuccess : action.addAdhocVolumeSuccess
    });

const editStoreSchedules = (state, action) =>
    updateObject(state, {
        editStoreSchedulesRunning: action.editStoreSchedulesRunning === undefined ? state.editStoreSchedulesRunning : action.editStoreSchedulesRunning,
        editStoreSchedulesError: action.editStoreSchedulesError === undefined ? state.editStoreSchedulesError : action.editStoreSchedulesError,
        editStoreSchedulesSuccess: action.editStoreSchedulesSuccess === undefined ? state.editStoreSchedulesSuccess : action.editStoreSchedulesSuccess
    });

const holdOverStoreSchedules = (state, action) =>
    updateObject(state, {
        holdOverStoreSchedulesItems: action.holdOverStoreSchedulesItems === undefined ? state.holdOverStoreSchedulesItems : action.holdOverStoreSchedulesItems,
        holdOverStoreSchedulesRunning:
            action.holdOverStoreSchedulesRunning === undefined ? state.holdOverStoreSchedulesRunning : action.holdOverStoreSchedulesRunning,
        holdOverStoreSchedulesError: action.holdOverStoreSchedulesError === undefined ? state.holdOverStoreSchedulesError : action.holdOverStoreSchedulesError,
        holdOverStoreSchedulesSuccess:
            action.holdOverStoreSchedulesSuccess === undefined ? state.holdOverStoreSchedulesSuccess : action.holdOverStoreSchedulesSuccess
    });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SCHEDULE:
            return getScheduleStart(state, action);
        case actionTypes.DOWNLOAD_SCHEDULE:
            return downloadSchedule(state, action);
        case actionTypes.DELETE_STORE_SCHEDULES:
            return deleteStoreSchedules(state, action);
        case actionTypes.EDIT_STORE_SCHEDULES:
            return editStoreSchedules(state, action);
        case actionTypes.HOLD_OVER_STORE_SCHEDULES:
            return holdOverStoreSchedules(state, action);
        case actionTypes.SET_ACTIVE_SCHEDULE:
            return setActiveSchedule(state, action);
        case actionTypes.ADD_ADHOC_VOLUME:
            return addAdhocVolume(state, action);
        default:
            return state;
    }
};

export default reducer;
