import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const getLocationsStart = () => {
    return {
        type: actionTypes.GET_LOCATIONS_START,
        locations: null
    };
};

export const getLocationsSuccess = locations => {
    return {
        type: actionTypes.GET_LOCATIONS_SUCCESS,
        locations
    };
};

export const getLocationsFail = error => {
    return {
        type: actionTypes.GET_LOCATIONS_FAIL,
        locationsError: error,
        locations: null
    };
};
export const getLocationsReset = () => {
    return {
        type: actionTypes.GET_LOCATIONS_RESET,
        locationsError: null,
        locations: null
    };
};

export const getLocations = () => {
    return dispatch => {
        dispatch(getLocationsStart());
        const url = '/Locations';

        axios
            .get(url)
            .then(response => {
                if (response.status >= 200) {
                    dispatch(getLocationsSuccess(response.data));
                } else dispatch(getLocationsFail(`Getting locations failed ${response.data}`));
            })
            .catch(error => {
                dispatch(getLocationsFail(`Getting locations failed ${error}`));
            });
    };
};

export const updateLocationDetails = (headerId, data) => {
    return dispatch => {
        dispatch(updateLocationDetailsStart());

        const url = `/Locations/${headerId}`;

        axios
            .patch(url, data)
            .then(response => {
                if (response.status === 200) dispatch(updateLocationDetailsSuccess(response.data));
                else dispatch(updateLocationDetailsFail(response.data));
            })
            .catch(error => dispatch(updateLocationDetailsFail(error.message)));
    };
};

export const updateLocationDetailsStart = () => ({
    type: actionTypes.UPDATE_LOCATION_DETAILS,
    updateLocationDetailsSuccess: false,
    updateLocationDetailsRunning: true,
    updateLocationDetailsError: null
});
export const updateLocationDetailsSuccess = () => ({
    type: actionTypes.UPDATE_LOCATION_DETAILS,
    updateLocationDetailsSuccess: true,
    updateLocationDetailsRunning: false,
    updateLocationDetailsError: null
});
export const updateLocationDetailsFail = error => ({
    type: actionTypes.UPDATE_LOCATION_DETAILS,
    updateLocationDetailsSuccess: false,
    updateLocationDetailsRunning: false,
    updateLocationDetailsError: error
});

export const updateLocationDetailsReset = () => ({
    type: actionTypes.UPDATE_LOCATION_DETAILS,
    updateLocationDetailsSuccess: null,
    updateLocationDetailsError: null,
    updateLocationDetailsRunning: null
});
