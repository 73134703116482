// import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const diConfigGetDcsStart = () => ({ type: actionTypes.DI_CONFIG_GET_DCS_START });
export const diConfigGetDcsSuccess = dcs => ({ type: actionTypes.DI_CONFIG_GET_DCS_SUCCESS, diDcs: dcs });
export const diConfigGetDcsFail = error => ({ type: actionTypes.DI_CONFIG_GET_DCS_FAIL, diDcsError: error });

export const diConfigGetDcs = () => {
    // return dispatch => {
    //     // const url = '/configuration/getactivedcs';
    //     // dispatch(configGetDcsStart());
    //     // axios
    //     //     .get(url)
    //     //     .then(response => dispatch(configGetDcsSuccess(response.data)))
    //     //     .catch(error => dispatch(configGetDcsFail(error)));
    // };
};

export const diConfigGetOptionsStart = () => ({ type: actionTypes.DI_CONFIG_GET_OPTIONS_START });
export const diConfigGetOptionsSuccess = configOptions => ({ type: actionTypes.DI_CONFIG_GET_OPTIONS_SUCCESS, diConfigOptions: configOptions });
export const diConfigGetOptionsFail = error => ({ type: actionTypes.DI_CONFIG_GET_OPTIONS_FAIL, diConfigOptionsError: error });

export const diConfigGetOptions = () => {
    // planId
    // return dispatch => {
    //     // let url = `/configuration/getconfigurationoptions/${planId || 0}/${whse || 'none'}`;
    //     // if (!planId) url = `${url}/exclude`;
    //     // dispatch(configGetOptionsStart());
    //     // axios
    //     //     .get(url)
    //     //     .then(response => dispatch(configGetOptionsSuccess(response.data)))
    //     //     .catch(error => dispatch(configGetOptionsFail(error)));
    // };
};

export const diConfigSetOptionsStart = () => ({ type: actionTypes.DI_CONFIG_SET_OPTIONS_START });
export const diConfigSetOptionsSuccess = success => ({ type: actionTypes.DI_CONFIG_SET_OPTIONS_SUCCESS, setDiOptionsSuccess: success });
export const diConfigSetOptionsFail = error => ({ type: actionTypes.DI_CONFIG_SET_OPTIONS_FAIL, setDiOptionsError: error });

export const diConfigSetOptions = () => {
    // configOptions, planId
    // return dispatch => {
    //     // const url = `/configuration/setoptions/${planId || 0}`;
    //     // dispatch(configSetOptionsStart());
    //     // axios
    //     //     .post(url, configOptions)
    //     //     .then(response => dispatch(configSetOptionsSuccess(response.data)))
    //     //     .catch(error => dispatch(configSetOptionsFail(error)));
    // };
};
