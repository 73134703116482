import React from 'react';
import PropTypes from 'prop-types';

const save = ({ fill = 'none', height = 24, onClick, stroke = '#f06c00', strokeWidth = '2', viewBox = '0 0 24 24', width = 24, ...rest }) => (
    <div onClick={onClick}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}
        >
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
            <polyline points="17 21 17 13 7 13 7 21" />
            <polyline points="7 3 7 8 15 8" />
        </svg>
    </div>
);

save.propTypes = {
    fill: PropTypes.string,
    height: PropTypes.number,
    onClick: PropTypes.func,
    stroke: PropTypes.string,
    strokeWidth: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.number
};

export default save;
