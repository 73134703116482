import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Data, Filters, Toolbar } from 'react-data-grid-addons';
import DataGrid from 'react-data-grid';

import camelCase from 'lodash-es/camelCase';
import upperFirst from 'lodash-es/upperFirst';

import classes from './Trunks.module.scss';

const Trunks = props => {
    const [columns, setColumns] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filters, setFilters] = useState({});
    const selectors = Data.Selectors;
    const { AutoCompleteFilter, NumericFilter } = Filters;
    const { editable, fieldData, siteFilter, sourceData } = props;

    /* eslint-disable-next-line react/no-multi-comp, react/prop-types */
    const formatter = ({ value }) => <div className="ln-u-text-align-center">{value}</div>;

    // setup the data grid
    useEffect(() => {
        if (sourceData && sourceData.length > 0) {
            const cols = [
                { key: 'Day', name: 'Day', filterable: true, filterRenderer: AutoCompleteFilter, formatter: formatter, width: 60 },
                { key: 'Shift', name: 'Shift', filterable: true, filterRenderer: AutoCompleteFilter, formatter: formatter, width: 60 }
            ];

            // if field data has been passed in then use that, otherwise get it from the supplied sourceData
            const fields = fieldData || Object.keys(sourceData[0]).filter(k => k.length === 3 && k !== 'CDC' && k !== 'Day');

            fields.forEach(f => {
                // required for consistency
                const field = upperFirst(camelCase(f));
                cols.push({
                    key: field,
                    name: field,
                    editable: editable,
                    filterable: true,
                    filterRenderer: NumericFilter,
                    formatter: formatter,
                    width: 50
                });
            });

            setColumns(cols);
            setFilteredRows(sourceData);
        }
    }, [AutoCompleteFilter, NumericFilter, editable, fieldData, sourceData]);

    // apply any site filter
    useEffect(() => {
        if (siteFilter) setFilteredRows(sourceData.filter(t => t.CDCId === siteFilter));
    }, [siteFilter, sourceData]);

    const handleFilterChange = filter => filters => {
        const newFilters = { ...filters };

        if (filter.filterTerm) newFilters[filter.column.key] = filter;
        else delete newFilters[filter.column.key];

        return newFilters;
    };

    const getValidFilterValues = (rows, columnId) => rows.map(r => r[columnId]).filter((item, i, a) => i === a.indexOf(item));
    const rowGetter = i => ({ ...filteredRows[i] });
    const sData = props.sourceData;
    const updateRows = useCallback(() => {
        const newRows = selectors.getRows({ rows: sData, filters });
        setFilteredRows(newRows);
    }, [filters, selectors, sData]);

    useEffect(() => {
        if (sData) updateRows();
    }, [sData, updateRows]);

    const toolbar = props.header ? (
        props.header
    ) : (
        <Toolbar enableFilter={true}>
            <div className={classes.gridTitle}>{props.title}</div>
        </Toolbar>
    );

    return (
        <div className={classes.fixWidth}>
            <DataGrid
                columns={columns}
                enableCellSelect={true}
                getValidFilterValues={columnKey => getValidFilterValues(props.sourceData, columnKey)}
                minHeight={600}
                onAddFilter={filter => setFilters(handleFilterChange(filter))}
                onClearFilters={() => setFilters({})}
                onGridRowsUpdated={props.onGridRowsUpdated}
                rowsCount={filteredRows.length}
                rowGetter={rowGetter}
                toolbar={toolbar}
            />
        </div>
    );
};

Trunks.propTypes = {
    editable: PropTypes.bool,
    fieldData: PropTypes.array,
    header: PropTypes.node,
    onGridRowsUpdated: PropTypes.func,
    siteFilter: PropTypes.number,
    sourceData: PropTypes.array,
    title: PropTypes.string
};

export default Trunks;
