import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    processPlanRunning: null,
    processPlanFail: null,
    processPlanComplete: null,
    processPlanStatus: null
};

const processPlanResponse = (state, action) => {
    return updateObject(state, {
        processPlanRunning: action.processPlanRunning === undefined ? state.processPlanRunning : action.processPlanRunning,
        processPlanFail: action.error === undefined ? state.error : action.error,
        processPlanComplete: action.processPlanComplete === undefined ? state.processPlanComplete : action.processPlanComplete,
        processPlanStatus: action.processPlanStatus === undefined ? state.processPlanStatus : action.processPlanStatus
    });
};

// prettier-ignore
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROCESS_PLAN_START: return processPlanResponse(state, action);
        case actionTypes.PROCESS_PLAN_RUNNING: return processPlanResponse(state, action);
        case actionTypes.PROCESS_PLAN_COMPLETE: return processPlanResponse(state, action);
        case actionTypes.PROCESS_PLAN_FAIL: return processPlanResponse(state, action);
        case actionTypes.PROCESS_PLAN_RESET: return processPlanResponse(state, action);
        case actionTypes.PROCESS_PLAN_UPDATE_STATUS: return processPlanResponse(state, action);
        default: return state;
    }
};
export default reducer;
