import React from 'react';

import classes from './fileIcon.module.scss';

const CsvIcon = () => {
    return (
        <div className={[classes.fileIcon, classes.csvIcon].join(' ')}>
            <svg viewBox="0 0 253 273" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M207.5 264H81.5C61.6177 264 45.5 247.882 45.5 228V45C45.5 25.1177 61.6177 9 81.5 9H161.913C172.372 9 182.313 13.548
                    189.151 21.4609L210.69 46.3848L234.738 74.2113C240.39 80.7513 243.5 89.1065 243.5 97.7503V228C243.5 247.882 227.382 264 207.5 264Z"
                    fill="#efefef"
                    stroke="#008000"
                    strokeWidth="18"
                />
                <rect y="109" width="216" height="108" rx="21" fill="#008000" />
                <path
                    d="M81.2422 187.008C78.9766 187.919 76.776 188.596 74.6406 189.039C72.5312 189.482 70.3307 189.703 68.0391 189.703C64.3672
                    189.703 61.112 189.169 58.2734 188.102C55.4609 187.008 53.0781 185.393 51.125 183.258C49.1979 181.122 47.7266 178.479 46.7109
                    175.328C45.7214 172.151 45.2266 168.466 45.2266 164.273C45.2266 159.977 45.7734 156.161 46.8672 152.828C47.9609 149.469 49.5234
                    146.643 51.5547 144.352C53.5859 142.034 56.0469 140.276 58.9375 139.078C61.8542 137.854 65.1224 137.242 68.7422 137.242C69.9141
                    137.242 71.0078 137.268 72.0234 137.32C73.0651 137.372 74.0807 137.477 75.0703 137.633C76.0599 137.763 77.0625 137.945 78.0781
                    138.18C79.0938 138.414 80.1484 138.714 81.2422 139.078V148.609C79.0286 147.568 76.9193 146.826 74.9141 146.383C72.9089 145.94
                    71.0859 145.719 69.4453 145.719C67.0234 145.719 64.9531 146.161 63.2344 147.047C61.5156 147.906 60.0964 149.13 58.9766
                    150.719C57.8828 152.281 57.0755 154.156 56.5547 156.344C56.0339 158.505 55.7734 160.888 55.7734 163.492C55.7734 166.253 56.0339
                    168.727 56.5547 170.914C57.1016 173.076 57.9349 174.911 59.0547 176.422C60.1745 177.932 61.6068 179.091 63.3516 179.898C65.0964
                    180.68 67.1667 181.07 69.5625 181.07C70.4219 181.07 71.3464 180.992 72.3359 180.836C73.3516 180.654 74.3672 180.432 75.3828
                    180.172C76.4245 179.885 77.4401 179.56 78.4297 179.195C79.4453 178.805 80.3828 178.414 81.2422 178.023V187.008ZM125.852
                    173.57C125.852 176.357 125.331 178.779 124.289 180.836C123.273 182.893 121.841 184.599 119.992 185.953C118.143 187.281 115.943
                    188.271 113.391 188.922C110.839 189.573 108.052 189.898 105.031 189.898C102.349 189.898 99.7578 189.742 97.2578 189.43C94.7578
                    189.117 92.2839 188.596 89.8359 187.867V178.375C91.0339 178.792 92.2839 179.182 93.5859 179.547C94.888 179.911 96.1901 180.237
                    97.4922 180.523C98.8203 180.784 100.135 180.992 101.438 181.148C102.74 181.305 104.003 181.383 105.227 181.383C107.049 181.383
                    108.599 181.227 109.875 180.914C111.151 180.602 112.193 180.172 113 179.625C113.807 179.078 114.393 178.427 114.758
                    177.672C115.122 176.917 115.305 176.083 115.305 175.172C115.305 173.922 114.94 172.88 114.211 172.047C113.508 171.188 112.57
                    170.432 111.398 169.781C110.227 169.13 108.885 168.544 107.375 168.023C105.891 167.503 104.367 166.943 102.805 166.344C101.268
                    165.745 99.7448 165.068 98.2344 164.312C96.75 163.557 95.4219 162.62 94.25 161.5C93.0781 160.38 92.1276 159.052 91.3984
                    157.516C90.6953 155.953 90.3438 154.078 90.3438 151.891C90.3438 149.885 90.7083 147.984 91.4375 146.188C92.1667 144.391 93.3125
                    142.828 94.875 141.5C96.4375 140.146 98.4427 139.078 100.891 138.297C103.339 137.49 106.294 137.086 109.758 137.086C110.773
                    137.086 111.828 137.138 112.922 137.242C114.042 137.32 115.135 137.438 116.203 137.594C117.297 137.724 118.339 137.893 119.328
                    138.102C120.344 138.284 121.268 138.479 122.102 138.688V147.477C121.268 147.164 120.357 146.878 119.367 146.617C118.378 146.357
                    117.349 146.135 116.281 145.953C115.24 145.745 114.185 145.589 113.117 145.484C112.049 145.38 111.034 145.328 110.07
                    145.328C108.378 145.328 106.945 145.471 105.773 145.758C104.602 146.018 103.638 146.396 102.883 146.891C102.128 147.359 101.581
                    147.945 101.242 148.648C100.904 149.352 100.734 150.133 100.734 150.992C100.734 152.06 101.086 152.984 101.789 153.766C102.518
                    154.521 103.469 155.211 104.641 155.836C105.839 156.435 107.18 157.008 108.664 157.555C110.174 158.076 111.711 158.648 113.273
                    159.273C114.862 159.872 116.398 160.562 117.883 161.344C119.393 162.125 120.734 163.076 121.906 164.195C123.104 165.315 124.055
                    166.643 124.758 168.18C125.487 169.69 125.852 171.487 125.852 173.57ZM158.312 189H145.578L129.992 137.945H140.891L149.523
                    168.688L152.297 178.766L155.188 168.102L163.742 137.945H173.977L158.312 189Z"
                    fill="white"
                />
                <path d="M197.574 85.3674C183.767 85.3674 172.574 74.1745 172.574 60.3674L172.574 4.36739L242.574 85.3674L197.574 85.3674Z" fill="#008000" />
                <path d="M197.574 85.3674C183.767 85.3674 172.574 74.1745 172.574 60.3674L172.574 4.36739L242.574 85.3674L197.574 85.3674Z" fill="#008000" />
            </svg>
        </div>
    );
};

export default CsvIcon;
