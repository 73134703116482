import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../axios';
import * as actions from '../../../store/actions/index';

import { Card } from '@jsluna/card';
import { Checkbox, Form, FormGroup, TextInputField } from '@jsluna/form';
import { ListGroup, ListGroupItem } from '@jsluna/list';
import { FilledButton } from '@jsluna/button';

import { ProgressBar, ProgressIndicator } from '@jsluna/progress';

import 'react-dates/initialize';
import { SingleDatePicker } from '@jsluna/date-picker';
import { updateObject } from '../../../shared/utility';

import { Growl } from 'primereact/growl';

import moment from 'moment';
moment.locale('en-GB');

const Plan = props => {
    const [focused, setFocused] = useState(false);

    const [planDetails, setPlanDetails] = useState({
        id: null,
        name: '',
        date: null,
        active: null,
        status: null,
        locked: null,
        valid: false
    });

    const { creatingPlan, onPlanCreated, onResetNewDIPlan, planId } = props;

    let growl;

    useEffect(() => {
        if (planId) onPlanCreated(planId);
    }, [onPlanCreated, onResetNewDIPlan, planId]);

    const handleChange = (e, field) => {
        let value;
        if (field === 'date') {
            value = moment(e);
            if (!value._isValid) value = null;
        } else if (field === 'active') value = e.target.checked;
        else value = e.target.value;

        let updatedPlan = updateObject(planDetails, {
            [field]: value
        });

        const valid = updatedPlan.name !== '' && updatedPlan.date !== null;

        updatedPlan = updateObject(updatedPlan, {
            valid
        });

        setPlanDetails(updatedPlan);
    };

    const creatingPlanSpinner = (
        <ProgressIndicator page loading={true}>
            <ProgressBar color="light" />
            Creating Plan...
        </ProgressIndicator>
    );

    return (
        <Card>
            <h2>Create New DI Plan</h2>
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    if (!planDetails.valid) {
                        growl.show({ severity: 'error', summary: 'Missing Information', detail: 'Please enter a name and select a date' });
                        return false;
                    }
                    if (planDetails.id === null) props.onCreatePlan(planDetails);
                }}
            >
                <FormGroup name="planCreation" label="DI Plan Name and Date">
                    <ListGroup type="full" inline>
                        <ListGroupItem>
                            <TextInputField
                                name="planName"
                                placeholder="Enter DI Plan Name"
                                value={planDetails.name ? planDetails.name : ''}
                                onChange={e => handleChange(e, 'name')}
                            />
                        </ListGroupItem>
                        <ListGroupItem>
                            <SingleDatePicker
                                id="planDate"
                                date={planDetails.date ? moment(planDetails.date) : null}
                                displayFormat="DD/MM/YYYY"
                                focused={focused}
                                disabled={planDetails.id !== null}
                                onDateChange={e => handleChange(e, 'date')}
                                onFocusChange={() => setFocused(!focused)}
                                isOutsideRange={() => false}
                            />
                        </ListGroupItem>
                        {planDetails.id === null ? null : (
                            <Fragment>
                                <ListGroupItem>
                                    <Checkbox name="planActive" label="Active" defaultChecked={planDetails.active} onChange={e => handleChange(e, 'active')} />
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Checkbox name="planProcessed" label="Processed" disabled defaultChecked={planDetails.status && planDetails.status === 4} />
                                </ListGroupItem>

                                <ListGroupItem>
                                    <Checkbox name="planLocked" label="Locked" disabled defaultChecked={planDetails.disabled} />
                                </ListGroupItem>
                            </Fragment>
                        )}
                        <ListGroupItem>
                            <FilledButton type="submit" style={{ marginTop: '0px' }}>
                                {planDetails.id === null ? 'Create' : 'Update'}
                            </FilledButton>
                        </ListGroupItem>
                    </ListGroup>
                </FormGroup>
            </Form>

            {creatingPlan ? creatingPlanSpinner : null}

            <Growl className="customGrowl" ref={el => (growl = el)}></Growl>
        </Card>
    );
};

Plan.propTypes = {
    creatingPlan: PropTypes.bool,
    onCreatePlan: PropTypes.func,
    onPlanCreated: PropTypes.func.isRequired,
    onResetNewDIPlan: PropTypes.func,
    planId: PropTypes.number
};

const mapStateToProps = state => {
    return {
        creatingPlan: state.di.creatingNewDIPlan,
        planId: +state.di.planId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreatePlan: planDetails => dispatch(actions.createNewDIPlan(planDetails))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Plan, axios));
