export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const GET_USER_SITES_START = 'GET_USER_SITES_START';
export const GET_USER_SITES_SUCCESS = 'GET_USER_SITE_SUCCESS';
export const GET_USER_SITES_FAIL = 'GET_USER_SITES_FAIL';

export const UPDATE_USER_SITE_START = 'USER_UPDATE_SITE_START';
export const UPDATE_USER_SITE_SUCCESS = 'USER_UPDATE_SITE_SUCCESS';
export const UPDATE_USER_SITE_FAIL = 'USER_UPDATE_SITE_FAIL';

export const GENERAL_GET_DATA_AS_OPTIONS_START = 'GENERAL_GET_DATA_AS_OPTIONS_START';
export const GENERAL_GET_DATA_AS_OPTIONS_SUCCESS = 'GENERAL_GET_DATA_AS_OPTIONS_SUCCESS';
export const GENERAL_GET_DATA_AS_OPTIONS_FAIL = 'GENERAL_GET_DATA_AS_OPTIONS_FAIL';

export const CVL_START = 'CVL_START';
export const CVL_SUCCESS = 'CVL_SUCCESS';
export const CVL_FAIL = 'CVL_FAIL';

export const GET_OUTSTANDING_PLAN_EXTRAS_START = 'GET_OUTSTANDING_PLAN_EXTRAS_START';
export const GET_OUTSTANDING_PLAN_EXTRAS_SUCCESS = 'GET_OUTSTANDING_PLAN_EXTRAS_SUCCESS';
export const GET_OUTSTANDING_PLAN_EXTRAS_FAIL = 'GET_OUTSTANDING_PLAN_EXTRAS_FAIL';

export const GET_AD_HOC_DELIVERY_TYPES_START = 'GET_AD_HOC_DELIVERY_TYPES_START';
export const GET_AD_HOC_DELIVERY_TYPES_SUCCESS = 'GET_AD_HOC_DELIVERY_TYPES_SUCCESS';
export const GET_AD_HOC_DELIVERY_TYPES_FAIL = 'GET_AD_HOC_DELIVERY_TYPES_FAIL';

export const GET_ACTIONED_REQUESTS_START = 'GET_ACTIONED_REQUESTS_START';
export const GET_ACTIONED_REQUESTS_SUCCESS = 'GET_ACTIONED_REQUESTS_SUCCESS';
export const GET_ACTIONED_REQUESTS_FAIL = 'GET_ACTIONED_REQUESTS_FAIL';

export const GET_PROCESSED_REQUESTS_COUNT_START = 'GET_PROCESSED_REQUESTS_COUNT_START';
export const GET_PROCESSED_REQUESTS_COUNT_SUCCESS = 'GET_PROCESSED_REQUESTS_COUNT_SUCCESS';
export const GET_PROCESSED_REQUESTS_COUNT_FAIL = 'GET_PROCESSED_REQUESTS_COUNT_FAIL';

export const GET_PROCESSED_REQUESTS_START = 'GET_PROCESSED_REQUESTS_START';
export const GET_PROCESSED_REQUESTS_SUCCESS = 'GET_PROCESSED_REQUESTS_SUCCESS';
export const GET_PROCESSED_REQUESTS_FAIL = 'GET_PROCESSED_REQUESTS_FAIL';

export const PULL_CVL_START = 'PULL_CVL_START';
export const PULL_CVL_SUCCESS = 'PULL_CVL_SUCCESS';
export const PULL_CVL_FAIL = 'PULL_CVL_FAIL';

export const GET_CVL_LIST_START = 'GET_CVL_LIST_START';
export const GET_CVL_LIST_SUCCESS = 'GET_CVL_LIST_SUCCESS';
export const GET_CVL_LIST_FAIL = 'GET_CVL_LIST_FAIL';

export const CONFIG_GET_DCS_START = 'CONFIG_GET_DCS_START';
export const CONFIG_GET_DCS_SUCCESS = 'CONFIG_GET_DCS_SUCCESS';
export const CONFIG_GET_DCS_FAIL = 'CONFIG_GET_DCS_FAIL';

export const CONFIG_GET_OPTIONS_START = 'CONFIG_GET_OPTIONS_START';
export const CONFIG_GET_OPTIONS_SUCCESS = 'CONFIG_GET_OPTIONS_SUCCESS';
export const CONFIG_GET_OPTIONS_FAIL = 'CONFIG_GET_OPTIONS_FAIL';

export const CONFIG_SET_OPTIONS_START = 'CONFIG_SET_OPTIONS_START';
export const CONFIG_SET_OPTIONS_SUCCESS = 'CONFIG_SET_OPTIONS_SUCCESS';
export const CONFIG_SET_OPTIONS_FAIL = 'CONFIG_SET_OPTIONS_FAIL';

export const CREATE_PLAN_START = 'CREATE_PLAN_START';
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';
export const CREATE_PLAN_FAIL = 'CREATE_PLAN_FAIL';

export const GET_PLANS_START = 'GET_PLANS_START';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_FAIL = 'GET_PLANS_FAIL';

export const GET_PLAN_START = 'GET_PLAN_START';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAIL = 'GET_PLAN_FAIL';
export const DELETE_PLAN_START = 'DELETE_PLAN_START';
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS';
export const DELETE_PLAN_FAIL = 'DELETE_PLAN_FAIL';
export const DELETE_PLAN_RESET = 'DELETE_PLAN_RESET';

export const UPDATE_PLAN = 'UPDATE_PLAN';

export const GET_PIPELINE_START = 'GET_PIPELINE_START';
export const GET_PIPELINE_SUCCESS = 'GET_PIPELINE_SUCCESS';
export const GET_PIPELINE_FAIL = 'GET_PIPELINE_FAIL';
export const GET_PIPELINE_RESET = 'GET_PIPELINE_RESET';

export const UPDATE_PIPELINE_START = 'UPDATE_PIPELINE_START';
export const UPDATE_PIPELINE_SUCCESS = 'UPDATE_PIPELINE_SUCCESS';
export const UPDATE_PIPELINE_FAIL = 'UPDATE_PIPELINE_FAIL';
export const UPDATE_PIPELINE_RESET = 'UPDATE_PIPELINE_RESET';

export const PROCESS_PLAN_START = 'PROCESS_PLAN_START';
export const PROCESS_PLAN_RUNNING = 'PROCESS_PLAN_RUNNING';
export const PROCESS_PLAN_COMPLETE = 'PROCESS_PLAN_COMPLETE';
export const PROCESS_PLAN_FAIL = 'PROCESS_PLAN_FAIL';
export const PROCESS_PLAN_RESET = 'PROCESS_PLAN_RESET';
export const PROCESS_PLAN_UPDATE_STATUS = 'PROCESS_PLAN_UPDATE_STATUS';

export const GET_VOLUME_TYPES_START = 'GET_VOLUME_TYPES_START';
export const GET_VOLUME_TYPES_SUCCESS = 'GET_VOLUME_TYPES_SUCCESS';
export const GET_VOLUME_TYPES_FAIL = 'GET_VOLUME_TYPES_FAIL';
export const GET_OUTPUT_TARGETS_START = 'GET_OUTPUT_TARGETS_START';
export const GET_OUTPUT_TARGETS_SUCCESS = 'GET_OUTPUT_TARGETS_SUCCESS';
export const GET_OUTPUT_TARGETS_FAIL = 'GET_OUTPUT_TARGETS_FAIL';

export const GET_GENERATED_OUTPUT_START = 'GET_GENERATED_OUTPUT_START';
export const GET_GENERATED_OUTPUT_SUCCESS = 'GET_GENERATED_OUTPUT_SUCCESS';
export const GET_GENERATED_OUTPUT_FAIL = 'GET_GENERATED_OUTPUT_FAIL';

export const GET_OUTPUT_SUMMARY_START = 'GET_OUTPUT_SUMMARY_START';
export const GET_OUTPUT_SUMMARY_SUCCESS = 'GET_OUTPUT_SUMMARY_SUCCESS';
export const GET_OUTPUT_SUMMARY_FAIL = 'GET_OUTPUT_SUMMARY_FAIL';
export const GET_OUTPUT_SUMMARY_RESET = 'GET_OUTPUT_SUMMARY_RESET';

export const SIGNALR_PIPELINE_SUCCESS = 'SIGNALR_PIPELINE_SUCCESS';
export const SIGNALR_PIPELINE_RESET = 'SIGNALR_PIPELINE_RESET';
export const SIGNALR_CVL_SUCCESS = 'SIGNALR_CVL_SUCCESS';
export const SIGNALR_CVL_INFORM = 'SIGNALR_CVL_INFORM';

export const GET_MERGED_FILE_START = 'GET_MERGED_FILE_START';
export const GET_MERGED_FILE_SUCCESS = 'GET_MERGED_FILE_SUCCESS';
export const GET_MERGED_FILE_FAIL = 'GET_MERGED_FILE_FAIL';

export const GET_POTENTIAL_ADDITIONALS_START = 'GET_POTENTIAL_ADDITIONALS_START';
export const GET_POTENTIAL_ADDITIONALS_SUCCESS = 'GET_POTENTIAL_ADDITIONALS_SUCCESS';
export const GET_POTENTIAL_ADDITIONALS_FAIL = 'GET_POTENTIAL_ADDITIONALS_FAIL';

export const GET_WEIGHTED_GROWTH_START = 'GET_WEIGHTED_GROWTH_START';
export const GET_WEIGHTED_GROWTH_SUCCESS = 'GET_WEIGHTED_GROWTH_SUCCESS';
export const GET_WEIGHTED_GROWTH_FAIL = 'GET_WEIGHTED_GROWTH_FAIL';

export const GET_GROWTH_SUMMARY_START = 'GET_GROWTH_SUMMARY_START';
export const GET_GROWTH_SUMMARY_SUCCESS = 'GET_GROWTH_SUMMARY_SUCCESS';
export const GET_GROWTH_SUMMARY_FAIL = 'GET_GROWTH_SUMMARY_FAIL';

export const SET_ACTIVE_SCHEDULE = 'SET_ACTIVE_SCHEDULE';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const DOWNLOAD_SCHEDULE = 'DOWNLOAD_SCHEDULE';
export const DELETE_STORE_SCHEDULES = 'DELETE_STORE_SCHEDULES';
export const EDIT_STORE_SCHEDULES = 'EDIT_STORE_SCHEDULES';
export const HOLD_OVER_STORE_SCHEDULES = 'HOLD_OVER_STORE_SCHEDULES';

export const GET_TRUNK_SCHEDULE = 'GET_TRUNK_SCHEDULE';
export const DOWNLOAD_TRUNK_SCHEDULE = 'DOWNLOAD_TRUNK_SCHEDULE';
export const EDIT_TRUNK_SCHEDULES = 'EDIT_TRUNK_SCHEDULES';
export const DELETE_TRUNK_SCHEDULES = 'DELETE_TRUNK_SCHEDULES';
export const PUBLISH_SCHEDULE = 'PUBLISH_SCHEDULE';
export const UNPUBLISH_SCHEDULE = 'UNPUBLISH_SCHEDULE';
export const DOWNLOAD_JSON_SCHEDULE = 'DOWNLOAD_JSON_SCHEDULE';
export const HOLD_OVER_TRUNK_SCHEDULES = 'HOLD_OVER_TRUNK_SCHEDULES';

export const GET_DELIVERY_DCS_START = 'GET_DELIVERY_DCS_START';
export const GET_DELIVERY_DCS_SUCCESS = 'GET_DELIVERY_DCS_SUCCESS';
export const GET_DELIVERY_DCS_FAIL = 'GET_DELIVERY_DCS_FAIL';

export const GET_DELIVERY_DAYS_START = 'GET_DELIVERY_DAYS_START';
export const GET_DELIVERY_DAYS_SUCCESS = 'GET_DELIVERY_DAYS_SUCCESS';
export const GET_DELIVERY_DAYS_FAIL = 'GET_DELIVERY_DAYS_FAIL';

export const SAVE_CATS_START = 'SAVE_CATS_START';
export const SAVE_CATS_SUCCESS = 'SAVE_CATS_SUCCESS';
export const SAVE_CATS_FAIL = 'SAVE_CATS_FAIL';

export const GET_CATS_START = 'GET_CATS_START';
export const GET_CATS_SUCCESS = 'GET_CATS_SUCCESS';
export const GET_CATS_FAIL = 'GET_CATS_FAIL';

export const GET_ALLOCATION_START = 'GET_ALLOCATION_START';
export const GET_ALLOCATION_SUCCESS = 'GET_ALLOCATION_SUCCESS';
export const GET_ALLOCATION_FAIL = 'GET_ALLOCATION_FAIL';

export const GET_DI_PLANS_START = 'GET_DI_PLANS_START';
export const GET_DI_PLANS_SUCCESS = 'GET_DI_PLANS_SUCCESS';
export const GET_DI_PLANS_FAIL = 'GET_DI_PLANS_FAIL';

export const GET_DI_PLAN_DETAIL_START = 'GET_DI_PLAN_DETAIL_START';
export const GET_DI_PLAN_DETAIL_SUCCESS = 'GET_DI_PLAN_DETAIL_SUCCESS';
export const GET_DI_PLAN_DETAIL_FAIL = 'GET_DI_PLAN_DETAIL_FAIL';

export const DI_CONFIG_GET_DCS_START = 'DI_CONFIG_GET_DCS_START';
export const DI_CONFIG_GET_DCS_SUCCESS = 'DI_CONFIG_GET_DCS_SUCCESS';
export const DI_CONFIG_GET_DCS_FAIL = 'DI_CONFIG_GET_DCS_FAIL';

export const DI_CONFIG_GET_OPTIONS_START = 'DI_CONFIG_GET_OPTIONS_START';
export const DI_CONFIG_GET_OPTIONS_SUCCESS = 'DI_CONFIG_GET_OPTIONS_SUCCESS';
export const DI_CONFIG_GET_OPTIONS_FAIL = 'DI_CONFIG_GET_OPTIONS_FAIL';

export const DI_CONFIG_SET_OPTIONS_START = 'DI_CONFIG_SET_OPTIONS_START';
export const DI_CONFIG_SET_OPTIONS_SUCCESS = 'DI_CONFIG_SET_OPTIONS_SUCCESS';
export const DI_CONFIG_SET_OPTIONS_FAIL = 'DI_CONFIG_SET_OPTIONS_FAIL';

export const DI_GET_CDC_START = 'DI_GET_CDC_START';
export const DI_GET_CDC_SUCCESS = 'DI_GET_CDC_SUCCESS';
export const DI_GET_CDC_FAIL = 'DI_GET_CDC_FAIL';

export const DI_CREATE_PLAN_START = 'DI_CREATE_PLAN_START';
export const DI_CREATE_PLAN_SUCCESS = 'DI_CREATE_PLAN_SUCCESS';
export const DI_CREATE_PLAN_FAIL = 'DI_CREATE_PLAN_FAIL';
export const DI_PLAN_RESET = 'DI_PLAN_RESET';

export const DI_GET_FORECAST_TRUNKS_START = 'DI_GET_FORECAST_TRUNKS_START';
export const DI_GET_FORECAST_TRUNKS_SUCCESS = 'DI_GET_FORECAST_TRUNKS_SUCCESS';
export const DI_GET_FORECAST_TRUNKS_FAIL = 'DI_GET_FORECAST_TRUNKS_FAIL';

export const DI_SAVE_CHANGES_START = 'DI_SAVE_CHANGES_START';
export const DI_SAVE_CHANGES_SUCCESS = 'DI_SAVE_CHANGES_SUCCESS';
export const DI_SAVE_CHANGES_FAIL = 'DI_SAVE_CHANGES_FAIL';

export const DI_WLFC_START = 'DI_WLFC_START';
export const DI_WLFC_SUCCESS = 'DI_WLFC_SUCCESS';
export const DI_WLFC_FAIL = 'DI_WLFC_FAIL';

export const DI_DOWNLOAD_RAIL_START = 'DI_DOWNLOAD_RAIL_START';
export const DI_DOWNLOAD_RAIL_SUCCESS = 'DI_DOWNLOAD_RAIL_SUCCESS';
export const DI_DOWNLOAD_RAIL_FAIL = 'DI_DOWNLOAD_RAIL_FAIL';

export const GET_EMAIL_DISTRIBUTION_START = 'GET_EMAIL_DISTRIBUTION_START';
export const GET_EMAIL_DISTRIBUTION_SUCCESS = 'GET_EMAIL_DISTRIBUTION_SUCCESS';
export const GET_EMAIL_DISTRIBUTION_FAIL = 'GET_EMAIL_DISTRIBUTION_FAIL';

export const ADD_NEW_GROUP_START = 'ADD_NEW_GROUP_START';
export const ADD_NEW_GROUP_SUCCESS = 'ADD_NEW_GROUP_SUCCESS';
export const ADD_NEW_GROUP_FAIL = 'ADD_NEW_GROUP_FAIL';

export const ADD_NEW_EMAIL_START = 'ADD_NEW_EMAIL_START';
export const ADD_NEW_EMAIL_SUCCESS = 'ADD_NEW_EMAIL_SUCCESS';
export const ADD_NEW_EMAIL_FAIL = 'ADD_NEW_EMAIL_FAIL';

export const EMAIL_SAVE_CHANGES_START = 'EMAIL_SAVE_CHANGES_START';
export const EMAIL_SAVE_CHANGES_SUCCESS = 'EMAIL_SAVE_CHANGES_SUCCESS';
export const EMAIL_SAVE_CHANGES_FAIL = 'EMAIL_SAVE_CHANGES_FAIL';

export const GET_EMAIL_GROUP_NAMES_START = 'GET_EMAIL_GROUP_NAMES_START';
export const GET_EMAIL_GROUP_NAMES_SUCCESS = 'GET_EMAIL_GROUP_NAMES_SUCCESS';
export const GET_EMAIL_GROUP_NAMES_FAIL = 'GET_EMAIL_GROUP_NAMES_FAIL';

export const SEND_GROUP_EMAIL_START = 'SEND_GROUP_EMAIL_START';
export const SEND_GROUP_EMAIL_SUCCESS = 'SEND_GROUP_EMAIL_SUCCESS';
export const SEND_GROUP_EMAIL_FAIL = 'SEND_GROUP_EMAIL_FAIL';

export const GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_START = 'GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_START';
export const GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_SUCCESS = 'GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_SUCCESS';
export const GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_FAIL = 'GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_FAIL';

export const DOWNLOAD_PRE_DEFINED_ROUTES_START = 'DOWNLOAD_PRE_DEFINED_ROUTES_START';
export const DOWNLOAD_PRE_DEFINED_ROUTES_SUCCESS = 'DOWNLOAD_PRE_DEFINED_ROUTES_SUCCESS';
export const DOWNLOAD_PRE_DEFINED_ROUTES_FAIL = 'DOWNLOAD_PRE_DEFINED_ROUTES_FAIL';
export const DOWNLOAD_PRE_DEFINED_ROUTES_RESET = 'DOWNLOAD_PRE_DEFINED_ROUTES_RESET';

export const GET_PLANS_FOR_PLAN_EXTRAS_START = 'GET_PLANS_FOR_PLAN_EXTRAS_START';
export const GET_PLANS_FOR_PLAN_EXTRAS_SUCCESS = 'GET_PLANS_FOR_PLAN_EXTRAS_SUCCESS';
export const GET_PLANS_FOR_PLAN_EXTRAS_FAIL = 'GET_PLANS_FOR_PLAN_EXTRAS_FAIL';
export const GET_PLANS_FOR_PLAN_EXTRAS_RESET = 'GET_PLANS_FOR_PLAN_EXTRAS_RESET';

export const GET_PLANS_FOR_PRE_DEFINED_START = 'GET_PLANS_FOR_PRE_DEFINED_START';
export const GET_PLANS_FOR_PRE_DEFINED_SUCCESS = 'GET_PLANS_FOR_PRE_DEFINED_SUCCESS';
export const GET_PLANS_FOR_PRE_DEFINED_FAIL = 'GET_PLANS_FOR_PRE_DEFINED_FAIL';
export const GET_PLANS_FOR_PRE_DEFINED_RESET = 'GET_PLANS_FOR_PRE_DEFINED_RESET';

export const DOWNLOAD_PLAN_EXTRAS_START = 'DOWNLOAD_PLAN_EXTRAS_START';
export const DOWNLOAD_PLAN_EXTRAS_SUCCESS = 'DOWNLOAD_PLAN_EXTRAS_SUCCESS';
export const DOWNLOAD_PLAN_EXTRAS_FAIL = 'DOWNLOAD_PLAN_EXTRAS_FAIL';
export const DOWNLOAD_PLAN_EXTRAS_RESET = 'DOWNLOAD_PLAN_EXTRAS_RESET';

export const PLAN_PLAN_EXTRAS_START = 'PLAN_PLAN_EXTRAS_START';
export const PLAN_PLAN_EXTRAS_SUCCESS = 'PLAN_PLAN_EXTRAS_SUCCESS';
export const PLAN_PLAN_EXTRAS_FAIL = 'PLAN_PLAN_EXTRAS_FAIL';
export const PLAN_PLAN_EXTRAS_RESET = 'PLAN_PLAN_EXTRAS_RESET';

export const PLAN_PRE_DEFINED_START = 'PLAN_PRE_DEFINED_START';
export const PLAN_PRE_DEFINED_SUCCESS = 'PLAN_PRE_DEFINED_SUCCESS';
export const PLAN_PRE_DEFINED_FAIL = 'PLAN_PRE_DEFINED_FAIL';
export const PLAN_PRE_DEFINED_RESET = 'PLAN_PRE_DEFINED_RESET';

export const GET_PRE_DEFINED_ROUTES_START = 'GET_PRE_DEFINED_ROUTES_START';
export const GET_PRE_DEFINED_ROUTES_SUCCESS = 'GET_PRE_DEFINED_ROUTES_SUCCESS';
export const GET_PRE_DEFINED_ROUTES_FAIL = 'GET_PRE_DEFINED_ROUTES_FAIL';

export const UPDATE_PRE_DEFINED_ROUTE_START = 'UPDATE_PRE_DEFINED_ROUTE_START';
export const UPDATE_PRE_DEFINED_ROUTE_SUCCESS = 'UPDATE_PRE_DEFINED_ROUTE_SUCCESS';
export const UPDATE_PRE_DEFINED_ROUTE_RESET = 'UPDATE_PRE_DEFINED_ROUTE_RESET';
export const UPDATE_PRE_DEFINED_ROUTE_FAIL = 'UPDATE_PRE_DEFINED_ROUTE_FAIL';
export const DELETE_PRE_DEFINED_ROUTES_START = 'DELETE_PRE_DEFINED_ROUTES_START';
export const DELETE_PRE_DEFINED_ROUTES_SUCCESS = 'DELETE_PRE_DEFINED_ROUTES_SUCCESS';
export const DELETE_PRE_DEFINED_ROUTES_FAIL = 'DELETE_PRE_DEFINED_ROUTES_FAIL';
export const DELETE_PRE_DEFINED_ROUTES_RESET = 'DELETE_PRE_DEFINED_ROUTES_RESET';

export const GET_LOCATIONS_START = 'GET_LOCATIONS_START';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL';
export const GET_LOCATIONS_RESET = 'GET_LOCATIONS_RESET';
export const UPDATE_LOCATION_DETAILS = 'UPDATE_LOCATION_DETAILS';
export const TRANSFORM_CSV = 'TRANSFORM_CSV';
export const LEGACY_LOG = 'LEGACY_LOG';

export const GET_DI_CSV = 'GET_DI_DSV';
export const GET_DI_JSON = 'GET_DI_JSON';

export const ADD_ADHOC_VOLUME = 'ADD_ADHOC_VOLUME';
export const ADD_ADHOC_TRUNK_VOLUME = 'ADD_ADHOC_TRUNK_VOLUME';

export const DI_PLAN_SET_ACTIVE = 'DI_PLAN_SET_ACTIVE';
export const DI_PLAN_SET_ACTIVE_RESET = 'DI_PLAN_SET_ACTIVE_RESET';
export const DI_PLAN_DELETE = 'DI_PLAN_DELETE';
export const DI_PLAN_DELETE_RESET = 'DI_PLAN_DELETE_RESET';

export const GET_TRAILERS = 'GET_TRAILERS';

export const PUBLISH_DI = 'PUBLISH_DI';
