import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';

import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';
import { Card } from '@jsluna/card';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { Select, SelectField } from '@jsluna/form';
import { TabPanel } from '@jsluna/tabs';

// import { List } from '@jsluna/icons';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';

import CvlRow from './CvlRow/CvlRow';
import Totals from './Totals/Totals';

import classes from './Cvl.module.scss';
import './Cvl.scss';

import moment from 'moment';
import $ from 'jquery';
import { Modal, ModalHeading } from '@jsluna/modal';
import { SingleDatePicker } from '@jsluna/date-picker';

moment.locale('en-GB');

const options = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' }
];

const tabs = [
    { name: 'CVL', key: 'cvl' },
    { name: 'Totals', key: 'totals' }
];

const Cvl = props => {
    const [activeTab, setActiveTab] = useState(null);

    const [cvlModalIsOpen, setCvlModalIsOpen] = useState(false);
    const [focused, setFocused] = useState(false);

    const [cvlDate, setCvlDate] = useState(null);
    const [cvlListing, setCvlListing] = useState(null);
    const [cvlPoll, setCvlPoll] = useState('');
    const [expanded, setExpanded] = useState(null);
    const [pulling, setPulling] = useState(false);
    const { cvlList, cvlSummary, cvlSummaryLoading, gettingCvlList, onGetCvl, onGetCvlList, pullingCvl, pullStatus } = props;

    const cvlInfo = {
        dcNo: null,
        cvlDate: null,
        cvlType: 'latest'
    };

    let growl;
    let datatable;

    useEffect(() => {
        if (!cvlSummary && !cvlSummaryLoading) {
            onGetCvl(cvlInfo);
            if (activeTab !== 'cvl') setActiveTab('cvl');
        }
    }, [cvlInfo, cvlSummary, cvlSummaryLoading, onGetCvl, activeTab]);

    useEffect(() => {
        if (pullStatus !== null && pullStatus !== undefined && growl && pulling) {
            if (!pullingCvl) {
                if (typeof pullStatus === 'boolean') {
                    if (!pullStatus) growl.show({ severity: 'error', summary: 'Pull CVL', detail: 'CVL pull failed' });
                    else {
                        growl.show({ severity: 'success', summary: 'Pull CVL', detail: 'CVL pulled successfully' });
                        onGetCvl(cvlInfo);
                    }
                } else growl.show({ severity: 'error', summary: 'Pull CVL', detail: pullStatus });
                setPulling(false);
            }
        }
    }, [cvlInfo, growl, onGetCvl, pulling, pullingCvl, pullStatus]);

    useEffect(() => {
        if (!cvlList && !gettingCvlList) onGetCvlList();

        if (cvlList && cvlList.length > 0) {
            const list = cvlList.map(l => {
                const date = moment(l.cvlDate).format('DD/MM/YYYY');
                const poll = l.cvlType;

                return { label: `${date} ${poll}`, value: `${l.cvlDate}_${l.cvlType}` };
            });

            setCvlListing(list);
        }
    }, [cvlList, gettingCvlList, onGetCvlList]);

    useEffect(() => {
        if (cvlSummary && cvlSummary.length > 0) {
            setCvlDate(moment(cvlSummary[0].cvlDate).format('DD/MM/YYYY'));
            setCvlPoll(cvlSummary[0].cvlType);
        }
    }, [cvlSummary]);

    // const pullCvl = () => {
    //     // const cvlDate = moment('22/01/2020', 'DD/MM/YYYY').format('YYYY-MM-DD');
    //     // const cvlType = 'A';

    //     props.onPullCvl(cvlDate, cvlPoll);
    //     setPulling(true);
    // };

    // const openCvlModal = () => {
    //     setCvlPoll('');
    //     setCvlModalIsOpen(!cvlModalIsOpen);
    // };

    // const paginatorLeft = (
    //     <IconButton variant="outlined" label="Import CVL" onClick={openCvlModal}>
    //         <List />
    //     </IconButton>
    // );

    const handleDateChange = e => {
        setCvlDate(e === null ? null : moment(e).format('DD/MM/YYYY'));
    };

    const handleCvlPollChange = e => {
        setCvlPoll(e.target.value);
    };

    const handlePullCvl = () => {
        props.onPullCvl(moment(cvlDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), cvlPoll);
        setCvlModalIsOpen(!cvlModalIsOpen);
    };

    const cvlModal = (
        <Modal small handleClose={() => setCvlModalIsOpen(!cvlModalIsOpen)} open={cvlModalIsOpen} className="modalOverflow">
            <ModalHeading element="h3">Import CVL</ModalHeading>
            <GridWrapper>
                <GridItem size="1/2">
                    <SingleDatePicker
                        // block={true}
                        id="cvlDate"
                        // {...filterProps(inputProps, ['aria-describedby', 'invalid'])}
                        date={cvlDate !== null ? moment(cvlDate, 'DD/MM/YYYY') : null}
                        displayFormat="DD/MM/YYYY"
                        focused={focused}
                        // disabled={planDetails.id !== null}
                        onDateChange={e => handleDateChange(e)}
                        onFocusChange={() => setFocused(!focused)}
                    />
                </GridItem>
                <GridItem size="1/2">
                    <Select name="cvlPoll" options={options} onChange={event => handleCvlPollChange(event)} />
                </GridItem>
            </GridWrapper>

            {/* <p style={{ height: '200px', paddingTop: '50px' }}>Please select the date and which cvl you want to import!</p> */}
            <p>Please select the date and which cvl you want to import!</p>
            <p>Clearing the date field will import the latest CVL as will not selecting a CVL Poll</p>

            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={handlePullCvl}>Import</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={() => setCvlModalIsOpen(!cvlModalIsOpen)}>Cancel</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );

    // const paginatorRight = (
    //     <div>
    //         <span className="ln-u-color-orange">CVL Date:</span> {cvlDate} &nbsp;&nbsp;<span className="ln-u-color-orange">Poll:</span> {cvlPoll}
    //     </div>
    // );

    const loading = props.cvlSummaryLoading || props.pullingCvl || props.gettingCvlList;

    /* eslint-disable-next-line react/no-multi-comp */
    const rowTemplate = data => <CvlRow data={data} />;

    /*  eslint-disable-block prettier/prettier */
    const cvl = loading ? (
        <ProgressIndicator page loading={loading}>
            <ProgressSpinner />
            Loading...
        </ProgressIndicator>
    ) : (
        <DataTable
            value={props.cvlSummary}
            paginator
            rows={8}
            // paginatorLeft={paginatorLeft}
            // paginatorRight={paginatorRight}
            expandedRows={expanded}
            onRowToggle={event => setExpanded(event.data)}
            rowExpansionTemplate={rowTemplate}
            ref={el => (datatable = el)}
        >
            <Column expander style={{ width: '2em' }} />
            <Column header="Store No" field="storeNo" className="ln-u-text-align-center" filter />
            <Column header="Store Name" field="locationName" className="ln-u-text-align-center" filter />
            <Column header="DC" field="dcName" className="ln-u-text-align-center" filter />
            <Column header="Type" field="locationType" className="ln-u-text-align-center" filter />
            <Column header="Cluster Hub" field="clusterHub" className="ln-u-text-align-center" filter />
            <Column header="All ULCs" field="servUlcC" className={classes.nofilter} />
        </DataTable>
    );

    const reset = () => {
        datatable.reset();
        $('.p-inputtext').val('');
    };

    const cvlSelectOnChange = event => {
        const val = event.target.value;

        if (val) {
            const parts = val.split('_');
            cvlInfo.cvlDate = parts[0];
            cvlInfo.cvltype = parts[1];
        } else {
            cvlInfo.cvlDate = null;
            cvlInfo.cvlType = 'latest';
        }

        onGetCvl(cvlInfo);
    };

    const buttons = loading ? (
        <span></span>
    ) : (
        <GridWrapper>
            <GridItem size="1/2">
                <SelectField
                    name="cvl-select"
                    label="Select another CVL"
                    options={cvlListing || []}
                    hideLabel
                    placeholder="Select another CVL"
                    onChange={event => cvlSelectOnChange(event)}
                />
            </GridItem>
            <GridItem size="1/2">
                <Button style={{ marginLeft: '3rem' }} onClick={reset} icon="pi pi-refresh" tooltip="Reset filters" tooltipOptions={{ position: 'right' }} />
            </GridItem>
        </GridWrapper>
    );

    const totals = loading ? (
        <ProgressIndicator page loading={loading}>
            <ProgressSpinner />
            Loading...
        </ProgressIndicator>
    ) : (
        <Totals cvl={cvlSummary} /> // cvlName={paginatorRight} />
    );

    const tabPanel = <TabPanel>{activeTab === 'cvl' ? cvl : totals}</TabPanel>;
    // const tabPanel = <TabPanel>{activeTab === 'cvl' ? <h3>Tab 1</h3> : <h3>Tab 2</h3>}</TabPanel>;

    // const tabPanel = (
    //     <Fragment>
    //         <TabPanel active={activeTab === 'cvl'}>
    //             <h3>Tab 1</h3>
    //         </TabPanel>
    //         <TabPanel active={activeTab === 'totals'}>
    //             <h3>Tab 2</h3>
    //         </TabPanel>
    //     </Fragment>
    // );

    // TODO: this is causing a 'Cannot update during an existing state transition' error
    // I don't know why - it suggests maybe activeTab is being changed during intial render
    // // but haven't figure it out yet
    // const tabsHeader = (
    //     <Tabs>
    //         <TabLink key={0} element="button" active={activeTab === 'cvl'} onClick={() => setActiveTab('cvl')}>
    //             CVL
    //         </TabLink>
    //         <TabLink key={1} element="button" active={activeTab === 'totals'} onClick={() => setActiveTab('totals')}>
    //             Totals
    //         </TabLink>
    //     </Tabs>
    // );

    const tabsHeader = (
        <nav className="ln-c-tabs ln-c-tabs">
            <div className="ln-c-tabs__list-wrap">
                <div className="ln-c-tabs__list">
                    {tabs.map(tab => (
                        <div
                            key={tab.key}
                            className={`ln-c-tabs__link ${activeTab === tab.key ? 'is-active' : ''}`}
                            role="tab"
                            onClick={() => setActiveTab(tab.key)}
                            aria-selected={activeTab === tab.key}
                        >
                            {tab.name}
                        </div>
                    ))}
                </div>
            </div>
        </nav>
    );

    return (
        <Card>
            <GridWrapper>
                <GridItem size="2/3">
                    <h2 style={{ width: '30%', marginBottom: '0' }}>CVL Summary</h2>
                    <div className="ln-u-push-bottom">
                        <span className="ln-u-color-orange">CVL Date:</span> {cvlDate} &nbsp;&nbsp;<span className="ln-u-color-orange">Poll:</span> {cvlPoll}
                    </div>
                </GridItem>
                <GridItem size="1/3">{buttons}</GridItem>
                <GridItem size="1/1">{tabsHeader}</GridItem>
            </GridWrapper>
            <Growl ref={el => (growl = el)} />
            {tabPanel}
            {cvlModal}
        </Card>
    );
};

Cvl.propTypes = {
    cvlList: PropTypes.array,
    cvlSummary: PropTypes.array,
    cvlSummaryLoading: PropTypes.bool,
    gettingCvlList: PropTypes.bool,
    onGetCvl: PropTypes.func,
    onGetCvlList: PropTypes.func,
    onPullCvl: PropTypes.func,
    pullStatus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    pullingCvl: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        cvlList: state.cvl.cvlList,
        cvlSummary: state.cvl.cvlSummary,
        cvlSummaryLoading: state.cvl.cvlSummaryLoading,
        gettingCvlList: state.cvl.gettingCvlList,
        pullingCvl: state.cvl.pullingCvl,
        pullStatus: state.cvl.pullStatus
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetCvl: cvlInfo => dispatch(actions.getCvl(cvlInfo)),
        onGetCvlList: () => dispatch(actions.getCvlList()),
        onPullCvl: (cvlDate, cvlType) => dispatch(actions.pullCvl(cvlDate, cvlType))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Cvl, axios));
