import axios from '../../axios';

import * as actionTypes from './actionTypes';

import { getPipelineReset } from './pipeline';
import { signalRPipelineReset } from './signalr';

import moment from 'moment';

moment.locale('en-GB');

export const getPlansStart = () => ({ type: actionTypes.GET_PLANS_START, getPlansError: null });
export const getPlansSuccess = plans => ({ type: actionTypes.GET_PLANS_SUCCESS, plans, getPlansError: null });
export const getPlansFail = error => ({ type: actionTypes.GET_PLANS_FAIL, getPlansError: error });

export const getPlans = () => {
    return dispatch => {
        dispatch(getPipelineReset());
        dispatch(getPlansStart());

        const url = '/plan/getplans';

        axios
            .get(url)
            .then(response => {
                dispatch(getPlansSuccess(response.data));
            })
            .catch(error => dispatch(getPlansFail(error)));
    };
};

export const deletePlanStart = () => ({
    type: actionTypes.DELETE_PLAN_START,
    deletePlanSuccess: null,
    deletePlanError: null,
    plan: null,
    planSuccess: null
});
export const deletePlanSuccess = () => ({
    type: actionTypes.DELETE_PLAN_SUCCESS,
    deletePlanSuccess: true,
    deletePlanError: null,
    plan: null,
    planSuccess: null
});
export const deletePlanFail = error => ({
    type: actionTypes.DELETE_PLAN_FAIL,
    deletePlanError: error,
    deletePlanSuccess: false,
    plan: null,
    planSuccess: null
});
export const deletePlanReset = () => ({
    type: actionTypes.DELETE_PLAN_RESET,
    deletePlanSuccess: null,
    deletePlanError: null,
    plan: null,
    planSuccess: null
});

export const deletePlan = planId => {
    return dispatch => {
        dispatch(deletePlanStart());
        dispatch(getPipelineReset());

        const url = `/plan/delete/${planId}`;
        axios
            .post(url)
            .then(() => dispatch(deletePlanSuccess()))
            .catch(error => dispatch(deletePlanFail(error)));
    };
};

export const createPlanStart = () => {
    return {
        type: actionTypes.CREATE_PLAN_START,
        plan: null,
        error: null,
        planSuccess: null,
        creatingPlan: true
    };
};

export const createPlanSuccess = response => {
    return {
        type: actionTypes.CREATE_PLAN_SUCCESS,
        plan: response.data,
        error: null,
        planSuccess: true,
        creatingPlan: false
    };
};

export const createPlanFail = error => {
    return {
        type: actionTypes.CREATE_PLAN_FAIL,
        plan: null,
        error: error,
        planSuccess: false,
        creatingPlan: null
    };
};

export const createPlan = plan => {
    return dispatch => {
        dispatch(createPlanStart());
        const url = `/Plan/createPlan/${plan.date.format('YYYY-MM-DD')}/${plan.name}`;
        axios
            .post(url)
            .then(response => {
                if (response) {
                    if (response.status >= 200 && response.status < 300) dispatch(createPlanSuccess(response));
                    else dispatch(createPlanFail(`Failed creating plan ${response.data}`));
                } else {
                    dispatch(createPlanFail('Failed creating plan'));
                }
            })
            .catch(error => {
                dispatch(createPlanFail(`Failed creating plan ${error}`));
            });
    };
};

// #region getPlan
export const getPlanStart = () => {
    return {
        type: actionTypes.GET_PLAN_START,
        plan: null,
        planError: null,
        planSuccess: null
    };
};

export const getPlanSuccess = plan => {
    return {
        type: actionTypes.GET_PLAN_SUCCESS,
        plan,
        planError: null,
        planSuccess: true
    };
};

export const getPlanFail = error => {
    return {
        type: actionTypes.GET_PLAN_FAIL,
        plan: null,
        planError: error,
        planSuccess: null,
        getPlansError: true
    };
};

export const getPlan = planId => {
    return dispatch => {
        dispatch(signalRPipelineReset());
        dispatch(getPlanStart());

        const url = `/Plan/${planId}`;

        axios
            .get(url)
            .then(response => {
                if (response.status >= 200 && response.status < 300) dispatch(getPlanSuccess(response.data));
                else {
                    let message = '';
                    if (response.status === 500) message = 'Plan does not exist';
                    dispatch(getPlanFail(`Failed getting plan. ${message}`));
                }
            })
            .catch(error => {
                dispatch(getPlanFail(`Failed getting plan. ${error}`));
            });
    };
};
// #endregion

// #region updatePlan
export const updatePlanStart = () => ({
    type: actionTypes.UPDATE_PLAN,
    plan: null,
    planError: null,
    planSuccess: null,
    updatingPlan: true
});

export const updatePlanSuccess = plan => ({
    type: actionTypes.UPDATE_PLAN,
    plan,
    planError: null,
    planSuccess: true,
    updatingPlan: null
});

export const updatePlanFail = error => ({
    type: actionTypes.UPDATE_PLAN,
    plan: null,
    planError: error,
    planSuccess: false,
    updatingPlan: null
});

export const updatePlan = plan => {
    return dispatch => {
        dispatch(updatePlanStart());

        const url = '/Plan/updatePlan';

        axios
            .post(url, plan)
            .then(response => {
                dispatch(updatePlanSuccess(response.data));
            })
            .catch(error => {
                dispatch(updatePlanFail(`Failed creating plan ${error}`));
            });
    };
};
// #endregion

export const getVolumeTypesStart = () => ({ type: actionTypes.GET_VOLUME_TYPES_START });
export const getVolumeTypesSuccess = volumeTypes => ({ type: actionTypes.GET_VOLUME_TYPES_SUCCESS, volumeTypes: volumeTypes });
export const getVolumeTypesFail = error => ({ type: actionTypes.GET_VOLUME_TYPES_FAIL, volumeTypesErrorObject: error });
export const getVolumeTypes = () => {
    return dispatch => {
        dispatch(getVolumeTypesStart());

        const url = '/plan/volumetypes';

        axios
            .get(url)
            .then(response => dispatch(getVolumeTypesSuccess(response.data)))
            .catch(error => dispatch(getVolumeTypesFail(error)));
    };
};

export const getOutputTargetsStart = () => ({ type: actionTypes.GET_OUTPUT_TARGETS_START });
export const getOutputTargetsSuccess = volumeTypes => ({ type: actionTypes.GET_OUTPUT_TARGETS_SUCCESS, outputTargets: volumeTypes });
export const getOutputTargetsFail = error => ({ type: actionTypes.GET_OUTPUT_TARGETS_FAIL, outputTargetsErrorObject: error });

export const getOutputTargets = () => {
    return dispatch => {
        dispatch(getOutputTargetsStart());

        const url = '/plan/outputtargets';

        axios
            .get(url)
            .then(response => dispatch(getOutputTargetsSuccess(response.data)))
            .catch(error => dispatch(getOutputTargetsFail(error)));
    };
};

export const getGeneratedOutputStart = () => ({ type: actionTypes.GET_GENERATED_OUTPUT_START });
export const getGeneratedOutputSuccess = output => ({ type: actionTypes.GET_GENERATED_OUTPUT_SUCCESS, output: output });
export const getGeneratedOutputFail = error => ({ type: actionTypes.GET_GENERATED_OUTPUT_FAIL, outputErrorObject: error });

export const getGeneratedOutput = (planId, targetIds) => {
    return dispatch => {
        dispatch(getGeneratedOutputStart());

        const url = `/plan/getoutput/${planId}`;

        axios
            .post(url, targetIds, { responseType: 'blob' })
            .then(response => dispatch(getGeneratedOutputSuccess(response.data)))
            .catch(error => dispatch(getGeneratedOutputFail(error)));
    };
};

export const getOutputSummaryStart = () => ({
    type: actionTypes.GET_OUTPUT_SUMMARY_START,
    outputSummary: null,
    outputSummaryLoading: true,
    outputSummaryError: null
});
export const getOutputSummarySuccess = data => ({
    type: actionTypes.GET_OUTPUT_SUMMARY_SUCCESS,
    outputSummary: data,
    outputSummaryLoading: false
});
export const getOutputSummaryFail = error => ({ type: actionTypes.GET_OUTPUT_SUMMARY_FAIL, outputSummaryError: error, outputSummaryLoading: null });
export const getOutputSummaryReset = () => ({
    type: actionTypes.GET_OUTPUT_SUMMARY_RESET,
    outputSummary: null,
    outputSummaryLoading: false,
    outputSummaryError: null
});
export const getOutputSummary = planId => {
    return dispatch => {
        dispatch(getOutputSummaryStart());

        const url = `/plan/getoutputsummary/${planId}`;

        axios
            .get(url)
            .then(response => {
                if (response.data instanceof Object && Object.keys(response.data).length > 0) dispatch(getOutputSummarySuccess(response.data));
                else dispatch(getOutputSummaryFail('no data'));
            })
            .catch(error => dispatch(getOutputSummaryFail(error)));
    };
};

export const getPotentialAdditionalsStart = () => ({ type: actionTypes.GET_POTENTIAL_ADDITIONALS_START });
export const getPotentialAdditionalsSuccess = additionals => ({ type: actionTypes.GET_POTENTIAL_ADDITIONALS_SUCCESS, additionals: additionals });
export const getPotentialAdditionalsFail = error => ({ type: actionTypes.GET_POTENTIAL_ADDITIONALS_FAIL, additionalsErrorObject: error });

export const getPotentialAdditionals = (cvlDate, cvlType) => {
    return dispatch => {
        dispatch(getPotentialAdditionalsStart());

        const url = `/plan/getpotentialadditionals/${cvlDate}/${cvlType}`;

        axios
            .get(url)
            .then(response => dispatch(getPotentialAdditionalsSuccess(response.data)))
            .catch(error => dispatch(getPotentialAdditionalsFail(error)));
    };
};

// #region publishSchedule
export const publishSchedule = (planId, dcNo) => {
    return dispatch => {
        dispatch(publishScheduleStart());

        let url = `/schedule/publishJsonTransportOrder/${planId}`;
        if (dcNo) url = `${url}/${dcNo}`;

        axios
            .get(url)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(publishScheduleSuccess(response.data));
                else dispatch(publishScheduleFail(response.data));
            })
            .catch(error => dispatch(publishScheduleFail(error)));
    };
};
export const publishScheduleStart = () => ({
    type: actionTypes.PUBLISH_SCHEDULE,
    publishScheduleSuccess: null,
    publishScheduleRunning: true,
    publishScheduleError: null,
    publishedInfo: null
});

export const publishScheduleSuccess = published => ({
    type: actionTypes.PUBLISH_SCHEDULE,
    publishScheduleSuccess: true,
    publishScheduleRunning: false,
    publishScheduleError: null,
    publishedInfo: published
});

export const publishScheduleFail = error => ({
    type: actionTypes.PUBLISH_SCHEDULE,
    publishScheduleSuccess: false,
    publishScheduleRunning: false,
    publishScheduleError: error,
    publishedInfo: null
});

export const publishScheduleReset = () => ({
    type: actionTypes.PUBLISH_SCHEDULE,
    publishScheduleSuccess: null,
    publishScheduleRunning: null,
    publishScheduleError: null
});
// #endregion

// #region unpublishSchedule
export const unpublishSchedule = (planId, dcNo, headerIds) => {
    return dispatch => {
        dispatch(unpublishScheduleStart());

        const url = '/schedule/unpublishJsonTransportOrder';

        axios
            .post(url, { planId, dcNo, headerIds })
            .then(response => {
                let resp = 'Unknown error, the response was undefined.';
                if (response) resp = response.data;
                // TODO: better way to handle different response ? if any
                if (response && response.status === 200) dispatch(unpublishScheduleSuccess(resp));
                else dispatch(unpublishScheduleFail(resp));
            })
            .catch(error => {
                dispatch(unpublishScheduleFail(error));
            });
    };
};
export const unpublishScheduleStart = () => ({
    type: actionTypes.UNPUBLISH_SCHEDULE,
    unpublishScheduleSuccess: null,
    unpublishScheduleRunning: true,
    unpublishScheduleError: null
});

export const unpublishScheduleSuccess = () => ({
    type: actionTypes.UNPUBLISH_SCHEDULE,
    unpublishScheduleSuccess: true,
    unpublishScheduleRunning: false,
    unpublishScheduleError: null
});

export const unpublishScheduleFail = error => ({
    type: actionTypes.UNPUBLISH_SCHEDULE,
    unpublishScheduleSuccess: false,
    unpublishScheduleRunning: false,
    unpublishScheduleError: error
});

export const unpublishScheduleReset = () => ({
    type: actionTypes.UNPUBLISH_SCHEDULE,
    unpublishScheduleSuccess: null,
    unpublishScheduleRunning: null,
    unpublishScheduleError: null
});
// #endregion

// #region downloadJSONSchedule
export const downloadJsonSchedule = (planId, dcNo) => {
    return dispatch => {
        dispatch(downloadJsonScheduleStart());

        const url = `/schedule/downloadJsonTransportOrder/${planId}/${dcNo || null}`;

        axios
            .get(url)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) {
                    let str = response.headers['content-disposition'];
                    str = str.substring(str.indexOf('filename=') + 10);
                    str = str.substring(0, str.indexOf(';') - 1);
                    dispatch(downloadJsonScheduleSuccess(JSON.stringify(response.data), str));
                } else dispatch(downloadJsonScheduleFail(response.data));
            })
            .catch(error => dispatch(downloadJsonScheduleFail(error)));
    };
};
export const downloadJsonScheduleStart = () => ({
    type: actionTypes.DOWNLOAD_JSON_SCHEDULE,
    downloadJsonScheduleData: null,
    downloadJsonScheduleSuccess: null,
    downloadJsonScheduleRunning: true,
    downloadJsonScheduleError: null,
    downloadJsonScheduleFilename: null
});

export const downloadJsonScheduleSuccess = (data, filename) => ({
    type: actionTypes.DOWNLOAD_JSON_SCHEDULE,
    downloadJsonScheduleData: data,
    downloadJsonScheduleSuccess: true,
    downloadJsonScheduleRunning: false,
    downloadJsonScheduleError: null,
    downloadJsonScheduleFilename: filename
});

export const downloadJsonScheduleFail = error => ({
    type: actionTypes.DOWNLOAD_JSON_SCHEDULE,
    downloadJsonScheduleData: null,
    downloadJsonScheduleSuccess: false,
    downloadJsonScheduleRunning: false,
    downloadJsonScheduleError: error,
    downloadJsonScheduleFilename: null
});

export const downloadJsonScheduleReset = () => ({
    type: actionTypes.DOWNLOAD_JSON_SCHEDULE,
    downloadJsonScheduleData: null,
    downloadJsonScheduleSuccess: null,
    downloadJsonScheduleRunning: null,
    downloadJsonScheduleError: null,
    downloadJsonScheduleFilename: null
});
// #endregion
