import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {};

const getDeliveryDcsStart = state => updateObject(state, { loadingDeliveryDcs: true });
const getDeliveryDcsSuccess = (state, action) => updateObject(state, { loadingDeliveryDcs: false, locationDeliveryDcs: action.locationDeliveryDcs });
const getDeliveryDcsFail = (state, action) => updateObject(state, { loadingDeliveryDcs: false, locationDeliveryDcsError: action.locationDeliveryDcsError });

const getDeliveryDaysStart = state => updateObject(state, { gettingDeliveryDays: true });
const getDeliveryDaysSuccess = (state, action) => updateObject(state, { gettingDeliveryDays: false, deliveryDays: action.deliveryDays });
const getDeliveryDaysFail = (state, action) => updateObject(state, { gettingDeliveryDays: false, deliveryDaysError: action.deliveryDaysError });

const saveCatsStart = state => updateObject(state, { savingCats: true });
const saveCatsSuccess = (state, action) => updateObject(state, { savingCats: false, saveCatsStatus: action.saveCatsStart });
const saveCatsFail = (state, action) => updateObject(state, { savingCats: false, saveCatsError: action.saveCatsError });

const getCatsStart = state => updateObject(state, { gettingCats: true });
const getCatsSuccess = (state, action) => updateObject(state, { gettingCats: false, cats: action.cats });
const getCatsFail = (state, action) => updateObject(state, { gettingCats: false, catsError: action.catsError });

const getAllocationStart = state => updateObject(state, { gettingAllocation: true });
const getAllocationSuccess = (state, action) => updateObject(state, { gettingAllocation: false, allocation: action.allocation });
const getAllocationFail = (state, action) => updateObject(state, { gettingAllocation: false, allocationError: action.allocationError });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DELIVERY_DCS_START:
            return getDeliveryDcsStart(state);
        case actionTypes.GET_DELIVERY_DCS_SUCCESS:
            return getDeliveryDcsSuccess(state, action);
        case actionTypes.GET_DELIVERY_DCS_FAIL:
            return getDeliveryDcsFail(state, action);
        case actionTypes.GET_DELIVERY_DAYS_START:
            return getDeliveryDaysStart(state);
        case actionTypes.GET_DELIVERY_DAYS_SUCCESS:
            return getDeliveryDaysSuccess(state, action);
        case actionTypes.GET_DELIVERY_DAYS_FAIL:
            return getDeliveryDaysFail(state, action);
        case actionTypes.SAVE_CATS_START:
            return saveCatsStart(state);
        case actionTypes.SAVE_CATS_SUCCESS:
            return saveCatsSuccess(state, action);
        case actionTypes.SAVE_CATS_FAIL:
            return saveCatsFail(state, action);
        case actionTypes.GET_CATS_START:
            return getCatsStart(state);
        case actionTypes.GET_CATS_SUCCESS:
            return getCatsSuccess(state, action);
        case actionTypes.GET_CATS_FAIL:
            return getCatsFail(state, action);
        case actionTypes.GET_ALLOCATION_START:
            return getAllocationStart(state);
        case actionTypes.GET_ALLOCATION_SUCCESS:
            return getAllocationSuccess(state, action);
        case actionTypes.GET_ALLOCATION_FAIL:
            return getAllocationFail(state, action);
        default:
            return state;
    }
};

export default reducer;
