import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    pipeline: null,
    pipelineSuccess: null,
    pipelineUpdateSuccess: null,
    pipelineError: null,
    pipelineLoading: null
};

const getPipelineResponse = (state, action) => {
    return updateObject(state, {
        pipeline: action.pipeline,
        pipelineSuccess: action.pipelineSuccess,
        pipelineUpdateSuccess: action.pipelineUpdateSuccess,
        pipelineComplete: action.pipelineComplete,
        pipelineError: action.pipelineError,
        pipelineLoading: action.pipelineLoading
    });
};

const updatePipelineStart = (state, action) => {
    return updateObject(state, {
        pipelineError: action.pipelineError,
        pipelineUpdateSuccess: action.pipelineUpdateSuccess
    });
};

const updatePipelineSuccess = (state, action) => {
    return updateObject(state, {
        pipelineError: action.pipelineError,
        pipelineUpdateSuccess: action.pipelineUpdateSuccess
    });
};

const updatePipelineFail = (state, action) => {
    return updateObject(state, {
        pipelineError: action.pipelineError,
        pipelineUpdateSuccess: action.pipelineUpdateSuccess
    });
};

const updatePiplineReset = (state, action) => {
    return updateObject(state, {
        pipelineUpdateSuccess: action.pipelineUpdateSuccess
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PIPELINE_START:
            return getPipelineResponse(state, action);
        case actionTypes.GET_PIPELINE_SUCCESS:
            return getPipelineResponse(state, action);
        case actionTypes.GET_PIPELINE_FAIL:
            return getPipelineResponse(state, action);
        case actionTypes.GET_PIPELINE_RESET:
            return getPipelineResponse(state, action);
        case actionTypes.UPDATE_PIPELINE_START:
            return updatePipelineStart(state, action);
        case actionTypes.UPDATE_PIPELINE_SUCCESS:
            return updatePipelineSuccess(state, action);
        case actionTypes.UPDATE_PIPELINE_FAIL:
            return updatePipelineFail(state, action);
        case actionTypes.UPDATE_PIPELINE_RESET:
            return updatePiplineReset(state, action);
        default:
            return state;
    }
};
export default reducer;
