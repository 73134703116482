import axios from '../../axios';
import * as actionTypes from './actionTypes';

import moment from 'moment';

moment.locale('en-GB');

// this is used for the schedule or trunk to determine which schedule is active
export const setActiveSchedule = tab => ({
    type: actionTypes.SET_ACTIVE_SCHEDULE,
    activeSchedule: tab
});

// #region getSchedule
export const getSchedule = (planId, dcNo) => {
    return dispatch => {
        dispatch(getScheduleStart(planId, dcNo));
        const url = `/Schedule/${planId}/${dcNo}`;
        axios
            .get(url)
            .then(response => {
                dispatch(getScheduleSuccess(response.data));
            })
            .catch(() => {
                dispatch(getScheduleFail('Failed getting schedule'));
            });
    };
};
export const getScheduleStart = (planId, dcNo) => ({
    type: actionTypes.GET_SCHEDULE,
    schedule: null,
    scheduleError: null,
    scheduleSuccess: null,
    scheduleLoading: true,
    planId,
    dcNo
});

export const getScheduleSuccess = schedule => ({
    type: actionTypes.GET_SCHEDULE,
    schedule,
    scheduleError: null,
    scheduleSuccess: true,
    scheduleLoading: false
});

export const getScheduleFail = error => ({
    type: actionTypes.GET_SCHEDULE,
    schedule: null,
    scheduleError: error,
    scheduleSuccess: false,
    scheduleLoading: null
});
// #endregion

// #region deleteStoreSchedules
export const deleteStoreSchedules = (schedules, action) => {
    return dispatch => {
        dispatch(deleteStoreSchedulesStart());

        const url = '/schedule/toggleStoreSchedules';

        const data = schedules.map(m => ({ ...m, action: action }));

        axios
            .post(url, data)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(deleteStoreSchedulesSuccess(response.data));
                else dispatch(deleteStoreSchedulesFail(response.status));
            })
            .catch(error => dispatch(deleteStoreSchedulesFail(error)));
    };
};
export const deleteStoreSchedulesStart = () => ({
    type: actionTypes.DELETE_STORE_SCHEDULES,
    deleteStoreSchedulesSuccess: false,
    deleteStoreSchedulesRunning: true,
    deleteStoreSchedulesError: null
});
export const deleteStoreSchedulesSuccess = success => ({
    type: actionTypes.DELETE_STORE_SCHEDULES,
    deleteStoreSchedulesSuccess: success,
    deleteStoreSchedulesRunning: false,
    deleteStoreSchedulesError: null
});
export const deleteStoreSchedulesFail = error => ({
    type: actionTypes.DELETE_STORE_SCHEDULES,
    deleteStoreSchedulesSuccess: false,
    deleteStoreSchedulesRunning: false,
    deleteStoreSchedulesError: error
});

export const deleteStoreSchedulesReset = () => ({
    type: actionTypes.DELETE_STORE_SCHEDULES,
    deleteStoreSchedulesSuccess: null,
    deleteStoreSchedulesError: null,
    deleteStoreSchedulesRunning: null
});
// #endregion

// #region addAdhocVolume
export const addAdhocVolume = (headerId, data) => {
    return dispatch => {
        dispatch(addAdhocVolumeStart());

        const url = `/schedule/addAdhocVolume/${headerId}`;

        axios
            .post(url, data)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(addAdhocVolumeSuccess(response.data));
                else dispatch(addAdhocVolumeFail(response.status.toString()));
            })
            .catch(error => dispatch(addAdhocVolumeFail(error)));
    };
};
export const addAdhocVolumeStart = () => ({
    type: actionTypes.ADD_ADHOC_VOLUME,
    addAdhocVolumeSuccess: false,
    addAdhocVolumeRunning: true,
    addAdhocVolumeError: null
});
export const addAdhocVolumeSuccess = adhocVolume => ({
    type: actionTypes.ADD_ADHOC_VOLUME,
    adhocVolume,
    addAdhocVolumeSuccess: true,
    addAdhocVolumeRunning: false,
    addAdhocVolumeError: null
});
export const addAdhocVolumeFail = error => ({
    type: actionTypes.ADD_ADHOC_VOLUME,
    addAdhocVolumeSuccess: false,
    addAdhocVolumeRunning: false,
    addAdhocVolumeError: error
});

export const addAdhocVolumeReset = () => ({
    type: actionTypes.ADD_ADHOC_VOLUME,
    addAdhocVolumeSuccess: null,
    addAdhocVolumeError: null,
    addAdhocVolumeRunning: null
});
// #endregion

// #region downloadSchedule
export const downloadSchedule = (planId, dcNo) => {
    return dispatch => {
        dispatch(downloadScheduleStart());

        const url = `/Schedule/DownloadSchedule/${planId}/${dcNo}`;

        axios
            .get(url, { responseType: 'blob' })
            .then(response => {
                let str = response.headers['content-disposition'];
                str = str.substring(str.indexOf('filename=') + 10);
                str = str.substring(0, str.indexOf(';') - 1);
                dispatch(downloadScheduleSuccess(response.data, str));
            })
            .catch(error => dispatch(downloadScheduleFail(error)));
    };
};
export const downloadScheduleStart = () => ({
    type: actionTypes.DOWNLOAD_SCHEDULE,
    downloadSuccess: null,
    downloadingSchedule: true,
    downloadFilename: null
});
export const downloadScheduleSuccess = (data, filename) => ({
    type: actionTypes.DOWNLOAD_SCHEDULE,
    downloadData: data,
    downloadSuccess: true,
    downloadingSchedule: false,
    downloadFilename: filename
});
export const downloadScheduleFail = error => ({
    type: actionTypes.DOWNLOAD_SCHEDULE,
    downloadSuccess: false,
    error,
    downloadingSchedule: false,
    downloadFilename: null
});

export const downloadScheduleReset = () => ({
    type: actionTypes.DOWNLOAD_SCHEDULE,
    downloadSuccess: null,
    error: null,
    downloadingSchedule: null,
    downloadFilename: null
});
// #endregion

// #region editStoreSchedules
export const editStoreSchedules = schedules => {
    return dispatch => {
        dispatch(editStoreSchedulesStart());

        const url = '/schedule/editStoreSchedules';

        axios
            .post(url, schedules)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(editStoreSchedulesSuccess(response.data));
                else dispatch(editStoreSchedulesFail(response.status));
            })
            .catch(error => dispatch(editStoreSchedulesFail(error)));
    };
};

export const editStoreSchedulesStart = () => ({
    type: actionTypes.EDIT_STORE_SCHEDULES,
    editStoreSchedulesSuccess: false,
    editStoreSchedulesRunning: true,
    editStoreSchedulesError: null
});
export const editStoreSchedulesSuccess = success => ({
    type: actionTypes.EDIT_STORE_SCHEDULES,
    editStoreSchedulesSuccess: success,
    editStoreSchedulesRunning: false,
    editStoreSchedulesError: null
});
export const editStoreSchedulesFail = error => ({
    type: actionTypes.EDIT_STORE_SCHEDULES,
    editStoreSchedulesSuccess: false,
    editStoreSchedulesRunning: false,
    editStoreSchedulesError: error
});

export const editStoreSchedulesReset = () => ({
    type: actionTypes.EDIT_STORE_SCHEDULES,
    editStoreSchedulesSuccess: null,
    editStoreSchedulesError: null,
    editStoreSchedulesRunning: null
});
// #endregion

// #region holdOverStoreSchedules
export const holdOverStoreSchedules = schedules => {
    return dispatch => {
        dispatch(holdOverStoreSchedulesStart());

        const url = '/schedule/holdOverDeliveries';

        axios
            .post(url, schedules)
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(holdOverStoreSchedulesSuccess(true, schedules));
                else dispatch(holdOverStoreSchedulesFail(true));
            })
            .catch(error => dispatch(holdOverStoreSchedulesFail(error)));
    };
};

export const holdOverStoreSchedulesStart = () => ({
    type: actionTypes.HOLD_OVER_STORE_SCHEDULES,
    holdOverStoreSchedulesSuccess: false,
    holdOverStoreSchedulesRunning: true,
    holdOverStoreSchedulesError: null
});
export const holdOverStoreSchedulesSuccess = (success, schedules) => ({
    type: actionTypes.HOLD_OVER_STORE_SCHEDULES,
    holdOverStoreSchedulesItems: schedules,
    holdOverStoreSchedulesSuccess: success,
    holdOverStoreSchedulesRunning: false,
    holdOverStoreSchedulesError: null
});
export const holdOverStoreSchedulesFail = error => ({
    type: actionTypes.HOLD_OVER_STORE_SCHEDULES,
    holdOverStoreSchedulesSuccess: false,
    holdOverStoreSchedulesRunning: false,
    holdOverStoreSchedulesError: error
});

export const holdOverStoreSchedulesReset = () => ({
    type: actionTypes.HOLD_OVER_STORE_SCHEDULES,
    holdOverStoreSchedulesItems: null,
    holdOverStoreSchedulesSuccess: null,
    holdOverStoreSchedulesError: null,
    holdOverStoreSchedulesRunning: false
});
// #endregion
