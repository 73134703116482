import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeading } from '@jsluna/modal';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';

const DeleteRestore = props => {
    const { handleClose, open, contextAction, selectedRows, confirmAction } = props;

    return (
        <Modal small handleClose={handleClose} open={open}>
            <ModalHeading element="h3">
                {contextAction} Route{selectedRows.length > 1 ? 's' : ''}
            </ModalHeading>
            <p>
                Please click '{contextAction}' again to confirm {contextAction === 'Delete' ? 'deleting' : 'restoring'} the below route
                {selectedRows.length > 1 ? 's' : ''}
            </p>
            <ul>
                {selectedRows.map(r => {
                    return (
                        <li key={r.planLocationHeaderId}>
                            {r.locationNo} {r.locationName}
                        </li>
                    );
                })}
            </ul>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={confirmAction}>{contextAction || 'Submit'}</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={handleClose}>Cancel</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );
};

DeleteRestore.propTypes = {
    confirmAction: PropTypes.func,
    contextAction: PropTypes.string,
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    selectedRows: PropTypes.array
};
export default DeleteRestore;
