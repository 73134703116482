import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    creatingPlan: null,
    plan: null,
    planSuccess: null,
    planError: null,
    updatingPlan: null,
    outputSummary: null,
    outputSummaryError: null,
    outputSummaryLoading: false,
    publishScheduleSuccess: null,
    publishScheduleRunning: null,
    publishScheduleError: null,
    unpublishScheduleSuccess: null,
    unpublishScheduleRunning: null,
    unpublishScheduleError: null,
    downloadJsonScheduleData: null,
    downloadJsonScheduleSuccess: null,
    downloadJsonScheduleFilename: null,
    downloadJsonScheduleRunning: null,
    downloadJsonScheduleError: null
};

const createPlanStart = state => {
    return updateObject(state, { planError: null, creatingPlan: true, planSuccess: null, outputSummary: null });
};

const createPlanSuccess = (state, action) => {
    const planDetails = updateObject(state.plan, {
        id: action.plan
    });

    return updateObject(state, {
        plan: planDetails,
        planError: null,
        planSuccess: true,
        creatingPlan: false
    });
};

const createPlanFail = (state, action) => {
    return updateObject(state, {
        planError: action.error,
        planSuccess: false,
        creatingPlan: false
    });
};

const getPlanStart = state => {
    return updateObject(state, { planError: null, gettingPlan: true, planSuccess: null, outputSummary: null });
};

const getPlanSuccess = (state, action) => {
    return updateObject(state, {
        plan: action.plan,
        planError: null,
        gettingPlan: false
    });
};

const getPlanFail = (state, action) => {
    return updateObject(state, {
        planError: action.error,
        gettingPlan: false,
        planSuccess: false,
        getPlansError: true
    });
};

const deletePlanResponse = (state, action) =>
    updateObject(state, {
        deletePlanSuccess: action.deletePlanSuccess === undefined ? state.deletePlanSuccess : action.deletePlanSuccess,
        planError: action.planError === undefined ? state.planError : action.planError,
        outputSummary: action.outputSummary === undefined ? state.outputSummary : action.outputSummary,
        plan: action.plan === undefined ? state.plan : action.plan,
        planSuccess: action.planSuccess === undefined ? state.planSuccess : action.planSuccess
    });

const getPlansStart = state => {
    return updateObject(state, { getPlansError: null, plan: null, getPlansSuccess: null, outputSummary: null, plans: null });
};

const getPlansSuccess = (state, action) => {
    return updateObject(state, {
        plans: action.plans,
        getPlansSuccess: true,
        getPlansError: null
    });
};

const getPlansFail = (state, action) => {
    return updateObject(state, {
        plans: null,
        getPlansError: action.error,
        getPlansSuccess: false
    });
};

const updatePlan = (state, action) =>
    updateObject(state, {
        planError: action.planError === undefined ? state.planError : action.planError,
        updatingPlan: action.updatingPlan === undefined ? state.updatingPlan : action.updatingPlan,
        planSuccess: action.planSuccess === undefined ? state.planSuccess : action.planSuccess
    });

const getVolumeTypesStart = state => updateObject(state, { loadingVolumeTypes: true });
const getVolumeTypesSuccess = (state, action) => updateObject(state, { loadingVolumeTypes: false, volumeTypes: action.volumeTypes });
const getVolumeTypesFail = state => updateObject(state, { loadingVolumeTypes: false, volumeTypesError: true });

const getOutputTargetsStart = state => updateObject(state, { gettingOutputTargets: true });
const getOutputTargetsSuccess = (state, action) => updateObject(state, { gettingOutputTargets: false, outputTargets: action.outputTargets });
const getOutputTargetsFail = state => updateObject(state, { gettingOutputTargets: false, outputTargetsError: true });

const getGeneratedOutputStart = state => updateObject(state, { gettingOutput: true });
const getGeneratedOutputSuccess = (state, action) => updateObject(state, { gettingOutput: false, output: action.output });
const getGeneratedOutputFail = state => updateObject(state, { gettingOutput: false, outputError: true });

const getOutputSummaryResponse = (state, action) =>
    updateObject(state, {
        gettingOutputSummary: action.gettingOutputSummary === undefined ? state.gettingOutputSummary : action.gettingOutputSummary,
        outputSummary: action.outputSummary === undefined ? state.outputSummary : action.outputSummary,
        outputSummaryError: action.outputSummaryError === undefined ? state.outputSummaryError : action.outputSummaryError,
        outputSummaryLoading: action.outputSummaryLoading === undefined ? state.outputSummaryLoading : action.outputSummaryLoading
    });

const getPotentialAdditionalsStart = state => updateObject(state, { gettingAdditionals: true });
const getPotentialAdditionalsSuccess = (state, action) => updateObject(state, { gettingAdditionals: false, additional: action.additionals });
const getPotentialAdditionalsFail = state => updateObject(state, { gettingAdditionals: false, additionalsError: true });

const publishSchedule = (state, action) =>
    updateObject(state, {
        publishScheduleSuccess: action.publishScheduleSuccess === undefined ? state.publishScheduleSuccess : action.publishScheduleSuccess,
        publishScheduleRunning: action.publishScheduleRunning === undefined ? state.publishScheduleRunning : action.publishScheduleRunning,
        publishScheduleError: action.publishScheduleError === undefined ? state.publishScheduleError : action.publishScheduleError,
        publishedInfo: action.publishedInfo
    });

const unpublishSchedule = (state, action) =>
    updateObject(state, {
        unpublishScheduleSuccess: action.unpublishScheduleSuccess === undefined ? state.unpublishScheduleSuccess : action.unpublishScheduleSuccess,
        unpublishScheduleRunning: action.unpublishScheduleRunning === undefined ? state.unpublishScheduleRunning : action.unpublishScheduleRunning,
        unpublishScheduleError: action.unpublishScheduleError === undefined ? state.unpublishScheduleError : action.unpublishScheduleError
    });

const downloadJsonSchedule = (state, action) =>
    updateObject(state, {
        downloadJsonScheduleFilename:
            action.downloadJsonScheduleFilename === undefined ? state.downloadJsonScheduleFilename : action.downloadJsonScheduleFilename,
        downloadJsonScheduleData: action.downloadJsonScheduleData === undefined ? state.downloadJsonScheduleData : action.downloadJsonScheduleData,
        downloadJsonScheduleSuccess: action.downloadJsonScheduleSuccess === undefined ? state.downloadJsonScheduleSuccess : action.downloadJsonScheduleSuccess,
        downloadJsonScheduleRunning: action.downloadJsonScheduleRunning === undefined ? state.downloadJsonScheduleRunning : action.downloadJsonScheduleRunning,
        downloadJsonScheduleError: action.downloadJsonScheduleError === undefined ? state.downloadJsonScheduleError : action.downloadJsonScheduleError
    });

// prettier-ignore
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_PLAN_START: return createPlanStart(state);
        case actionTypes.CREATE_PLAN_SUCCESS: return createPlanSuccess(state, action);
        case actionTypes.CREATE_PLAN_FAIL: return createPlanFail(state, action);
        case actionTypes.DELETE_PLAN_START: return deletePlanResponse(state, action);
        case actionTypes.DELETE_PLAN_SUCCESS: return deletePlanResponse(state, action);
        case actionTypes.DELETE_PLAN_FAIL: return deletePlanResponse(state, action);
        case actionTypes.DELETE_PLAN_RESET: return deletePlanResponse(state, action);
        case actionTypes.GET_PLAN_START: return getPlanStart(state);
        case actionTypes.GET_PLAN_SUCCESS: return getPlanSuccess(state, action);
        case actionTypes.GET_PLAN_FAIL: return getPlanFail(state, action);
        case actionTypes.GET_PLANS_START: return getPlansStart(state);
        case actionTypes.GET_PLANS_SUCCESS: return getPlansSuccess(state, action);
        case actionTypes.GET_PLANS_FAIL: return getPlansFail(state, action);
        case actionTypes.UPDATE_PLAN: return updatePlan(state, action);
        case actionTypes.GET_VOLUME_TYPES_START: return getVolumeTypesStart(state);
        case actionTypes.GET_VOLUME_TYPES_SUCCESS: return getVolumeTypesSuccess(state, action);
        case actionTypes.GET_VOLUME_TYPES_FAIL: return getVolumeTypesFail(state);
        case actionTypes.GET_OUTPUT_TARGETS_START: return getOutputTargetsStart(state);
        case actionTypes.GET_OUTPUT_TARGETS_SUCCESS: return getOutputTargetsSuccess(state, action);
        case actionTypes.GET_OUTPUT_TARGETS_FAIL: return getOutputTargetsFail(state);
        case actionTypes.GET_GENERATED_OUTPUT_START: return getGeneratedOutputStart(state);
        case actionTypes.GET_GENERATED_OUTPUT_SUCCESS: return getGeneratedOutputSuccess(state, action);
        case actionTypes.GET_GENERATED_OUTPUT_FAIL: return getGeneratedOutputFail(state);
        case actionTypes.GET_POTENTIAL_ADDITIONALS_START: return getPotentialAdditionalsStart(state);
        case actionTypes.GET_POTENTIAL_ADDITIONALS_SUCCESS: return getPotentialAdditionalsSuccess(state, action);
        case actionTypes.GET_POTENTIAL_ADDITIONALS_FAIL: return getPotentialAdditionalsFail(state);
        case actionTypes.GET_OUTPUT_SUMMARY_START: return getOutputSummaryResponse(state, action);
        case actionTypes.GET_OUTPUT_SUMMARY_SUCCESS: return getOutputSummaryResponse(state, action);
        case actionTypes.GET_OUTPUT_SUMMARY_FAIL: return getOutputSummaryResponse(state, action);
        case actionTypes.GET_OUTPUT_SUMMARY_RESET: return getOutputSummaryResponse(state, action);
        case actionTypes.PUBLISH_SCHEDULE: return publishSchedule(state, action);
        case actionTypes.UNPUBLISH_SCHEDULE: return unpublishSchedule(state, action);
        case actionTypes.DOWNLOAD_JSON_SCHEDULE: return downloadJsonSchedule(state, action);

        default: return state;
    }
};

export default reducer;
