import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

import asyncComponent from './hoc/asyncComponent/asyncComponent';

// import Catalogues from './components/Catalogues/Catalogues';
import Configuration from './components/Configuration/Configuration';
import Legacy from './components/LegacyFileMgt/LegacyFileMgt';
import Cvl from './components/Cvl/Cvl';
import DI from './components/DI/DI';
import Home from './components/Home/Home';
import ImportCDC from './components/DI/ImportCDC/ImportCDC';
import Layout from './components/Layout/Layout';
import PlanConfig from './components/Configuration/PlanConfig/PlanConfig';
import PlanView from './components/DI/PlanView/PlanView';
import Capability from './components/Plans/Capability/Capability';
import Schedules from './components/Schedules/Schedules';
import Schedule from './components/Schedules/Schedule/Schedule';
import TrunkSchedule from './components/Schedules/TrunkSchedule/TrunkSchedule';
import PlanIndex from './components/Plans/PlanIndex';
import Plan from './components/Plans/Plan';
import Requests from './components/Requests/Requests';
import PreDefinedRoutes from './components/Requests/PreDefinedRoutes/PreDefinedRoutes';
import EditPreDefinedRoute from './components/Requests/EditPreDefinedRoute/EditPreDefinedRoute';

import * as actions from './store/actions/index';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './assets/css/primereact-argos-theme.scss';

const asyncAuth = asyncComponent(() => import('./components/Auth/Auth'));

export const App = props => {
    const { onAuth, buildType } = props;

    useEffect(() => {
        onAuth();
        document.documentElement.classList.add(buildType);
    }, [onAuth, buildType]);

    const routes = (
        <Switch>
            <Route path="/auth" component={asyncAuth} />
            {/* <Route path="/catalogues" component={Catalogues} /> */}
            <Route path="/configuration" component={Configuration} />
            <Route path="/legacy" component={Legacy} />
            <Route path="/cvl" component={Cvl} />
            <Route path="/di" component={DI} />
            <Route path="/importcdc" component={ImportCDC} />
            <Route path="/planconfig" component={PlanConfig} />
            <Route path="/plan/:id?" component={Plan} />
            <Route path="/planview/:id?" component={PlanView} />
            <Route path="/capability/:id?" component={Capability} />
            <Route path="/schedules/:planId?/:dcNo?" component={Schedules} />
            <Route path="/schedule/:planId?/:dcNo?" component={Schedule} />
            <Route path="/trunkSchedule/:planId?/:dcNo?" component={TrunkSchedule} />
            <Route path="/plans" component={PlanIndex} />
            <Route path="/requests" exact component={Requests} />
            <Route path="/requests/PreDefinedRoutes" component={PreDefinedRoutes} />
            <Route path="/requests/EditPreDefinedRoute" component={EditPreDefinedRoute} />
            <Route path="/" exact component={Home} />
            <Redirect to="/" />
        </Switch>
    );

    return (
        <Layout buildType={props.buildType}>
            {routes}
            <div className="devLabel">{props.buildType}</div>
        </Layout>
    );
};

App.propTypes = {
    buildType: PropTypes.string,
    onAuth: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        username: state.auth.givenName
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: () => dispatch(actions.auth())
    };
};

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: window._env_.APPINSIGHTS_INSTRUMENTATIONKEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

appInsights.loadAppInsights();

export default withAITracking(reactPlugin, withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
