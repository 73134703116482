import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ButtonGroupPrimary, ButtonGroupWrapper, FilledButton } from '@jsluna/button';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { Modal, ModalHeading } from '@jsluna/modal';

const PublishedModal = props => {
    return (
        <Modal handleClose={props.handleClose} open={props.open} fullScreen>
            <ModalHeading element="h3">Publish Plan</ModalHeading>
            <GridWrapper>
                <GridItem size="1/1" className="ln-u-push-bottom">
                    The following actions have been taken for the relevant depots:
                </GridItem>
                {props.publishedInfo.map((p, i) => (
                    <Fragment key={i}>
                        <GridItem size="1/4" className="ln-u-push-left ln-u-color-dark">
                            {p.key}
                        </GridItem>
                        <GridItem size="1/2" className={p.value.includes('Published') ? 'ln-u-color-orange' : 'ln-u-color-medium'}>
                            {p.value}
                        </GridItem>
                    </Fragment>
                ))}
            </GridWrapper>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={props.handleClose}>OK</FilledButton>
                </ButtonGroupPrimary>
            </ButtonGroupWrapper>
        </Modal>
    );
};

PublishedModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    publishedInfo: PropTypes.array.isRequired
};

export default PublishedModal;
