import * as actionTypes from './actionTypes';
import axios from '../../axios';
import { getToken } from '../../adalConfig';

export const getUserSitesStart = () => ({ type: actionTypes.GET_USER_SITES_START });
export const getUserSitesSuccess = userSites => ({ type: actionTypes.GET_USER_SITES_SUCCESS, userSites: userSites });
export const getUserSitesFail = error => ({ type: actionTypes.GET_USER_SITES_FAIL, error: error });

export const getUserSites = () => {
    const url = '/user/getusersites';
    const token = getToken();

    return dispatch => {
        dispatch(getUserSitesStart());

        if (!token) getUserSitesFail('Please login to access the features');
        else {
            axios
                .get(url)
                .then(res => {
                    const userSites = res.data.map(s => ({
                        label: s.siteName,
                        value: s.siteId,
                        isActive: s.isActive
                    }));
                    dispatch(getUserSitesSuccess(userSites));
                })
                .catch(err => dispatch(getUserSitesFail(err)));
        }
    };
};

export const updateUserSiteStart = () => ({
    type: actionTypes.UPDATE_USER_SITE_START
});
export const updateUserSiteSuccess = userSites => ({
    type: actionTypes.UPDATE_USER_SITE_SUCCESS,
    userSites: userSites
});
export const updateUserSiteFail = error => ({
    type: actionTypes.UPDATE_USER_SITE_FAIL,
    error: error
});

export const updateUserSite = id => {
    const url = '/user/updateusersite/' + id;

    return dispatch => {
        dispatch(updateUserSiteStart());
        axios
            .put(url)
            .then(res => {
                const userSites = res.data.map(s => ({
                    label: s.siteName,
                    value: s.siteId,
                    isActive: s.isActive
                }));
                dispatch(updateUserSiteSuccess(userSites));
            })
            .catch(err => dispatch(updateUserSiteFail(err)));
    };
};
