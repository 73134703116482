import React, { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import axios from '../../../axios';

import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';

import classes from './PreDefinedRoutes.module.scss';

import PropTypes from 'prop-types';

import moment from 'moment';

import { updateObject } from '../../../shared/utility';

import { Growl } from 'primereact/growl';

import { Card } from '@jsluna/card';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { Modal, ModalHeading } from '@jsluna/modal';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';

import EditPreDefinedRoute from '../EditPreDefinedRoute/EditPreDefinedRoute';
import PreDefinedRouteExpanded from './PreDefinedRoutesExpanded';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import saveAs from 'file-saver';
import ConfirmationDialog from '../../UI/ConfirmationDialog/ConfirmationDialog';

moment.locale('en-GB');

const PreDefinedRoutes = props => {
    const {
        adHocDeliveryTypes,
        downloadPreDefinedRoutesError,
        downloadPreDefinedRoutesFail,
        downloadPreDefinedRoutesLoading,
        downloadPreDefinedRoutesSuccess,
        fieldOptions,
        fieldOptionsLoading,
        file,
        fileName,
        fileType,
        plansForPreDefined,
        plansForPreDefinedSuccess,
        plansForPreDefinedFail,
        preDefinedDetails,
        preDefinedRoutesDeleting,
        preDefinedRoutesDeleteSuccess,
        preDefinedRoutesError,
        loadingAdHocDeliveryTypes,
        locations,
        onDeletePreDefinedRoutes,
        onDeletePreDefinedRoutesReset,
        onDownloadPreDefinedRoutes,
        onDownloadPreDefinedRoutesReset,
        onGetAdHocDeliveryTypes,
        onGetFieldOptions,
        onGetLocations,
        onGetPlansForPreDefined,
        onGetPlansForPreDefinedReset,
        onPlanPreDefined,
        onPlanPreDefinedReset,
        planPreDefinedError,
        planPreDefinedFail,
        planPreDefinedSuccess,
        preDefinedRoutes
    } = props;
    let growl;

    const [editPreDefinedDetails, setEditPreDefinedDetails] = useState({
        fieldOptions: [],
        deliveryTypes: [],
        locations: []
    });
    const [showPreDefinedModal, setShowPreDefinedModal] = useState(false);
    const [canAddRoute, setCanAddRoute] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [preDefinedRoutesRows, setPreDefinedRoutesRows] = useState(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandedFields, setExpandedFields] = useState([]);
    const [showSimulatedModal, setShowSimulatedModal] = useState(false);
    const [downloadFormat, setDownloadFormat] = useState(null);
    const [showAddToPlanModal, setShowAddToPlanModal] = useState(false);

    useEffect(() => {
        if (!fieldOptions && !fieldOptionsLoading) {
            onGetFieldOptions();
        } else {
            setCanAddRoute(adHocDeliveryTypes && fieldOptions && locations);
        }
    }, [onGetFieldOptions, fieldOptions, fieldOptionsLoading, adHocDeliveryTypes, setCanAddRoute, locations]);

    useEffect(() => {
        if (!adHocDeliveryTypes && !loadingAdHocDeliveryTypes && !locations) {
            onGetAdHocDeliveryTypes();
            onGetLocations();
        } else {
            setCanAddRoute(adHocDeliveryTypes && fieldOptions && locations);
        }
    }, [onGetAdHocDeliveryTypes, adHocDeliveryTypes, loadingAdHocDeliveryTypes, fieldOptions, setCanAddRoute, locations, onGetLocations]);

    useEffect(() => {
        if (preDefinedRoutesDeleting === false) {
            if (preDefinedRoutesDeleteSuccess === true && selectedRows.length > 0) {
                onDeletePreDefinedRoutesReset();
                growl.show({ severity: 'success', summary: 'Routes deleted.' });
                let newPreDefinedRoutes = preDefinedRoutesRows;
                selectedRows.forEach(d => {
                    newPreDefinedRoutes = newPreDefinedRoutes.filter(r => r.id !== d.id);
                });
                setPreDefinedRoutesRows(newPreDefinedRoutes);
                setSelectedRows([]);
                setShowDeleteModal(false);
            } else if (preDefinedRoutesDeleteSuccess === false && selectedRows.length > 0) {
                growl.show({ severity: 'error', summary: preDefinedRoutesError });
                setShowDeleteModal(false);
            }
        }
    }, [
        preDefinedRoutesDeleteSuccess,
        preDefinedRoutesDeleting,
        preDefinedRoutesError,
        onDeletePreDefinedRoutesReset,
        selectedRows,
        setSelectedRows,
        growl,
        preDefinedRoutes,
        preDefinedRoutesRows,
        setPreDefinedRoutesRows
    ]);

    const handleClose = (success, record, msg) => {
        if (success === true) {
            setShowPreDefinedModal(!showPreDefinedModal);
            growl.show({ severity: 'success', summary: msg ? msg : 'Route saved.' });
            let newPreDefinedRoutes = [];
            if (preDefinedRoutesRows && preDefinedRoutesRows !== null && preDefinedRoutesRows.length > 0) {
                newPreDefinedRoutes = preDefinedRoutesRows.filter(p => p.id !== record.id);
            }
            const fields = record.fields;
            Object.keys(fields).forEach(r => {
                record[r] = fields[r];
            });
            let activeDays = '';
            if (record.activeDaysAll) activeDays = 'All';
            else {
                if (record.activeDaysSun) activeDays = `${activeDays}${(activeDays === '' ? '' : ',') + 'Su'}`;
                if (record.activeDaysMon) activeDays = `${activeDays}${(activeDays === '' ? '' : ',') + 'Mo'}`;
                if (record.activeDaysTue) activeDays = `${activeDays}${(activeDays === '' ? '' : ',') + 'Tu'}`;
                if (record.activeDaysWed) activeDays = `${activeDays}${(activeDays === '' ? '' : ',') + 'We'}`;
                if (record.activeDaysThu) activeDays = `${activeDays}${(activeDays === '' ? '' : ',') + 'Th'}`;
                if (record.activeDaysFri) activeDays = `${activeDays}${(activeDays === '' ? '' : ',') + 'Fr'}`;
                if (record.activeDaysSat) activeDays = `${activeDays}${(activeDays === '' ? '' : ',') + 'Sa'}`;
            }
            record.activeDays = activeDays;
            newPreDefinedRoutes.push(record);
            newPreDefinedRoutes = newPreDefinedRoutes.sort((a, b) => a.id - b.id);

            setPreDefinedRoutesRows(newPreDefinedRoutes);
            setSelectedRows([]);
        } else if (success === false) {
            growl.show({ severity: 'error', summary: msg ? msg : 'Route save failed.' });
        } else {
            setShowPreDefinedModal(!showPreDefinedModal);
        }
    };

    const editPreDefinedModal = (
        <Modal
            fullScreen
            className={classes.largeModal}
            restrictClose
            handleClose={(success, record, message) => handleClose(success, record, message)}
            open={showPreDefinedModal}
        >
            <EditPreDefinedRoute formDetails={editPreDefinedDetails} handleClose={(success, record, message) => handleClose(success, record, message)} />
        </Modal>
    );

    const onEditPreDefinedRoute = e => {
        const defaultFields = {};
        if (e) {
            Object.keys(fieldOptions).forEach(field => {
                defaultFields[fieldOptions[field].shortName] = e[fieldOptions[field].shortName] || null;
            });
        } else {
            Object.keys(fieldOptions).forEach(field => {
                defaultFields[fieldOptions[field].shortName] = null;
            });
        }

        const deliveryTypes = [];
        adHocDeliveryTypes.forEach(d => {
            deliveryTypes.push({ value: d.id, label: d.name });
        });
        const newLocations = [];
        locations.forEach(l => {
            newLocations.push({ value: l.locationId, label: l.locationName });
        });
        const newEditPreDefinedDetails = updateObject(preDefinedDetails, {
            record: {
                id: e ? e.id : 0,
                deliveryTypeId: e ? e.deliveryTypeId : 1,
                description: e ? e.description : 'New Route',
                source: e ? e.source : null,
                destination: e ? e.destination : null,
                dateFrom: e ? moment(e.dateFrom) : null,
                dateTo: e ? moment(e.dateTo) : null,
                fields: defaultFields,
                activeDaysAll: e ? e.activeDaysAll : true,
                activeDaysSun: e ? e.activeDaysSun : false,
                activeDaysMon: e ? e.activeDaysMon : false,
                activeDaysTue: e ? e.activeDaysTue : false,
                activeDaysWed: e ? e.activeDaysWed : false,
                activeDaysThu: e ? e.activeDaysThu : false,
                activeDaysFri: e ? e.activeDaysFri : false,
                activeDaysSat: e ? e.activeDaysSat : false
            },
            options: {
                deliveryTypes: deliveryTypes,
                locations: newLocations,
                fieldOptions: fieldOptions
            }
        });
        setEditPreDefinedDetails(newEditPreDefinedDetails);
        setTimeout(() => {
            setShowPreDefinedModal(true);
        }, 100);
    };

    const dateTemplate = useCallback(
        (rowData, column, fieldData) => {
            if (fieldData.fieldType === 'date') {
                return <span>{moment(rowData[column.field]).format('DD/MM/YYYY')}</span>;
            } else if (fieldData.fieldType === 'select' || fieldData.fieldType === 'autocomplete') {
                const fo = fieldOptions.find(f => f.shortName === column.field);
                if (!fo) return <span>{rowData[column.field]}</span>;
                const fieldValue = fo.options.find(f => f.value.toString() === rowData[column.field].toString());
                if (!fieldValue) return <span>{rowData[column.field]}</span>;
                return <span>{fieldValue.label}</span>;
            } else if (fieldData.fieldType === 'lookup') {
                const options = fieldData.lookupData.values.filter(l => l[fieldData.lookupData.valueCol] === Number(rowData[column.field]));
                if (options.length > 0) {
                    const option = options[0];
                    return <span>{option[fieldData.lookupData.labelCol]}</span>;
                } else {
                    return <span>{rowData[column.field]}</span>;
                }
            } else {
                return <span>{rowData[column.field]}</span>;
            }
        },
        [fieldOptions]
    );

    useEffect(() => {
        if (!preDefinedRoutesRows && preDefinedRoutes) {
            setPreDefinedRoutesRows(preDefinedRoutes);
        } else {
            if (fieldOptions && locations && columns.length === 0) {
                let fields = fieldOptions
                    .filter(fo => fo.viewLevel < 1)
                    .map(fo => ({
                        // fields[fieldOptions[fo].shortName] = preDefinedRoutes[fieldOptions[fo].shortName].value;
                        field: fo.shortName,
                        label: fo.name,
                        filter: null,
                        fieldType: fo.fieldTypeName
                    }));

                const staticFields = [
                    { field: 'description', label: 'Description', filter: 'contains', fieldType: 'string' },
                    {
                        field: 'deliveryTypeId',
                        label: 'Type',
                        filter: null,
                        fieldType: 'lookup',
                        lookupData: { values: adHocDeliveryTypes, valueCol: 'id', labelCol: 'name' }
                    },
                    {
                        field: 'source',
                        label: 'Source',
                        filter: null,
                        fieldType: 'lookup',
                        lookupData: { values: locations, valueCol: 'locationId', labelCol: 'locationName' }
                    },
                    {
                        field: 'destination',
                        label: 'Destination',
                        filter: null,
                        fieldType: 'lookup',
                        lookupData: { values: locations, valueCol: 'locationId', labelCol: 'locationName' }
                    },
                    { field: 'dateFrom', label: 'Date From', filter: null, fieldType: 'date' },
                    { field: 'dateTo', label: 'Date To', filter: null, fieldType: 'date' },
                    { field: 'activeDays', label: 'Active Days', filter: null, fieldType: 'string' }
                ];

                fields = staticFields.concat(fields);

                const newColumns = fields.map((c, i) => (
                    <Column
                        key={i}
                        body={(rowData, column) => dateTemplate(rowData, column, c)}
                        field={c.field}
                        header={c.label}
                        className="ln-u-text-align-center"
                        filter={c.filter !== null}
                        filterMatchMode={c.filter}
                    />
                ));
                setColumns(newColumns);

                const newExpandedFields = fieldOptions
                    .filter(fo => fo.viewLevel === 1)
                    .map(fo => ({
                        // fields[fieldOptions[fo].shortName] = preDefinedRoutes[fieldOptions[fo].shortName].value;
                        field: fo.shortName,
                        label: fo.name,
                        filter: null,
                        fieldType: fo.fieldTypeName
                    }));

                setExpandedFields(newExpandedFields);
            }
        }
    }, [fieldOptions, preDefinedRoutes, preDefinedRoutesRows, setPreDefinedRoutesRows, locations, adHocDeliveryTypes, dateTemplate, columns]);

    const handleSelectionChange = e => {
        if (!e.originalEvent.ctrlKey) {
            if (selectedRows && selectedRows.length === 1 && selectedRows.filter(r => r.id === e.value[0].id).length > 0) {
                setSelectedRows([]);
                setExpandedRows([]);
            } else {
                setSelectedRows(e.value);
            }
        } else {
            setSelectedRows(e.value);
        }
    };

    const rowExpansionTemplate = e => {
        if (!e || e.length === 0) {
            return null;
        } else {
            const filteredFields = [];
            fieldOptions.forEach(fo => {
                if (fo.validDeliveryTypes && fo.validDeliveryTypes.filter(dt => dt === e.deliveryTypeId).length > 0) {
                    if (expandedFields.filter(ex => ex.field === fo.shortName).length > 0)
                        filteredFields.push(expandedFields.filter(ex => ex.field === fo.shortName)[0]);
                }
            });
            return <PreDefinedRouteExpanded fields={filteredFields} data={e} />;
        }
    };

    const onExpandedRows = e => {
        setSelectedRows(e);
        setExpandedRows(e);
    };

    const pageContent = (
        <DataTable
            value={preDefinedRoutesRows}
            paginator
            rows={10}
            selectionMode="multiple"
            selection={selectedRows}
            onSelectionChange={e => handleSelectionChange(e)}
            rowExpansionTemplate={rowExpansionTemplate}
            onRowToggle={e => onExpandedRows(e.data)}
            expandedRows={expandedRows}
        >
            <Column expander={true} style={{ width: '2em' }} />
            {columns}
        </DataTable>
    );

    const deleteRoutes = routes => {
        onDeletePreDefinedRoutes(routes);
    };

    const onDownloadClicked = format => {
        onGetPlansForPreDefined();
        setDownloadFormat(format);
    };

    const downloadSimulated = () => {
        onDownloadPreDefinedRoutes(
            selectedRows.map(r => r.id),
            downloadFormat,
            true
        );
        setShowSimulatedModal(false);
    };

    const downloadSimulatedModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setShowSimulatedModal(!showSimulatedModal)}
            open={showSimulatedModal}
            heading="No Plan Found"
            message="No plan found for the selected routes, do you want to download them using a simulated plan?"
            confirmAction={downloadSimulated}
            cancelButton="No"
            confirmButton="Yes"
        />
    );

    useEffect(() => {
        if (planPreDefinedSuccess === true) {
            onPlanPreDefinedReset();
            growl.show({ severity: 'success', summary: 'Add to plan OK', detail: 'Successfully planned the selected items.' });
        } else if (planPreDefinedFail === true) {
            onPlanPreDefinedReset();
            growl.show({ severity: 'error', summary: 'Add to plan Failed', detail: `Error: ${planPreDefinedError}` });
        }
    }, [planPreDefinedSuccess, planPreDefinedFail, planPreDefinedError, onPlanPreDefinedReset, growl]);

    const addSelectedAvailableToPlan = () => {
        onPlanPreDefined(selectedRows.map(r => r.id));
        setShowAddToPlanModal(!showAddToPlanModal);
    };

    const addToPlanModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setShowAddToPlanModal(!showAddToPlanModal)}
            open={showAddToPlanModal}
            heading="Add to plan"
            message="Would you like to add the selected routes to plan?"
            confirmAction={addSelectedAvailableToPlan}
            cancelButton="No"
            confirmButton="Yes"
        />
    );

    // trigger the download dialog for the file
    useEffect(() => {
        if (downloadPreDefinedRoutesSuccess && !downloadPreDefinedRoutesLoading) {
            const blob = new Blob([file], { type: fileType });
            saveAs(blob, fileName);
            onDownloadPreDefinedRoutesReset();
        } else if (downloadPreDefinedRoutesFail) {
            growl.show({ severity: 'error', summary: 'Download Failed', detail: `Error: ${downloadPreDefinedRoutesError}` });
            onDownloadPreDefinedRoutesReset();
        }
    }, [
        downloadPreDefinedRoutesLoading,
        downloadPreDefinedRoutesSuccess,
        downloadPreDefinedRoutesFail,
        downloadPreDefinedRoutesError,
        onDownloadPreDefinedRoutesReset,
        file,
        fileName,
        fileType,
        growl
    ]);

    useEffect(() => {
        if (plansForPreDefinedSuccess === true) {
            onDownloadPreDefinedRoutes(
                selectedRows.map(r => r.id),
                downloadFormat,
                false
            );
            onGetPlansForPreDefinedReset();
        } else if (plansForPreDefinedFail === true) {
            setShowSimulatedModal(true);
            onGetPlansForPreDefinedReset();
        }
    }, [
        onDownloadPreDefinedRoutes,
        plansForPreDefined,
        plansForPreDefinedFail,
        plansForPreDefinedSuccess,
        downloadFormat,
        selectedRows,
        setShowSimulatedModal,
        onGetPlansForPreDefinedReset
    ]);

    // trigger the download dialog for the file
    /*
    useEffect(() => {
        if (downloadPreDefinedRoutesSuccess) {
            const blob = new Blob([file], { type: fileType });
            saveAs(blob, fileName);
        }
    }, [downloadPreDefinedRoutesSuccess, file, fileName, fileType]);
*/
    const deleteModal = (
        <Modal small handleClose={() => setShowDeleteModal(!showDeleteModal)} open={showDeleteModal}>
            <ModalHeading element="h3">Delete Route{selectedRows.length > 1 ? 's' : ''}</ModalHeading>
            <p>
                Please click 'DELETE' again to confirm Delete the below route
                {selectedRows.length > 1 ? 's' : ''}
            </p>
            <ul>
                {selectedRows.map((r, i) => {
                    return <li key={i}>{r.description}</li>;
                })}
            </ul>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={() => deleteRoutes(selectedRows)}>Delete</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={() => setShowDeleteModal(!showDeleteModal)}>Cancel</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );

    return (
        <Card>
            <h5>Pre-Defined Routes</h5>
            <GridWrapper>
                <GridItem size="1/2" className="ln-u-text-align-left">
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => onEditPreDefinedRoute(null)}
                        icon="pi pi-plus"
                        tooltip="Add Pre-Defined Route"
                        tooltipOptions={{ position: 'top' }}
                        disabled={!canAddRoute}
                    ></Button>
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => onEditPreDefinedRoute(selectedRows[0])}
                        icon="pi pi-pencil"
                        tooltip="Edit Pre-Defined Route"
                        tooltipOptions={{ position: 'top' }}
                        disabled={!selectedRows || selectedRows.length !== 1}
                    ></Button>
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => setShowDeleteModal(true)}
                        icon="pi pi-trash"
                        tooltip="Delete Selected"
                        tooltipOptions={{ position: 'top' }}
                        disabled={!selectedRows || selectedRows.length === 0}
                    ></Button>
                    {/*
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => onDownloadClicked('text/csv')}
                        icon="pi pi-download"
                        tooltip="Download Selected (CSV)"
                        tooltipOptions={{ position: 'top' }}
                        disabled={!selectedRows || selectedRows.length === 0}
                    >
                        <span className="notificationCounter">{'csv'}</span>
                    </Button>
                    */}
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => onDownloadClicked('application/json')}
                        icon="pi pi-download"
                        tooltip="Download Selected (JSON)"
                        tooltipOptions={{ position: 'top' }}
                        disabled={!selectedRows || selectedRows.length === 0}
                    >
                        <span className="notificationCounter">{'js'}</span>
                    </Button>
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => setShowAddToPlanModal(!showAddToPlanModal)}
                        icon="pi pi-sign-in"
                        tooltip="Add To Plan"
                        tooltipOptions={{ position: 'top' }}
                        disabled={!selectedRows || selectedRows.length === 0}
                    ></Button>
                </GridItem>
                <GridItem size="1/1" className={`ln-u-text-align-left ${classes.lunaDataTableStyle}`}>
                    {pageContent}
                </GridItem>
            </GridWrapper>
            {editPreDefinedModal}
            {deleteModal}
            {downloadSimulatedModal}
            {addToPlanModal}
            <Growl className="customGrowl" ref={el => (growl = el)}></Growl>
        </Card>
    );
};

PreDefinedRoutes.propTypes = {
    adHocDeliveryTypes: PropTypes.array,
    downloadPreDefinedRoutesError: PropTypes.string,
    downloadPreDefinedRoutesFail: PropTypes.bool,
    downloadPreDefinedRoutesLoading: PropTypes.bool,
    downloadPreDefinedRoutesSuccess: PropTypes.bool,
    fieldOptions: PropTypes.array,
    fieldOptionsLoading: PropTypes.bool,
    file: PropTypes.any,
    fileName: PropTypes.string,
    fileType: PropTypes.string,
    loadingAdHocDeliveryTypes: PropTypes.bool,
    locations: PropTypes.array,
    onDeletePreDefinedRoutes: PropTypes.func,
    onDeletePreDefinedRoutesReset: PropTypes.func,
    onDownloadPreDefinedRoutes: PropTypes.func,
    onDownloadPreDefinedRoutesReset: PropTypes.func,
    onGetAdHocDeliveryTypes: PropTypes.func,
    onGetFieldOptions: PropTypes.func,
    onGetLocations: PropTypes.func,
    onGetPlansForPreDefined: PropTypes.func,
    onGetPlansForPreDefinedReset: PropTypes.func,
    onPlanPreDefined: PropTypes.func,
    onPlanPreDefinedReset: PropTypes.func,
    planPreDefinedError: PropTypes.string,
    planPreDefinedFail: PropTypes.bool,
    planPreDefinedSuccess: PropTypes.bool,
    plansForPreDefined: PropTypes.array,
    plansForPreDefinedFail: PropTypes.bool,
    plansForPreDefinedSuccess: PropTypes.bool,
    preDefinedDetails: PropTypes.object,
    preDefinedRoutes: PropTypes.array,
    preDefinedRoutesDeleteSuccess: PropTypes.bool,
    preDefinedRoutesDeleting: PropTypes.bool,
    preDefinedRoutesError: PropTypes.string
};

const mapStateToProps = state => {
    return {
        adHocDeliveryTypes: state.requests.adHocDeliveryTypes,
        downloadPreDefinedRoutesError: state.requests.downloadPreDefinedRoutesError,
        downloadPreDefinedRoutesLoading: state.requests.downloadPreDefinedRoutesLoading,
        downloadPreDefinedRoutesSuccess: state.requests.downloadPreDefinedRoutesSuccess,
        downloadPreDefinedRoutesFail: state.requests.downloadPreDefinedRoutesFail,
        file: state.requests.file,
        fileName: state.requests.fileName,
        fileType: state.requests.fileType,
        locations: state.locations.locations,
        loadingAdHocDeliveryTypes: state.requests.loadingAdHocDeliveryTypes,
        fieldOptions: state.requests.preDefinedRoutesFieldOptions,
        fieldOptionsLoading: state.requests.preDefinedRoutesFieldOptionsLoading,
        preDefinedRoutesDeleteFail: state.requests.preDefinedRoutesDeleteFail,
        preDefinedRoutesDeleteSuccess: state.requests.preDefinedRoutesDeleteSuccess,
        preDefinedRoutesError: state.requests.error,
        preDefinedRoutesDeleting: state.requests.preDefinedRoutesDeleting,
        planPreDefinedError: state.requests.planPreDefinedError,
        planPreDefinedLoading: state.requests.planPreDefinedLoading,
        planPreDefinedSuccess: state.requests.planPreDefinedSuccess,
        planPreDefinedFail: state.requests.planPreDefinedFail,
        plansForPreDefined: state.requests.plansForPreDefined,
        plansForPreDefinedSuccess: state.requests.plansForPreDefinedSuccess,
        plansForPreDefinedFail: state.requests.plansForPreDefinedFail
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetFieldOptions: () => dispatch(actions.getPreDefinedRoutesFieldOptions()),
        onGetAdHocDeliveryTypes: () => dispatch(actions.getAdHocDeliveryTypes()),
        onDeletePreDefinedRoutes: routes => dispatch(actions.deletePreDefinedRoutes(routes)),
        onDeletePreDefinedRoutesReset: () => dispatch(actions.deletePreDefinedRoutesReset()),
        onDownloadPreDefinedRoutes: (ids, format, simulatePlan) => dispatch(actions.downloadPreDefinedRoutes(ids, format, simulatePlan)),
        onDownloadPreDefinedRoutesReset: () => dispatch(actions.downloadPreDefinedRoutesReset()),
        onPlanPreDefined: ids => dispatch(actions.planPreDefined(ids)),
        onPlanPreDefinedReset: () => dispatch(actions.planPreDefinedReset()),
        onGetPlansForPreDefined: () => dispatch(actions.getPlansForPreDefined()),
        onGetPlansForPreDefinedReset: () => dispatch(actions.getPlansForPreDefinedReset()),
        onGetLocations: () => dispatch(actions.getLocations())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(PreDefinedRoutes, axios));
