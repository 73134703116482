import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '@jsluna/form';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { ListGroup, ListGroupItem } from '@jsluna/list';

import { Button } from 'primereact/button';

import classes from './TopBar.module.scss';

const TopBar = ({ diPlanHeader, downloading, handleActiveClick, handleDeleteClick, handleDownloadClick, publishClick, publishRunning, selectedPlanDay }) => {
    return (
        <GridWrapper>
            <GridItem size="1/1" className={classes.statusBar}>
                <ListGroup inline>
                    <ListGroupItem>
                        <h3 className="ln-u-push-right" style={{ marginBottom: '0' }}>
                            DI Planning Tool
                        </h3>
                    </ListGroupItem>
                    <ListGroupItem>
                        <Checkbox name="planActive" label="Active" defaultChecked={diPlanHeader.active} onChange={handleActiveClick} />
                    </ListGroupItem>
                    <ListGroupItem>
                        <Button onClick={handleDeleteClick} icon="pi pi-trash" tooltip="Delete this plan" tooltipOptions={{ position: 'top' }} />
                    </ListGroupItem>
                    <ListGroupItem>
                        <h5 style={{ marginBottom: '0', marginTop: '10px' }}>{diPlanHeader.name}</h5>
                    </ListGroupItem>
                    <ListGroupItem>
                        <Button
                            type="button"
                            onClick={handleDownloadClick}
                            icon={`pi ${downloading ? 'pi-spin pi-spinner' : 'pi-cloud-download'}`}
                            className={`${downloading ? 'buttonActive' : ''}`}
                            tooltip="Download Json Plan"
                            tooltipOptions={{ position: 'top' }}
                            style={{ display: 'inline-block' }}
                        />
                    </ListGroupItem>
                    <ListGroupItem>
                        <Button
                            type="button"
                            onClick={publishClick}
                            icon={`pi ${publishRunning ? 'pi-spin pi-spinner' : 'pi-cloud-upload'}`}
                            className={publishRunning ? 'buttonActive' : ''}
                            tooltip="Publish Plan"
                            tooltipOptions={{ position: 'top' }}
                            disabled={(selectedPlanDay || 'none') === 'none'}
                        />
                    </ListGroupItem>
                </ListGroup>
            </GridItem>
        </GridWrapper>
    );
};

TopBar.propTypes = {
    diPlanHeader: PropTypes.object,
    downloading: PropTypes.bool,
    handleActiveClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    handleDownloadClick: PropTypes.func.isRequired,
    publishClick: PropTypes.func.isRequired,
    publishRunning: PropTypes.bool,
    selectedPlanDay: PropTypes.string
};

export default TopBar;
