import React from 'react';

import classes from './fileIcon.module.scss';

const JsonIcon = () => {
    return (
        <div className={[classes.fileIcon, classes.jsonIcon].join(' ')}>
            <svg viewBox="0 0 253 273" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M207.5 264H81.5C61.6177 264 45.5 247.882 45.5 228V45C45.5 25.1177 61.6177 9 81.5 9H161.913C172.372 9 182.313
                    13.548 189.151 21.4609L210.69 46.3848L234.738 74.2113C240.39 80.7513 243.5 89.1065 243.5 97.7503V228C243.5 247.882
                    227.382 264 207.5 264Z"
                    fill="#efefef"
                    stroke="#575757"
                    strokeWidth="18"
                />
                <rect y="109" width="216" height="108" rx="21" fill="#575757" />
                <path
                    d="M55.3828 173.727C55.3828 176.122 55.0052 178.31 54.25 180.289C53.4948 182.268 52.388 183.961 50.9297 185.367C49.4714
                    186.773 47.6615 187.867 45.5 188.648C43.3385 189.404 40.8255 189.781 37.9609 189.781C35.8516 189.781 33.8203 189.534 31.8672
                    189.039C29.9141 188.544 28.2083 187.919 26.75 187.164V177.867C27.401 178.414 28.1562 178.922 29.0156 179.391C29.901 179.859
                    30.8385 180.276 31.8281 180.641C32.8177 180.979 33.8333 181.253 34.875 181.461C35.9167 181.643 36.9453 181.734 37.9609
                    181.734C38.8203 181.734 39.7057 181.578 40.6172 181.266C41.5547 180.953 42.388 180.432 43.1172 179.703C43.8464 178.974 44.4453
                    178.023 44.9141 176.852C45.3828 175.654 45.6172 174.182 45.6172 172.438V145.875H27.1406V137.945H55.3828V173.727ZM103.859
                    173.57C103.859 176.357 103.339 178.779 102.297 180.836C101.281 182.893 99.849 184.599 98 185.953C96.151 187.281 93.9505 188.271
                    91.3984 188.922C88.8464 189.573 86.0599 189.898 83.0391 189.898C80.3568 189.898 77.7656 189.742 75.2656 189.43C72.7656 189.117
                    70.2917 188.596 67.8438 187.867V178.375C69.0417 178.792 70.2917 179.182 71.5938 179.547C72.8958 179.911 74.1979 180.237 75.5
                    180.523C76.8281 180.784 78.1432 180.992 79.4453 181.148C80.7474 181.305 82.0104 181.383 83.2344 181.383C85.0573 181.383 86.6068
                    181.227 87.8828 180.914C89.1589 180.602 90.2005 180.172 91.0078 179.625C91.8151 179.078 92.401 178.427 92.7656 177.672C93.1302
                    176.917 93.3125 176.083 93.3125 175.172C93.3125 173.922 92.9479 172.88 92.2188 172.047C91.5156 171.188 90.5781 170.432 89.4062
                    169.781C88.2344 169.13 86.8932 168.544 85.3828 168.023C83.8984 167.503 82.375 166.943 80.8125 166.344C79.276 165.745 77.7526
                    165.068 76.2422 164.312C74.7578 163.557 73.4297 162.62 72.2578 161.5C71.0859 160.38 70.1354 159.052 69.4062 157.516C68.7031
                    155.953 68.3516 154.078 68.3516 151.891C68.3516 149.885 68.7161 147.984 69.4453 146.188C70.1745 144.391 71.3203 142.828 72.8828
                    141.5C74.4453 140.146 76.4505 139.078 78.8984 138.297C81.3464 137.49 84.3021 137.086 87.7656 137.086C88.7812 137.086 89.8359
                    137.138 90.9297 137.242C92.0495 137.32 93.1432 137.438 94.2109 137.594C95.3047 137.724 96.3464 137.893 97.3359 138.102C98.3516
                    138.284 99.276 138.479 100.109 138.688V147.477C99.276 147.164 98.3646 146.878 97.375 146.617C96.3854 146.357 95.3568 146.135
                    94.2891 145.953C93.2474 145.745 92.1927 145.589 91.125 145.484C90.0573 145.38 89.0417 145.328 88.0781 145.328C86.3854 145.328
                    84.9531 145.471 83.7812 145.758C82.6094 146.018 81.6458 146.396 80.8906 146.891C80.1354 147.359 79.5885 147.945 79.25
                    148.648C78.9115 149.352 78.7422 150.133 78.7422 150.992C78.7422 152.06 79.0938 152.984 79.7969 153.766C80.526 154.521 81.4766
                    155.211 82.6484 155.836C83.8464 156.435 85.1875 157.008 86.6719 157.555C88.1823 158.076 89.7188 158.648 91.2812 159.273C92.8698
                    159.872 94.4062 160.562 95.8906 161.344C97.401 162.125 98.7422 163.076 99.9141 164.195C101.112 165.315 102.062 166.643 102.766
                    168.18C103.495 169.69 103.859 171.487 103.859 173.57ZM150.539 163.258C150.539 167.893 149.992 171.891 148.898 175.25C147.831
                    178.609 146.346 181.37 144.445 183.531C142.57 185.693 140.357 187.294 137.805 188.336C135.279 189.378 132.57 189.898 129.68
                    189.898C122.961 189.898 117.909 187.672 114.523 183.219C111.138 178.74 109.445 172.242 109.445 163.727C109.445 159.091 109.979
                    155.094 111.047 151.734C112.141 148.375 113.625 145.615 115.5 143.453C117.401 141.292 119.615 139.69 122.141 138.648C124.693
                    137.607 127.414 137.086 130.305 137.086C137.023 137.086 142.076 139.312 145.461 143.766C148.846 148.219 150.539 154.716 150.539
                    163.258ZM140.383 163.727C140.383 157.685 139.576 153.18 137.961 150.211C136.346 147.242 133.69 145.758 129.992 145.758C128.143
                    145.758 126.568 146.148 125.266 146.93C123.964 147.685 122.883 148.805 122.023 150.289C121.19 151.773 120.578 153.609 120.188
                    155.797C119.797 157.958 119.602 160.445 119.602 163.258C119.602 169.299 120.409 173.805 122.023 176.773C123.664 179.742 126.32
                    181.227 129.992 181.227C131.841 181.227 133.417 180.849 134.719 180.094C136.047 179.312 137.128 178.18 137.961 176.695C138.794
                    175.211 139.406 173.388 139.797 171.227C140.188 169.039 140.383 166.539 140.383 163.727ZM180.891 189L167.219 158.531L164.406
                    151.969V173.141V189H155.656V137.945H167.453L181.398 168.609L183.859 174.586V152.047V137.945H192.609V189H180.891Z"
                    fill="white"
                />
                <path d="M197.574 85.3674C183.767 85.3674 172.574 74.1745 172.574 60.3674L172.574 4.36739L242.574 85.3674L197.574 85.3674Z" fill="#575757" />
                <path d="M197.574 85.3674C183.767 85.3674 172.574 74.1745 172.574 60.3674L172.574 4.36739L242.574 85.3674L197.574 85.3674Z" fill="#575757" />
            </svg>
        </div>
    );
};

export default JsonIcon;
