import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const getEmailDistributionStart = () => ({ type: actionTypes.GET_EMAIL_DISTRIBUTION_START });
export const getEmailDistributionSuccess = distribution => ({ type: actionTypes.GET_EMAIL_DISTRIBUTION_SUCCESS, distribution: distribution });
export const getEmailDistributionFail = error => ({ type: actionTypes.GET_EMAIL_DISTRIBUTION_FAIL, distributionError: error });

export const getEmailDistribution = () => {
    return dispatch => {
        const url = '/email/distribution';

        dispatch(getEmailDistributionStart());
        axios
            .get(url)
            .then(response => dispatch(getEmailDistributionSuccess(response.data)))
            .catch(error => dispatch(getEmailDistributionFail(error)));
    };
};

export const addNewGroupStart = () => ({ type: actionTypes.ADD_NEW_GROUP_START });
export const addNewGroupSuccess = id => ({ type: actionTypes.ADD_NEW_GROUP_SUCCESS, newGroupId: id });
export const addNewGroupFail = error => ({ type: actionTypes.ADD_NEW_GROUP_FAIL, newGroupError: error });

export const addNewGroup = groupName => {
    return dispatch => {
        const url = `/email/newgroup/${encodeURI(groupName)}`;

        dispatch(addNewGroupStart());
        axios
            .get(url)
            .then(response => dispatch(addNewGroupSuccess(response.data)))
            .catch(error => dispatch(addNewGroupFail(error)));
    };
};

export const addNewEmailStart = () => ({ type: actionTypes.ADD_NEW_EMAIL_START });
export const addNewEmailSuccess = id => ({ type: actionTypes.ADD_NEW_EMAIL_SUCCESS, newEmailId: id });
export const addNewEmailFail = error => ({ type: actionTypes.ADD_NEW_EMAIL_FAIL, newEmailError: error });

export const addNewEmail = (fullName, address) => {
    return dispatch => {
        const url = `/email/newemail/${encodeURI(fullName)}/${encodeURI(address)}`;

        dispatch(addNewEmailStart());
        axios
            .get(url)
            .then(response => dispatch(addNewEmailSuccess(response.data)))
            .catch(error => dispatch(addNewEmailFail(error)));
    };
};

export const emailSaveChangesStart = () => ({ type: actionTypes.EMAIL_SAVE_CHANGES_START });
export const emailSaveChangesSuccess = success => ({ type: actionTypes.EMAIL_SAVE_CHANGES_SUCCESS, emailSaveChangesSuccess: success });
export const emailSaveChangesFail = error => ({ type: actionTypes.EMAIL_SAVE_CHANGES_FAIL, emailSaveChangesError: error });

export const emailSaveChanges = changes => {
    return dispatch => {
        const url = '/email/savechanges';

        dispatch(emailSaveChangesStart());
        axios
            .post(url, changes)
            .then(response => dispatch(emailSaveChangesSuccess(response.data)))
            .catch(error => dispatch(emailSaveChangesFail(error)));
    };
};

export const getEmailGroupNamesStart = () => ({ type: actionTypes.GET_EMAIL_GROUP_NAMES_START });
export const getEmailGroupNamesSuccess = groups => ({ type: actionTypes.GET_EMAIL_GROUP_NAMES_SUCCESS, emailGroups: groups });
export const getEmailGroupNamesFail = error => ({ type: actionTypes.GET_EMAIL_GROUP_NAMES_FAIL, emailGroupsError: error });

export const getEmailGroupNames = () => {
    return dispatch => {
        const url = '/email/groups';

        dispatch(getEmailGroupNamesStart());
        axios
            .get(url)
            .then(response => dispatch(getEmailGroupNamesSuccess(response.data)))
            .catch(error => dispatch(getEmailGroupNamesFail(error)));
    };
};

export const sendGroupEmailStart = () => ({ type: actionTypes.SEND_GROUP_EMAIL_START });
export const sendGroupEmailSuccess = success => ({ type: actionTypes.SEND_GROUP_EMAIL_SUCCESS, sendGroupEmailSuccess: success });
export const sendGroupEmailFail = error => ({ type: actionTypes.SEND_GROUP_EMAIL_FAIL, sendGroupEmailError: error });

export const sendGroupEmail = (groupid, planid) => {
    return dispatch => {
        const url = `/email/sendgroupemail/${groupid}/${planid}`;

        dispatch(sendGroupEmailStart());
        axios
            .get(url)
            .then(response => dispatch(sendGroupEmailSuccess(response.data)))
            .catch(error => dispatch(sendGroupEmailFail(error)));
    };
};
