export const menuOptions = {
    mainMenu: [
        {
            id: 0,
            title: 'CVL ',
            key: 'mainMenu',
            label: true,
            to: '/cvl',
            description: 'Import and Analyse CVL'
        },
        {
            id: 1,
            title: 'Plans',
            key: 'mainMenu',
            label: true,
            to: '/plans',
            description: 'View index of all plans. Create/Edit a plan'
        },
        {
            id: 2,
            title: 'Extras',
            key: 'mainMenu',
            label: true,
            to: '/requests',
            description: 'View pending/ completed requests. Manage pre-defined routes.'
        },
        {
            id: 4,
            title: 'Legacy CSV Management',
            key: 'mainMenu',
            label: true,
            to: '/legacy',
            description: 'Merge or transform multiple Paragon CSV files'
        },
        // {
        //     id: 5,
        //     title: 'Catalogue Planner',
        //     key: 'mainMenu',
        //     label: true,
        //     to: '/catalogues',
        //     description: 'Plan catalogue deliveries'
        // },
        {
            id: 6,
            title: 'DI Plans',
            key: 'mainMenu',
            label: true,
            to: '/DI',
            description: 'View index of all DI plans. Create/Edit a DI plan'
        }
    ],
    settings: [
        {
            id: 0,
            title: 'Settings',
            key: 'settings',
            label: false,
            icon: 'Settings',
            to: '/settings'
        },
        {
            id: 1,
            title: 'Logout',
            key: 'settings',
            label: false,
            icon: 'Account'
            // click: this.props.logOutHandler
        }
    ]
};
