import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';

import { Card } from '@jsluna/card';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';

import classes from '../Schedule.module.scss';

import { saveAs } from 'file-saver';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ContextMenu } from 'primereact/contextmenu';
import { InputText } from 'primereact/inputtext';

import { updateObject } from '../../../shared/utility';

import { resizeAction } from '../ScheduleResize';

import DeleteRestore from '../DeleteRestore/DeleteRestore';
import EditSchedule from '../EditSchedule/EditSchedule';
import { scheduleColumns } from './ScheduleColumns';
import ScheduleExpanded from '../ScheduleExpanded';

import cloneDeep from 'lodash-es/cloneDeep';

import ConfirmationDialog from '../../UI/ConfirmationDialog/ConfirmationDialog';

import $ from 'jquery';
import moment from 'moment';

moment.locale('en-GB');

const Schedule = props => {
    const {
        addAdhocVolumeSuccess,
        addAdhocVolumeError,
        addAdhocVolumeRunning,
        adhocVolume,
        growl,
        holdOverStoreSchedulesItems,
        onGetSchedule,
        schedule,
        onCloseAdHocVolume,
        onDeleteSchedules,
        onDeleteSchedulesReset,
        onDownloadSchedule,
        onDownloadScheduleReset,
        onHoldOverSchedule,
        onEditSchedules,
        onEditSchedulesReset,
        onSaveAddAdHocVolume,
        onSaveAddAdHocVolumeReset,
        onUpdateLocationDetails,
        deleteStoreSchedulesRunning,
        deleteStoreSchedulesSuccess,
        downloadSuccess,
        editStoreSchedulesRunning,
        editStoreSchedulesSuccess,
        updateLocationDetailsError,
        updateLocationDetailsRunning,
        updateLocationDetailsSuccess,
        loadingSchedule,
        downloadData,
        downloadingSchedule,
        planId,
        dcNo,
        downloadFilename,
        plan,
        activeSchedule,
        onAddAdHocVolume,
        onHoldOverReset,
        onUnpublishSchedule,
        onUnpublishScheduleReset,
        unpublishScheduleError,
        unpublishScheduleSuccess,
        unpublishScheduleRunning
    } = props;

    const row = useRef();
    const cm = useRef(null);

    const pid = props.match ? props.match.params.planId : planId;
    const dcNum = props.match ? props.match.params.dcNo : dcNo;

    let datatable2;

    const [showDeleteScheduleModal, setShowDeleteScheduleModal] = useState(false);
    const [showEditScheduleModal, setShowEditScheduleModal] = useState(false);
    const [contextAction, setContextAction] = useState(null);
    const [originalScheduleDetails, setOriginalScheduleDetails] = useState([]);
    const [scheduleDetails, setScheduleDetails] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [cols, setCols] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [editRows, setEditRows] = useState([]);
    const [clonedLocationRow, setClonedLocationRow] = useState({});
    const [scheduleHeading, setScheduleHeading] = useState('');
    const [includeInactive, setIncludeInactive] = useState(false);
    const [inactiveCount, setInactiveCount] = useState(false);
    const [deleteAction, setDeleteAction] = useState(false);
    const [editAction, setEditAction] = useState(false);
    const [fixScroll, setFixScroll] = useState(false);
    const [adhocHeaderId, setAdhocHeaderId] = useState(-1);
    const [menu, setMenu] = useState([
        { label: 'Edit', disabled: true, icon: 'pi pi-fw pi-pencil', command: () => menuAction('Edit') },
        { label: 'Delete', disabled: true, icon: 'pi pi-fw pi-times', command: () => menuAction('Delete') },
        { label: 'Restore', disabled: true, icon: 'pi pi-fw pi-plus', command: () => menuAction('Restore') }
    ]);
    const [showUnpublishModal, setShowUnpublishModal] = useState(false);

    const [scrollHeight, setScrollHeight] = useState('1px');

    const resize = useCallback(() => {
        const scheduleBody = document.querySelector('.scheduleTable .p-datatable-scrollable-body');
        if (!scheduleBody) return;
        const rs = resizeAction(document.querySelector('.scheduleTable .p-datatable-scrollable-wrapper'), 20);
        if (!rs) return null;
        rs.then(e => {
            if (e !== 0) {
                setScrollHeight(e);
                scheduleBody.style.minHeight = e;
            }
        });
    }, []);

    window.addEventListener('resize', () => resize());

    useEffect(() => {
        if (activeSchedule === 'store') resize();
    }, [activeSchedule, resize]);

    useEffect(() => {
        if (!fixScroll) {
            enableScroll();
            window.scrollTo(0, 0);
        } else {
            const pos = document.querySelector('.toolbarStore').offsetTop;
            window.scrollTo(0, pos);
            setTimeout(() => disableScroll(pos), 500);
        }
    }, [fixScroll]);

    const disableScroll = pos => {
        window.onscroll = () => {
            window.scrollTo(0, pos);
        };
    };

    const enableScroll = () => {
        window.onscroll = () => {};
    };

    useEffect(() => {
        if (schedule === null && !isNaN(pid) && !isNaN(dcNum) && pid !== null && dcNum !== null) onGetSchedule(+pid, dcNum);
    }, [pid, dcNum, onGetSchedule, schedule]);

    useEffect(() => {
        if (downloadSuccess === null) return;
        if (downloadSuccess) {
            const blob = new Blob([downloadData], { type: 'application/vnd.ms-excel' });
            saveAs(blob, downloadFilename);
            growl.show({ severity: 'success', summary: 'Schedule Downloaded' });
        } else growl.show({ severity: 'error', summary: 'Schedule Download Failed' });
        onDownloadScheduleReset();
    }, [downloadSuccess, growl, downloadData, downloadFilename, onDownloadScheduleReset]);

    useEffect(() => {
        if (holdOverStoreSchedulesItems === null) return;
        onHoldOverReset();
        const newSchedule = scheduleDetails.filter(
            s => holdOverStoreSchedulesItems.filter(h => h.PlanLocationHeaderId === s.planLocationHeaderId).length === 0
        );
        const newOriginalSchedule = originalScheduleDetails.filter(
            s => holdOverStoreSchedulesItems.filter(h => h.PlanLocationHeaderId === s.planLocationHeaderId).length === 0
        );
        setScheduleDetails(newSchedule);
        setOriginalScheduleDetails(newOriginalSchedule);
    }, [holdOverStoreSchedulesItems, scheduleDetails, originalScheduleDetails, setScheduleDetails, setOriginalScheduleDetails, onHoldOverReset]);

    const notIn = (array1, array2) =>
        array1.filter(item1 => {
            const item1Str = JSON.stringify(item1);
            return !array2.find(item2 => item1Str === JSON.stringify(item2));
        });

    const toggleInactiveCallback = useCallback(() => {
        const count = originalScheduleDetails.filter(m => !m.active).length;
        if (includeInactive && count <= 1 && contextAction === 'Restore') setIncludeInactive(!includeInactive);
    }, [includeInactive, originalScheduleDetails, contextAction]);

    const inactiveCounter = () => {
        if (!includeInactive) return null;
        else return <span className="notificationCounter">{inactiveCount}</span>;
    };

    const removeFromSchedule = useCallback(() => {
        onDeleteSchedulesReset();
        const updateScheduleDetails = originalScheduleDetails.map(sd => {
            if (selectedRows.find(r => r.planLocationHeaderId === sd.planLocationHeaderId)) return { ...sd, active: !sd.active };
            return sd;
        });
        setOriginalScheduleDetails(updateScheduleDetails);
        const newScheduleDetails = notIn(originalScheduleDetails, selectedRows);
        setScheduleDetails(newScheduleDetails);
        toggleInactiveCallback();
    }, [selectedRows, onDeleteSchedulesReset, originalScheduleDetails, toggleInactiveCallback]);

    useEffect(() => {
        if (!deleteStoreSchedulesSuccess) return;
        const msg = contextAction === 'Delete' ? 'Deleted' : 'Restored';
        if (deleteStoreSchedulesSuccess === false) {
            growl.show({ severity: 'error', summary: `Schedule Store ${msg} Failed` });
        } else if (deleteStoreSchedulesSuccess === true) {
            removeFromSchedule();
            growl.show({ severity: 'success', summary: `Schedule Store ${msg}` });
        }
        setSelectedRows([]);
    }, [removeFromSchedule, deleteStoreSchedulesSuccess, growl, selectedRows, contextAction]);

    // TODO : Not yet implemented to update the schedule with any edits
    const updateSchedule = useCallback(() => {
        onEditSchedulesReset();
    }, [onEditSchedulesReset]);

    useEffect(() => {
        if (!editStoreSchedulesSuccess) return;
        const msg = contextAction === 'Edit' ? 'Edited' : '';
        if (editStoreSchedulesSuccess === false) {
            growl.show({ severity: 'error', summary: `${contextAction} Schedule Failed` });
        } else if (editStoreSchedulesSuccess === true) {
            updateSchedule();
            growl.show({ severity: 'success', summary: `Schedule Successfully ${msg}` });
        }
        setSelectedRows([]);
    }, [updateSchedule, editStoreSchedulesSuccess, growl, selectedRows, contextAction]);

    useEffect(() => {
        if (deleteAction && selectedRows && selectedRows.length > 0) {
            onDeleteSchedules(selectedRows, contextAction);
            setDeleteAction(false);
            setShowDeleteScheduleModal(false);
        }
    }, [deleteAction, selectedRows, onDeleteSchedules, contextAction]);

    useEffect(() => {
        if (editAction && selectedRows && selectedRows.length > 0) {
            onEditSchedules(selectedRows);
            setEditAction(false);
            setShowEditScheduleModal(false);
        }
    }, [editAction, selectedRows, onEditSchedules]);

    const menuAction = action => {
        setContextAction(action);
        switch (action) {
            case 'Edit':
                setShowEditScheduleModal(true);
                break;
            default:
                setShowDeleteScheduleModal(true);
                break;
        }
    };

    const reApplyFilter = useCallback(() => {
        if (originalScheduleDetails.length === 0) return;
        let data = [];
        if (Object.keys(filterData).length === 0) {
            if (includeInactive) data = originalScheduleDetails;
            else {
                const filteredRows = originalScheduleDetails.filter(r => !r.active);
                data = notIn(originalScheduleDetails, filteredRows);
            }
        } else {
            data = originalScheduleDetails.filter(sd => {
                for (const key in filterData) {
                    if (
                        sd[key] === undefined ||
                        !sd[key]
                            .toString()
                            .toLowerCase()
                            .includes(filterData[key].toString().toLowerCase()) ||
                        !(sd.active === true || includeInactive)
                    )
                        return false;
                }
                return true;
            });
        }
        setScheduleDetails(data);
        const inactive = originalScheduleDetails.filter(m => !m.active);
        const inactiveLength = originalScheduleDetails.filter(m => !m.active).length;
        const notInFilteredCount = notIn(inactive, data).length;
        setInactiveCount(inactiveLength - notInFilteredCount);
    }, [filterData, includeInactive, originalScheduleDetails]);

    useEffect(() => {
        if (scrollHeight === '1px') resize();
        reApplyFilter(filterData);
    }, [scrollHeight, resize, reApplyFilter, filterData]);

    const populateState = useCallback(() => {
        const dynamicSortMultiple = (...args) => {
            /*
             * save the arguments object as it will be overwritten
             * note that arguments object is an array-like object
             * consisting of the names of the properties to sort by
             */

            return (obj1, obj2) => {
                let i = 0;
                let result = 0;
                const numberOfProperties = args.length;
                /* try getting a different result from 0 (equal)
                 * as long as we have extra properties to compare
                 */
                while (result === 0 && i < numberOfProperties) {
                    result = dynamicSort(args[i])(obj1, obj2);
                    i++;
                }
                return result;
            };
        };

        const dynamicSort = property => {
            let sortOrder = 1;
            if (property[0] === '-') {
                sortOrder = -1;
                property = property.substr(1);
            }
            return (a, b) => {
                const itemA = Array.isArray(a) ? a[0] : a;
                const itemB = Array.isArray(b) ? b[0] : b;

                const result = itemA[property] < itemB[property] ? -1 : itemA[property] > itemB[property] ? 1 : 0;
                return result * sortOrder;
            };
        };

        // SCHEDULE SPECIFIC //
        if (schedule && schedule !== null && Object.keys(schedule).length > 0) {
            const scheduleData = [];
            let volumeColumns = [];
            const pos = scheduleColumns.columns.findIndex(m => m.field === 'estimate') + 1;

            Object.keys(schedule).forEach((os, ind) => {
                const dc = schedule[os];
                if (dc[0].locationName !== null)
                    if (ind === 0) {
                        volumeColumns = dc.map((d, i) => {
                            return {
                                seq: pos + i,
                                header: d.volumeTypeName,
                                field: d.volumeTypeName,
                                className: 'ln-u-text-align-center',
                                filter: 'true',
                                filterMatchMode: 'contains',
                                filterElement: 'onFilterChange'
                            };
                        });
                        setScheduleHeading(dc[0].delName);
                    }
            });

            const startColumns = [...scheduleColumns.columns.slice(0, pos)].map((c, i) => ({ ...c, seq: i }));
            const endColumns = [...scheduleColumns.columns.slice(pos)].map((c, i) => ({ ...c, seq: pos + volumeColumns.length + i }));

            const allColumns = updateObject(cols, {
                columns: [...startColumns, ...volumeColumns, ...endColumns]
            });

            setCols(allColumns);

            const objToArray = Object.keys(schedule).map(os => schedule[os]);
            const sortedScheduleData = objToArray.sort(dynamicSortMultiple('svcPickTime'));

            sortedScheduleData.forEach(dc => {
                if (dc[0].locationName === null) return true;
                let totalVolume = 0;
                const cols = {};
                dc.forEach(d => {
                    totalVolume += d.totalDU;
                    cols[d.volumeTypeName] = d.totalDU;
                });
                const row = {
                    locationNo: dc[0].locationNo,
                    locationName: dc[0].locationName,
                    svcPickTime: moment(dc[0].svcPickTime).format('ddd HH:mm'),
                    svcDepartTime: moment(dc[0].svcDepartTime).format('ddd HH:mm'),
                    dailyPattern: dc[0].dailyPattern,
                    delNo: dc[0].delNo,
                    storeTrailerMax: dc[0].storeTrailerMax,
                    totalVolume: totalVolume,
                    csoCutOffTime: dc[0].csoCutOffTime === null ? '' : dc[0].csoCutOffTime,
                    cols,
                    estimate: dc[0].estimate ? 'Yes' : '-',
                    planLocationHeaderId: dc[0].planLocationHeaderId,
                    planId: planId,
                    active: dc[0].active,
                    volumeGradeDetails: dc[0].volumeGradeDetails
                };
                const fullRow = updateObject(row, cols);
                scheduleData.push(fullRow);
            });
            setOriginalScheduleDetails(scheduleData);
        }
    }, [planId, schedule, cols]);

    useEffect(() => {
        if (originalScheduleDetails.length !== 0) return;
        populateState();
    }, [planId, schedule, populateState, originalScheduleDetails]);

    const handleChange = e => {
        if (!row.current) {
            let rowsSelected = [];
            if (e.originalEvent.type === 'contextmenu') {
                const rows = selectedRows.filter(m => m.planLocationHeaderId === e.value.planLocationHeaderId);
                if (rows.length === 0 && !e.originalEvent.ctrlKey) {
                    setSelectedRows([e.value]);
                    rowsSelected = [e.value];
                } else {
                    const newrows = selectedRows.filter(m => m.planLocationHeaderId !== e.value.planLocationHeaderId);
                    newrows.push(e.value);
                    setSelectedRows(newrows);
                    rowsSelected = newrows;
                }
            } else {
                if (!e.originalEvent.ctrlKey && !e.originalEvent.shiftKey) {
                    const newrows = selectedRows.filter(m => m.planLocationHeaderId === e.value[0].planLocationHeaderId);
                    if (newrows.length > 0) setSelectedRows([]);
                    else setSelectedRows(e.value);
                } else {
                    setSelectedRows(e.value);
                }
            }

            if (e.originalEvent.type === 'contextmenu') {
                // are any inactive and active selected
                const mixed = rowsSelected.filter(m => !m.active).length > 0 && rowsSelected.filter(m => m.active).length > 0;

                if (mixed)
                    growl.show({
                        severity: 'warn',
                        summary: 'Not allowed',
                        detail: 'You cant make changes to active and inactive stores at the same time'
                    });

                // this way is for just disabling the relevant button
                const newMenu = menu.map(m => ({
                    ...m,
                    disabled: mixed || (m.label !== (e.value.active ? 'Delete' : 'Restore') && m.label !== (e.value.active ? 'Edit' : ''))
                }));

                // this way is for hiding the relevant button
                // const newMenu = menu.map(m => ({
                //     ...m,
                //     disabled: mixed,
                //     style: { display: mixed || m.label !== (e.value.active ? 'Delete' : 'Restore') ? 'none' : 'block' }
                // }));
                setMenu(newMenu);
            }
        } else {
            row.current = undefined;
            hideMenu(e);
        }
    };

    const displaySelection = data => {
        if (!data || data.length === 0) {
            return <div style={{ textAlign: 'left' }}>No Selection</div>;
        } else {
            if (data instanceof Array)
                return (
                    <ul style={{ textAlign: 'left', margin: '0 5px' }}>
                        {data.map((row, i) => (
                            <li key={i}>{row.locationNo + ' - ' + row.locationName}</li>
                        ))}
                    </ul>
                );
            else return <div style={{ textAlign: 'left' }}>Selected Store: {data.locationNo + ' - ' + data.locationName}</div>;
        }
    };

    const reset = () => {
        datatable2.reset();
        $('.p-inputtext').val('');
        setSelectedRows([]);
        setExpandedRows([]);
        setFilterData({});
    };

    const onEditorValueChangeForRowEditing = (props, value) => {
        const updatedLocations = [...props.value];
        updatedLocations[props.rowIndex][props.field] = value;
        setScheduleDetails(updatedLocations);
    };

    // eslint-disable-next-line no-unused-vars
    const editorForRowEditing = (props, field) => {
        if (!field) return <span></span>;
        else return <InputText type="text" value={props.rowData[field] || ''} onChange={e => onEditorValueChangeForRowEditing(props, e.target.value)} />;
    };

    const onRowEditorValidator = rowData => {
        const value = rowData['locationNo'];
        return value.length > 0;
    };

    const onRowEditInit = e => {
        if (!e) return;
        callSetEditRows(e);
        clonedLocationRow[e.data.locationNo] = { ...e.data };
    };

    // SCHEDULE SPECIFIC //
    const callSetEditRows = useCallback(
        (e, remove) => {
            const newrows = editRows.filter(m => m.planLocationHeaderId !== e.data.planLocationHeaderId);
            if (!remove) newrows.push(e.data);
            setEditRows(newrows);
            setExpandedRows(newrows);
        },
        [editRows]
    );

    useEffect(() => {
        if (!updateLocationDetailsRunning) {
            if (updateLocationDetailsSuccess === true) {
                setEditRows([]);
                setExpandedRows([]);
                setSelectedRows([]);
                growl.show({ severity: 'success', summary: 'Success', detail: 'Location has been updated' });
            } else if (updateLocationDetailsSuccess === false && updateLocationDetailsError) {
                growl.show({ severity: 'error', summary: 'Error', detail: `Location update failed: ${updateLocationDetailsError}` });
            } else if (updateLocationDetailsSuccess === false) {
                growl.show({ severity: 'error', summary: 'Error', detail: 'Location update failed' });
            }
        }
    }, [updateLocationDetailsSuccess, updateLocationDetailsError, updateLocationDetailsRunning, growl]);

    useEffect(() => {
        if (addAdhocVolumeRunning === false && growl) {
            onSaveAddAdHocVolumeReset();
            if (addAdhocVolumeSuccess === true && adhocVolume !== null) {
                onCloseAdHocVolume(addAdhocVolumeSuccess, adhocVolume, 'Volume Added Successfully', growl);

                const recIndex = scheduleDetails.findIndex(s => s.planLocationHeaderId === adhocHeaderId);
                // Update schedule details with new returned adhoc
                const newScheduleDetails = cloneDeep(scheduleDetails);
                newScheduleDetails[recIndex].volumeGradeDetails = [].concat(newScheduleDetails[recIndex].volumeGradeDetails, adhocVolume);
                setScheduleDetails(newScheduleDetails);
                // Update Original Schedule Details with new returned adhoc
                const newOriginalScheduleDetails = cloneDeep(originalScheduleDetails);
                newOriginalScheduleDetails[recIndex].volumeGradeDetails = [].concat(newOriginalScheduleDetails[recIndex].volumeGradeDetails, adhocVolume);
                setOriginalScheduleDetails(newOriginalScheduleDetails);
            } else {
                onCloseAdHocVolume(addAdhocVolumeSuccess, adhocVolume, addAdhocVolumeError, growl);
            }
        }
    }, [
        addAdhocVolumeSuccess,
        addAdhocVolumeRunning,
        addAdhocVolumeError,
        onSaveAddAdHocVolumeReset,
        adhocVolume,
        growl,
        adhocHeaderId,
        onCloseAdHocVolume,
        originalScheduleDetails,
        scheduleDetails
    ]);

    useEffect(() => {
        if (unpublishScheduleSuccess === null) return;
        else {
            growl.show({ severity: 'error', summary: 'Unpublish failed', detail: unpublishScheduleError });
            onUnpublishScheduleReset();
        }
    }, [unpublishScheduleSuccess, unpublishScheduleError, onUnpublishScheduleReset, growl]);

    const onRowEditSave = e => {
        const data = scheduleDetails.filter(s => s.planLocationHeaderId === e.data.planLocationHeaderId)[0];
        if (onRowEditorValidator(data)) {
            // Send data to api
            onUpdateLocationDetails(e.data.planLocationHeaderId, {
                updateType: 0,
                volumeGradeDetails: data.volumeGradeDetails
            });
            setClonedLocationRow({});
        }
    };

    const onRowEditCancel = e => {
        if (!e) return;
        callSetEditRows(e, true);
        const newScheduleDetails = [...scheduleDetails];
        newScheduleDetails[e.index] = Object.values(clonedLocationRow)[0];
        delete clonedLocationRow[e.data.locationNo];
        setScheduleDetails(newScheduleDetails);
    };

    // SCHEDULE SPECIFIC //
    const rowExpansionTemplate = e => {
        if (!e || e.length === 0) {
            return null;
        } else {
            const editRow = editRows.findIndex(m => m.planLocationHeaderId === e.planLocationHeaderId) > -1;
            const details = scheduleDetails.filter(m => m.planLocationHeaderId === e.planLocationHeaderId)[0].volumeGradeDetails;
            const cols = columns(true);
            return (
                <ScheduleExpanded
                    cols={cols}
                    edit={editRow}
                    details={details}
                    onEditTotal={(event, volTypeName) => editedTotalChanged(event, volTypeName, e.planLocationHeaderId)}
                    onAddVolume={event => onAddVolumeClick(event, e.planLocationHeaderId)}
                />
            );
        }
    };

    const onAddVolumeClick = (e, headerId) => {
        setAdhocHeaderId(headerId);
        onAddAdHocVolume(headerId, validateNewAdhocData, onSaveAddAdHocVolumeReset);
    };

    const validateNewAdhocData = data => {
        const newData = {
            volumeGradeDetails: [
                {
                    volumeTypeId: +data.fields.volumeTypeId,
                    du: +data.fields.du,
                    qty: 1
                }
            ]
        };
        const recIndex = scheduleDetails.findIndex(s => s.planLocationHeaderId === data.headerId);
        // Check if this store already has this adhoc type
        if (scheduleDetails[recIndex].volumeGradeDetails.findIndex(v => v.volumeTypeId === newData.volumeGradeDetails[0].volumeTypeId) === -1) {
            onSaveAddAdHocVolume(data.headerId, newData);
        } else {
            growl.show({ severity: 'error', summary: 'Error', detail: 'Volume type already exists for this store.' });
        }
    };

    const editedTotalChanged = (e, volTypeName, headerId) => {
        const recIndex = scheduleDetails.findIndex(m => m.planLocationHeaderId === headerId);
        const newScheduleDetails = cloneDeep(scheduleDetails);
        const target = +e.target.value;
        if (target > 99 || (newScheduleDetails[recIndex].StoreTrailerMax && target > newScheduleDetails[recIndex].StoreTrailerMax)) {
            growl.show({ severity: 'error', summary: 'Error', detail: 'Invalid total value entered.' });
            return;
        }
        // start volume change calculations

        const volumeGradeDetails = cloneDeep(originalScheduleDetails[recIndex].volumeGradeDetails.filter(v => v.volumeTypeName === volTypeName));
        const unSelectedVolumeGradeDetails = cloneDeep(scheduleDetails[recIndex].volumeGradeDetails.filter(v => v.volumeTypeName !== volTypeName));

        let gradeCumulative = 0;
        for (let i = 0; i < volumeGradeDetails.length; i++) {
            gradeCumulative += volumeGradeDetails[i].du;
        }

        // Set if we are adding dus or removing
        const orientation = gradeCumulative < target ? '+' : '-';

        // sort grades in priority descending order
        const sortedGrades = volumeGradeDetails.sort((a, b) => (a.volumeGradePriority < b.volumeGradePriority ? 1 : -1));
        if (orientation === '+') {
            const dusToAdd = target - gradeCumulative;
            const volumeGradeIndex = volumeGradeDetails.findIndex(g => g === sortedGrades[0]);
            volumeGradeDetails[volumeGradeIndex].du = volumeGradeDetails[volumeGradeIndex].du + dusToAdd;
            newScheduleDetails[recIndex].volumeGradeDetails = []
                .concat(unSelectedVolumeGradeDetails, volumeGradeDetails)
                .sort((a, b) => (a.volumeGradePriority < b.volumeGradePriority ? 1 : -1))
                .sort((a, b) => (a.volumeTypeId > b.volumeTypeId ? 1 : -1));
        } else {
            let dusToRemove = gradeCumulative - target;
            sortedGrades.forEach(g => {
                if (dusToRemove > 0) {
                    if (g.du <= dusToRemove) {
                        dusToRemove = dusToRemove - g.du;
                        g.du = 0;
                    } else {
                        g.du = Math.round((g.du - dusToRemove + Number.EPSILON) * 100) / 100;
                        dusToRemove = 0;
                    }
                }
            });
            newScheduleDetails[recIndex].volumeGradeDetails = []
                .concat(unSelectedVolumeGradeDetails, sortedGrades)
                .sort((a, b) => (a.volumeGradePriority < b.volumeGradePriority ? 1 : -1))
                .sort((a, b) => (a.volumeTypeId > b.volumeTypeId ? 1 : -1));
        }

        setScheduleDetails(newScheduleDetails);
        // End Volume change calculations
    };

    // SCHEDULE SPECIFIC //
    const columns = detail => {
        if (cols !== null && cols.columns)
            return cols.columns
                .filter(m => (detail ? m.detail : !m.detail))
                .map((col, i) => {
                    let style = null;
                    if (col.style && col.style.length > 0) style = col.style.map(m => m)[0];

                    let editorFunction = null;
                    // eslint-disable-next-line no-eval
                    if (col.editor) editorFunction = props => eval(col.editor)(props, col.field);

                    let filterFunction = null;
                    // eslint-disable-next-line no-eval
                    if (col.filterElement) filterFunction = eval(modelFilter)(props, col.field);

                    return (
                        <Column
                            key={i}
                            header={col.header}
                            field={col.field}
                            className={col.className}
                            style={style}
                            filter={true}
                            filterMatchMode="contains"
                            filterElement={filterFunction}
                            editor={editorFunction}
                        />
                    );
                });
    };

    const showMenu = e => cm.current.show(e.originalEvent);
    const hideMenu = e => cm.current.hide(e.originalEvent);

    // SCHEDULE SPECIFIC //
    const onRowState = e => {
        if (!e || (selectedRows.length === 0 && expandedRows.length === 0 && e.active && editRows.length === 0)) return;
        const selectedRow = selectedRows.findIndex(m => m.planLocationHeaderId === e.planLocationHeaderId) > -1;
        const editRow = editRows.findIndex(m => m.planLocationHeaderId === e.planLocationHeaderId) > -1;
        const expandedRow = editRow || expandedRows.findIndex(m => m.planLocationHeaderId === e.planLocationHeaderId) > -1;
        return {
            [classes.inactiveItem]: !e.active,
            [classes.selectedItem]: selectedRow || expandedRow,
            [classes.expandedItem]: expandedRow,
            [classes.editItem]: editRow
        };
    };

    const filterAction = (value, field) => {
        let obj;
        if (value === '') {
            // eslint-disable-next-line no-unused-vars
            const { [field]: _, ...remaining } = filterData;
            delete filterData[field];
            obj = remaining;
        } else {
            obj = updateObject(filterData, {
                [field]: value
            });
        }
        setFilterData(obj);
    };

    const modelFilter = (props, field) => {
        if (field === '') return null;
        else return <InputText style={{ width: '100%' }} className="ui-column-filter" onChange={e => filterAction(e.currentTarget.value, field)} />;
    };

    const handleInactive = () => {
        setIncludeInactive(!includeInactive);
    };

    const pageContent = (
        <Fragment>
            <DataTable
                scrollable={true}
                scrollHeight={scrollHeight}
                className={[classes.tableFontSize, classes.scheduleStyle, 'scheduleTable'].join(' ')}
                value={scheduleDetails}
                selectionMode="multiple"
                onContextMenu={e => showMenu(e)}
                onContextMenuSelectionChange={e => handleChange(e)}
                selection={selectedRows}
                onSelectionChange={e => handleChange(e)}
                editable={true}
                expandedRows={expandedRows}
                rowExpansionTemplate={e => rowExpansionTemplate(e)}
                onRowToggle={e => setExpandedRows(e.data)}
                footer={displaySelection(selectedRows)}
                ref={el => (datatable2 = el)}
                editMode="row"
                rowEditorValidator={onRowEditorValidator}
                onRowEditInit={onRowEditInit}
                onRowEditSave={onRowEditSave}
                onRowEditCancel={onRowEditCancel}
                rowClassName={e => onRowState(e)}
                resizableColumns={true}
                columnResizeMode="fit"
            >
                <Column expander={true} style={{ width: '2em' }} />
                <Column rowEditor={true} style={{ width: '55px', textAlign: 'center' }}></Column>
                {columns()}
            </DataTable>
        </Fragment>
    );

    // eslint-disable-next-line react/no-multi-comp
    const pleaseWait = () => {
        let msg = '';
        let loading = false;
        let color = 'light';
        switch (true) {
            case deleteStoreSchedulesRunning:
                msg = `${contextAction === 'Delete' ? 'Deleting' : 'Restoring'} Store Schedules`;
                loading = true;
                break;
            case editStoreSchedulesRunning:
                msg = `${contextAction === 'Edit' ? 'Editing' : ''} Store Schedules`;
                loading = true;
                break;
            case downloadingSchedule:
                msg = 'Downloading Schedule';
                loading = true;
                break;
            case loadingSchedule:
                msg = 'Loading Schedule';
                loading = true;
                break;
            default:
                color = 'dark'; // not implemented
                break;
        }
        return (
            <ProgressIndicator page loading={loading}>
                <ProgressSpinner color={color} />
                {msg}
            </ProgressIndicator>
        );
    };

    const deleteRestoreModal = (
        <DeleteRestore
            handleClose={() => setShowDeleteScheduleModal(!showDeleteScheduleModal)}
            open={showDeleteScheduleModal}
            contextAction={contextAction}
            selectedRows={selectedRows}
            confirmAction={() => setDeleteAction(true)}
        />
    );

    const editSchedulesModal = (
        <EditSchedule
            handleClose={() => setShowEditScheduleModal(!showEditScheduleModal)}
            open={showEditScheduleModal}
            contextAction={contextAction}
            selectedRows={selectedRows}
            confirmAction={() => setEditAction(true)}
        />
    );

    const handleUnpublishPlan = () => {
        setShowUnpublishModal(!showUnpublishModal);
        onUnpublishSchedule(
            plan.id,
            dcNo,
            selectedRows.map(r => r.planLocationHeaderId)
        );
    };

    const unPublishModalMessage = (
        <p>
            This process will set all items on the selected rows as unpublished in Plex. <br />
            <b>
                THIS DOES NOT AFFECT THE STATE OF THIS INFORMATION ON EXTERNAL SYSTEMS <br />
                THIS IS A PLEX PROCESS ONLY.{' '}
            </b>
            <br />
            Please click <b>'Unpublish'</b> again to confirm unpublishing.
        </p>
    );

    const unPublishModal = (
        <ConfirmationDialog
            fullScreen
            handleClose={() => setShowUnpublishModal(!showUnpublishModal)}
            open={showUnpublishModal}
            heading="Unpublish Schedules"
            message={unPublishModalMessage}
            confirmAction={handleUnpublishPlan}
            confirmButton="Unpublish"
        />
    );

    return (
        <Card>
            <ContextMenu model={menu} ref={cm} />
            <GridWrapper>
                <GridItem size="1/1" className="ln-u-text-align-left ln-u-flush">
                    <h3>
                        {scheduleHeading} Store Schedule for {plan === null ? null : moment(plan.date).format('DD/MM/YYYY')}
                    </h3>
                </GridItem>
                <GridItem size="1/2" className="ln-u-text-align-left ln-u-padding-bottom ln-u-padding-top toolbarStore">
                    <Button
                        onClick={() => onDownloadSchedule(pid, dcNum)}
                        icon={`pi ${downloadingSchedule ? 'pi-spin pi-spinner' : 'pi-cloud-download'}`}
                        className={downloadingSchedule ? 'buttonActive' : ''}
                        tooltip="Download Schedule"
                        tooltipOptions={{ position: 'top' }}
                    />
                    <Button style={{ marginLeft: '1rem' }} onClick={reset} icon="pi pi-refresh" tooltip="Reset filters" tooltipOptions={{ position: 'top' }} />
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => onHoldOverSchedule(selectedRows, 'store')}
                        icon="pi pi-window-maximize"
                        disabled={!selectedRows || selectedRows.length === 0}
                        tooltip="Hold Over"
                        tooltipOptions={{ position: 'top' }}
                    />
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={handleInactive}
                        icon="pi pi-sign-in"
                        className={includeInactive ? 'buttonActive' : ''}
                        tooltip="Toggle Inactive Schedules"
                        tooltipOptions={{ position: 'top' }}
                    >
                        {inactiveCounter()}
                    </Button>
                    <Button
                        style={{ marginLeft: '1rem' }}
                        type="button"
                        onClick={() => setShowUnpublishModal(!showUnpublishModal)}
                        disabled={!selectedRows || selectedRows.length === 0}
                        icon={`pi ${unpublishScheduleRunning ? 'pi-spin pi-spinner' : 'pi-cloud-upload'}`}
                        className={unpublishScheduleRunning ? 'buttonActive' : ''}
                        tooltip="Unpublish Selected"
                        tooltipOptions={{ position: 'top' }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="22.5" width="22.5" className="iconOverlay">
                            <g>
                                <line x1="0" y1="0" x2="22.5" y2="22.5" />
                                <line x1="0" y1="22.5" x2="22.5" y2="0" />
                            </g>
                        </svg>
                    </Button>
                    <Button
                        style={{ marginLeft: '1rem' }}
                        onClick={() => setFixScroll(!fixScroll)}
                        icon={`pi ${fixScroll ? 'pi-caret-down' : 'pi-caret-up'}`}
                        className={fixScroll ? 'buttonActive' : ''}
                        tooltip={fixScroll ? 'Release Scroll' : 'Fix Scroll'}
                        tooltipOptions={{ position: 'top' }}
                    />
                </GridItem>
                <GridItem size="1/1">
                    {loadingSchedule ? pleaseWait() : pageContent}
                    {pleaseWait()}
                </GridItem>
            </GridWrapper>
            {deleteRestoreModal}
            {editSchedulesModal}
            {unPublishModal}
        </Card>
    );
};

Schedule.propTypes = {
    activeSchedule: PropTypes.string,
    addAdhocVolumeError: PropTypes.string,
    addAdhocVolumeRunning: PropTypes.bool,
    addAdhocVolumeSuccess: PropTypes.bool,
    adhocVolume: PropTypes.array,
    dcNo: PropTypes.string,
    deleteStoreSchedulesRunning: PropTypes.bool,
    deleteStoreSchedulesSuccess: PropTypes.bool,
    downloadData: PropTypes.any,
    downloadFilename: PropTypes.string,
    downloadSuccess: PropTypes.bool,
    downloadingSchedule: PropTypes.bool,
    editStoreSchedulesRunning: PropTypes.bool,
    editStoreSchedulesSuccess: PropTypes.bool,
    field: PropTypes.string,
    growl: PropTypes.object,
    holdOverStoreSchedulesItems: PropTypes.array,
    loadingSchedule: PropTypes.bool,
    match: PropTypes.object,
    onAddAdHocVolume: PropTypes.func,
    onCloseAdHocVolume: PropTypes.func,
    onDeleteSchedules: PropTypes.func,
    onDeleteSchedulesReset: PropTypes.func,
    onDownloadSchedule: PropTypes.func,
    onDownloadScheduleReset: PropTypes.func,
    onEditSchedules: PropTypes.func,
    onEditSchedulesReset: PropTypes.func,
    onGetSchedule: PropTypes.func,
    onHoldOverReset: PropTypes.func,
    onHoldOverSchedule: PropTypes.func,
    onSaveAddAdHocVolume: PropTypes.func,
    onSaveAddAdHocVolumeReset: PropTypes.func,
    onUnpublishSchedule: PropTypes.func,
    onUnpublishScheduleReset: PropTypes.func,
    onUpdateLocationDetails: PropTypes.func,
    plan: PropTypes.object,
    planId: PropTypes.number,
    rowData: PropTypes.array,
    rowIndex: PropTypes.number,
    schedule: PropTypes.object,
    unpublishScheduleError: PropTypes.string,
    unpublishScheduleRunning: PropTypes.bool,
    unpublishScheduleSuccess: PropTypes.bool,
    updateLocationDetailsError: PropTypes.string,
    updateLocationDetailsRunning: PropTypes.bool,
    updateLocationDetailsSuccess: PropTypes.bool,
    value: PropTypes.any
};

const mapStateToProps = state => {
    return {
        activeSchedule: state.schedule.activeSchedule,
        addAdhocVolumeSuccess: state.schedule.addAdhocVolumeSuccess,
        addAdhocVolumeError: state.schedule.addAdhocVolumeError,
        addAdhocVolumeRunning: state.schedule.addAdhocVolumeRunning,
        adhocVolume: state.schedule.adhocVolume,
        dcNo: state.schedule.dcNo,
        downloadFilename: state.schedule.downloadFilename,
        downloadingSchedule: state.schedule.downloadingSchedule,
        downloadSuccess: state.schedule.downloadSuccess,
        downloadData: state.schedule.downloadData,
        holdOverStoreSchedulesItems: state.schedule.holdOverStoreSchedulesItems,
        planId: state.schedule.planId,
        schedule: state.schedule.schedule,
        loadingSchedule: state.schedule.loadingSchedule,
        plan: state.plan.plan,
        deleteStoreSchedulesRunning: state.schedule.deleteStoreSchedulesRunning,
        deleteStoreSchedulesSuccess: state.schedule.deleteStoreSchedulesSuccess,
        editStoreSchedulesRunning: state.schedule.editStoreSchedulesRunning,
        editStoreSchedulesSuccess: state.schedule.editStoreSchedulesSuccess,
        updateLocationDetailsRunning: state.locations.updateLocationDetailsRunning,
        updateLocationDetailsSuccess: state.locations.updateLocationDetailsSuccess,
        updateLocationDetailsError: state.locations.updateLocationDetailsError,
        unpublishScheduleError: state.plan.unpublishScheduleError,
        unpublishScheduleRunning: state.plan.unpublishScheduleRunning,
        unpublishScheduleSuccess: state.plan.unpublishScheduleSuccess
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetSchedule: (planId, dcNo) => dispatch(actions.getSchedule(planId, dcNo)),
        onDownloadSchedule: (planId, dcNo) => dispatch(actions.downloadSchedule(planId, dcNo)),
        onDownloadScheduleReset: () => dispatch(actions.downloadScheduleReset()),
        onDeleteSchedules: (data, action) => dispatch(actions.deleteStoreSchedules(data, action)),
        onDeleteSchedulesReset: () => dispatch(actions.deleteStoreSchedulesReset()),
        onEditSchedules: data => dispatch(actions.editStoreSchedules(data)),
        onEditSchedulesReset: () => dispatch(actions.editStoreSchedulesReset()),
        onUpdateLocationDetails: (headerId, data) => dispatch(actions.updateLocationDetails(headerId, data)),
        onUpdateLocationDetailsReset: () => dispatch(actions.updateLocationDetailsReset()),
        onSaveAddAdHocVolume: (headerId, data) => dispatch(actions.addAdhocVolume(headerId, data)),
        onSaveAddAdHocVolumeReset: () => dispatch(actions.addAdhocVolumeReset()),
        onUnpublishSchedule: (planId, dcNo, headerIds) => dispatch(actions.unpublishSchedule(planId, dcNo, headerIds)),
        onUnpublishScheduleReset: () => dispatch(actions.unpublishScheduleReset())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Schedule, axios));
