export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const minsToHHMM = minutes => {
    if (isNaN(+minutes)) return null;

    const sign = minutes < 0 ? '-' : '';
    const min = Math.floor(Math.abs(minutes / 60));
    const sec = Math.floor(Math.abs(minutes / 60) * 60) % 60;
    return `${sign}${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;
};

export const HHMMTomins = time => {
    const hms = time.split(':');
    if (hms.length !== 2) return null;
    return +hms[0] * 60 + +hms[1];
};

export const shortDays = () => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// *** Validation functions ***

// validate a time - looks for hh:mm or [h]h:mm am/pm (if twentyFourHourFormat = false), returns null if not valid
export const isValidTime = (timeInput, twentyFourHourFormat = true, type) => {
    let regExp;
    if (type === 'windowValid') {
        regExp = /([0-1][0-9]:[0-5][0-9]-[0-1][0-9]:[0-5][0-9])|(No Delivery)/gi;
    } else {
        if (timeInput === '') return true;
        const twentyFour = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
        const twelve = /^(0?[1-9]|1[012])(:[0-5]\d)\s?(AM|PM)$/gi;
        regExp = twentyFourHourFormat ? twentyFour : twelve;
    }

    return regExp.test(timeInput);
};

export const isValidDay = (dayInput, format = '') => {
    const daysShort = shortDays().map(d => d.toUpperCase());
    const daysLong = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    let days;

    switch (format.toLowerCase()) {
        case 'short':
            days = daysShort;
            break;
        case 'long':
            days = daysLong;
            break;
        default:
            days = daysShort.concat(daysLong);
    }

    const valid = days.includes(dayInput.toUpperCase());

    return valid;
};

export const isValidNumber = (numberInput, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY, emptyCheck = false, integerCheck = false) => {
    if (emptyCheck) if ((numberInput || '') === '') return false;

    const num = +numberInput;

    if (isNaN(num)) return false;
    if (num < min || num > max) return false;
    if (integerCheck) return num === Math.round(num);

    return true;
};

// only allow a-z A-Z 0-9 _ {space} ( ) / -
export const isValidText = textInput => {
    const isValid = /^[\w ()-/]*$/.test(textInput);
    return isValid;
};

// as above but returns the invalid character
export const getInvalidText = textInput => {
    const invalidChar = textInput.match(/([^\w ()-/])/);
    return invalidChar ? invalidChar[0] : null;
};

// ****************************

export const sortByKey = (array, key) =>
    array.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });

// usage: async () => { await sleep(1000) }
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// return a graduated colour based in the percent number given
// based on this code: https://gist.github.com/mlocati/7210513
export const colourScale = pc => {
    let r, g, b;

    if (pc >= 0 && pc <= 33 + 1 / 3) {
        r = 255;
        g = Math.round(7.65 * pc);
        b = 0;
    } else if (pc > 33 + 1 / 3 && pc <= 66 + 2 / 3) {
        r = Math.round(-7.65 * pc + 510);
        g = 255;
        b = 0;
    } else if (pc > 66 + 2 / 3) {
        r = 0;
        g = 128;
        b = Math.round(7.65 * pc) - 510;
    }

    const h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
};
