// TODO: Complete and tidy
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { GridItem, GridWrapper } from '@jsluna/grid';
import { IconButton, OutlinedButton } from '@jsluna/button';
import { SelectField } from '@jsluna/form';

import { Messages } from '@jsluna/icons';

const Header = ({ groups, handleLimitSelect, handlePlanDaySelect, handleSend, limit, planDays }) => {
    const [selectedGroup, setSelectedGroup] = useState(null);
    const history = useHistory();

    return (
        <GridWrapper>
            <GridItem size="1/6">
                <SelectField
                    name="di-email-select"
                    label="Email to..."
                    options={groups}
                    onChange={e => setSelectedGroup(e.target.value)}
                    hideLabel
                    placeholder="Email to..."
                />
            </GridItem>
            <GridItem size="1/6">
                <IconButton variant="outlined" label="Send" onClick={() => handleSend(selectedGroup)}>
                    <Messages />
                </IconButton>
            </GridItem>
            <GridItem size="2/3">
                <GridWrapper>
                    <GridItem size="1/2">
                        <SelectField
                            name="di-csv-day-select"
                            label="Select Plan Day"
                            options={planDays || []}
                            onChange={e => handlePlanDaySelect(e.target.value)}
                            hideLabel={true}
                            placeholder="Select Plan Day"
                        />
                    </GridItem>
                    <GridItem size="1/3">
                        <SelectField
                            name="di-output-limit-select"
                            label="Limit Output"
                            options={limit || []}
                            onChange={e => handleLimitSelect(e.target.value)}
                            hideLabel={true}
                            placeholder="Limit Output content"
                        />
                    </GridItem>
                    <GridItem size="1/6">
                        <OutlinedButton
                            variant="outlined"
                            label="Back"
                            onClick={() => {
                                history.push('/DI');
                            }}
                        >
                            Back
                        </OutlinedButton>
                    </GridItem>
                </GridWrapper>
            </GridItem>
        </GridWrapper>
    );
};

Header.propTypes = {
    groups: PropTypes.array,
    handleLimitSelect: PropTypes.func.isRequired,
    handlePlanDaySelect: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    limit: PropTypes.array,
    planDays: PropTypes.array
};

export default Header;
