import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CheckboxField, SelectField, TextInputField } from '@jsluna/form';
import { AutocompleteField } from '@jsluna/autocomplete';

import SingleDatePickerWithLabel from './SingleDatePickerWithLabel/SingleDatePickerWithLabel';

import moment from 'moment';

const Input = props => {
    const {
        name,
        label,
        hideLabel,
        options,
        listType,
        className,
        style,
        fieldType,
        error,
        onChange,
        onValidate,
        disabled,
        value,
        placeholder,
        id,
        isRequired
    } = props;
    let inputElement = null;
    const [validationError, setValidationError] = useState(null);
    const [dateFocused, setDateFocused] = useState(false);

    const validateField = (e, name) => {
        let valid = true;
        let msg = '';
        let value;
        let field = name;
        let updateForced = false;
        // Get field value depending on type
        switch (fieldType) {
            case 'date':
                if (!e) {
                    updateForced = true;
                    value = null;
                } else value = moment(e);
                break;
            case 'dateTime':
                value = moment(e);
                break;
            case 'switch':
            case 'check':
                value = e.target.checked;
                field = e.target.value;
                break;
            case 'autocomplete':
                value = e === null ? null : e.value;
                updateForced = true;
                break;
            default:
                value = e.target.value;
                break;
        }

        // check if value is required and that its not null
        if ((value === null || value === '') && isRequired) {
            valid = false;
            updateForced = true;
            msg = 'Required Field';
        } else {
            // if not required and has data, validate the data
            switch (fieldType) {
                case 'time':
                    updateForced = true;
                    if (value !== null && value !== '') {
                        valid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
                        if (!valid) {
                            msg = 'Invalid time';
                        }
                    }
                    break;
                case 'date':
                case 'dateTime':
                    if (value !== '' && !value._isValid) {
                        valid = false;
                        msg = 'Invalid Date';
                        value = null;
                    }
                    if (value === '') {
                        value = null;
                        valid = false;
                    }
                    break;
                case 'autocomplete':
                    valid = value !== null;
                    break;
                case 'int':
                    valid = value - Math.floor(value) === 0;
                    if (!valid) {
                        msg = 'Not Allowed';
                        updateForced = true;
                    }
                    break;
                default:
                    valid = true;
                    break;
            }
        }
        if (valid) {
            setValidationError(null);
        } else {
            setValidationError(msg);
        }
        if (valid || updateForced) {
            onChange(field, value, e);
        }
        if (onValidate) onValidate(name, valid);
    };

    switch (fieldType) {
        case 'time':
        case 'string':
            inputElement = (
                <TextInputField
                    label={label}
                    hideLabel={hideLabel}
                    onChange={e => validateField(e, name)}
                    disabled={disabled}
                    name={name}
                    className={className}
                    style={style}
                    options={options}
                    error={validationError === null ? error : validationError}
                    placeholder={placeholder}
                    value={value || ''}
                    type="text"
                />
            );
            break;
        case 'int':
        case 'integer':
        case 'number':
        case 'decimal':
            inputElement = (
                <TextInputField
                    label={label}
                    hideLabel={hideLabel}
                    onChange={e => validateField(e, name)}
                    disabled={disabled}
                    name={name}
                    className={className}
                    style={style}
                    options={options}
                    error={validationError === null ? error : validationError}
                    placeholder={placeholder}
                    value={value || ''}
                    type="number"
                />
            );
            break;
        case 'check':
            inputElement = (
                <CheckboxField
                    label={label}
                    hideLabel={hideLabel}
                    onChange={e => validateField(e, name)}
                    disabled={disabled}
                    name={name}
                    className={className}
                    style={style}
                    listType={listType}
                    options={options}
                />
            );
            break;
        case 'switch':
            break;
        case 'select':
            inputElement = (
                <SelectField
                    name={name}
                    className={className}
                    label={label}
                    hideLabel={hideLabel}
                    style={style}
                    error={validationError === null ? error : validationError}
                    options={options}
                    onChange={e => validateField(e, name)}
                    disabled={disabled}
                    defaultValue={value}
                />
            );
            break;
        case 'autocomplete':
            inputElement = (
                <AutocompleteField
                    name={name}
                    className={className}
                    label={label}
                    hideLabel={hideLabel}
                    style={style}
                    error={error}
                    placeholder={placeholder}
                    options={options}
                    onSelect={e => validateField(e, name)}
                    onClear={() => validateField(null, name)}
                    disabled={disabled}
                    selectedItem={
                        value === null
                            ? null
                            : options.filter(o => +o.value === +value).length > 0
                            ? { value: value, label: options.filter(o => +o.value === +value)[0].label }
                            : null
                    }
                />
            );
            break;
        case 'date':
            inputElement = (
                <SingleDatePickerWithLabel
                    // block={true}
                    id={id}
                    dateVal={value}
                    date={value}
                    label={hideLabel === true ? label : null}
                    name={label}
                    error={validationError === null ? error : validationError}
                    // hideLabel={hideLabel}
                    displayFormat="DD/MM/YYYY"
                    onDateChange={e => validateField(e, name)}
                    focused={dateFocused}
                    onFocusChange={() => setDateFocused(!dateFocused)}
                    isOutsideRange={() => false}
                    className={className}
                />
            );
            break;
        case 'datetime':
            inputElement = (
                <SingleDatePickerWithLabel
                    // block={true}
                    id={id}
                    dateVal={value}
                    label={label}
                    error={validationError === null ? error : validationError}
                    hideLabel={hideLabel}
                    displayFormat="DD/MM/YYYY HH:mm"
                    onChange={e => validateField(e, name)}
                    focused={dateFocused}
                    onFocusChange={() => setDateFocused(!dateFocused)}
                    isOutsideRange={() => false}
                    className={className}
                />
            );
            break;
        default:
            break;
    }
    // if (onValidate) onValidate(name, false);
    return inputElement;
};

Input.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fieldType: PropTypes.string,
    focused: PropTypes.bool,
    hideLabel: PropTypes.bool,
    id: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    listType: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onFocused: PropTypes.func,
    onValidate: PropTypes.func.isRequired,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.any
};

export default Input;
