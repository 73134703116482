import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    fileName: null,
    fileType: null,
    loadingOutstandingPlanExtras: false,
    outstandingPlanExtras: [],
    plansForPlanExtras: [],
    plansForPreDefined: [],
    preDefinedRouteError: null,
    preDefinedRouteSuccess: null,
    preDefinedRouteUpdating: null,
    preDefinedRoutesDeleting: null,
    preDefinedRoutesDeleteSuccess: null,
    downloadPreDefinedRoutesFail: null,
    downloadPreDefinedRoutesSuccess: null,
    downloadPreDefinedRoutesLoading: null,
    downloadPlanExtrasFail: null,
    downloadPlanExtrasSuccess: null,
    downloadPlanExtrasLoading: null,
    planPlanExtrasFail: null,
    planPlanExtrasSuccess: null,
    planPlanExtrasLoading: null,
    planPreDefinedFail: null,
    planPreDefinedSuccess: null,
    planPreDefinedLoading: null
};

const getOutstandingPlanExtrasStart = state => updateObject(state, { loadingOutstandingPlanExtras: true });
const getOutstandingPlanExtrasSuccess = (state, action) =>
    updateObject(state, { loadingOutstandingPlanExtras: false, outstandingPlanExtras: action.outstandingPlanExtras });
const getOutstandingPlanExtrasFail = (state, action) =>
    updateObject(state, {
        loadingOutstandingPlanExtras: false,
        outstandingPlanExtrasError: true,
        outstandingPlanExtrasErrorObject: action.outstandingPlanExtrasError
    });

const getPlansForPlanExtrasStart = state => updateObject(state, { loadingPlansForPlanExtras: true });
const getPlansForPlanExtrasSuccess = (state, action) =>
    updateObject(state, { loadingPlansForPlanExtras: false, plansForPlanExtrasSuccess: true, plansForPlanExtras: action.plansForPlanExtras });
const getPlansForPlanExtrasFail = (state, action) =>
    updateObject(state, {
        loadingPlansForPlanExtras: false,
        plansForPlanExtrasFail: true,
        plansForPlanExtrasError: action.plansForPlanExtrasError
    });
const getPlansForPlanExtrasReset = state =>
    updateObject(state, {
        loadingPlansForPlanExtras: false,
        plansForPlanExtrasFail: null,
        plansForPlanExtrasSuccess: null,
        plansForPlanExtrasError: null
    });

const getPlansForPreDefinedStart = state => updateObject(state, { loadingPlansForPreDefined: true });
const getPlansForPreDefinedSuccess = (state, action) =>
    updateObject(state, { loadingPlansForPreDefined: false, plansForPreDefinedSuccess: true, plansForPreDefined: action.plansForPreDefined });
const getPlansForPreDefinedFail = (state, action) =>
    updateObject(state, {
        loadingPlansForPreDefined: false,
        plansForPreDefinedFail: true,
        plansForPreDefinedError: action.plansForPreDefinedError
    });
const getPlansForPreDefinedReset = state =>
    updateObject(state, {
        loadingPlansForPreDefined: false,
        plansForPreDefinedFail: null,
        plansForPreDefinedSuccess: null,
        plansForPreDefinedError: null
    });

const getProcessedRequestsCountStart = state => updateObject(state, { loadingProcessedRequestsCount: true });
const getProcessedRequestsCountSuccess = (state, action) =>
    updateObject(state, { loadingProcessedRequestsCount: false, processedRequestsCount: action.processedRequestsCount });
const getProcessedRequestsCountFail = (state, action) =>
    updateObject(state, {
        loadingProcessedRequestsCount: false,
        processedRequestsCountsError: true,
        processedRequestsCountsErrorObject: action.processedRequestsCountsError
    });

const getProcessedRequestsStart = state => updateObject(state, { loadingProcessedRequests: true });
const getProcessedRequestsSuccess = (state, action) => updateObject(state, { loadingProcessedRequests: false, processedRequests: action.processedRequests });
const getProcessedRequestsFail = (state, action) =>
    updateObject(state, {
        loadingProcessedRequests: false,
        processedRequestsError: true,
        processedRequestsErrorObject: action.processedRequestsError
    });

const getAdHocDeliveryTypesStart = state => updateObject(state, { loadingAdHocDeliveryTypes: true });
const getAdHocDeliveryTypesSuccess = (state, action) =>
    updateObject(state, { loadingAdHocDeliveryTypes: false, adHocDeliveryTypes: action.adHocDeliveryTypes });
const getAdHocDeliveryTypesFail = (state, action) =>
    updateObject(state, {
        loadingAdHocDeliveryTypes: false,
        adHocDeliveryTypesError: true,
        adHocDeliveryTypesErrorObject: action.adHocDeliveryTypesError
    });

const getPreDefinedRoutesFieldOptionsStart = state => updateObject(state, { loadingPreDefinedRoutesFieldOptions: true });
const getPreDefinedRoutesFieldOptionsSuccess = (state, action) =>
    updateObject(state, { loadingPreDefinedFieldOptions: false, preDefinedRoutesFieldOptions: action.preDefinedRoutesFieldOptions });
const getPreDefinedRoutesFieldOptionsFail = (state, action) =>
    updateObject(state, {
        loadingPreDefinedRoutesFieldOptions: false,
        preDefinedRoutesFieldOptionsError: true,
        preDefinedRoutesFieldOptionsErrorObject: action.preDefinedRoutesFieldOptionsError
    });

const getPreDefinedRoutesStart = state => updateObject(state, { loadingPreDefinedRoutes: true });
const getPreDefinedRoutesSuccess = (state, action) => updateObject(state, { loadingPreDefinedRoutes: false, preDefinedRoutes: action.preDefinedRoutes });
const getPreDefinedRoutesFail = (state, action) =>
    updateObject(state, {
        loadingPreDefinedRoutes: false,
        preDefinedRoutesError: true,
        preDefinedRoutesErrorObject: action.preDefinedRoutesError
    });

const downloadPreDefinedRoutesStart = state => updateObject(state, { downloadPreDefinedRoutesLoading: true });
const downloadPreDefinedRoutesSuccess = (state, action) =>
    updateObject(state, {
        downloadPreDefinedRoutesSuccess: true,
        downloadPreDefinedRoutesFail: false,
        downloadPreDefinedRoutesLoading: false,
        file: action.file,
        fileName: action.fileName,
        fileType: action.fileType
    });
const downloadPreDefinedRoutesFail = (state, action) =>
    updateObject(state, {
        loadingPreDefinedRoutes: false,
        downloadPreDefinedRoutesFail: true,
        preDefinedRoutesErrorObject: action.downloadPreDefinedRoutesError,
        error: action.error
    });

const downloadPreDefinedRoutesReset = state =>
    updateObject(state, {
        loadingPreDefinedRoutes: null,
        downloadPreDefinedRoutesFail: null,
        downloadPreDefinedRoutesSuccess: null,
        preDefinedRoutesErrorObject: null,
        error: null
    });

const downloadPlanExtrasStart = state =>
    updateObject(state, {
        downloadPlanExtrasLoading: true,
        downloadPlanExtrasFail: null,
        downloadPlanExtrasSuccess: null,
        error: null
    });
const downloadPlanExtrasSuccess = (state, action) =>
    updateObject(state, {
        downloadPlanExtrasFail: false,
        downloadPlanExtrasSuccess: true,
        downloadPlanExtrasLoading: false,
        file: action.file,
        fileName: action.fileName,
        fileType: action.fileType
    });
const downloadPlanExtrasFail = (state, action) =>
    updateObject(state, {
        downloadPlanExtrasFail: true,
        downloadPlanExtrasSuccess: false,
        loadingPlanExtras: false,
        planExtrasErrorObject: action.downloadPlanExtrasError,
        error: action.error
    });

const downloadPlanExtrasReset = state =>
    updateObject(state, {
        downloadPlanExtrasFail: null,
        downloadPlanExtrasSuccess: null,
        loadingPlanExtras: null,
        planExtrasErrorObject: null,
        error: null
    });

const planPlanExtrasStart = state =>
    updateObject(state, {
        planPlanExtrasLoading: true,
        planPlanExtrasFail: null,
        planPlanExtrasSuccess: null,
        error: null
    });
const planPlanExtrasSuccess = state =>
    updateObject(state, {
        planPlanExtrasFail: false,
        planPlanExtrasSuccess: true,
        planPlanExtrasLoading: false
    });
const planPlanExtrasFail = (state, action) =>
    updateObject(state, {
        planPlanExtrasFail: true,
        planPlanExtrasSuccess: false,
        loadingPlanExtras: false,
        planExtrasErrorObject: action.planPlanExtrasError,
        error: action.error
    });

const planPlanExtrasReset = state =>
    updateObject(state, {
        planPlanExtrasFail: null,
        planPlanExtrasSuccess: null,
        loadingPlanExtras: null,
        planExtrasErrorObject: null,
        error: null
    });

const planPreDefinedStart = state =>
    updateObject(state, {
        planPreDefinedLoading: true,
        planPreDefinedFail: null,
        planPreDefinedSuccess: null,
        error: null
    });
const planPreDefinedSuccess = state =>
    updateObject(state, {
        planPreDefinedFail: false,
        planPreDefinedSuccess: true,
        planPreDefinedLoading: false
    });
const planPreDefinedFail = (state, action) =>
    updateObject(state, {
        planPreDefinedFail: true,
        planPreDefinedSuccess: false,
        loadingPreDefined: false,
        preDefinedErrorObject: action.planPreDefinedError,
        error: action.error
    });

const planPreDefinedReset = state =>
    updateObject(state, {
        planPreDefinedFail: null,
        planPreDefinedSuccess: null,
        loadingPreDefined: null,
        preDefinedErrorObject: null,
        error: null
    });

const updatePreDefinedRouteReset = (state, action) => {
    return updateObject(state, {
        preDefinedRoute: action.preDefinedRoute === undefined ? state.preDefinedRoute : action.preDefinedRoute,
        preDefinedRouteError: action.preDefinedRouteError === undefined ? state.preDefinedRouteError : action.preDefinedRouteError,
        preDefinedRouteSuccess: action.preDefinedRouteSuccess === null ? state.preDefinedRouteSuccess : action.preDefinedRouteSuccess,
        preDefinedRouteUpdating: action.preDefinedRouteUpdating === null ? state.preDefinedRouteUpdating : action.preDefinedRouteUpdating
    });
};

const deletePreDefinedRoutesStart = state => updateObject(state, { preDefinedRoutesDeleting: true });
const deletePreDefinedRoutesSuccess = (state, action) =>
    updateObject(state, { preDefinedRoutesDeleting: false, preDefinedRoutesDeleteSuccess: true, preDefinedRoutes: action.preDefinedRoutes });
const deletePreDefinedRoutesFail = (state, action) =>
    updateObject(state, {
        preDefinedRoutesDeleting: false,
        preDefinedRoutesDeleteError: true,
        preDefinedRoutesDeleteErrorObject: action.preDefinedRoutesDeleteError
    });

const deletePreDefinedRoutesReset = (state, action) => {
    return updateObject(state, {
        preDefinedRoutesDeleteError: action.preDefinedRouteError === undefined ? state.preDefinedRouteError : action.preDefinedRouteError,
        preDefinedRoutesDeleteSuccess: action.preDefinedRouteSuccess === null ? state.preDefinedRouteSuccess : action.preDefinedRouteSuccess,
        preDefinedRoutesDeleting: action.preDefinedRouteUpdating === null ? state.preDefinedRouteUpdating : action.preDefinedRouteUpdating
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_OUTSTANDING_PLAN_EXTRAS_START:
            return getOutstandingPlanExtrasStart(state);
        case actionTypes.GET_OUTSTANDING_PLAN_EXTRAS_SUCCESS:
            return getOutstandingPlanExtrasSuccess(state, action);
        case actionTypes.GET_OUTSTANDING_PLAN_EXTRAS_FAIL:
            return getOutstandingPlanExtrasFail(state, action);
        case actionTypes.GET_PLANS_FOR_PLAN_EXTRAS_START:
            return getPlansForPlanExtrasStart(state);
        case actionTypes.GET_PLANS_FOR_PLAN_EXTRAS_SUCCESS:
            return getPlansForPlanExtrasSuccess(state, action);
        case actionTypes.GET_PLANS_FOR_PLAN_EXTRAS_FAIL:
            return getPlansForPlanExtrasFail(state, action);
        case actionTypes.GET_PLANS_FOR_PLAN_EXTRAS_RESET:
            return getPlansForPlanExtrasReset(state, action);
        case actionTypes.GET_PLANS_FOR_PRE_DEFINED_START:
            return getPlansForPreDefinedStart(state);
        case actionTypes.GET_PLANS_FOR_PRE_DEFINED_SUCCESS:
            return getPlansForPreDefinedSuccess(state, action);
        case actionTypes.GET_PLANS_FOR_PRE_DEFINED_FAIL:
            return getPlansForPreDefinedFail(state, action);
        case actionTypes.GET_PLANS_FOR_PRE_DEFINED_RESET:
            return getPlansForPreDefinedReset(state, action);
        case actionTypes.GET_PROCESSED_REQUESTS_COUNT_START:
            return getProcessedRequestsCountStart(state);
        case actionTypes.GET_PROCESSED_REQUESTS_COUNT_SUCCESS:
            return getProcessedRequestsCountSuccess(state, action);
        case actionTypes.GET_PROCESSED_REQUESTS_COUNT_FAIL:
            return getProcessedRequestsCountFail(state, action);
        case actionTypes.GET_PROCESSED_REQUESTS_START:
            return getProcessedRequestsStart(state);
        case actionTypes.GET_PROCESSED_REQUESTS_SUCCESS:
            return getProcessedRequestsSuccess(state, action);
        case actionTypes.GET_PROCESSED_REQUESTS_FAIL:
            return getProcessedRequestsFail(state, action);
        case actionTypes.GET_AD_HOC_DELIVERY_TYPES_START:
            return getAdHocDeliveryTypesStart(state);
        case actionTypes.GET_AD_HOC_DELIVERY_TYPES_SUCCESS:
            return getAdHocDeliveryTypesSuccess(state, action);
        case actionTypes.GET_AD_HOC_DELIVERY_TYPES_FAIL:
            return getAdHocDeliveryTypesFail(state, action);
        case actionTypes.GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_START:
            return getPreDefinedRoutesFieldOptionsStart(state);
        case actionTypes.GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_SUCCESS:
            return getPreDefinedRoutesFieldOptionsSuccess(state, action);
        case actionTypes.GET_PRE_DEFINED_ROUTES_FIELD_OPTIONS_FAIL:
            return getPreDefinedRoutesFieldOptionsFail(state, action);
        case actionTypes.UPDATE_PRE_DEFINED_ROUTE_START:
        case actionTypes.UPDATE_PRE_DEFINED_ROUTE_SUCCESS:
        case actionTypes.UPDATE_PRE_DEFINED_ROUTE_FAIL:
        case actionTypes.UPDATE_PRE_DEFINED_ROUTE_RESET:
            return updatePreDefinedRouteReset(state, action);
        case actionTypes.GET_PRE_DEFINED_ROUTES_START:
            return getPreDefinedRoutesStart(state);
        case actionTypes.GET_PRE_DEFINED_ROUTES_SUCCESS:
            return getPreDefinedRoutesSuccess(state, action);
        case actionTypes.GET_PRE_DEFINED_ROUTES_FAIL:
            return getPreDefinedRoutesFail(state, action);
        case actionTypes.DELETE_PRE_DEFINED_ROUTES_START:
            return deletePreDefinedRoutesStart(state);
        case actionTypes.DELETE_PRE_DEFINED_ROUTES_SUCCESS:
            return deletePreDefinedRoutesSuccess(state, action);
        case actionTypes.DELETE_PRE_DEFINED_ROUTES_FAIL:
            return deletePreDefinedRoutesFail(state, action);
        case actionTypes.DELETE_PRE_DEFINED_ROUTES_RESET:
            return deletePreDefinedRoutesReset(state, action);
        case actionTypes.DOWNLOAD_PRE_DEFINED_ROUTES_START:
            return downloadPreDefinedRoutesStart(state);
        case actionTypes.DOWNLOAD_PRE_DEFINED_ROUTES_SUCCESS:
            return downloadPreDefinedRoutesSuccess(state, action);
        case actionTypes.DOWNLOAD_PRE_DEFINED_ROUTES_FAIL:
            return downloadPreDefinedRoutesFail(state, action);
        case actionTypes.DOWNLOAD_PRE_DEFINED_ROUTES_RESET:
            return downloadPreDefinedRoutesReset(state);
        case actionTypes.DOWNLOAD_PLAN_EXTRAS_START:
            return downloadPlanExtrasStart(state);
        case actionTypes.DOWNLOAD_PLAN_EXTRAS_SUCCESS:
            return downloadPlanExtrasSuccess(state, action);
        case actionTypes.DOWNLOAD_PLAN_EXTRAS_FAIL:
            return downloadPlanExtrasFail(state, action);
        case actionTypes.DOWNLOAD_PLAN_EXTRAS_RESET:
            return downloadPlanExtrasReset(state);
        case actionTypes.PLAN_PLAN_EXTRAS_START:
            return planPlanExtrasStart(state);
        case actionTypes.PLAN_PLAN_EXTRAS_SUCCESS:
            return planPlanExtrasSuccess(state, action);
        case actionTypes.PLAN_PLAN_EXTRAS_FAIL:
            return planPlanExtrasFail(state, action);
        case actionTypes.PLAN_PLAN_EXTRAS_RESET:
            return planPlanExtrasReset(state);
        case actionTypes.PLAN_PRE_DEFINED_START:
            return planPreDefinedStart(state);
        case actionTypes.PLAN_PRE_DEFINED_SUCCESS:
            return planPreDefinedSuccess(state, action);
        case actionTypes.PLAN_PRE_DEFINED_FAIL:
            return planPreDefinedFail(state, action);
        case actionTypes.PLAN_PRE_DEFINED_RESET:
            return planPreDefinedReset(state);
        default:
            return state;
    }
};

export default reducer;
