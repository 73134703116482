import { AuthenticationContext } from 'react-adal';

export const adalConfig = {
    tenant: 'e11fd634-26b5-47f4-8b8c-908e466e9bdf', // '4b82bbbb-aa0b-4a6f-9d22-5ff41811aaed',
    clientId: '55e10b4b-6ae9-4f5e-ab92-4563aa28fd44', // '4d02c4af-8ae7-4c10-a864-e44f9ec12aee',
    endpoints: {
        api: '55e10b4b-6ae9-4f5e-ab92-4563aa28fd44' // '4d02c4af-8ae7-4c10-a864-e44f9ec12aee'
    },
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    cacheLocation: 'localStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
    return new Promise((resolve, reject) => {
        authContext.acquireToken(authContext.config.clientId, err => {
            if (err) return reject(err);
            resolve(authContext.getCachedToken(authContext.config.clientId));
        });
    });

    // return authContext.getCachedToken(authContext.config.clientId);
};
