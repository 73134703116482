import React, { useState } from 'react';

import { Card } from '@jsluna/card';
import { FormGroup } from '@jsluna/form';
import { GridItem, GridWrapper } from '@jsluna/grid';

import DatePicker from '../../UI/DatePickerWithWeek/DatePickerWithWeek';
import Import from '../../Common/Import/Import';

import moment from 'moment';
moment.locale('en-GB');

const ImportCDC = () => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [showImport, setShowImport] = useState(false);
    const [wcDate, setWcDate] = useState(moment().startOf('week'));
    const [wcFocused, setWcFocused] = useState(false);

    const dateChangedHandler = event => {
        setWcDate(event.startOf('week'), setShowImport(true));
    };

    const handleError = event => setErrorMsg(JSON.parse(event.xhr.response).Message);

    const importDialog = showImport ? (
        <GridItem size="1/1">
            <Import
                accept=".xlsx"
                detail="File successfully uploaded"
                name="cdcImport"
                onError={handleError}
                path={`CDC/Import/${wcDate.format('YYYY-MM-DD')}`}
                summary="File uploaded"
            />
        </GridItem>
    ) : null;

    const msg = errorMsg ? (
        <GridItem size="1/1">
            <h4 className="ln-u-push-top ln-u-color-red ln-u-text-align-center">{errorMsg}</h4>
        </GridItem>
    ) : null;
    return (
        <Card>
            <GridWrapper>
                <GridItem size="1/1">
                    <h3>CDI Import</h3>
                </GridItem>
                <GridItem size="1/1">
                    <FormGroup name="sel-date-grp" label="Select a week commencing date">
                        <DatePicker
                            id="cdcDate"
                            value={wcDate}
                            changed={event => dateChangedHandler(event)}
                            onFocused={event => setWcFocused(event.focused)}
                            focused={wcFocused}
                        />
                    </FormGroup>
                </GridItem>
                {importDialog}
                {msg}
            </GridWrapper>
        </Card>
    );
};

export default ImportCDC;
