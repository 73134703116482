import axios from '../../axios';
import * as actionTypes from './actionTypes';
export const getMergedFileStart = () => ({ type: actionTypes.GET_MERGED_FILE_START });
export const getMergedFileSuccess = (data, fileName) => ({ type: actionTypes.GET_MERGED_FILE_SUCCESS, mergedFile: data, mergedFileName: fileName });
export const getMergedFileFail = error => ({ type: actionTypes.GET_MERGED_FILE_FAIL, mergedFileError: error });

export const getMergedFile = (files, targetId) => {
    return dispatch => {
        const url = `/csvmerge/${targetId}`;

        dispatch(getMergedFileStart());
        const formData = new FormData();
        files.forEach(file => formData.append(file.name, file));

        axios
            .post(url, formData, { responseType: 'blob', headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                // get the filename from the content-disposition
                // this has to be exposed by the API first
                const disposition = response.headers['content-disposition'];
                const fileName = disposition
                    .split(';')
                    .find(f => f.includes('filename='))
                    .match(/"(.*)"/)[1];
                dispatch(getMergedFileSuccess(response.data, fileName));
            })
            .catch(error => dispatch(getMergedFileFail(error)));
    };
};

// #region
export const transformCsvStart = () => ({
    type: actionTypes.TRANSFORM_CSV,
    transformCsvData: null,
    transformCsvFileName: null,
    transformCsvSuccess: null,
    transformCsvRunning: true,
    transformCsvError: null
});

export const transformCsvSuccess = (data, fileName) => ({
    type: actionTypes.TRANSFORM_CSV,
    transformCsvData: data,
    transformCsvFileName: fileName,
    transformCsvSuccess: true,
    transformCsvRunning: false,
    transformCsvError: null
});

export const transformCsvFail = error => ({
    type: actionTypes.TRANSFORM_CSV,
    transformCsvData: null,
    transformCsvFileName: null,
    transformCsvSuccess: false,
    transformCsvRunning: false,
    transformCsvError: error
});

export const transformCsvReset = () => ({
    type: actionTypes.TRANSFORM_CSV,
    transformCsvData: null,
    transformCsvFileName: null,
    transformCsvSuccess: null,
    transformCsvRunning: null,
    transformCsvError: null
});

export const transformCsvDownload = (files, datetime) => {
    return dispatch => {
        const url = `/TransportOrder/csvToTransportDownload/${datetime.format('YYYY-MM-DD')}`;

        dispatch(transformCsvStart());
        const formData = new FormData();
        files.forEach(file => formData.append(file.name, file));

        axios
            .post(url, formData, { responseType: 'blob', headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                if (response.status === 200) {
                    // get the filename from the content-disposition
                    // this has to be exposed by the API first
                    const disposition = response.headers['content-disposition'];
                    const fileName = disposition
                        .split(';')
                        .find(f => f.includes('filename='))
                        .match(/"(.*)"/)[1];
                    dispatch(transformCsvSuccess(response.data, fileName));
                } else {
                    // if (response.status === 422 || response.status === 303 || response.status === 500) {
                    const reader = new FileReader();
                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', () => {
                        // const text = e.srcElement.result;
                        const data = JSON.parse(reader.result);
                        dispatch(transformCsvFail(data.detail));
                    });

                    reader.readAsText(response.data);
                    // } else {
                    //     dispatch(transformCsvFail('Failed downloading file, unexpected error'));
                    // }
                }
            })
            .catch(() => {
                dispatch(transformCsvFail('Failed downloading file'));
            });
    };
};

export const transformCsvPublish = (files, datetime) => {
    return dispatch => {
        const url = `/TransportOrder/csvToTransportPublish/${datetime.format('YYYY-MM-DD')}`;

        dispatch(transformCsvStart());
        const formData = new FormData();
        files.forEach(file => formData.append(file.name, file));

        axios
            .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                // TODO: better way to handle different response ? if any
                if (response.status === 200) dispatch(transformCsvSuccess(response.data));
                else {
                    dispatch(transformCsvFail(response.data.detail || 'Failed publishing plan'));
                }
            })
            .catch(error => dispatch(transformCsvFail(error)));
    };
};
// #endregion

// #region legacyfailurelog
export const legacyLogStart = () => ({
    type: actionTypes.LEGACY_LOG,
    legacyLogRunning: true,
    legacyLogSuccess: null,
    legacyLogData: [],
    legacyLogError: null
});

export const legacyLogSuccess = data => ({
    type: actionTypes.LEGACY_LOG,
    legacyLogRunning: false,
    legacyLogSuccess: true,
    legacyLogData: data,
    legacyLogError: null
});

export const legacyLogFail = data => ({
    type: actionTypes.LEGACY_LOG,
    legacyLogRunning: false,
    legacyLogSuccess: null,
    legacyLogData: data,
    legacyLogError: true
});

export const legacyLogReset = () => ({
    type: actionTypes.LEGACY_LOG,
    legacyLogRunning: null,
    legacyLogSuccess: null,
    legacyLogData: [],
    legacyLogError: null
});

export const getLegacyLog = (date, fileName) => {
    return dispatch => {
        const url = `/TransportOrder/FailedLegacyResults/${date.format('YYYY-MM-DD')}/${fileName || 'A'}`;

        dispatch(legacyLogStart());

        axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    dispatch(legacyLogSuccess(response.data));
                } else {
                    dispatch(legacyLogFail(response.data));
                }
            })
            .catch(error => {
                dispatch(legacyLogFail(error));
            });
    };
};
// #endregion
