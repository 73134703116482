import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const getPipelineStart = () => {
    return {
        type: actionTypes.GET_PIPELINE_START,
        pipeline: null,
        pipelineSuccess: null,
        pipelineError: null,
        pipelineUpdateSuccess: null,
        pipelineLoading: true
    };
};

export const getPipelineSuccess = pipeline => {
    return {
        type: actionTypes.GET_PIPELINE_SUCCESS,
        pipeline: pipeline && pipeline.length === 0 ? null : pipeline,
        pipelineSuccess: true,
        pipelineError: null,
        pipelineUpdateSuccess: null,
        pipelineLoading: false
    };
};

export const getPipelineFail = error => {
    return {
        type: actionTypes.GET_PIPELINE_FAIL,
        pipeline: null,
        pipelineSuccess: false,
        pipelineError: error,
        pipelineUpdateSuccess: null,
        pipelineLoading: null
    };
};

export const getPipelineReset = () => {
    return {
        type: actionTypes.GET_PIPELINE_RESET,
        pipeline: null,
        pipelineSuccess: null,
        pipelineError: null,
        pipelineUpdateSuccess: null,
        pipelineLoading: null
    };
};

export const getPipeline = planId => {
    return dispatch => {
        dispatch(getPipelineStart());

        const url = `/pipeline/${planId}`;

        axios
            .get(url)
            .then(response => {
                const sortedOptions = Object.values(response.data).sort((a, b) => a.sequence - b.sequence);
                dispatch(getPipelineSuccess(sortedOptions));
            })
            .catch(error => {
                dispatch(getPipelineFail(`Failed getting pipeline ${error}`));
            });
    };
};

export const updatePipelineStart = () => {
    return {
        type: actionTypes.UPDATE_PIPELINE_START,
        error: null,
        pipelineSuccess: null,
        pipelineUpdateSuccess: null,
        pipelineComplete: null
    };
};

export const updatePipelineSuccess = () => {
    return {
        type: actionTypes.UPDATE_PIPELINE_SUCCESS,
        error: null,
        pipelineUpdateSuccess: true
    };
};

export const updatePipelineFail = error => {
    return {
        type: actionTypes.UPDATE_PIPELINE_FAIL,
        error: error,
        pipelineUpdateSuccess: false
    };
};

export const updatePipelineReset = () => {
    return {
        type: actionTypes.UPDATE_PIPELINE_RESET,
        pipelineUpdateSuccess: null
    };
};

export const updatePipeline = options => {
    return dispatch => {
        dispatch(updatePipelineStart());

        const url = '/pipeline/updatePipeline';

        axios
            .put(url, options)
            .then(response => {
                dispatch(updatePipelineSuccess(response.data));
            })
            .catch(error => {
                dispatch(updatePipelineFail(`Failed updating pipeline ${error}`));
            });
    };
};
