import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { GridItem, GridWrapper } from '@jsluna/grid';

const PreDefinedRoutesExpanded = props => {
    const { fields, data } = props;
    return (
        <GridWrapper className="ln-u-flush">
            {fields.map(c => {
                return (
                    <Fragment key={c.field}>
                        <GridItem size="1/8" className="ln-u-text-align-right">
                            <p style={{ lineHeight: '48px' }}>{c.label}</p>
                        </GridItem>
                        <GridItem size="1/8">
                            <p style={{ lineHeight: '48px' }}>{data[c.field]}</p>
                        </GridItem>
                    </Fragment>
                );
            })}
        </GridWrapper>
    );
};

PreDefinedRoutesExpanded.propTypes = {
    data: PropTypes.object,
    fields: PropTypes.array
};

export default PreDefinedRoutesExpanded;
