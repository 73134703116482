import axios from '../../axios';

import * as actionTypes from './actionTypes';

import moment from 'moment';
moment.locale('en-GB');

export const getTrailers = () => {
    return dispatch => {
        dispatch(getTrailersStart());

        const url = '/di/trailers';

        axios
            .get(url)
            .then(response => dispatch(getTrailersSuccess(response.data)))
            .catch(error => dispatch(getTrailersFail(error)));
    };
};

export const getTrailersStart = () => ({
    type: actionTypes.GET_TRAILERS,
    trailers: null,
    trailersError: null,
    trailersSuccess: null,
    trailersLoading: true
});

export const getTrailersSuccess = trailers => ({
    type: actionTypes.GET_TRAILERS,
    trailers,
    trailersError: null,
    trailersSuccess: true,
    trailersLoading: false
});

export const getTrailersFail = trailersError => ({
    type: actionTypes.GET_TRAILERS,
    trailers: null,
    trailersError,
    trailersSuccess: false,
    trailersLoading: false
});
