import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';

import PlanExtras from './PlanExtras/PlanExtras';
// import Processed from './Processed/Processed';
import PreDefinedRoutes from './PreDefinedRoutes/PreDefinedRoutes';

import { Card } from '@jsluna/card';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
// import { TabLink, TabPanel, Tabs } from '@jsluna/tabs';
import { TabPanel } from '@jsluna/tabs';

const Requests = props => {
    const tabs = [
        { name: 'Plan Extras', key: 'planExtras', visible: true },
        { name: 'Pre-Defined Routes', key: 'predefinedroutes', visible: true }
    ];

    // const [currentSite, setCurrentSite] = useState(null);
    const [activeTab, setActiveTab] = useState('predefinedroutes');

    const {
        onGetOutstandingPlanExtras,
        onGetPreDefinedRoutes,
        // onGetUserSites,
        // userSites,
        outstandingPlanExtras,
        // getPreDefinedRoutesSuccess,
        preDefinedRoutes
    } = props;
    const rows = 8;

    /*
    useEffect(() => {
        onGetUserSites();
    }, [onGetUserSites]);
    */
    useEffect(() => {
        /*
        if (userSites) {
            const activeSite = userSites.find(us => us.isActive);
            // setCurrentSite(activeSite);

            if (activeSite) {
                onGetOutstandingPlanExtras(activeSite.value);
                onGetPreDefinedRoutes(activeSite.value);
            }
        } else
        */
        if (!preDefinedRoutes) {
            if (!outstandingPlanExtras || outstandingPlanExtras.length === 0) {
                onGetOutstandingPlanExtras();
            }
            onGetPreDefinedRoutes();
        }
    }, [onGetOutstandingPlanExtras, onGetPreDefinedRoutes, preDefinedRoutes, outstandingPlanExtras]);

    /*
    useEffect(() => {
    }, [preDefinedRoutes, getPreDefinedRoutesSuccess, getPreDefinedRoutesLoading]);
*/

    const isLoading = props.loadingUserSites || props.loadingOutstandingPlanExtras;
    const loading = (
        <ProgressIndicator page loading={isLoading}>
            <ProgressSpinner />
            Loading...
        </ProgressIndicator>
    );

    /*
    const planExtras = <PlanExtras outstandingPlanExtras={outstandingPlanExtras} rows={rows} />;
    const preDefined = <PreDefinedRoutes preDefinedRoutes={preDefinedRoutes} rows={rows} reloadData={onGetPreDefinedRoutes} />;

    */
    let tabPanel;

    switch (activeTab) {
        case 'planExtras':
            tabPanel = (
                <TabPanel>
                    <Card>
                        <PlanExtras outstandingPlanExtras={outstandingPlanExtras} rows={rows} />
                    </Card>
                </TabPanel>
            );
            break;
        /*
        case 'processed':
            tabPanel = (
                <TabPanel>
                    <Card>{processed}</Card>
                </TabPanel>
            );
            break;
            */
        default:
        case 'predefinedroutes':
            tabPanel = (
                <TabPanel>
                    <Card>
                        <PreDefinedRoutes preDefinedRoutes={preDefinedRoutes} rows={rows} reloadData={onGetPreDefinedRoutes} />
                    </Card>
                </TabPanel>
            );
            break;
    }

    return (
        <Fragment>
            <Card>
                <h2>Plan Extras {/* currentSite ? `For ${currentSite.label === 'Argos' ? 'All Sites' : currentSite.label}` : '' */}</h2>
                <nav className="ln-c-tabs ln-c-tabs--fill">
                    <div className="ln-c-tabs__list-wrap">
                        <div className="ln-c-tabs__list">
                            {tabs
                                .filter(m => m.visible)
                                .map(tab => (
                                    <div
                                        key={tab.key}
                                        className={`ln-c-tabs__link ${activeTab === tab.key ? 'is-active' : ''}`}
                                        role="tab"
                                        onClick={() => setActiveTab(tab.key)}
                                        aria-selected="true"
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                        </div>
                    </div>
                </nav>
                {tabPanel}
            </Card>
            {loading}
        </Fragment>
    );
};

Requests.propTypes = {
    // getPreDefinedRoutesLoading: PropTypes.bool,
    // getPreDefinedRoutesSuccess: PropTypes.bool,
    loadingOutstandingPlanExtras: PropTypes.bool,
    loadingUserSites: PropTypes.bool,
    onGetOutstandingPlanExtras: PropTypes.func,
    onGetPreDefinedRoutes: PropTypes.func,
    // onGetUserSites: PropTypes.func,
    outstandingPlanExtras: PropTypes.array,
    preDefinedRoutes: PropTypes.array
};

const mapStateToProps = state => {
    return {
        loadingOutstandingPlanExtras: state.requests.loadingOutstandingPlanExtras,
        loadingPreDefinedRoutes: state.requests.loadingPreDefinedRoutes,
        loadingUserSites: state.userSite.loadingUserSites,
        outstandingPlanExtras: state.requests.outstandingPlanExtras,
        preDefinedRoutes: state.requests.preDefinedRoutes,
        getPreDefinedRoutesLoading: state.requests.getPreDefinedRoutesLoading,
        getPreDefinedRoutesSuccess: state.requests.getPreDefinedRoutesSuccess,
        userSites: state.userSite.userSites
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetOutstandingPlanExtras: () => dispatch(actions.getOutstandingPlanExtras()),
        onGetPreDefinedRoutes: () => dispatch(actions.getPreDefinedRoutes()),
        onGetUserSites: () => dispatch(actions.getUserSites())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Requests, axios));
