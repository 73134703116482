import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    pipelineMsg: null,
    cvlMsg: null
};

const signalRPipelineSuccess = (state, action) => updateObject(state, { pipelineMsg: action.data });
const signalRPipelineReset = (state, action) => updateObject(state, { pipelineMsg: action.data });
const signalRCVLSuccess = (state, action) => updateObject(state, { cvlMsg: action.data });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIGNALR_PIPELINE_RESET:
            return signalRPipelineReset(state, action);
        case actionTypes.SIGNALR_PIPELINE_SUCCESS:
            return signalRPipelineSuccess(state, action);
        case actionTypes.SIGNALR_CVL_SUCCESS:
            return signalRCVLSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
