import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Modal, ModalHeading } from '@jsluna/modal';

const withErrorHandler = (WrappedComponent, axios) => {
    return props => {
        const [error, setError] = useState(null);
        const [toHome, setToHome] = useState(false);
        const resInterceptorRef = useRef();
        const reqInterceptorRef = useRef();

        const ejectInterceptors = useCallback(() => {
            axios.interceptors.request.eject(reqInterceptorRef.current);
            axios.interceptors.response.eject(resInterceptorRef.cuurent);
        }, [reqInterceptorRef, resInterceptorRef]);

        useEffect(() => {
            reqInterceptorRef.current = axios.interceptors.request.use(req => {
                setError(null);
                return req;
            });

            resInterceptorRef.current = axios.interceptors.response.use(
                res => res,
                // err => setError(err)
                err => responseInfo(err)
            );

            return () => ejectInterceptors();
        }, [ejectInterceptors]);

        const errorConfirmedHandler = () => {
            setError(null);
            setToHome(true);
        };

        if (toHome === true) {
            setToHome(false);
            return <Redirect to="/" />;
        }

        const responseInfo = err => {
            if (err.response && err.response.data instanceof Blob) {
                const reader = new FileReader();
                // This fires after the blob has been read/loaded.
                reader.addEventListener('loadend', () => {
                    const data = JSON.parse(reader.result);
                    setError({
                        message: data.detail
                    });
                });

                reader.readAsText(err.response.data);
                // return 'Error downloading file';
            } else {
                setError(err);
            }
        };

        return (
            <Fragment>
                <Modal small handleClose={errorConfirmedHandler} open={error !== null}>
                    <ModalHeading element="h3">Oops, we've hit an error</ModalHeading>
                    <p>{error ? error.message : ''}</p>
                </Modal>
                <WrappedComponent {...props} />
            </Fragment>
        );
    };
};

export default withErrorHandler;
