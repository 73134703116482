import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { GridItem, GridWrapper } from '@jsluna/grid';
import { TableBody, TableCell, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow, TableRow } from '@jsluna/table';
import { SelectField, SwitchField } from '@jsluna/form';

import classes from './Totals.module.scss';

import { updateObject } from '../../../shared/utility';

import uniqBy from 'lodash-es/uniqBy';

const Totals = props => {
    const [cutOffValue, setCutoffValue] = useState(0.1);
    const [dcInfo, setDcInfo] = useState(null);
    const [doCalc, setDoCalc] = useState(false);
    const [selVol, setSelVol] = useState({});
    const [useCutoff, setUseCutoff] = useState(null);
    const cutOffVals = useRef();
    const { cvl } = props;
    const initialState = {
        csoItemTotal: 0,
        aItemTotal: 0,
        bItemTotal: 0,
        cItemTotal: 0,
        csoUlcTotal: 0,
        aUlcTotal: 0,
        bUlcTotal: 0,
        cUlcTotal: 0,
        csoCageFill: 0,
        aCageFill: 0,
        bCageFill: 0,
        cCageFill: 0,
        csoGradeCageFill: 0,
        aGradeCageFill: 0,
        bGradeCageFill: 0,
        cGradeCageFill: 0,
        allItems: 0,
        allUlcs: 0
    };

    // setup the values for the cut off selection
    useEffect(() => {
        const vals = [];
        for (let x = 0.1; x < 0.9; x += 0.1) vals.push({ label: x.toFixed(1), value: x.toFixed(1) });
        cutOffVals.current = vals;
    }, []);

    // set the colour class for selected / deselected item grades
    const isSelected = useCallback(
        (dc, type) => {
            if (selVol[dc])
                if (selVol[dc][type]) return classes.selected;
                else if (selVol[dc][type] === false) return classes.deselected;
                else return classes.selected;

            return classes.selected;
        },
        [selVol]
    );

    // click handler for a grade
    const aToggle = useCallback(
        dcNo => {
            const aIsSelected = isSelected(dcNo, 'aItems') === classes.selected;
            let newSet;

            if (aIsSelected) newSet = updateObject(selVol, { [dcNo]: { aItems: false, bItems: false, cItems: false } });
            else newSet = updateObject(selVol, { [dcNo]: { aItems: true, bItems: false, cItems: false } });

            setSelVol(newSet);
        },
        [isSelected, selVol, setSelVol]
    );

    // click handler for b grade
    const bToggle = useCallback(
        dcNo => {
            const bIsSelected = isSelected(dcNo, 'bItems') === classes.selected;
            let newSet;

            if (bIsSelected) newSet = updateObject(selVol, { [dcNo]: { bItems: false, cItems: false } });
            else newSet = updateObject(selVol, { [dcNo]: { aItems: true, bItems: true, cItems: false } });

            setSelVol(newSet);
        },
        [isSelected, selVol, setSelVol]
    );

    // click handler for c grade
    const cToggle = useCallback(
        dcNo => {
            const cIsSelected = isSelected(dcNo, 'cItems') === classes.selected;
            let newSet;

            if (cIsSelected) newSet = updateObject(selVol, { [dcNo]: { cItems: false } });
            else newSet = updateObject(selVol, { [dcNo]: { aItems: true, bItems: true, cItems: true } });

            setSelVol(newSet);
        },
        [isSelected, selVol, setSelVol]
    );

    // build table content
    const setupDcInfo = useCallback(
        (vol, vdcs) => {
            setDcInfo(
                !vdcs
                    ? null
                    : vdcs.map(dc => (
                          <TableRow key={`dc_${dc.dcNo}`}>
                              <TableCell colSpan={2}>{`${dc.dcNo} ${dc.dcName}`}</TableCell>
                              <TableCell>{vol[dc.dcNo].csoItemTotal}</TableCell>
                              <TableCell className={isSelected(dc.dcNo, 'aItems')} onClick={() => aToggle(dc.dcNo)}>
                                  {vol[dc.dcNo].aItemTotal}
                              </TableCell>
                              <TableCell className={isSelected(dc.dcNo, 'bItems')} onClick={() => bToggle(dc.dcNo)}>
                                  {vol[dc.dcNo].bItemTotal}
                              </TableCell>
                              <TableCell className={isSelected(dc.dcNo, 'cItems')} onClick={() => cToggle(dc.dcNo)}>
                                  {vol[dc.dcNo].cItemTotal}
                              </TableCell>
                              <TableCell>{vol[dc.dcNo].allItems}</TableCell>
                              <TableCell>{vol[dc.dcNo].csoUlcTotal}</TableCell>
                              <TableCell>{vol[dc.dcNo].aUlcTotal}</TableCell>
                              <TableCell>{vol[dc.dcNo].bUlcTotal}</TableCell>
                              <TableCell>{vol[dc.dcNo].allUlcs}</TableCell>
                              {/* <TableCell>{vol[dc.dcNo].csoGradeCageFill.toFixed(2)}</TableCell> */}
                              <TableCell>{vol[dc.dcNo].aGradeCageFill.toFixed(2)}</TableCell>
                              <TableCell>{vol[dc.dcNo].bGradeCageFill.toFixed(2)}</TableCell>
                              <TableCell>{vol[dc.dcNo].cGradeCageFill.toFixed(2)}</TableCell>
                              <TableCell>{vol[dc.dcNo].cCageFill.toFixed(2)}</TableCell>
                          </TableRow>
                      ))
            );
        },
        [aToggle, bToggle, cToggle, isSelected]
    );

    // calculate the volume figures
    const calculate = useCallback(() => {
        // let cvlInUse;

        // #region cutoff
        /*
        if (useCutoff) {
            // apply the ulc cut off value
            cvlInUse = cvl.map(cv => {
                const newc = { ...cv };

                // only apply to Kettering
                if (+cv.dcNo === 631) {
                    newc.servUlcA = Math.abs(newc.servUlcA - cutOffValue);
                    newc.servUlcB = Math.abs(newc.servUlcB - cutOffValue);
                    newc.servUlcC = Math.abs(newc.servUlcC - cutOffValue);

                    // calculate new item quanitites
                    if (cv.servUlcA > 0) {
                        const cso = cv.servItemsCSO;
                        const a = cv.servItemsA + cso;
                        const b = cv.servItemsB + a;
                        const c = cv.servItemsC + b;
                        newc.servItemsA = Math.ceil((a / cv.servUlcA) * newc.servUlcA) - cso;
                        newc.servItemsB = Math.ceil((b / cv.servUlcB) * newc.servUlcB) - newc.servItemsA - cso;
                        newc.servItemsC = Math.ceil((c / cv.servUlcC) * newc.servUlcC) - newc.servItemsB - newc.servItemsA - cso;
                    }
                }

                return newc;
            });
        } else cvlInUse = cvl;
        */
        // #endregion

        // get a unique list of dcs
        const vdcs = uniqBy(cvl, c => c.dcNo);
        const vol = {};
        let useC = true;
        let useB = true;
        let useA = true;

        // calculate volumes
        vdcs.forEach(dc => {
            const calcVol = { ...initialState };
            const dcCvl = cvl.filter(c => c.dcNo === dc.dcNo);

            dcCvl.forEach(c => {
                const storeVol = { ...initialState };
                storeVol.csoItemTotal = c.servItemsCSO;
                storeVol.aItemTotal = c.servItemsA;
                storeVol.bItemTotal = c.servItemsB;
                storeVol.cItemTotal = c.servItemsC;
                storeVol.csoUlcTotal = c.servUlcCSO;
                storeVol.aUlcTotal = c.servUlcA;
                storeVol.bUlcTotal = c.servUlcB;
                storeVol.cUlcTotal = c.servUlcC;

                // apply any A / B / C grade selections / deselections
                if (selVol[dc.dcNo]) {
                    useC = selVol[dc.dcNo].cItems === undefined ? true : selVol[dc.dcNo].cItems;
                    useB = selVol[dc.dcNo].bItems === undefined ? true : selVol[dc.dcNo].bItems;
                    useA = selVol[dc.dcNo].aItems === undefined ? true : selVol[dc.dcNo].aItems;
                }

                switch (true) {
                    case useC:
                        storeVol.allUlcs = storeVol.cUlcTotal;
                        break;
                    case useB:
                        storeVol.allUlcs = storeVol.bUlcTotal;
                        // storeVol.cCageFill = storeVol.bCageFill;
                        break;
                    case useA:
                        storeVol.allUlcs = storeVol.aUlcTotal;
                        // storeVol.cCageFill = storeVol.aCageFill;
                        break;
                    default:
                        storeVol.allUlcs = storeVol.csoUlcTotal;
                        // storeVol.cCageFill = storeVol.csoCageFill;
                        break;
                }

                if (useCutoff && +dc.dcNo === 631) storeVol.allUlcs = Math.ceil(storeVol.allUlcs - cutOffValue);

                calcVol.csoItemTotal += storeVol.csoItemTotal;
                calcVol.aItemTotal += storeVol.aItemTotal;
                calcVol.bItemTotal += storeVol.bItemTotal;
                calcVol.cItemTotal += storeVol.cItemTotal;
                calcVol.csoUlcTotal += Math.ceil(storeVol.csoUlcTotal);
                calcVol.aUlcTotal += Math.ceil(storeVol.aUlcTotal);
                calcVol.bUlcTotal += Math.ceil(storeVol.bUlcTotal);
                calcVol.cUlcTotal += Math.ceil(storeVol.cUlcTotal);
                calcVol.allUlcs += storeVol.allUlcs;
            });

            // calculate cumulative cage fills
            calcVol.csoCageFill = calcVol.csoUlcTotal > 0 ? calcVol.csoItemTotal / calcVol.csoUlcTotal : 0;
            calcVol.aCageFill = calcVol.aUlcTotal > 0 ? (calcVol.aItemTotal + calcVol.csoItemTotal) / calcVol.aUlcTotal : 0;
            calcVol.bCageFill = calcVol.bUlcTotal > 0 ? (calcVol.aItemTotal + calcVol.bItemTotal + calcVol.csoItemTotal) / calcVol.bUlcTotal : 0;
            calcVol.cCageFill =
                calcVol.cUlcTotal > 0 ? (calcVol.aItemTotal + calcVol.bItemTotal + calcVol.csoItemTotal + calcVol.cItemTotal) / calcVol.cUlcTotal : 0;

            // calculate cage fills by grade
            const acages = calcVol.aUlcTotal - calcVol.csoUlcTotal;
            const bcages = calcVol.bUlcTotal - calcVol.aUlcTotal;
            const ccages = calcVol.cUlcTotal - calcVol.bUlcTotal;

            calcVol.csoGradeCageFill = calcVol.csoUlcTotal > 0 ? calcVol.csoItemTotal / calcVol.csoUlcTotal : 0;
            calcVol.aGradeCageFill = acages > 0 ? calcVol.aItemTotal / acages : 0;
            calcVol.bGradeCageFill = bcages > 0 ? calcVol.bItemTotal / bcages : 0;
            calcVol.cGradeCageFill = ccages > 0 ? calcVol.cItemTotal / ccages : 0;

            calcVol.allItems = calcVol.csoItemTotal + (useA ? calcVol.aItemTotal : 0) + (useB ? calcVol.bItemTotal : 0) + (useC ? calcVol.cItemTotal : 0);

            if (useCutoff && +dc.dcNo === 631) calcVol.allItems = Math.round(calcVol.allUlcs * calcVol.cCageFill);
            calcVol.allUlcs = Math.ceil(calcVol.allUlcs);

            vol[dc.dcNo] = calcVol;
        });
        setupDcInfo(vol, vdcs);
    }, [cutOffValue, cvl, initialState, selVol, setupDcInfo, useCutoff]);

    // trigger the calculation if doCalc === true
    useEffect(() => {
        if (doCalc) {
            calculate();
            setDoCalc(false);
        }
    }, [doCalc, setDoCalc, calculate]);

    // trigger the initial calculation on CVL load
    useEffect(() => {
        if (cvl) setDoCalc(true);
    }, [cvl, setDoCalc]);

    // trigger the calculation when A / B / C grade selection is changed
    useEffect(() => {
        if (Object.keys(selVol).length > 0) setDoCalc(true);
    }, [selVol]);

    // trigger the calculation when either the cut off switch is changed or the cut off value selection is changed
    useEffect(() => {
        if (useCutoff !== null) setDoCalc(true);
    }, [cutOffValue, useCutoff]);

    const ulcCutoffOptions = [{ value: 'apply', label: 'Apply ULC Cutoff' }];

    return (
        <GridWrapper>
            <GridItem size="1/1">
                <TableContainer className="ln-u-push-top">
                    <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell colSpan={2}>DC</TableHeaderCell>
                            <TableHeaderCell colSpan={1}>Items</TableHeaderCell>
                            <TableCell colSpan={3}>
                                <SwitchField
                                    className={classes.cutoff}
                                    label="Apply ULC Cutoff"
                                    name="cutoff-switch"
                                    hideLabel={true}
                                    onClick={event => setUseCutoff(event.target.checked)}
                                    options={ulcCutoffOptions}
                                />
                            </TableCell>
                            <TableCell>
                                <SelectField
                                    name="cutoff-select"
                                    label="Cutoff"
                                    options={cutOffVals.current || []}
                                    hideLabel={true}
                                    placeholder=""
                                    onChange={event => setCutoffValue(+event.target.value)}
                                    disabled={!useCutoff}
                                    className={classes.select}
                                />
                            </TableCell>
                            <TableHeaderCell colSpan={4}>ULCs</TableHeaderCell>
                            <TableHeaderCell>Fill</TableHeaderCell>
                        </TableHeaderRow>
                        <TableHeaderRow>
                            <TableHeaderCell colSpan={2}></TableHeaderCell>
                            <TableHeaderCell>CSO</TableHeaderCell>
                            <TableHeaderCell>A</TableHeaderCell>
                            <TableHeaderCell>B</TableHeaderCell>
                            <TableHeaderCell>C</TableHeaderCell>
                            <TableHeaderCell>All</TableHeaderCell>
                            <TableHeaderCell>CSO</TableHeaderCell>
                            <TableHeaderCell>CSO+A</TableHeaderCell>
                            <TableHeaderCell>CSO+A+B</TableHeaderCell>
                            <TableHeaderCell>All</TableHeaderCell>
                            {/* <TableHeaderCell>CSO</TableHeaderCell> */}
                            <TableHeaderCell>A</TableHeaderCell>
                            <TableHeaderCell>B</TableHeaderCell>
                            <TableHeaderCell>C</TableHeaderCell>
                            <TableHeaderCell>All</TableHeaderCell>
                        </TableHeaderRow>
                    </TableHeader>
                    <TableBody>{dcInfo}</TableBody>
                </TableContainer>
            </GridItem>
            <GridItem size="1/6" offsetRight={{ sm: '5/6', md: '5/6' }} className="ln-u-push-top">
                <span style={{ fontSize: '0.85em' }}>{props.cvlName}</span>
            </GridItem>
        </GridWrapper>
    );
};

Totals.propTypes = {
    cvl: PropTypes.array,
    cvlName: PropTypes.node
};

export default Totals;
