import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeading } from '@jsluna/modal';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';

const EditSchedule = props => {
    const { handleClose, open, confirmAction, confirmButton, cancelButton } = props;

    return (
        <Modal fullScreen handleClose={handleClose} open={open}>
            <ModalHeading element="h3">Edit Schedule</ModalHeading>
            <p>Not yet implemented</p>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={confirmAction}>{confirmButton || 'Save'}</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={handleClose}>{cancelButton || 'Cancel'}</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );
};

EditSchedule.propTypes = {
    cancelButton: PropTypes.string,
    confirmAction: PropTypes.func,
    confirmButton: PropTypes.string,
    handleClose: PropTypes.func,
    open: PropTypes.bool
};

export default EditSchedule;
