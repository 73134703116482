import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ListGroupItem } from '@jsluna/list';

import StatusIcon from '../UI/Icons/StatusIcon';

import moment from 'moment';
moment.locale('en-GB');

const PlanTile = props => {
    const { plan, click, last } = props;

    const onClickHandler = id => click(id);

    return (
        <Fragment>
            {plan === null || !plan ? null : (
                <ListGroupItem
                    key={plan.id.toString()}
                    size="1/8"
                    onClick={() => onClickHandler(plan.id)}
                    className={[
                        'ln-u-text-align-center',
                        'ln-u-display-block',
                        'ln-u-border-left',
                        `${!last ? 'ln-u-margin-bottom*4' : ''}`,
                        'planTile',
                        'ln-u-font-weight-bold',
                        'cardAction'
                    ].join(' ')}
                >
                    <div className="planDate">{moment(plan.date).format('DD/MM/YYYY')}</div>
                    <div className="planTileHeading">{plan.name}</div>
                    <div className="statusIcon">{<StatusIcon status={plan.status} />}</div>
                    <div className="statusMessage">{plan.statusMessage || 'Plan Created'}</div>
                    <div className="activeIcon">{<StatusIcon status={plan.active} />}</div>
                </ListGroupItem>
            )}
        </Fragment>
    );
};

PlanTile.propTypes = {
    click: PropTypes.func,
    last: PropTypes.bool,
    plan: PropTypes.object
};

export default PlanTile;
