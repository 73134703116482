import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import './App.scss';
import App from './App';

import * as serviceWorker from './serviceWorker';

/* reducers */
import authReducer from './store/reducers/auth';
import catReducer from './store/reducers/catalogues';
import configReducer from './store/reducers/config';
import csvReducer from './store/reducers/csv';
import cvlReducer from './store/reducers/cvl';
import diConfigReducer from './store/reducers/diConfig';
import diReducer from './store/reducers/diPlans';
import generalReducer from './store/reducers/general';
import emailReducer from './store/reducers/email';
import locationsReducer from './store/reducers/locations';
import pipelineReducer from './store/reducers/pipeline';
import planReducer from './store/reducers/plans';
import processPlanReducer from './store/reducers/processPlan';
import requestsReducer from './store/reducers/requests';
import scheduleReducer from './store/reducers/schedule';
import signalrReducer from './store/reducers/signalr';
import trailersReducer from './store/reducers/trailers';
import trunkScheduleReducer from './store/reducers/trunkSchedule';
import userReducer from './store/reducers/userSite';
import userSiteReducer from './store/reducers/userSite';

/* auth */
import { authContext } from './adalConfig';
import { runWithAdal } from 'react-adal';

// import * as SignalR from '@aspnet/signalr';

/* middleware */
import { signalRInvokeMiddleware, signalRRegisterCommands } from './store/actions/signalr';
// import { HubConnectionBuilder } from '@aspnet/signalr';

/* eslint-disable-next-line no-undef*/
const buildType = window._env_.REACT_APP_BUILD_TYPE;
/*

need to install redux devtools for this to work.
visit: https://github.com/zalmoxisus/redux-devtools-extension
*/
const composeEnhancers =
    /* eslint-disable-next-line no-undef */
    process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

// /* eslint-disable-next-line no-undef */
// const hubConnectURL = process.env.REACT_APP_SERVER_URL + '/planHub';
// /* eslint-disable-next-line no-unused-vars */
// const hubConnect = new HubConnectionBuilder().withUrl(hubConnectURL).build();
// // const hubConnect = new SignalR.HubConnection(hubConnectURL);

const rootReducers = combineReducers({
    auth: authReducer,
    catalogues: catReducer,
    config: configReducer,
    csv: csvReducer,
    cvl: cvlReducer,
    di: diReducer,
    diConfig: diConfigReducer,
    email: emailReducer,
    general: generalReducer,
    requests: requestsReducer,
    locations: locationsReducer,
    pipeline: pipelineReducer,
    plan: planReducer,
    processPlan: processPlanReducer,
    schedule: scheduleReducer,
    trailers: trailersReducer,
    trunkSchedule: trunkScheduleReducer,
    user: userReducer,
    userSite: userSiteReducer,
    signalr: signalrReducer
});

const middleware = [thunk, signalRInvokeMiddleware];

const store = createStore(rootReducers, composeEnhancers(applyMiddleware(...middleware)));

const app = (
    <BrowserRouter>
        <Provider store={store}>
            <App buildType={buildType} />
        </Provider>
    </BrowserRouter>
);

signalRRegisterCommands(store, () =>
    runWithAdal(
        authContext,
        () => {
            ReactDOM.render(app, document.getElementById('root'));
        },
        false
    )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
