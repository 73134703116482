import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../../axios';

import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';

import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';

import PlanTile from './PlanTile';

import { Accordion, AccordionItem } from '@jsluna/accordion';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { Card } from '@jsluna/card';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';

import FailedModal from './FailedModal/FailedModal';

import { actionStatus } from './Pipeline/ActionStatus';

import moment from 'moment';
import './Plan.scss';
import { ListGroup } from '@jsluna/list';

moment.locale('en-GB');

const PlanIndex = props => {
    const [deleteId, setDeleteId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { deletePlanSuccess, getPlansError, history, isAuthenticated, onDeletePlanReset, onGetPlans, planError, plans } = props;
    let growl;

    useEffect(() => {
        onGetPlans();
    }, [onGetPlans]);

    useEffect(() => {
        if (getPlansError) growl.show({ severity: 'error', summary: 'Error retrieving plan(s)' });
    }, [plans, getPlansError, growl]);

    useEffect(() => {
        if (planError !== null && planError) {
            console.error(planError);
            growl.show({ severity: 'error', summary: planError });
        }
    }, [growl, planError]);

    const handleAddEditPlan = id => {
        if (!id || id === null) {
            history.push('/plan');
            return;
        }

        let plan;
        for (let w = 0; w < plans.length; w++) {
            for (let g = 0; g < plans[w].length; g++) {
                plan = plans[w][g].find(p => p.id === id);
                if (plan) break;
            }
            if (plan) break;
        }

        if (plan && plan.status === actionStatus.error) {
            setDeleteId(id);
            setShowModal(true);
        } else history.push(`/plan/${id}`);
    };

    const handleConfigClick = () => history.push('configuration');

    const planList = () => {
        if (!plans || plans === null || plans.length === 0) return null;

        return plans.map(week => {
            let planCount = 0;
            week.forEach(d => (planCount += d.length));

            const obj = week; // [ind];

            // week.map(day => {

            const wcDate = moment(week[0][0].wcDate.substring(0, 10));
            const isOpen =
                wcDate ===
                moment()
                    .startOf('week')
                    .format('DD/MM/YYYY');
            return (
                <AccordionItem
                    className="planHeader ln-u-border-sides"
                    defaultOpen={isOpen}
                    key={wcDate.format('YYYY-MM-DD')}
                    id={wcDate.format('YYYY-MM-DD')}
                    title={`WC: ${wcDate.format('DD/MM/YYYY')} (${planCount} Plan${planCount > 1 ? 's' : ''})`}
                >
                    <GridWrapper horizontalAlign="center">
                        {[0, 1, 2, 3, 4, 5, 6].map((d, ind) => {
                            const date = moment(wcDate).add(d, 'days');
                            let dayPlans = [];

                            obj.forEach(dd => (dayPlans = dayPlans.concat(dd.filter(m => m.date.substring(0, 10) === date.format('YYYY-MM-DD')))));

                            return (
                                <GridItem key={ind} size="1/8">
                                    <h3 style={{ textAlign: 'center' }}>{date.format('ddd')}</h3>
                                    {Object.values(dayPlans).length === 0 ? null : (
                                        <ListGroup actionable className="ln-u-text-align-center">
                                            {Object.values(dayPlans).map((plan, ind) => {
                                                return (
                                                    <PlanTile
                                                        key={ind.toString()}
                                                        plan={plan}
                                                        click={id => handleAddEditPlan(id)}
                                                        last={dayPlans.length === ind + 1}
                                                    />
                                                );
                                            })}
                                        </ListGroup>
                                    )}
                                </GridItem>
                            );
                        })}
                    </GridWrapper>
                </AccordionItem>
            );
        });
    };

    const display = () => {
        if (!plans || plans === null || plans.length === 0) {
            return (
                <p>
                    No plans exist, click the
                    <Button
                        style={{ marginLeft: '0.4rem', marginRight: '0.4rem' }}
                        onClick={() => handleAddEditPlan(null)}
                        icon="pi pi-plus"
                        tooltip="Create New Plan"
                        tooltipOptions={{ position: 'top' }}
                    />
                    icon to create a new plan
                </p>
            );
        } else return <Accordion titleElement="h3">{planList()}</Accordion>;
    };

    const handleClose = () => {
        props.onDeletePlan(deleteId);
        setShowModal(false);
    };

    useEffect(() => {
        if (deletePlanSuccess) {
            window.location.reload();
            onDeletePlanReset();
        }
    }, [deletePlanSuccess, onDeletePlanReset]);

    const page = () => {
        if (!isAuthenticated)
            return (
                <ProgressIndicator page loading={true}>
                    <ProgressSpinner />
                    Loading...
                </ProgressIndicator>
            );
        else
            return (
                <Card>
                    <GridWrapper>
                        <GridItem size="1/4">
                            <h2>Plan Index</h2>
                        </GridItem>
                        <GridItem size="2/8" element="li" offsetRight={{ sm: '4/8', md: '4/8' }} style={{ textAlign: 'right' }}>
                            <Button
                                style={{ marginLeft: '3rem' }}
                                onClick={() => handleAddEditPlan(null)}
                                icon="pi pi-plus"
                                tooltip="Create New Plan"
                                tooltipOptions={{ position: 'left' }}
                            />
                            <Button
                                style={{ marginLeft: '3rem' }}
                                onClick={handleConfigClick}
                                icon="pi pi-cog"
                                tooltip="Go to Base Configuration"
                                tooltipOptions={{ position: 'left' }}
                            />
                        </GridItem>
                    </GridWrapper>
                    {display()}

                    <FailedModal open={showModal} handleClose={handleClose} />
                </Card>
            );
    };

    return (
        <Fragment>
            {page()}
            <Growl className="customGrowl" ref={el => (growl = el)}></Growl>
        </Fragment>
    );
};

PlanIndex.propTypes = {
    deletePlanSuccess: PropTypes.bool,
    getPlansError: PropTypes.bool,
    history: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
    onDeletePlan: PropTypes.func,
    onDeletePlanReset: PropTypes.func,
    onGetPlans: PropTypes.func,
    planError: PropTypes.string,
    plans: PropTypes.array
};

const mapStateToProps = state => {
    return {
        deletePlanSuccess: state.plan.deletePlanSuccess,
        plans: state.plan.plans,
        planError: state.plan.planError,
        getPlansError: state.plan.getPlansError,
        isAuthenticated: state.auth.givenName !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDeletePlan: planId => dispatch(actions.deletePlan(planId)),
        onDeletePlanReset: () => dispatch(actions.deletePlanReset()),
        onGetPlans: () => dispatch(actions.getPlans())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(PlanIndex, axios));
