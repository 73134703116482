import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import axios from '../../../axios';

import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';

import * as actions from '../../../store/actions/index';

import { TextButton } from '@jsluna/button';
import { HeaderDivider } from '@jsluna/header';
import { Account, Home } from '@jsluna/icons';
import { Modal, ModalHeading } from '@jsluna/modal';
import { SelectField } from '@jsluna/form';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton } from '@jsluna/button';

const User = props => {
    const [currentSite, setCurrentSite] = useState([]);
    const [siteSelectionState, setSiteSelectionState] = useState(false);
    const siteSelectionHandler = () => setSiteSelectionState(!siteSelectionState);
    const { onGetSites, userSites } = props;

    useEffect(() => {
        onGetSites();
    }, [onGetSites]);

    useEffect(() => {
        const updatedObject = { ...userSites };
        let mySite = '';
        if (currentSite.length === 0 && Object.keys(updatedObject).length > 0) {
            mySite = updatedObject.length === 0 ? 'Login' : Object.values(updatedObject).filter(m => m.isActive)[0];
            setCurrentSite(mySite);
            userSites.forEach(v => delete v.isActive);
        }
    }, [currentSite, userSites]);

    const changeSiteHandler = () => {
        siteSelectionHandler();
        props.onUpdateSite(currentSite.value);
    };

    const handleChange = event => {
        const index = event.nativeEvent.target.selectedIndex;
        const option = event.nativeEvent.target[index];
        setCurrentSite(option);
        // this.setState({ tempSiteId: option.value });
    };

    // const siteSelection = () => {
    //     if (props.userSites.length === 0) {
    //         return <p>No Sites</p>;
    //     }
    //     else {
    //         return <SelectField
    //             style={{ width: '200px' }}
    //             name="siteSelection"
    //             label="Select Site"
    //             defaultValue={!currentSite.value ? '' : currentSite.value.toString()}
    //             options={props.userSites}
    //             onChange={handleChange} />;
    //     }
    // };

    const siteStyle = { display: props.showSite ? 'inline-flex' : 'none' };

    return (
        <div className={props.classes} style={{ alignItems: 'right' }}>
            <div style={siteStyle}>
                <TextButton onClick={siteSelectionHandler} onMouseDown={props.keyUp} onKeyUp={e => (e.keyCode === 13 || e.keyCode === 32 ? props.keyUp : null)}>
                    <span className="ln-u-visually-hidden">Current Site</span>
                    {!currentSite ? '' : currentSite.label} <Home />
                </TextButton>
                <HeaderDivider />
            </div>
            <TextButton onClick={props.click} onMouseDown={props.keyUp} onKeyUp={e => (e.keyCode === 13 || e.keyCode === 32 ? props.keyUp : null)}>
                <span className="ln-u-visually-hidden">Account</span>
                {props.fullName} <Account />
            </TextButton>
            <Modal small restrictClose handleClose={siteSelectionHandler} open={siteSelectionState}>
                <ModalHeading element="h3">Change Site</ModalHeading>
                <div>
                    <SelectField
                        style={{ display: 'block' }}
                        name="siteSelection"
                        label="Select Site"
                        defaultValue={!currentSite ? '' : !currentSite.value ? '' : currentSite.value.toString()}
                        options={props.userSites}
                        onChange={handleChange}
                    />
                </div>
                <ButtonGroupWrapper actionBar>
                    <ButtonGroupPrimary>
                        <FilledButton onClick={changeSiteHandler}>Apply</FilledButton>
                    </ButtonGroupPrimary>
                    <ButtonGroupSecondary>
                        <TextButton onClick={siteSelectionHandler}>Cancel</TextButton>
                    </ButtonGroupSecondary>
                </ButtonGroupWrapper>
            </Modal>
        </div>
    );
};

User.propTypes = {
    classes: PropTypes.string,
    click: PropTypes.func,
    fullName: PropTypes.string,
    keyUp: PropTypes.func,
    onGetSites: PropTypes.func,
    onUpdateSite: PropTypes.func,
    showSite: PropTypes.bool,
    userSites: PropTypes.array
};

User.defaults = {
    showSite: false
};

const mapStateToProps = state => {
    return {
        fullName: state.auth.fullName,
        userSites: state.user.userSites
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: () => dispatch(actions.auth()),
        onLogout: () => dispatch(actions.logout()),
        onGetSites: () => dispatch(actions.getUserSites()),
        onUpdateSite: site => dispatch(actions.updateUserSite(site))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(User, axios));
