export const scheduleColumns = {
    columns: [
        {
            header: 'Location No',
            field: 'locationNo',
            className: 'ln-u-text-align-center',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange'
        },
        {
            header: '   Location Name',
            field: 'locationName',
            className: 'ln-u-text-align-left',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange',
            style: [{ width: '170px' }]
        },
        {
            header: 'Pick Time',
            field: 'svcPickTime',
            className: 'ln-u-text-align-center',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange'
        },
        {
            header: 'Depart Time',
            field: 'svcDepartTime',
            className: 'ln-u-text-align-center',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange'
        },
        {
            header: 'Window',
            field: 'dailyPattern',
            className: 'ln-u-text-align-center',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange'
        },
        {
            header: 'Del No',
            field: 'delNo',
            className: 'ln-u-text-align-center',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange'
        },
        {
            header: 'Store Max',
            field: 'storeTrailerMax',
            className: 'ln-u-text-align-center',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange'
            // editor: 'editorForRowEditing'
        },
        {
            header: 'Estimate',
            field: 'estimate',
            className: 'ln-u-text-align-center',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange'
        },
        {
            header: 'Warehouse Comments',
            field: 'warehouseComments',
            className: 'ln-u-text-align-left',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange',
            editor: 'editorForRowEditing',
            detail: true
        },
        {
            header: 'Transport Comments',
            field: 'transportComments',
            className: 'ln-u-text-align-left',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange',
            editor: 'editorForRowEditing',
            detail: true
        },
        {
            header: 'Destination Comments',
            field: 'destinationComments',
            className: 'ln-u-text-align-left',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange',
            editor: 'editorForRowEditing',
            detail: true
        },
        {
            header: 'Other Comments',
            field: 'otherComments',
            className: 'ln-u-text-align-left',
            filter: 'true',
            filterMatchMode: 'contains',
            filterElement: 'onFilterChange',
            editor: 'editorForRowEditing',
            detail: true
        }
    ]
};
