import React, { Fragment, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Card } from '@jsluna/card';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { Modal, ModalHeading } from '@jsluna/modal';
import 'react-dates/initialize';

import { ScrollPanel } from 'primereact/scrollpanel';

import { connect } from 'react-redux';
import axios from '../../../axios';

import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
// import * as actions from '../../../store/actions/index';

import { updateObject } from '../../../shared/utility';

import Input from '../../UI/Input/Input';

import classes from './AddAdHocVolume.module.scss';

const AddAdHocVolume = props => {
    const { formDetails, onUpdateRecord, recordToEdit, handleClose, recordSaveSuccess, recordSaving, onUpdateRecordReset } = props;

    const [formRecord, setformRecord] = useState(null);
    const [validation, setValidation] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState({ show: false, title: 'Confirmation', message: 'Are you sure?' });

    const confirmationModalDefault = { show: false, title: 'Confirmation', message: 'Are you sure?' };

    useEffect(() => {
        if (formDetails && !formRecord) {
            setformRecord(formDetails.record);
            const newValidation = {};
            const isNewRecord = true;

            formDetails.options.fieldOptions.forEach(f => {
                newValidation[f.shortName] = !isNewRecord;
            });

            setValidation(newValidation);
        }
    }, [formRecord, formDetails]);

    useEffect(() => {
        if (recordSaving === false) {
            if (recordSaveSuccess === true && recordToEdit) {
                onUpdateRecordReset();
                setformRecord(null);
                handleClose(true, recordToEdit);
            } else if (recordSaveSuccess === false) {
                handleClose(false, null, 'Error saving record.');
            }
        }
    }, [onUpdateRecord, recordSaveSuccess, recordSaving, handleClose, onUpdateRecordReset, formRecord, recordToEdit]);

    const confirmationModal = (
        <Modal small handleClose={() => setShowConfirmationModal(confirmationModalDefault)} open={showConfirmationModal.show}>
            <ModalHeading element="h3">{showConfirmationModal.title}</ModalHeading>
            <p>{showConfirmationModal.message}</p>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={() => handleClose()}>Yes</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={() => setShowConfirmationModal(confirmationModalDefault)}>No</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );

    const handleChange = (field, value, isOption) => {
        if (isOption) {
            const newFields = updateObject(formRecord.fields, {
                [field]: value
            });
            const newRecord = updateObject(formRecord, {
                fields: newFields
            });
            setformRecord(newRecord);
        } else {
            const newRecord = updateObject(formRecord, {
                [field]: value
            });
            setformRecord(newRecord);
        }
        setHasChanges(true);
    };

    const closeForm = () => {
        if (hasChanges) {
            setShowConfirmationModal({ show: true, title: 'Outstanding Changes', message: 'All changes will be lost, are you sure?' });
        } else handleClose();
    };

    const saveForm = () => {
        let formValid = true;
        if (
            Object.keys(validation).forEach(r => {
                const fo = formDetails.options.fieldOptions.filter(fo => fo.shortName === r)[0];
                if (fo && !validation[fo.shortName]) {
                    formValid = false;
                }
            })
        );

        if (formValid === true) {
            if (hasChanges) {
                formDetails.saveFunction(formRecord);
                formDetails.saveResetFunction();
                // onUpdateRecord(formRecord);
                // onUpdateRecordReset();
            } else handleClose(false, null, 'No changes have been made.');
        } else {
            handleClose(false, null, 'Required fields empty or invalid.');
        }
    };

    const onFieldValidate = (field, valid) => {
        const newValidation = updateObject(validation, { [field]: valid });
        setValidation(newValidation);
    };

    const formContent = () => {
        if (!formRecord) {
            return (
                <GridItem size="1/1">
                    <span></span>
                </GridItem>
            );
        }
        return formDetails.options.fieldOptions.map((d, i) => {
            return (
                <GridItem key={i} size={d.fieldTypeName === 'autocomplete' || d.fieldTypeName === 'select' ? '1/1' : '1/2'}>
                    <GridWrapper>
                        <GridItem size={d.fieldTypeName === 'autocomplete' || d.fieldTypeName === 'select' ? '1/4' : '2/4'} className="ln-u-text-align-right">
                            <p>{`${d.name}${d.isRequired ? '*' : ''}`}</p>
                        </GridItem>
                        <GridItem size={d.fieldTypeName === 'autocomplete' || d.fieldTypeName === 'select' ? '3/4' : '2/4'}>
                            <Input
                                label={d.name}
                                hideLabel={true}
                                fieldType={d.fieldTypeName}
                                onChange={(field, value) => handleChange(field, value, true)}
                                options={d.options}
                                name={d.shortName}
                                isRequired={d.isRequired}
                                value={formRecord.fields[d.shortName]}
                                onValidate={(field, valid) => onFieldValidate(field, valid)}
                            />
                        </GridItem>
                    </GridWrapper>
                </GridItem>
            );
        });
    };
    const mainContent = !formRecord ? null : <GridWrapper className={classes.textAlignVerticalCenter}>{formContent()}</GridWrapper>;
    return (
        <Card style={{ padding: 0, marginBottom: 0 }}>
            <Fragment>
                <h3 element="h3">{formRecord && formRecord.id > 0 ? 'Edit' : 'Add'} Adhoc Volume</h3>
                <ScrollPanel className="custombar1" style={{ width: '100%', height: '400px' }}>
                    {mainContent}
                </ScrollPanel>
                <p>* = Required Field</p>
            </Fragment>
            <ButtonGroupWrapper style={{ paddingTop: 0, marginBottom: 0 }}>
                <ButtonGroupPrimary style={{ paddingTop: 0, marginBottom: 0 }}>
                    <FilledButton onClick={() => saveForm()}>Save</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary style={{ paddingTop: 0, marginBottom: 0 }}>
                    <TextButton onClick={() => closeForm()}>Cancel</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
            {confirmationModal}
        </Card>
    );
};

const mapStateToProps = state => {
    return {
        recordToEdit: state.requests.preDefinedRoute,
        recordSaveSuccess: state.requests.preDefinedRouteSuccess,
        recordSaving: state.requests.preDefinedRouteUpdating
    };
};

AddAdHocVolume.propTypes = {
    formDetails: PropTypes.object,
    handleClose: PropTypes.func,
    onUpdateRecord: PropTypes.func,
    onUpdateRecordReset: PropTypes.func,
    recordSaveSuccess: PropTypes.bool,
    recordSaving: PropTypes.bool,
    recordToEdit: PropTypes.object
};

// const mapDispatchToProps = dispatch => {
//     return {
//         /*
//         onUpdateRecord: data => dispatch(actions.updatePreDefinedRoute(data)),
//         onUpdateRecordReset: () => dispatch(actions.updatePreDefinedRouteReset())
//         */
//     };
// };

export default connect(mapStateToProps, null)(withErrorHandler(AddAdHocVolume, axios));
