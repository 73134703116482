import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { GridItem, GridWrapper } from '@jsluna/grid';

import classes from './CvlRow.module.scss';

const CvlRow = props => {
    const title = `${classes.bold} ln-u-text-align-center`;
    const data = props.data;

    return (
        <Fragment>
            <GridWrapper>
                {/* <GridItem size="1/12" className={title}>
                CSO Lines
            </GridItem>
            <GridItem size="1/12" className={title}>
                A Lines
            </GridItem>
            <GridItem size="1/12" className={title}>
                B Lines
            </GridItem>
            <GridItem size="1/12" className={title}>
                C Lines
            </GridItem> */}
                <GridItem size="1/12" className={title}>
                    CSO Units
                </GridItem>
                <GridItem size="1/12" className={title}>
                    A Units
                </GridItem>
                <GridItem size="1/12" className={title}>
                    B Units
                </GridItem>
                <GridItem size="1/12" className={title}>
                    C Units
                </GridItem>
                <GridItem size="1/12" className={title}>
                    CSO ULCs
                </GridItem>
                <GridItem size="1/12" className={title}>
                    CSO+A ULCs
                </GridItem>
                <GridItem size="1/12" className={title}>
                    CSO+A+B ULCs
                </GridItem>
                <GridItem size="1/12" className={title}>
                    CSO+A+B+C ULCs
                </GridItem>
                <GridItem size="1/12" className={title}>
                    CSO Cube
                </GridItem>
                {/* <GridItem size="1/12" className="ln-u-text-align-center">
                {data.servLinesCSO}
            </GridItem>
            <GridItem size="1/12" className="ln-u-text-align-center">
                {data.servLinesA}
            </GridItem>
            <GridItem size="1/12" className="ln-u-text-align-center">
                {data.servLinesB}
            </GridItem>
            <GridItem size="1/12" className="ln-u-text-align-center">
                {data.servLinesC}
            </GridItem> */}
            </GridWrapper>
            <GridWrapper>
                <GridItem size="1/12" className="ln-u-text-align-center">
                    {data.servItemsCSO}
                </GridItem>
                <GridItem size="1/12" className="ln-u-text-align-center">
                    {data.servItemsA}
                </GridItem>
                <GridItem size="1/12" className="ln-u-text-align-center">
                    {data.servItemsB}
                </GridItem>
                <GridItem size="1/12" className="ln-u-text-align-center">
                    {data.servItemsC}
                </GridItem>
                <GridItem size="1/12" className="ln-u-text-align-center">
                    {data.servUlcCSO}
                </GridItem>
                <GridItem size="1/12" className="ln-u-text-align-center">
                    {data.servUlcA}
                </GridItem>
                <GridItem size="1/12" className="ln-u-text-align-center">
                    {data.servUlcB}
                </GridItem>
                <GridItem size="1/12" className="ln-u-text-align-center">
                    {data.servUlcC}
                </GridItem>
                <GridItem size="1/12" className="ln-u-text-align-center">
                    {data.csoUlcCube}
                </GridItem>
            </GridWrapper>
        </Fragment>
    );
};

CvlRow.propTypes = {
    data: PropTypes.object
};

export default CvlRow;
