import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeading } from '@jsluna/modal';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { Info } from '@jsluna/icons';

const ConfirmationDialog = props => {
    const { cancelButton, confirmAction, confirmButton, fullScreen, handleClose, heading, icon, iconGridLeft, iconGridRight, message, small, open } = props;

    const iconWrapper = (
        <GridWrapper>
            <GridItem size={iconGridLeft}>{icon}</GridItem>
            <GridItem size={iconGridRight} className="ln-u-push-bottom">
                {message}
            </GridItem>
        </GridWrapper>
    );

    const content = icon ? iconWrapper : <p>{message}</p>;

    return (
        <Modal small={small} fullScreen={fullScreen} handleClose={handleClose} open={open}>
            <ModalHeading element="h3">{heading}</ModalHeading>
            {content}
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={confirmAction}>{confirmButton}</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={handleClose}>{cancelButton}</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );
};

ConfirmationDialog.propTypes = {
    cancelButton: PropTypes.string,
    confirmAction: PropTypes.func,
    confirmButton: PropTypes.string,
    fullScreen: PropTypes.bool,
    handleClose: PropTypes.func,
    heading: PropTypes.string,
    icon: PropTypes.node,
    iconGridLeft: PropTypes.string,
    iconGridRight: PropTypes.string,
    message: PropTypes.any,
    open: PropTypes.bool,
    small: PropTypes.bool
};

ConfirmationDialog.defaultProps = {
    cancelButton: 'Cancel',
    confirmButton: 'Yes',
    heading: 'Confirmation Required',
    icon: <Info />,
    iconGridLeft: '1/4',
    iconGridRight: '3/4',
    message: 'Click Yes to confirm'
};

export default ConfirmationDialog;
