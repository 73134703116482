import React from 'react';

import { GridItem, GridWrapper } from '@jsluna/grid';

import classes from './About.module.scss';

const About = () => {
    return (
        <GridWrapper className={classes.about}>
            <GridItem size="1/1">
                <h6>PLEX</h6>
            </GridItem>
            <GridItem size="1/1">
                <GridWrapper className={classes.content}>
                    <GridItem size="1/1">Planning and Execution</GridItem>
                    <GridItem size="1/1" className="ln-u-push-top">
                        v0.1 alpha
                    </GridItem>
                    <GridItem size="1/1">
                        19<sup>th</sup> December 2019
                    </GridItem>
                    <GridItem size="1/1">
                        <a href="mailto:SolutionDevelopment@homeretail.onmicrosoft.com">Solution Development</a>
                    </GridItem>
                </GridWrapper>
            </GridItem>
        </GridWrapper>
    );
};

export default About;
