import React from 'react';

import './Intro.css';

const Intro = () => {
    return (
        <div className="logoWrapper">
            <svg id="logo" width="352" height="94" viewBox="0 0 352 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M79.36 29.936C79.36 19.568 76.048 12.656 69.856 8.04802C62.944 2.86402 52.432 0.848022 37.6
                    0.848022H0.303986V14.96H9.08799V92H29.104V61.472H41.056C53.728 61.472 63.664 58.736 70.432 52.688C76.048 47.648 79.36 40.304
                    79.36 29.936ZM26.5 14.96H37.744C45.664 14.96 50.704 15.968 53.872 18.416C57.184 21.008 58.768 25.04 58.768 30.368C58.768
                    36.56 57.04 40.736 53.872 43.472C50.56 46.352 45.232 47.504 38.176 47.504H29.104V14.96Z"
                    stroke="#F06C00"
                    strokeWidth="3"
                />
                <path d="M124.309 75V0.848022H104.293V92H162.325L163.477 77.744H124.309Z" stroke="#F06C00" strokeWidth="3" />
                <path
                    d="M208.908 75V52.688H239.436V38.72H208.908V14.96H246.492V0.848022H181.26V14.96H188.892V92H248.508L249.804 77.744H208.908Z"
                    stroke="#F06C00"
                    strokeWidth="3"
                />
                <path
                    d="M351.963 76.88C350.235 78.176 347.787 79.04 345.915 79.04C342.891 79.04 341.163 77.6 338.859 73.856L322.155 46.208L349.083
                    0.848022H328.779L312.795 30.656L294.939 0.848022H272.331L299.403 44.624L271.179 92H291.915L309.051 60.032L321.723 81.344C326.475
                    89.408 331.083 93.152 339.435 93.152C343.899 93.152 348.651 91.568 351.099 89.84L351.963 76.88Z"
                    stroke="#F06C00"
                    strokeWidth="3"
                />
            </svg>

            <svg id="logo-full" width="1077" height="50" viewBox="0 0 1077 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M58.24 30.624C58.24 23.712 56.032 19.104 51.904 16.032C47.296 12.576 40.288 11.232 30.4
                    11.232H5.53601V20.64H11.392V72H24.736V51.648H32.704C41.152 51.648 47.776 49.824 52.288 45.792C56.032 42.432 58.24 37.536 58.24
                    30.624ZM24.736 20.64H30.496C35.776 20.64 39.136 21.312 41.248 22.944C43.456 24.672 44.512 27.36 44.512 30.912C44.512 35.04 43.36
                    37.824 41.248 39.648C39.04 41.568 35.488 42.336 30.784 42.336H24.736V20.64Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M70.252 6.33601V15.36H75.82V61.824C75.82 64.896 76.3 67.584 77.548 69.312C79.084 71.424 81.772 72.576 85.708 72.576C89.164
                    72.576 92.332 71.424 94.156 70.176L94.54 62.88C93.772 63.456 92.044 64.128 90.892 64.128C89.356 64.128 88.588 63.264 88.588
                    61.248V9.408C88.588 7.392 87.82 6.33601 85.612 6.33601H70.252Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M155.611 62.88C154.843 63.456 153.019 64.128 151.867 64.128C150.331 64.128 149.659 63.264 149.659 61.248V41.376C149.659 36
                    148.411 32.16 145.531 29.472C141.979 26.112 136.603 24.576 129.307 24.576C123.547 24.576 117.787 25.632 113.371
                    27.648V36.288C117.883 33.984 122.779 32.832 127.291 32.832C131.035 32.832 133.627 33.696 135.067 35.328C136.507 36.96 136.891
                    38.88 136.891 41.952V43.2C134.491 42.336 130.843 41.664 127.483 41.664C116.539 41.664 109.243 47.04 109.243 57.408C109.243
                    67.392 115.867 72.864 125.755 72.864C130.939 72.864 135.835 70.944 138.331 67.68C139.387 70.944 142.651 72.576 146.587
                    72.576C150.043 72.576 153.211 71.52 155.131 70.176L155.611 62.88ZM136.891 56.544C136.891 59.04 136.219 61.056 134.971
                    62.4C133.723 63.744 131.707 64.608 129.403 64.608C124.987 64.608 122.107 61.92 122.107 56.832C122.107 52.32 124.699 48.96
                    130.267 48.96C132.667 48.96 135.451 49.536 136.891 50.112V56.544Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M182.25 28.32C181.866 26.112 180.906 25.44 178.89 25.44H167.274V34.464H172.842V72H185.61V45.312C185.61 41.76 185.994 39.168
                    187.53 37.152C188.874 35.424 190.986 34.176 194.058 34.176C196.842 34.176 198.858 35.136 200.106 36.768C201.354 38.496 201.642
                    40.512 201.642 43.584V72H214.41V41.568C214.41 36.288 213.354 32.256 210.378 29.088C207.498 26.112 203.082 24.576
                    197.61 24.576C190.89 24.576 185.802 27.264 182.73 31.392L182.25 28.32Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M245.381 28.32C244.997 26.112 244.037 25.44 242.021 25.44H230.405V34.464H235.973V72H248.741V45.312C248.741 41.76 249.125
                    39.168 250.661 37.152C252.005 35.424 254.117 34.176 257.189 34.176C259.973 34.176 261.989 35.136 263.237 36.768C264.485
                    38.496 264.773 40.512 264.773 43.584V72H277.541V41.568C277.541 36.288 276.485 32.256 273.509 29.088C270.629 26.112 266.213
                    24.576 260.741 24.576C254.021 24.576 248.933 27.264 245.861 31.392L245.381 28.32Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M312.64 12.768C312.64 8.832 309.568 5.664 305.632 5.664C301.696 5.664 298.528 8.832 298.528 12.768C298.528 16.704 301.792
                    19.872 305.728 19.872C309.664 19.872 312.64 16.704 312.64 12.768ZM299.584 34.464V61.824C299.584 64.896 300.064 67.584 301.312
                    69.312C302.848 71.424 305.536 72.576 309.472 72.576C312.928 72.576 316.096 71.424 317.92 70.176L318.304 62.88C317.536 63.456
                    315.808 64.128 314.656 64.128C313.12 64.128 312.352 63.264 312.352 61.248V28.512C312.352 26.496 311.584 25.44 309.376
                    25.44H294.016V34.464H299.584Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M346.143 28.32C345.759 26.112 344.799 25.44 342.783 25.44H331.167V34.464H336.735V72H349.503V45.312C349.503 41.76
                    349.887 39.168 351.423 37.152C352.767 35.424 354.879 34.176 357.951 34.176C360.735 34.176 362.751 35.136 363.999
                    36.768C365.247 38.496 365.535 40.512 365.535 43.584V72H378.303V41.568C378.303 36.288 377.247 32.256 374.271 29.088C371.391
                    26.112 366.975 24.576 361.503 24.576C354.783 24.576 349.695 27.264 346.623 31.392L346.143 28.32Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M439.227 27.36C434.714 25.728 427.899 24.576 422.427 24.576C406.299 24.576 395.451 34.176 395.451 50.688C395.451 64.32
                    402.554 72.192 414.842 72.192C420.026 72.192 424.251 70.176 426.459 67.584V71.52C426.459 76.032 425.787 78.816 423.867
                    80.736C422.139 82.464 419.259 83.232 415.803 83.232C410.715 83.232 404.955 81.6 400.539 78.912L399.771 88.32C404.283
                    90.72 411.387 92.352 417.627 92.352C425.307 92.352 431.259 90.432 434.811 86.304C437.979 82.656 439.227 78.24 439.227
                    70.272V27.36ZM426.459 53.664C426.459 56.64 425.883 59.04 424.347 60.672C422.907 62.304 420.699 63.264 417.915
                    63.264C411.867 63.264 408.411 58.656 408.411 49.824C408.411 39.456 413.595 33.024 422.139 33.024C423.675 33.024 425.403
                    33.312 426.459 33.696V53.664Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M529.478 41.952C529.478 46.56 528.806 50.016 527.27 52.992L515.174 40.128C524.006 35.52 527.942 30.336 527.942
                    23.808C527.942 16.896 521.414 10.176 510.566 10.176C499.43 10.176 491.558 16.704 491.558 25.152C491.558 29.376 493.19
                    33.408 497.99 38.88C489.83 43.296 485.798 48.768 485.798 55.584C485.798 65.472 494.246 72.96 508.55 72.96C514.214
                    72.96 520.454 71.52 525.83 68.16C529.382 71.328 532.55 72.96 537.158 72.96C540.902 72.96 543.878 71.808 546.086
                    70.08L546.854 61.92C544.742 63.168 542.438 64.032 540.422 64.032C538.502 64.032 536.774 62.88 534.278 60.192C537.062
                    55.968 538.694 50.88 538.886 45.696H545.798V36.672H532.07C530.054 36.672 529.478 37.344 529.478 39.36V41.952ZM509.99
                    63.552C503.558 63.552 499.046 59.712 499.046 54.432C499.046 50.784 500.582 47.808 504.326 45.408L519.11 61.248C516.518
                    62.688 513.254 63.552 509.99 63.552ZM504.038 24.864C504.038 21.408 506.63 19.008 510.182 19.008C513.254 19.008 515.846
                    21.216 515.846 24.672C515.846 28.32 513.446 31.584 508.646 33.984C505.094 30.432 504.038 27.744 504.038 24.864Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M608.818 62.496V45.792H629.17V36.48H608.818V20.64H633.874V11.232H590.386V20.64H595.474V72H635.218L636.082 62.496H608.818Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M694.492 62.88C693.724 63.36 692.092 64.032 690.652 64.032C688.828 64.032 687.772 63.168 686.716 61.536L678.46
                    48.576L692.572 25.44H681.244L672.796 40.032L663.388 25.44H650.14L664.54 47.712L649.564 72H661.18L670.396 56.448L675.964
                    65.28C679.516 70.848 682.588 72.576 687.004 72.576C689.788 72.576 692.476 71.616 694.108 70.464L694.492 62.88Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M749.369 45.984C749.369 33.024 740.921 24.576 728.249 24.576C715.193 24.576 706.265 33.6 706.265 48.96C706.265 63.456
                    714.521 72.864 729.401 72.864C735.641 72.864 742.361 71.232 746.777 68.064L747.257 59.328C743.225 62.112 737.369 64.032
                    731.993 64.032C724.889 64.032 720.281 60.384 719.417 52.896H743.609C747.929 52.896 749.369 50.976 749.369 45.984ZM737.081
                    44.64C737.081 45.312 736.889 45.6 736.217 45.6H719.225C719.609 36.96 723.353 33.216 728.249 33.216C733.721 33.216 737.081
                    37.344 737.081 44.64Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M803.019 27.456C799.563 25.92 794.859 24.576 789.195 24.576C774.699 24.576 764.427 33.696 764.427 48.864C764.427 64.512
                    772.971 72.864 787.947 72.864C793.803 72.864 799.563 71.136 803.499 68.544L803.979 59.136C799.947 61.824 794.955 63.552
                    790.155 63.552C782.763 63.552 777.387 59.616 777.387 48.768C777.387 38.496 782.283 33.792 790.347 33.792C794.667 33.792
                    798.507 34.848 802.443 36.864L803.019 27.456Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M868.197 62.88C867.429 63.456 865.701 64.128 864.549 64.128C863.013 64.128 862.245 63.264 862.245
                    61.248V25.44H849.477V52.128C849.477 55.68 849.093 58.272 847.557 60.288C846.213 62.016 844.101 63.264 841.029
                    63.264C838.245 63.264 836.229 62.304 834.981 60.672C833.733 58.944 833.445 56.928 833.445 53.856V25.44H820.677V55.872C820.677
                    61.152 821.733 65.184 824.709 68.352C827.589 71.328 832.005 72.864 837.477 72.864C843.237 72.864 847.845 70.944 850.917
                    67.776C852.165 71.04 855.525 72.576 859.173 72.576C862.533 72.576 865.605 71.52 867.717 70.08L868.197 62.88Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M913.363 61.248C911.155 62.592 908.179 63.744 905.491 63.744C901.555 63.744 900.403 61.344 900.403
                    56.928V34.464H911.155V25.44H900.403V10.752L887.635 13.92V25.44L880.339 27.264V34.464H887.635V57.12C887.635 63.168 888.499
                    66.528 890.899 69.12C893.107 71.424 896.947 72.864 901.459 72.864C905.779 72.864 910.387 71.616 913.075 69.792L913.363 61.248Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M946.09 12.768C946.09 8.832 943.018 5.664 939.082 5.664C935.146 5.664 931.978 8.832 931.978 12.768C931.978 16.704
                    935.242 19.872 939.178 19.872C943.114 19.872 946.09 16.704 946.09 12.768ZM933.034 34.464V61.824C933.034 64.896 933.514
                    67.584 934.762 69.312C936.298 71.424 938.986 72.576 942.922 72.576C946.378 72.576 949.546 71.424 951.37 70.176L951.754
                    62.88C950.986 63.456 949.258 64.128 948.106 64.128C946.57 64.128 945.802 63.264 945.802 61.248V28.512C945.802 26.496
                    945.034 25.44 942.826 25.44H927.466V34.464H933.034Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M1011.08 48.768C1011.08 33.696 1003.01 24.576 988.421 24.576C973.829 24.576 965.765 33.696 965.765 48.768C965.765
                    63.84 973.829 72.864 988.421 72.864C1003.01 72.864 1011.08 63.84 1011.08 48.768ZM998.117 48.768C998.117 60.096 994.373
                    64.128 988.421 64.128C982.469 64.128 978.725 60.096 978.725 48.768C978.725 37.44 982.469 33.312 988.421 33.312C994.373
                    33.312 998.117 37.44 998.117 48.768Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
                <path
                    d="M1039.72 28.32C1039.34 26.112 1038.38 25.44 1036.36 25.44H1024.75V34.464H1030.32V72H1043.08V45.312C1043.08 41.76
                    1043.47 39.168 1045 37.152C1046.35 35.424 1048.46 34.176 1051.53 34.176C1054.32 34.176 1056.33 35.136 1057.58 36.768C1058.83
                    38.496 1059.12 40.512 1059.12 43.584V72H1071.88V41.568C1071.88 36.288 1070.83 32.256 1067.85 29.088C1064.97 26.112 1060.56
                    24.576 1055.08 24.576C1048.36 24.576 1043.28 27.264 1040.2 31.392L1039.72 28.32Z"
                    stroke="#4C4C4C"
                    strokeWidth="3"
                />
            </svg>
        </div>
    );
};

export default Intro;
